import React from 'react'
import No from '../../Assets/Images/Login/no.svg'
import './SellOrderDeliveryWarning.css'
import { Modal } from 'react-bootstrap'
import { ExclamationTriangleFill } from 'react-bootstrap-icons'

function SellOrderDeliveryWarning({
  isNotValidDelivState,
  setIsNotValidDelivState,
  textTitle,
  textDescription,
  editQuote,
  returnToOrder,
  onEditQuoteCallback,
}) {
  return (
    <Modal show={isNotValidDelivState} centered>
      <div className="sell-preorder-modal-box">
        <div className="sell-preorder-modal-close">
          <img
            src={No}
            alt="close-modal"
            onClick={() => setIsNotValidDelivState(false)}
          />
        </div>
        <div className="sell-preorder-page-container">
          <div className="sell-preorder-page-header">
            <ExclamationTriangleFill className="sell-preorder-page-warning-icon" />
            <h2>{textTitle}</h2>
          </div>
        </div>
        <div className="sell-preorder-page-body">
          <p>{textDescription}</p>
          <div className="sell-preorder-page-buttons">
            <button
              className="sell-preorder-page-buttons-active"
              onClick={() => {
                onEditQuoteCallback()
              }}>
              {editQuote}
            </button>
            <button
              className="sell-preorder-page-buttons-inactive"
              onClick={() => {
                setIsNotValidDelivState(false)
              }}>
              {returnToOrder}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SellOrderDeliveryWarning
