import React, { useState } from 'react'
import { NumericFormat } from 'react-number-format'
export const ColumnRow = ({ item, setListOfNewCosts }) => {
  const [value, setNewValue] = useState()

  const handleChangeCost = value => {
    setNewValue(value)

    setListOfNewCosts(prev =>
      prev.map(prevItem =>
        prevItem.productId === item.productId
          ? { ...prevItem, newCost: value }
          : prevItem
      )
    )
  }

  return (
    <tr>
      <td className="single-product-cart-a">{item.reference}</td>
      <td className="single-product-cart-a">{item.description}</td>
      <td className="single-product-cart-a">{item.quantity}</td>
      <td className="single-product-cart-a">{item.price}</td>
      <td className="single-product-cart-a">{item.cost}</td>
      <td className="single-product-cart-a">
        <NumericFormat
          decimalSeparator={'.'}
          decimalScale={6}
          name="NewCost"
          value={value}
          onChange={e => handleChangeCost(e.target.value)}
        />
      </td>
    </tr>
  )
}
