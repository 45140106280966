/* eslint-disable no-useless-catch */
import {
  algoliaQueryService,
  getFinalPriceMdl22Service,
  getProductPriceInventoryService,
} from 'redux/services/clientsServices'

export const getPriceInventory = (PriceProducts, Customer) => {
  return async (dispatch, getState) => {
    const { company } = getState()

    const companyId = company.CompanyId
    const companyCode = company.CompanyCode
    const WarehouseId = company.WarehouseId
    const payload = {
      CompanyId: companyId,
      CompanyCode: companyCode,
      WarehouseId,
      ProductIds: PriceProducts || [],
      PriceListId: Customer?.PriceListId ? Customer.PriceListId : null,
      UoMId: null,
    }

    try {
      const response = await getProductPriceInventoryService(payload)
      const { data, status } = response
      if (status === 200 && data?.Data) {
        return data.Data
      } else {
        return null
      }
    } catch (err) {
      return null
    }
  }
}

export const getPriceInventoryGeneral = payload => async () => {
  try {
    const response = await getProductPriceInventoryService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return response
    }
  } catch (err) {
    throw err
  }
}

export const getFinalPrice = (PriceProducts, Client) => {
  return async (dispatch, getState) => {
    const { company } = getState()

    const companyId = company.CompanyId
    const WarehouseId = company.WarehouseId

    const payload = {
      CompanyId: companyId,
      WarehouseId,
      ProductId: PriceProducts || [],
      CustomerId: Client?.CustomerId ? Client.CustomerId : null,
    }

    try {
      const response = await getFinalPriceMdl22Service(payload)
      const { data } = response
      if (response.status === 200 && data.Data) {
        return data.Data
      } else {
        return null
      }
    } catch (err) {
      throw err
    }
  }
}

export const algoliaQueryAction = payload => async () => {
  try {
    const response = await algoliaQueryService(payload)
    const { data, status } = response
    if (status === 200 && data) {
      return response
    }
  } catch (err) {
    console.error('Error algoliaQueryAction', err)
    throw err
  }
}
