import * as Yup from 'yup'
import { t } from 'i18next'
import { regexCompanyName, regexDV, regexName } from 'Utils/constants'

export const useValidationSchema = () => {
  const legalValidationSchema = Yup.object({
    legalName: Yup.string()
      .required(t('CREATE_CLIENT_T.INVALID_COMPANY_NAME'))
      .matches(regexCompanyName, t('CREATE_CLIENT_T.INVALID_COMPANY_NAME'))
      .nullable(),
    dv: Yup.string()
      .matches(regexDV, t('CREATE_CLIENT_T.INVALID_DV'))
      .nullable(),
  })

  const naturalValidationSchema = Yup.object({
    firstName: Yup.string()
      .matches(regexName, t('CREATE_CLIENT_T.INTRODUCE_VALID_NAME'))
      .required(t('CREATE_CLIENT_T.INTRODUCE_VALID_NAME'))
      .nullable(),
    lastName: Yup.string()
      .matches(regexName, t('CREATE_CLIENT_T.INTRODUCE_VALID_LAST_NAME'))
      .required(t('CREATE_CLIENT_T.INTRODUCE_VALID_LAST_NAME'))
      .nullable(),
    birthdate: Yup.string()
      .nullable()
      .test(
        'is-age-valid',
        t('CREATE_CLIENT_T.INTRODUCE_VALID_BIRTH_DATE'),
        value => {
          if (!value) return true
          const birthdate = new Date(value)
          const isOlderThan = limit =>
            new Date(
              new Date().getFullYear() - limit,
              new Date().getMonth(),
              new Date().getDate()
            )
          const isOlderThan18 = birthdate <= isOlderThan(18)
          const isYoungerThan100 = birthdate >= isOlderThan(100)
          return isOlderThan18 && isYoungerThan100
        }
      ),
    genderId: Yup.string()
      .required(t('CREATE_CLIENT_T.INTRODUCE_VALID_GENDER'))
      .nullable(),
    DV: Yup.string()
      .matches(regexDV, t('CREATE_CLIENT_T.INVALID_DV'))
      .nullable(),
  })

  return { naturalValidationSchema, legalValidationSchema }
}
