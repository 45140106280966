import React from 'react'
import No from '../../Assets/Images/Login/no.svg'
import './CheckIsDDAvaliableModal.css'
import { Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { Box } from '@mui/material'

function CheckIsDDAvaliableModal({
  isNotValidDDProductState,
  setIsNotValidDDProductState,
  mainTitleFirst,
  mainTitleLast,
  referencesArray,
  onEditQuoteCallback,
  setIsDirectDelivery,
}) {
  return (
    <Modal show={isNotValidDDProductState} centered>
      <Box>
        <div className="DD-modal-box-confirm-start-sales-order">
          <div className="DD-delete-confirm-container">
            <div className="DD-confirmation-header">
              <span
                onClick={() => setIsNotValidDDProductState(false)}
                className="DD-close-modal">
                <img src={No} alt="close" />
              </span>
            </div>
            <div>
              <div className="DD-confirmation-message">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  size={'4x'}
                  style={{ color: '#ff2a40' }}
                />
                <p className="DD-confirmation-text-delete mt-4">
                  {mainTitleFirst}
                  {referencesArray.map((reference, index) => {
                    return <span key={index}>{reference}, </span>
                  })}
                  {mainTitleLast}
                </p>
              </div>
              <div className="DD-confirmation-message">
                <p className="DD-description-message mt-4">
                  {
                    'Puedes volver a la orden de venta y seleccionar otro tipo de entrega, o editar la cotización'
                  }
                </p>
              </div>
              <div className="DD-modal-buttons">
                <button
                  onClick={() => onEditQuoteCallback()}
                  className="DD-btn DD-modal-si">
                  Editar la cotización
                </button>
                <button
                  onClick={() => {
                    setIsNotValidDDProductState(false)
                    setIsDirectDelivery(false)
                  }}
                  className="DD-btn DD-modal-no">
                  Volver a la orden de venta
                </button>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default CheckIsDDAvaliableModal
