import React from 'react'
import Navigation from '../Navigation'
import './vaContainer.css'

const VAContainer = ({ title, children }) => {
  return (
    <>
      <Navigation />
      <div className="vad-content__main">
        {title && (
          <header className="vad-content__main-header">
            <h2 className="vad-content__main-title">{title}</h2>
          </header>
        )}
        <div className="vad-content__wrapper">{children}</div>
      </div>
    </>
  )
}

export default VAContainer
