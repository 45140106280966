import React from 'react'
import Popover from '@mui/material/Popover'

const DispatchNumberPopover = ({ props }) => {
  const { dispatchValue, setDispatchValue } = props

  return (
    <Popover
      open={dispatchValue.openPopover}
      onClose={() => setDispatchValue({ ...dispatchValue, openPopover: false })}
      anchorEl={dispatchValue.anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}>
      <ul className="sd-list-container">
        {dispatchValue.value.map(
          (sd, index) =>
            index !== 0 && (
              <li className="sd-list-item" key={index}>
                {`${sd}`}&nbsp;&nbsp;&nbsp;&nbsp;
                {`${(index + 1).toString().padStart(2, 0)}`}
              </li>
            )
        )}
      </ul>
    </Popover>
  )
}

export default DispatchNumberPopover
