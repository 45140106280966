import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTable, useGlobalFilter } from 'react-table'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import MiniLoading from '../../Components/MiniLoading'
import search from '../../Assets/Images/BuscarCotizacion/search.svg'
import duplicateGray from '../../Assets/Images/BuscarCotizacion/icon-duplicate-gray.svg'
import iconFunnel from '../../Assets/Images/BuscarCotizacion/icon-funnel.svg'
import CircularProgress from '@mui/material/CircularProgress'
import { Envelope, BoxArrowDown, PersonPlusFill } from 'react-bootstrap-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSquarePen,
  faShareNodes,
  faEye,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import Popover from '@mui/material/Popover'
import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import './actionTable.css'
import { t } from 'i18next'
import {
  activeModalCustomer,
  agregarCliente,
  downloadPdfAction,
  sendEmailCotizacion,
} from 'redux/actions/cotizacionActions'
import es from 'dayjs/locale/es'
import {
  closeStatusDocument,
  colorsStatus,
  docHeaderStatusId,
  documentTypeId,
  pendingStatusDocument,
  processStatusDocument,
  VAPermissions,
} from 'Utils/constants'
import { generateColorStatus } from 'Utils/functions'
import CustomerInfo from 'Components/CustomerInfo'
import { VAContext } from 'Context/VAContext'
import { useCheckFinalPrice } from './hooks/useCheckFinalPrice'
import DispatchNumberPopover from './components/DispatchNumberPopover'
import useValidatePermission from 'Hooks/useValidatePermission'
import { CreditAccountsModal } from 'Components/CustomerInfo/components/CreditAccountsModal'
import { CustomerContext } from 'state/context/CustomerFormContext'
function ActionTable({
  data,
  columns,
  loader,
  handleEmpty,
  handleFilterLasts,
  handleFilterAll,
  filterLasts,
  handleFilterDateRange,
  handleFilterStatusList,
  isActionsExtra,
  placeholder,
  isCustomer,
  showModalEditClient,
  setShowModalEditClient,
  clientToEdit,
  inModal,
  handleModal,
  resetAddressStatus,
  setDefaultWarehouseToQuotation,
  setClienteCotizacion,
  setShowModalClienteContado,
  searchString,
  setSearchString,
  handleSearch,
}) {
  const dispatch = useDispatch()
  const { showCreditAccounts, setShowCreditAccounts } = CustomerContext()

  const createNaturalClient = useValidatePermission(
    VAPermissions.createNaturalClient
  )

  const { checkFinalPrice } = useCheckFinalPrice()
  const [pdfLoading, setPdfLoading] = useState(null)
  const [dispatchValue, setDispatchValue] = useState({
    openPopover: false,
    value: [],
    anchorEl: null,
  })
  const tableInstance = useTable({ columns, data }, useGlobalFilter)
  const cart = useSelector(state => state.cart.cart)
  // Filters
  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false)
  const [isStatusFilterOpen, setIsStatusFilterOpen] = useState(false)
  const [dateFilterAnchorEl, setDateFilterAnchorEl] = useState(null)
  const [statusFilterAnchorEl, setStatusFilterAnchorEl] = useState(null)

  // Date pickers
  const [isFilterTodaySelected, setIsFilterTodaySelected] = useState(false)
  const [dateFromValue, setDateFromValue] = useState(null)
  const [dateToValue, setDateToValue] = useState(null)

  // Status filters
  const [isStatusOpenChecked, setIsStatusOpenChecked] = useState(false)
  const [isStatusCanceledChecked, setIsStatusCanceledChecked] = useState(false)
  const [isStatusExpiredChecked, setIsStatusExpiredChecked] = useState(false)
  const [isStatusDraftChecked, setIsStatusDraftChecked] = useState(false)
  const [isStatusQuoteClosedChecked, setIsStatusQuoteClosedChecked] =
    useState(false)
  const [isStatusPartialPickedChecked, setIsStatusPartialPickedChecked] =
    useState(false)
  const [isStatusFullyPickedChecked, setIsStatusFullyPickedChecked] =
    useState(false)
  const [
    isStatusDispatchInProgressChecked,
    setIsStatusDispatchInProgressChecked,
  ] = useState(false)
  const [isStatusCreatedChecked, setIsStatusCreatedChecked] = useState(false)
  const [isStatusOdvClosedChecked, setIsStatusOdvClosedChecked] =
    useState(false)

  const [customerId, setCustomerId] = useState(null)
  const [openCustomerInfo, setOpenCustomerInfo] = useState(false)

  const { CompanyId } = useSelector(state => state.company)

  const [prevCompanyId, setPrevCompanyId] = useState(CompanyId)

  const { selectClient } = useContext(VAContext)

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance

  const alignText = status => {
    const allStatusValues = [
      ...Object.values(pendingStatusDocument),
      ...Object.values(processStatusDocument),
      ...Object.values(closeStatusDocument),
    ]

    return allStatusValues.includes(status) ? 'center' : 'left'
  }

  const [emailLoading, setEmailLoading] = useState(false)

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0)',
      boxShadow: '0px 3px 6px #00000029',
      padding: '9px 5px',
      lineHeight: '16px',
    },
  }))

  const sendEmail = async (docHeaderId, documentNumber, email) => {
    setEmailLoading(docHeaderId)
    const payload = {
      DocumentTypeId: documentTypeId.QUOTATION,
      DocHeaderId: docHeaderId,
      DocumentNumber: documentNumber,
      Email: email,
    }
    await dispatch(sendEmailCotizacion(payload, setEmailLoading))
  }

  const downloadPdf = async docHeaderId => {
    setPdfLoading(docHeaderId)
    const payload = {
      DocumentTypeId: documentTypeId.QUOTATION,
      DocHeaderId: docHeaderId,
    }
    await dispatch(downloadPdfAction(payload, setPdfLoading))
  }
  const isSearchQuote = window.location.pathname.includes('buscar_cotizacion')
  const isSearchODV = window.location.pathname.includes('buscar_orden_venta')
  const isSearchSD = window.location.pathname.includes('buscar_despacho')
  const isSearchDocument = isSearchQuote || isSearchODV || isSearchSD

  const handleOpenFilter = async (event, type) => {
    if (type === 'date') {
      setDateFilterAnchorEl(event.currentTarget)
      setIsDateFilterOpen(true)
    } else if (type === 'status') {
      setStatusFilterAnchorEl(event.currentTarget)
      setIsStatusFilterOpen(true)
    }
  }

  const handleDateFilterClose = () => {
    setIsDateFilterOpen(false)
  }

  const handleStatusFilterClose = () => {
    setIsStatusFilterOpen(false)
  }

  const handleTodaySelected = () => {
    setIsFilterTodaySelected(!isFilterTodaySelected)
    setDateFromValue(null)
    setDateToValue(null)
  }

  const handleDateFilterClear = () => {
    setIsFilterTodaySelected(false)
    setDateFromValue(null)
    setDateToValue(null)
  }

  const handleDateRangeApply = async () => {
    if (isFilterTodaySelected) {
      const dateFrom = new Date()
      dateFrom.setHours(0, 0, 0, 0)

      const dateTo = new Date()

      handleFilterDateRange(dateFrom, dateTo)
    } else if (dateFromValue !== null) {
      const dateFrom = new Date(dateFromValue)
      dateFrom.setHours(0, 0, 0, 0)

      let dateTo
      if (dateToValue !== null) {
        dateTo = new Date(dateToValue)
      } else {
        dateTo = new Date()
      }

      handleFilterDateRange(dateFrom, dateTo)
    } else {
      // no filters
      handleFilterDateRange(null, new Date())
      handleDateFilterClear()
    }

    handleDateFilterClose()
  }

  const handleStatusOpenChange = () => {
    setIsStatusOpenChecked(!isStatusOpenChecked)
  }

  const handleStatusCanceledChange = () => {
    setIsStatusCanceledChecked(!isStatusCanceledChecked)
  }

  const handleStatusExpiredChange = () => {
    setIsStatusExpiredChecked(!isStatusExpiredChecked)
  }

  const handleStatusDraftChange = () => {
    setIsStatusDraftChecked(!isStatusDraftChecked)
  }

  const handleStatusQuoteClosedChange = () => {
    setIsStatusQuoteClosedChecked(!isStatusQuoteClosedChecked)
  }

  const handleStatusPartialPickedChange = () => {
    setIsStatusPartialPickedChecked(!isStatusPartialPickedChecked)
  }

  const handleStatusFullyPickedChange = () => {
    setIsStatusFullyPickedChecked(!isStatusFullyPickedChecked)
  }

  const handleStatusDispatchInProgressChange = () => {
    setIsStatusDispatchInProgressChecked(!isStatusDispatchInProgressChecked)
  }

  const handleStatusCreatedChange = () => {
    setIsStatusCreatedChecked(!isStatusCreatedChecked)
  }

  const handleStatusOdvCloseChange = () => {
    setIsStatusOdvClosedChecked(!isStatusOdvClosedChecked)
  }

  const handleStatusFilterApply = () => {
    const statusFilterList = []

    if (isStatusOpenChecked) {
      statusFilterList.push(docHeaderStatusId.open)
    }

    if (isStatusCanceledChecked) {
      statusFilterList.push(docHeaderStatusId.cancel)
    }

    if (isStatusExpiredChecked) {
      statusFilterList.push(docHeaderStatusId.expired)
    }

    if (isStatusDraftChecked) {
      statusFilterList.push(docHeaderStatusId.draft)
    }

    if (isStatusQuoteClosedChecked) {
      statusFilterList.push(docHeaderStatusId.quoteClosed)
    }
    if (isStatusPartialPickedChecked) {
      statusFilterList.push(docHeaderStatusId.parcialDispatch)
    }
    if (isStatusFullyPickedChecked) {
      statusFilterList.push(docHeaderStatusId.completeDispatch)
    }
    if (isStatusDispatchInProgressChecked) {
      statusFilterList.push(docHeaderStatusId.dispatchInProgress)
    }
    if (isStatusCreatedChecked) {
      statusFilterList.push(docHeaderStatusId.created)
    }
    if (isStatusOdvClosedChecked) {
      statusFilterList.push(docHeaderStatusId.odvClosed)
    }

    handleFilterStatusList(statusFilterList)

    handleStatusFilterClose()
  }

  const statusQuoteOptions = [
    {
      id: 'statusOpen',
      label: t('ACTION_TABLE_T.OPEN_STATUS_LABEL'),
      checked: isStatusOpenChecked,
      onChange: handleStatusOpenChange,
    },
    {
      id: 'statusCanceled',
      label: t('ACTION_TABLE_T.CANCELLED_STATUS_LABEL'),
      checked: isStatusCanceledChecked,
      onChange: handleStatusCanceledChange,
    },
    {
      id: 'statusExpired',
      label: t('ACTION_TABLE_T.EXPIRED_STATUS_LABEL'),
      checked: isStatusExpiredChecked,
      onChange: handleStatusExpiredChange,
    },
    {
      id: 'statusDraft',
      label: t('ACTION_TABLE_T.DRAFT_LABEL'),
      checked: isStatusDraftChecked,
      onChange: handleStatusDraftChange,
    },
    {
      id: 'statusClosed',
      label: t('ACTION_TABLE_T.CLOSED_STATUS_LABEL'),
      checked: isStatusQuoteClosedChecked,
      onChange: handleStatusQuoteClosedChange,
    },
  ]

  const statusOdvOptions = [
    {
      id: 'statusCreated',
      label: t('ACTION_TABLE_T.CREATED_STATUS_LABEL'),
      checked: isStatusCreatedChecked,
      onChange: handleStatusCreatedChange,
    },
    {
      id: 'statusDispatchInProgress',
      label: t('ACTION_TABLE_T.DISPATCH_IN_PROGRESS_LABEL'),
      checked: isStatusDispatchInProgressChecked,
      onChange: handleStatusDispatchInProgressChange,
    },
    {
      id: 'statusOdvClosed',
      label: t('ACTION_TABLE_T.ODV_CLOSED_STATUS_LABEL'),
      checked: isStatusOdvClosedChecked,
      onChange: handleStatusOdvCloseChange,
    },
  ]

  const statusDispatchOptions = [
    {
      id: 'statusPartialPicked',
      label: t('ACTION_TABLE_T.PARTIAL_PICKED_STATUS_LABEL'),
      checked: isStatusPartialPickedChecked,
      onChange: handleStatusPartialPickedChange,
    },
    {
      id: 'statusFullyPicked',
      label: t('ACTION_TABLE_T.FULLY_PICKED_STATUS_LABEL'),
      checked: isStatusFullyPickedChecked,
      onChange: handleStatusFullyPickedChange,
    },
  ]

  const statusOptions = isSearchQuote
    ? statusQuoteOptions
    : isSearchODV
    ? statusOdvOptions
    : isSearchSD
    ? statusDispatchOptions
    : []

  const handleStatusFilterClear = () => {
    setIsStatusOpenChecked(false)
    setIsStatusCanceledChecked(false)
    setIsStatusExpiredChecked(false)
    setIsStatusDraftChecked(false)
    setIsStatusQuoteClosedChecked(false)
    setIsStatusPartialPickedChecked(false)
    setIsStatusFullyPickedChecked(false)
    setIsStatusDispatchInProgressChecked(false)
    setIsStatusCreatedChecked(false)
    setIsStatusOdvClosedChecked(false)
  }

  useEffect(() => {
    if (prevCompanyId !== CompanyId) {
      setPrevCompanyId(CompanyId)
      handleStatusFilterClear()
      setIsFilterTodaySelected(false)
      setDateFromValue(null)
      setDateToValue(null)
      setIsDateFilterOpen(false)
    } else {
      setPrevCompanyId(CompanyId)
    }
    return () => {
      setShowCreditAccounts(false)
    }
  }, [CompanyId])

  const renderFilter = type => {
    const color = '#003082'
    const theme = createTheme({
      typography: {
        fontSize: 16,
      },
      components: {
        MuiIconButton: {
          styleOverrides: {
            sizeMedium: {
              color,
            },
          },
        },
      },
    })

    if (type === 'date') {
      return (
        <>
          <span className="float-end">
            <img
              src={iconFunnel}
              alt="filter"
              onClick={e => handleOpenFilter(e, type)}
            />
          </span>

          <Popover
            id="date-filter-popover"
            open={isDateFilterOpen}
            anchorEl={dateFilterAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={handleDateFilterClose}>
            <div className="filter-popover m-4">
              <div className="row mt-10">
                <div className="col-10">
                  <label
                    className="text-filter-popover-title"
                    htmlFor="dateFilterToday">
                    {t('ACTION_TABLE_T.TODAY_LABEL')}
                  </label>
                </div>
                <div className="col-2 text-end">
                  <input
                    type="radio"
                    id="dateFilterToday"
                    checked={isFilterTodaySelected}
                    name="dateFilterToday"
                    onChange={handleTodaySelected}
                  />
                </div>
              </div>
              <hr className="mt-5 mb-4" />
              <div className="row">
                <span className="text-filter-popover-title mb-3">
                  {t('ACTION_TABLE_T.RANGE_DATE_LABEL')}
                </span>
              </div>
              <div className="row m-1 text-filter-popover-title">
                <ThemeProvider theme={theme}>
                  <LocalizationProvider
                    adapterLocale={es}
                    dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label={t('ACTION_TABLE_T.FROM_LABEL')}
                      value={dateFromValue}
                      inputFormat="DD/MM/YYYY"
                      PopperProps={{
                        className: 'generic-date-picker-popper-styles',
                      }}
                      onChange={newValue => {
                        setIsFilterTodaySelected(false)
                        setDateFromValue(newValue)
                      }}
                      renderInput={params => (
                        <TextField {...params} sx={{ svg: { fontSize: 20 } }} />
                      )}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              </div>
              <div className="row m-1 mt-3">
                <ThemeProvider theme={theme}>
                  <LocalizationProvider
                    adapterLocale={es}
                    dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label={t('ACTION_TABLE_T.TO_LABEL')}
                      value={dateToValue}
                      inputFormat="DD/MM/YYYY"
                      PopperProps={{
                        className: 'generic-date-picker-popper-styles',
                      }}
                      onChange={newValue => {
                        setIsFilterTodaySelected(false)
                        setDateToValue(newValue)
                      }}
                      renderInput={params => (
                        <TextField {...params} sx={{ svg: { fontSize: 20 } }} />
                      )}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              </div>
              <div className="row mt-5">
                <div className="col-6">
                  <button
                    className="btn-clear-filter"
                    onClick={handleDateFilterClear}>
                    {t('ACTION_TABLE_T.CLEAN_LABEL')}
                  </button>
                </div>
                <div className="col-6">
                  <button
                    className="btn-apply-filter"
                    onClick={handleDateRangeApply}>
                    {t('ACTION_TABLE_T.APPLY_LABEL')}
                  </button>
                </div>
              </div>
            </div>
          </Popover>
        </>
      )
    } else if (type === 'status') {
      return (
        <>
          <span className="float-end">
            <img
              src={iconFunnel}
              alt="filter"
              onClick={e => handleOpenFilter(e, type)}
            />
          </span>

          <Popover
            id="status-filter-popover"
            open={isStatusFilterOpen}
            anchorEl={statusFilterAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={handleStatusFilterClose}>
            <div className="filter-popover m-4">
              {statusOptions?.map((option, index) => (
                <div
                  className={`status-filter-checkbox ${
                    index > 0 ? 'mt-3' : ''
                  }`}
                  key={option.id}>
                  <input
                    type="checkbox"
                    checked={option.checked}
                    onChange={option.onChange}
                    id={option.id}
                    name={option.id}
                  />
                  <label htmlFor={option.id}>{option.label}</label>
                </div>
              ))}
              <div className="row mt-5">
                <div className="col-6">
                  <button
                    className="btn-clear-filter"
                    onClick={handleStatusFilterClear}>
                    {t('ACTION_TABLE_T.CLEAN_LABEL')}
                  </button>
                </div>
                <div className="col-6">
                  <button
                    className="btn-apply-filter"
                    onClick={handleStatusFilterApply}>
                    {t('ACTION_TABLE_T.APPLY_LABEL')}
                  </button>
                </div>
              </div>
            </div>
          </Popover>
        </>
      )
    }
  }

  const renderBodyData = (value, header) => {
    const isDispatchNumberFormat =
      header === 'numero_despacho' && Array.isArray(value) && value.length > 1

    return (
      <div className="sd-number-container">
        <p
          className="action-table-cell"
          style={{
            backgroundColor: generateColorStatus(
              value,
              pendingStatusDocument,
              processStatusDocument,
              closeStatusDocument,
              colorsStatus
            ),
            textAlign: alignText(value),
          }}>
          {isDispatchNumberFormat ? value[0] : value}
        </p>
        {isDispatchNumberFormat && (
          <a
            href="#"
            onClick={({ currentTarget }) => {
              setDispatchValue({
                value,
                anchorEl: currentTarget,
                openPopover: true,
              })
            }}>{`+${value.length - 1}`}</a>
        )}
      </div>
    )
  }

  const handleOpen = state => {
    !state && setShowCreditAccounts(false)
    setOpenCustomerInfo(state)
  }

  return (
    <>
      {!loader ? (
        <div className="action-table-topbar">
          <div className="action-table-left">
            <div className="action-table-left">
              <div
                className={
                  inModal
                    ? 'container-buttons-clients-is-quote'
                    : 'container-buttons-clients'
                }>
                {isCustomer && createNaturalClient && (
                  <button
                    onClick={() => {
                      setShowModalEditClient(!showModalEditClient)
                      selectClient('', false, false)
                    }}
                    className="btn-action-create-quote">
                    <PersonPlusFill />
                    <p>{t('SEARCH_CLIENT_T.CREATE_NEW_CLIENT')}</p>
                  </button>
                )}
                {inModal && (
                  <button
                    onClick={() => {
                      setShowModalClienteContado(true)
                    }}
                    className="btn-action-create-quote">
                    <PersonPlusFill />
                    <p>{t('SEARCH_CLIENT_T.COUNTED_CLIENT')}</p>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="action-table-right">
            <form
              onSubmit={e => {
                e.preventDefault()
                handleSearch()
              }}>
              <div className="inventory-search">
                <input
                  onChange={e => setSearchString(e.target.value)}
                  name="searchString"
                  value={searchString}
                  type="text"
                  placeholder={t(placeholder)}
                />
                <button className="button-disabled" type="submit">
                  <img
                    src={search}
                    className="inv-search-icon"
                    alt="search"
                    onClick={() => {
                      handleSearch()
                    }}
                  />
                </button>
              </div>
            </form>
            <div className="action-table-buttons">
              <button
                onClick={handleFilterLasts}
                className={filterLasts ? 'activeFilter' : ''}>
                {t('ACTION_TABLE_T.LASTS_QUOTATIONS')}
              </button>
              <button
                onClick={handleFilterAll}
                className={filterLasts ? '' : 'activeFilter'}>
                {t('ACTION_TABLE_T.ALL_LABEL')}
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <div>
        {!loader ? (
          <>
            <table className="action-table" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup, i) => (
                  <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, i) => (
                      <th key={i} {...column.getHeaderProps()}>
                        {column.render('Header')}
                        {column.hasFilter && renderFilter(column.filterType)}
                      </th>
                    ))}
                    <th>{t('ACTION_TABLE_T.ACTIONS_LABEL')} </th>
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr key={i} {...row.getRowProps()}>
                      {row.cells.map((cell, i) => (
                        <td key={i} {...cell.getCellProps()}>
                          {renderBodyData(cell?.value, cell.column?.id)}
                        </td>
                      ))}
                      <td className="action-table-icons">
                        {pdfLoading === row.original.docHeaderId ||
                        emailLoading === row.original.docHeaderId ? (
                          <CircularProgress size="2.5rem" />
                        ) : isActionsExtra ? (
                          <LightTooltip
                            title={
                              <div
                                className="cotizacion-tooltip-buttons"
                                style={{
                                  display: 'flex',
                                  gap: '15px',
                                  alignItems: 'center',
                                  fontSize: '18px',
                                }}>
                                <BoxArrowDown
                                  style={{ color: '#9AE19F' }}
                                  onClick={() =>
                                    downloadPdf(row.original.docHeaderId)
                                  }
                                  className="hidden-icon"
                                />
                                <div>
                                  <Envelope
                                    style={{ color: '#3699FF' }}
                                    onClick={() =>
                                      sendEmail(
                                        row.original.docHeaderId,
                                        row.original.numero,
                                        row.original.email
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            }
                            placement="top">
                            <span>
                              <FontAwesomeIcon
                                icon={faShareNodes}
                                style={{ fontSize: '2rem' }}
                              />
                            </span>
                          </LightTooltip>
                        ) : null}
                        {inModal ? (
                          <FontAwesomeIcon
                            className="icon-in-table"
                            icon={faPlusCircle}
                            onClick={() => {
                              handleModal(false)
                              setClienteCotizacion(row.original)
                              resetAddressStatus()
                              dispatch(
                                agregarCliente({
                                  id: row.original.CustomerID,
                                  customerType: row.original['Tipo de cliente'],
                                  cliente: row.original['Nombre del cliente'],
                                  correo: row.original.Email,
                                  externalCode:
                                    row.original['Código del cliente'],
                                  priceListId: row.original.Listado,
                                  phoneNumber: row.original.Teléfono,
                                })
                              )
                              dispatch(activeModalCustomer(false))
                              if (cart.length > 0) {
                                checkFinalPrice({
                                  customerId: row.original.CustomerID,
                                })
                              }
                            }}
                          />
                        ) : null}
                        {!isCustomer ? (
                          createNaturalClient || isSearchDocument ? (
                            <Link to={row.original.route}>
                              <FontAwesomeIcon
                                icon={faSquarePen}
                                style={{ color: '#3FC5DF', fontSize: '2rem' }}
                              />
                            </Link>
                          ) : (
                            <FontAwesomeIcon
                              icon={faSquarePen}
                              style={{ color: '#999999', fontSize: '2rem' }}
                            />
                          )
                        ) : (
                          <>
                            <FontAwesomeIcon
                              className="icon-in-table"
                              icon={faEye}
                              style={{
                                color: '#11D29E',
                                fontSize: '2rem',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                handleOpen(true)
                                setCustomerId(row.original.CustomerID)
                              }}
                            />
                            {
                              <FontAwesomeIcon
                                className="icon-in-table"
                                icon={faSquarePen}
                                style={{
                                  color: createNaturalClient
                                    ? '#3FC5DF'
                                    : '#999999',
                                  fontSize: '2rem',
                                  cursor: createNaturalClient
                                    ? 'pointer'
                                    : 'auto',
                                }}
                                onClick={() => {
                                  if (createNaturalClient) {
                                    setShowModalEditClient(!showModalEditClient)
                                    clientToEdit(row.original.CustomerID)
                                  }
                                }}
                              />
                            }
                          </>
                        )}

                        {isActionsExtra && (
                          <img
                            src={duplicateGray}
                            alt="duplicate"
                            style={{ height: '2rem', width: '2rem' }}
                          />
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className={openCustomerInfo ? 'moda-background' : ''}>
              {showCreditAccounts && (
                <CreditAccountsModal customerId={customerId} />
              )}
              <CustomerInfo
                inModal={inModal}
                handleOpen={handleOpen}
                open={openCustomerInfo}
                customerId={customerId}
                setCustomerId={setCustomerId}
              />
            </div>

            <DispatchNumberPopover
              props={{ dispatchValue, setDispatchValue }}
            />
          </>
        ) : (
          <MiniLoading />
        )}
      </div>
    </>
  )
}

export default ActionTable
