import { algoliaApi, aludraApi } from '../../Api/axios'

const coreUrl = {
  customerURL: '/core/accountDetail/',
  validateEmailUrl: '/core/validateEmail/',
  registerURL: '/core/register/v2',
  razonesPrecioURL: '/core/reason/search',
  restoreURL: 'core/ForgotPassword',
  updateURL: '/core/update/v2',
  loginURL: 'core/Login',
  loginCompanyURL: 'core/CheckCompany/companyname/',
}
const mdl22 = {
  priceListUrl: 'mdl22/products/customerexistinpriceList/',
  finalPriceUrl: 'mdl22/products/getFinalprice',
  fullfillmentPlanUrl: 'mdl22/products/fulfillmentPlan',
  productsPricesURL: 'mdl22/products/productpriceinventory',
}

const mdl11 = {
  fullfillmentPlanDispatchUrl: 'mdl11/searchFulFillmentPlanTyDispatch',
  supplier: 'mdl04/providerProducts/search',
  creditAccUrl: 'mdl11/CreditAccount?CustomerId=',
  getAllCreditAccount: 'mdl11/CreditAccount/Search',
  creditAccSearch: 'mdl11/CreditAccount/Search',
}

const mdl03 = {
  accountDetail: 'mdl03/AccountDetail/',
  searchCustomer: 'mdl03/searchCustomers/post',
  deleteCustomerURL: 'mdl03/customer/customerId/',
  pickUp: 'mdl03/BranchOffice/Search',
  // Address
  provinceURL: '/mdl03/AddressProvince/Get/ProvinceId',
  provinceByCountryURL: '/mdl03/AddressProvince?countryId=', // filtered provices by country
  generalURL: '/mdl03/AddressByGeolocation/Post',
  townshipURL: '/mdl03/AddressTownship/Get/TownshipId?districtId=',
  districtURL: '/mdl03/AddressDistrict/Get/DistrictId?provinceId=',
  updateAddressURL: '/mdl03/addressCustomer/addUpdate',
  customerURL: '/mdl03/searchCustomers/post',
  statisticsURL: 'mdl03/MainStatistics',
  getCountries: '/mdl03/getAllcountry',
  telephoneType: 'mdl03/telephoneType',
  customerGroupURL: 'mdl03/CustomerGroup/Search',
  documentTypeURL: 'mdl03/documentType?customerTypeId=',
}
const mdl04 = {
  getListPrices: '/mdl04/GetPriceList/Post',
  getCochezListPrices: '/mdl04/getCochezPriceList/post',
  saveListPrices: '/mdl04/PriceListCustomer/Post',
  deleteListPrice: '/mdl04/PriceListCustomer/Delete/LinkId?LinkId=',
  getListPricesByCustomer: 'mdl04/PriceListCustomer/Search',
  branchesURL: 'mdl04/searchWarehouse/post',
  finalPriceURL: 'mdl04/GetFinalPrice/Post',
}
const algolia = {
  algoliaIndex: 'DB_Cochez/query',
}
export const getCustomerByIdService = id => {
  return aludraApi.get(coreUrl.customerURL + id)
}

export const mdl03GetCustomerByIdService = (
  id,
  hasParamsGet = false,
  params = {}
) => {
  if (hasParamsGet) {
    return aludraApi.get(mdl03.accountDetail + id, { ...params })
  } else {
    return aludraApi.get(mdl03.accountDetail + id)
  }
}

export const getWharehouseService = async payload => {
  return aludraApi.post(mdl03.pickUp, payload)
}

export const searchCustomersService = async payload => {
  return aludraApi.post(mdl03.searchCustomer, payload)
}

export const deleteCustomerService = async payload => {
  return aludraApi.delete(mdl03.deleteCustomerURL + payload)
}

export const getPriceListByCustomerIdService = async customerId => {
  return aludraApi.get(mdl22.priceListUrl + '?CustomerId=' + customerId)
}

export const validateCustomerEmailService = async customerEmail => {
  return aludraApi.get(coreUrl.validateEmailUrl + customerEmail)
}

export const getListPricesService = async payload => {
  return aludraApi.post(mdl04.getListPrices, payload)
}

export const getCochezListPricesService = async payload => {
  const { data } = await aludraApi.post(mdl04.getCochezListPrices, payload)
  return data
}

export const saveListPricesService = async payload => {
  return aludraApi.post(mdl04.saveListPrices, payload)
}

export const deleteListPricesService = async payload => {
  return aludraApi.delete(mdl04.deleteListPrice + payload)
}

export const getListPricesByCustomerService = async payload => {
  return aludraApi.post(mdl04.getListPricesByCustomer, payload)
}

export const registerCustomerService = async payload => {
  return aludraApi.post(coreUrl.registerURL, payload)
}

export const updateCustomerService = async payload => {
  return aludraApi.post(coreUrl.updateURL, payload)
}

export const updateAddressService = async payload => {
  return aludraApi.post(mdl03.updateAddressURL, payload)
}

export const fetchGeneralsService = async payload => {
  return aludraApi.post(mdl03.generalURL, payload)
}

export const fetchProvincesService = async => {
  return aludraApi.get(mdl03.provinceURL)
}

export const fetchProvincesByCountryService = async payload => {
  return aludraApi.get(mdl03.provinceByCountryURL + payload)
}

export const fetchDistrictService = async payload => {
  return aludraApi.get(mdl03.districtURL + payload)
}

export const fetchTownshipService = async payload => {
  return aludraApi.get(mdl03.townshipURL + payload)
}

export const getBranchesService = async payload => {
  return aludraApi.post(mdl04.branchesURL, payload)
}

export const getFinalPriceService = async payload => {
  return aludraApi.post(mdl04.finalPriceURL, payload)
}

export const getProductPriceInventoryService = async payload => {
  return aludraApi.post(mdl22.productsPricesURL, payload)
}

export const getRazonesPrecioService = async payload => {
  return aludraApi.post(coreUrl.razonesPrecioURL, payload)
}

export const getCustomersService = async payload => {
  return aludraApi.post(mdl03.customerURL, payload)
}

export const getStadisticsService = async payload => {
  return aludraApi.post(mdl03.statisticsURL, payload)
}

export const getCountriesService = async => {
  return aludraApi.get(mdl03.getCountries)
}

export const getTelephoneTypesService = async => {
  return aludraApi.get(mdl03.telephoneType)
}

export const getCustomerGroupService = async payload => {
  return aludraApi.post(mdl03.customerGroupURL, payload)
}

export const getDocumentTypesService = async payload => {
  return aludraApi.get(mdl03.documentTypeURL + payload)
}

export const restorePasswordService = async payload => {
  return aludraApi.post(coreUrl.restoreURL, payload)
}

export const algoliaQueryService = async payload => {
  return algoliaApi.post(algolia.algoliaIndex, payload)
}

export const getFinalPriceMdl22Service = async payload => {
  return aludraApi.post(mdl22.finalPriceUrl, payload)
}

export const fullfilmentPlanService = async => {
  return aludraApi.get(mdl22.fullfillmentPlanUrl)
}

export const fullfilmentPlanByDeliveryService = async payload => {
  return aludraApi.post(mdl11.fullfillmentPlanDispatchUrl, payload)
}
export const supplierServices = async payload => {
  return aludraApi.post(mdl11.supplier, payload)
}

export const creditAccountsService = async ({ customerId, companyId }) =>
  aludraApi.get(`${mdl11.creditAccUrl}${customerId}&CompanyId=${companyId}`)

export const allCreditAccountsService = async payload => {
  const data = aludraApi.post(mdl11.getAllCreditAccount, payload)
  return data
}

export const creditAccountsSearchService = async payload =>
  aludraApi.post(mdl11.creditAccSearch, payload)

export const loginUserService = async payload => {
  return aludraApi.post(coreUrl.loginURL, payload)
}

export const loginCompanyService = async payload => {
  return aludraApi.get(coreUrl.loginCompanyURL + payload)
}
