import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Box from '@mui/material/Box'

import No from '../../../../../Assets/Images/Login/no.svg'
import check from '../../../../../Assets/Images/Login/check.png'
import styles from './styles.module.css'

export const AlertModal = ({ show, label, onClick }) => {
  return (
    <Modal show={show} centered>
      <Box className="modal-box">
        <div className="generic-closing-button modal-client">
          <img src={No} onClick={onClick} alt="close" />
        </div>
        <div className="modal-content-text">
          <img src={check} alt="check" className={styles.modalCheckImage} />
          <h2>{label}</h2>
        </div>
      </Box>
    </Modal>
  )
}
