import React from 'react'
import { useSelector } from 'react-redux'
import { VAPermissions } from 'Utils/constants'
import useValidatePermission from 'Hooks/useValidatePermission'
import CostMarginVisibility from 'Components/CostMarginVisibility/CostMarginVisibility'

function MarginText() {
  const quoteLines = useSelector(state => state.cotizacion.QuoteHeader.Lines)
  const totalPrice = quoteLines.reduce(
    (acc, curr) => acc + curr.Price * curr.Quantity,
    0
  )

  function validateCost(curr) {
    return curr.Cost || curr.AvgCost || 0.0
  }

  const totalCost = quoteLines.reduce((acc, curr) => {
    return acc + validateCost(curr) * curr.Quantity
  }, 0)

  const seeMargenProd = useValidatePermission(VAPermissions.seeMargenProd)

  return (
    <div style={{ marginTop: 10 }}>
      {seeMargenProd && (
        <CostMarginVisibility
          seeMargenProd={seeMargenProd}
          cost={totalCost}
          price={totalPrice}
          istotalMargin
          secondaryColor
        />
      )}
    </div>
  )
}

export default MarginText
