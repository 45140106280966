/* eslint-disable no-prototype-builtins */
import React, { useContext } from 'react'
import { t } from 'i18next'
import { Row, Col } from 'react-bootstrap'
import NewCustomerMap from 'Components/NewCustomerMap'
import { VAContext } from 'Context/VAContext'
import { DisplayGenres } from './DisplayGenders'
import { DisplayListPrices } from '../DisplayListPrices'
import { CircularProgress } from '@mui/material'
import { getMinAvailableBirthday } from '../../utils/getMinAvailableBirthday'
import { getMaxAvailableBirthday } from '../../utils/getMaxAvailableBirthday'
import { useSelector } from 'react-redux'
import { isCallCenterUser } from 'Utils/functions'
import { DisplayCustomerGroup } from '../TabLegal/DisplayCustomerGroup'
import { VAPermissions } from 'Utils/constants'
import useValidatePermission from 'Hooks/useValidatePermission'

export const TabNatural = ({
  props: {
    formikNatural,
    tipoTelefono,
    documentType,
    setValueStateSelect,
    valueStateSelect,
    setValueStateSelectCustomer,
    valueStateSelectCustomer,
    setValueState,
    valueState,

    loadingSave,

    setHasAddress,
    hasAddress,
    setCountryCode,
    setProvinceCode,
    setDistrictCode,
    setTownshipCode,
    street,
    setStreet,
    name,
    setName,
  },
}) => {
  const { clientSelected } = useContext(VAContext)
  const loginInfo = useSelector(state => state.login?.user?.AuthenticationInfo)
  const isCallCenter = isCallCenterUser(loginInfo.UserRoles)
  const canUseCustomerType = useValidatePermission(VAPermissions.customerType)

  return (
    <div className="form-area">
      <form>
        <Row>
          {/* NOMBRE */}
          <Col xl={6} lg={6} md={6} sm={12}>
            <div className="form-group">
              <input
                type="text"
                id="firstName"
                name="firstName"
                className={
                  formikNatural.touched.firstName &&
                  formikNatural.errors.firstName
                    ? 'form-control error'
                    : 'form-control'
                }
                placeholder="Nombre*"
                value={formikNatural.values.firstName}
                onChange={formikNatural.handleChange}
                onBlur={formikNatural.handleBlur}
              />
            </div>
            <div className="input-feedback">
              {formikNatural.touched.firstName &&
                formikNatural.errors.firstName && (
                  <strong>{formikNatural.errors.firstName}</strong>
                )}
            </div>
          </Col>
          {/* APELLIDO */}
          <Col xl={6} lg={6} md={6} sm={12}>
            <div className="form-group">
              <input
                type="text"
                id="lastName"
                name="lastName"
                className={
                  formikNatural.touched.lastName &&
                  formikNatural.errors.lastName
                    ? 'form-control error'
                    : 'form-control'
                }
                placeholder="Apellido*"
                value={formikNatural.values.lastName}
                onChange={formikNatural.handleChange}
                onBlur={formikNatural.handleBlur}
              />
            </div>
            <div className="input-feedback">
              {formikNatural.touched.lastName &&
                formikNatural.errors.lastName && (
                  <strong>{formikNatural.errors.lastName}</strong>
                )}
            </div>
          </Col>

          {/* TIPODOCUMENTO */}
          <Col xl={12} lg={12} md={12} sm={12}>
            <div className="form-group radio-group">
              {documentType.map(document => (
                <div
                  className="custom-control custom-radio"
                  key={document?.DocumentTypeId}>
                  <input
                    type="radio"
                    id={document?.DocumentTypeId?.toUpperCase()}
                    name="documentTypeId"
                    className={
                      formikNatural.touched.documentTypeId &&
                      formikNatural.errors.documentTypeId
                        ? 'custom-control-input error'
                        : 'custom-control-input'
                    }
                    value={document.DocumentTypeId?.toUpperCase()}
                    checked={
                      formikNatural.values.documentTypeId?.toUpperCase() ===
                      document.DocumentTypeId?.toUpperCase()
                    }
                    onChange={formikNatural.handleChange}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={document.DocumentTypeId?.toUpperCase()}
                    style={{
                      marginRight: '15px',
                    }}>
                    {document.Name}
                  </label>
                </div>
              ))}
            </div>
          </Col>
          {/* NUMERO DE IDENTIFICACIÓN */}
          <Col xl={6} lg={6} md={6} sm={12}>
            <div className="form-group">
              <input
                type="text"
                name="idNumber"
                maxLength={20}
                className={
                  formikNatural.touched.idNumber &&
                  formikNatural.errors.idNumber
                    ? 'form-control error'
                    : 'form-control'
                }
                placeholder={t('CREATE_CLIENT_T.INTRODUCE_IDENT_PLACEHOLDER')}
                value={formikNatural.values.idNumber}
                onChange={e => {
                  const value = e.target.value
                  formikNatural.setFieldValue('idNumber', value.toUpperCase())
                }}
                onBlur={formikNatural.handleBlur}
              />
            </div>
            <div className="input-feedback">
              {formikNatural.touched.idNumber &&
                formikNatural.errors.idNumber && (
                  <strong>{formikNatural.errors.idNumber}</strong>
                )}
            </div>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12}>
            <div className="form-group">
              <input
                type="email"
                name="generalEmail[0].address"
                className={
                  formikNatural.touched.hasOwnProperty('generalEmail') &&
                  formikNatural.errors.hasOwnProperty('generalEmail')
                    ? formikNatural.errors.generalEmail[0]?.address
                      ? 'form-control error'
                      : 'form-control'
                    : 'form-control'
                }
                placeholder={t('CREATE_CLIENT_T.EMAIL_PLACEHOLDER')}
                value={formikNatural.values.generalEmail[0]?.address}
                onChange={formikNatural.handleChange}
                onBlur={formikNatural.handleBlur}
              />
            </div>
            <div className="input-feedback">
              {formikNatural.touched.hasOwnProperty('generalEmail') &&
                formikNatural.errors.hasOwnProperty('generalEmail') && (
                  <strong>
                    {formikNatural.errors.generalEmail[0]?.address}
                  </strong>
                )}
            </div>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12}>
            <div className="form-group">
              <input
                type="text"
                name="DV"
                maxLength={2}
                className={
                  formikNatural.touched.DV && formikNatural.errors.DV
                    ? 'form-control error'
                    : 'form-control'
                }
                placeholder="DV"
                value={formikNatural.values.DV}
                onChange={e => {
                  const onlyNums = e.target.value.replace(/[^0-9]/g, '')
                  formikNatural.setFieldValue('DV', onlyNums)
                }}
                onBlur={formikNatural.handleBlur}
              />
            </div>
            <div className="input-feedback">
              {formikNatural.touched.DV && formikNatural.errors.DV && (
                <strong>{formikNatural.errors.DV}</strong>
              )}
            </div>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12}>
            <div className="form-group">
              <input
                type="text"
                name="birthdate"
                min={getMaxAvailableBirthday()}
                max={getMinAvailableBirthday()}
                className={
                  formikNatural.touched.birthdate &&
                  formikNatural.errors.birthdate
                    ? 'form-control error'
                    : 'form-control'
                }
                placeholder={t('CREATE_CLIENT_T.BIRTHDATE_PLACEHOLDER')}
                value={formikNatural.values.birthdate}
                onChange={formikNatural.handleChange}
                onFocus={e => {
                  e.target.type = 'date'
                  e.target.placeholder = 'dd-mm-yyyy'
                }}
                onBlur={formikNatural.handleBlur}
              />
            </div>
            <div className="input-feedback">
              {formikNatural.touched.birthdate &&
                formikNatural.errors.birthdate && (
                  <strong>{formikNatural.errors.birthdate}</strong>
                )}
            </div>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12}>
            <div className="form-group radio-group">
              {tipoTelefono?.map((tipo, index) => (
                <div className="custom-control custom-radio  mr-2" key={index}>
                  <input
                    type="radio"
                    id={tipo.TelephoneTypeId.toUpperCase()}
                    className={
                      formikNatural.errors.generalTelephone?.telephoneTypeId &&
                      formikNatural.touched.generalTelephone?.telephoneTypeId
                        ? 'custom-control-input error'
                        : 'custom-control-input'
                    }
                    name="generalTelephone[0].telephoneTypeId"
                    value={tipo.TelephoneTypeId.toUpperCase()}
                    checked={
                      formikNatural.values.generalTelephone[0]?.telephoneTypeId.toUpperCase() ===
                      tipo?.TelephoneTypeId.toUpperCase()
                    }
                    onChange={formikNatural.handleChange}
                  />
                  <label
                    className="custom-control-label label-radio"
                    htmlFor={tipo.TelephoneTypeId.toUpperCase()}
                    style={{
                      marginRight: '15px',
                    }}>
                    {tipo.Description}
                  </label>
                </div>
              ))}
            </div>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12}>
            <div className="form-group">
              <input
                type="text"
                name="generalTelephone[0].number"
                maxLength={8}
                className={
                  formikNatural.touched.hasOwnProperty('generalTelephone') &&
                  formikNatural.errors.hasOwnProperty('generalTelephone')
                    ? formikNatural.errors.generalTelephone[0]?.number
                      ? 'form-control error'
                      : 'form-control'
                    : 'form-control'
                }
                placeholder={t('CREATE_CLIENT_T.PHONE_NUMBER_PLACEHOLDER')}
                value={formikNatural.values?.generalTelephone?.[0]?.number}
                onChange={e => {
                  const onlyNums = e.target.value.replace(/[^0-9]/g, '')
                  formikNatural.setFieldValue(
                    'generalTelephone[0].number',
                    onlyNums
                  )
                }}
                onBlur={formikNatural.handleBlur}
                autoComplete="off"
              />
            </div>
            <div className="input-feedback">
              {formikNatural.touched.hasOwnProperty('generalTelephone') &&
                formikNatural.errors.hasOwnProperty('generalTelephone') && (
                  <strong>
                    {formikNatural.errors.generalTelephone[0]?.number}
                  </strong>
                )}
            </div>
          </Col>
          {/* GENERO */}
          <Col xl={6} lg={6} md={6} sm={12}>
            <DisplayGenres
              formik={formikNatural}
              setValueStateSelect={setValueStateSelect}
              valueStateSelect={valueStateSelect}
            />
          </Col>
          {/* TIPOCLIENTE */}

          <Col xl={6} lg={6} md={6} sm={12}>
            {canUseCustomerType && (
              <DisplayCustomerGroup
                formik={formikNatural}
                valueStateSelectCustomerGroup={valueStateSelectCustomer}
                setValueStateSelectCustomerGroup={setValueStateSelectCustomer}
                type="natural"
              />
            )}
          </Col>
          {/* LISTAS */}
          <Col xl={6} lg={6} md={6} sm={12}>
            <div
              // eslint-disable-next-line react/no-unknown-property
              onLoad={() => {
                return document.getElementById('mySelect2')
                  ? (document.getElementById('mySelect2').selectedIndex = 0)
                  : 0
              }}>
              {isCallCenter && (
                <DisplayListPrices
                  setValueState={setValueState}
                  valueState={valueState}
                />
              )}
            </div>
          </Col>
          {/* MAPA DIRECCIÓN */}
          {/* DEBE MANTENERSE DE ESTA FORMA O SE PUEDE GENERAR UN ERROR EN CASO DE QUE OCURRA UNA RENDERIZACIÓN */}

          {!clientSelected?.edit && (
            <Col lg={12}>
              <NewCustomerMap
                setHasAddress={setHasAddress}
                hasAddress={hasAddress}
                setCountryCode={setCountryCode}
                setProvinceCode={setProvinceCode}
                setDistrictCode={setDistrictCode}
                setTownshipCode={setTownshipCode}
                personType={'NAT'}
                street={street}
                setStreet={setStreet}
                name={name}
                setName={setName}
                formik={formikNatural}
              />
            </Col>
          )}

          {/* RECIBIR PROMOCIONES */}
          <Col lg={12}>
            <div className="custom-control  mr-2">
              <input
                type="checkbox"
                className="custom-control-input checkbox-input"
                name="receivePromotions"
                id="receivePromotions"
                value={formikNatural.values.receivePromotions}
                checked={formikNatural.values.receivePromotions}
                onChange={formikNatural.handleChange}
              />
              <label
                className="custom-control-label"
                htmlFor="receivePromotions">
                {t('CREATE_CLIENT_T.ADVERTISE_EMAIL_INFO')}{' '}
              </label>
            </div>
          </Col>
          <div className="section-buttons">
            <button
              type="button"
              onClick={formikNatural.handleSubmit}
              disabled={loadingSave}
              className="btn btn-primary form-submit buttons-save">
              {loadingSave ? (
                <CircularProgress size="2.5rem" />
              ) : !clientSelected?.edit ? (
                t('CREATE_CLIENT_T.CREATE_CLIENT_SAVE_BUTTON')
              ) : (
                t('CREATE_CLIENT_T.SAVE_BUTTON')
              )}
            </button>
          </div>
        </Row>
      </form>
    </div>
  )
}
