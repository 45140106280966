import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch } from 'react-redux'
import {
  faArrowRightArrowLeft,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import Table from 'react-bootstrap/Table'
import './CalculatorProcess.css'
import no from '../../Assets/Images/Login/no.svg'
import { calculatorAction } from 'redux/actions/calculator'
import { t } from 'i18next'
import { calcOptions, statusCalculator } from 'Utils/constants'

function CalculatorProcess({ calcType, setCalcType, setChangeModal }) {
  const [cieloRaso, setCieloRaso] = useState(false)
  const [pvc, setPvc] = useState(false)
  const [paredGypsum, setParedGypsum] = useState(false)
  const [techoGypsum, setTechoGypsum] = useState(false)
  const [calc, setCalc] = useState(null)
  const [width, setWidth] = useState(null)
  const [depth, setDepth] = useState(null)
  const [sheet, setSheet] = useState(null)
  const [calcResult, setCalcResult] = useState(null)
  const [pvcErrorMessage, setPvcErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const refForm = useRef()
  const dispatch = useDispatch()

  const [selectedSheetIndex, setSelectedSheetIndex] = useState(null)

  const handleSheet = e => {
    setSheet(e.target.value)
    const selectedIndex = calcOptions.findIndex(
      option => option.value.toString() === e.target.value.toString()
    )
    setSelectedSheetIndex(selectedIndex) // Actualiza el estado con el índice seleccionado
  }

  const handleClean = () => {
    refForm.current.reset()
    setWidth(null)
    setDepth(null)
    setCalcResult(null)
    setSheet(null)
    setPvcErrorMessage('')
    setSelectedSheetIndex(null)
  }
  const status = () => {
    const mapping = {
      cieloRaso: { state: setCieloRaso, calc: statusCalculator.ceiling },
      pvc: { state: setPvc, calc: statusCalculator.pvcCeiling },
      paredGypsum: { state: setParedGypsum, calc: statusCalculator.gypsumWall },
      techoGypsum: {
        state: setTechoGypsum,
        calc: statusCalculator.gypsumCeiling,
      },
    }

    // eslint-disable-next-line no-prototype-builtins
    if (mapping.hasOwnProperty(calcType)) {
      const { state, calc } = mapping[calcType]
      state(true)
      setCalc(calc)
    }
  }

  const handleClose = () => {
    setCieloRaso(false)
    setPvc(false)
    setParedGypsum(false)
    setTechoGypsum(false)
    setCalcResult(null)
    setSheet(null)
    setPvcErrorMessage('')
    setChangeModal(false)
    setCalcType('')
    handleClean()
  }

  const handleCalculation = async e => {
    if (e) {
      e.preventDefault()
    }
    if ((pvc && !sheet) || !width || !depth || !calc) {
      setPvcErrorMessage('MODAL_CALCULATOR_T.CALCULATE_ALERT_FIELD_REQUERIED')
      return
    }
    const payload = {
      width: parseFloat(width),
      depth: parseFloat(depth),
      sheet: parseFloat(sheet),
      mode: calc,
    }
    setPvcErrorMessage('')
    setIsLoading(true)
    await dispatch(calculatorAction(payload, setCalcResult))
    setIsLoading(false)
  }

  useEffect(() => {
    status()
  }, [calcType])

  const inputs = [
    {
      name: 'ancho',
      id: 'ancho-home',
      placeholder: 'Ancho (M)',
      onChange: value => setWidth(value),
    },
    {
      name: 'fondo',
      id: 'fondo-home',
      placeholder: 'Fondo (M)',
      onChange: value => setDepth(value),
    },
  ]

  return (
    <form
      onSubmit={handleCalculation}
      ref={refForm}
      style={{ overflow: 'scroll' }}>
      <div className="calculator-option-home">
        <h2>
          {t(
            cieloRaso
              ? 'MODAL_CALCULATOR_T.CALCULATE_ROOF_MATERIALS_TITLE'
              : pvc
              ? 'MODAL_CALCULATOR_T.CALCULATE_PVC_ROOF_TITLE'
              : paredGypsum
              ? 'MODAL_CALCULATOR_T.CALCULATE_GYPSUM_WALL_TITLE'
              : techoGypsum
              ? 'MODAL_CALCULATOR_T.CALCULATE_GYPSUM_ROOF_TITLE'
              : ''
          )}
        </h2>
        <img src={no} alt="close" onClick={handleClose} />
      </div>
      <div className="calculator-data-home">
        {inputs.map((input, index) => (
          <React.Fragment key={index}>
            <input
              type="text"
              name={input.name}
              id={input.id}
              placeholder={input.placeholder}
              onChange={e => input.onChange(e.target.value)}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  handleCalculation()
                }
              }}
            />
            {index < inputs.length - 1 && (
              <FontAwesomeIcon icon={faArrowRightArrowLeft} />
            )}
          </React.Fragment>
        ))}
      </div>
      {pvc && (
        <div className="calculator-laminas">
          <h2>{`${t('MODAL_CALCULATOR_T.CALCULATE_RESULTS_LABEL')}: `}</h2>
          <div className="calculator-laminas-options">
            {calcOptions.map((option, index) => (
              <div
                className={`calculator-lamina-option ${
                  index === selectedSheetIndex ? 'selected' : ''
                }`}
                key={`option-${index}`}>
                <label htmlFor={`sheetop${index + 1}`}>
                  {option.value}
                  <input
                    type="radio"
                    name="sheet"
                    id={`sheetop${index + 1}`}
                    value={option.value}
                    onClick={handleSheet}
                  />
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
      {pvcErrorMessage && (
        <p className="pvcErrorMessage">{t(pvcErrorMessage)}</p>
      )}
      {calcResult && (
        <div className="calc-result-table">
          <h2>{t('MODAL_CALCULATOR_T.CALCULATE_RESULTS_LABEL')}</h2>
          <Table striped>
            <tbody>
              {calcResult.data.map((value, index) => (
                <tr key={`result-${index}`}>
                  <td>{value.label}</td>
                  <td>{value.value}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      <div className="calculator-buttons-home">
        <button className="calculator-buttons-limpiar" onClick={handleClean}>
          {t('MODAL_CALCULATOR_T.CALCULATE_CLEAN_LABEL')}
        </button>
        <button type="submit" className="calculator-buttons-calcular">
          {!isLoading ? (
            t('MODAL_CALCULATOR_T.CALCULATE_LABEL')
          ) : (
            <FontAwesomeIcon icon={faSpinner} className="spinner" />
          )}
        </button>
      </div>
    </form>
  )
}

export default CalculatorProcess
