import {
  ADD_TO_LIST,
  REMOVE_FROM_LIST,
  CLEAR_LIST,
  CART_FROM_LIST,
} from '../types'

const addToList = payload => {
  return {
    type: ADD_TO_LIST,
    payload,
  }
}

const removeFromList = payload => {
  return {
    type: REMOVE_FROM_LIST,
    payload,
  }
}

const clearList = () => {
  return {
    type: CLEAR_LIST,
  }
}

const cartFromList = bool => {
  return {
    type: CART_FROM_LIST,
    payload: bool,
  }
}

export { addToList, removeFromList, clearList, cartFromList }
