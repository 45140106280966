import React, { useState, useEffect, useRef } from 'react'
import Navigation from '../../Components/Navigation'
import { useDispatch, useSelector } from 'react-redux'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SingleProductCart from '../../Components/SingleProductCart'
import SummaryOrderCart from '../../Components/SummaryOrderCart'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch } from 'react-instantsearch-hooks-web'
import InfiniteHits from '../../Components/InfiniteHits'
import {
  addSalesAgent,
  addSalesAgentFullName,
  agregarCliente,
  agregarCotizacion,
  agregarPDV,
  agregarSucursal,
  cotizacionPriceChangeApproval,
  cotizacionReStart,
  guardarTypeDocument,
  mostrarDescuento,
  updateCotizacionCompany,
} from '../../redux/actions/cotizacionActions.js'
import { useHistory } from 'react-router-dom'
import './ventaPorProducto.css'
import { emptyCart } from '../../redux/actions/cartActions'
import { Modal } from 'react-bootstrap'
import ProductDetail from '../../Components/ProductDetail'
import { ClickAwayListener } from '@mui/material'
import LightTooltip from '../../Components/LightTooltip'
import { t } from 'i18next'
import CustomSearchBox from 'Components/CustomSearchBox'
import { ROUTES, VAPermissions } from 'Utils/constants'
import { Hit } from './components/Hit'
import { useCotizationLogic } from './hooks/useCotizationLogic'
import No from '../../Assets/Images/Login/no.svg'
import ModalCustomerCounted from 'Components/ModalCustomerCounted'
import { CustomerSection } from './components/CustomerSection/CustomerSection'
import useValidatePermission from 'Hooks/useValidatePermission'
import { useHasProductWithPriceUnderCost } from 'Components/CartProduct/hooks/useHasProductWithPriceUnderCost'
import ModalAlertCart from 'Components/ModalAlertCart/ModalAlertCart'

const searchClient = algoliasearch(
  'ZCZRBTYD8I',
  '6c9235a951666253b5b003e1cf34d9ce'
)

function VentaPorProducto() {
  const { cotizationType } = useCotizationLogic()
  const { isCommercialSeller, hasPriceUnderCost } =
    useHasProductWithPriceUnderCost()
  const contentModal = useRef(null)
  const [client, setClient] = useState(null)

  const [showModalCustomerCounted, setShowModalCustomerCounted] =
    useState(false)

  const [customerCounted, setCustomerCounted] = useState(null)

  const dispatch = useDispatch()
  const history = useHistory()

  const [visibleSummaryOrder, setVisibleSummaryOrder] = useState(true)

  const cerrarInstaSearch = () => {
    setVisibleSummaryOrder(!visibleSummaryOrder)
  }

  // Redux
  const cart = useSelector(state => state.cart.cart)

  const cotizacion = useSelector(state => state.cotizacion?.QuoteHeader)

  const userId = useSelector(
    state => state?.login?.user?.CustomerInfo?.CustomerId
  )
  const userName = useSelector(
    state => state?.login?.user?.CustomerInfo?.FullName
  )

  const selectedWarehouse = useSelector(state => state?.company)
  // Modal Produt Details
  const [isOpen, setIsOpen] = useState(false)
  const [productDetails, setProductDetails] = useState({})
  const [openWithInventario, setOpenWithInventario] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [direccion, setDireccion] = useState(false)
  // Tooltip
  const [isOpenTooltip, setIsOpenTooltip] = useState(false)
  // PRICE OVERRIDE PERMISSION

  // get company data to know if have default warehouse
  const company = useSelector(state => state.company)
  const [hasDefaultWarehouse, setHasDefaultWarehouse] = useState(false)

  const setDefaultWarehouseToQuotation = () => {
    const warehouse = {
      WarehouseId: company?.WarehouseId,
      WarehouseDescription: company?.WarehouseDescription,
      WarehouseAddress: company?.WarehouseAddress,
      WarehouseCode: company?.WarehouseCode,
      WarehouseName: company?.WarehouseName,
      Description: company?.WarehouseDescription,
    }
    dispatch(agregarSucursal(warehouse))
    setDireccion(false)
    setHasDefaultWarehouse(true)
    setSelectedAddress(warehouse)
  }

  const canChangePrice = useValidatePermission(VAPermissions.priceOverrideAutho)

  const disabledButton = () => {
    const hasProducts = cart?.length
    const invalidAmount = cart.some(product => product.Amount === 0)
    return invalidAmount || !hasProducts || !client || !selectedAddress
  }

  const handlePriceChange = lines => {
    const approvedLines = lines.map(line => {
      const newLine = {
        ...line,
        AuthorizedPriceBy: userId,
        AuthorizedPriceByName: userName,
      }
      return newLine
    })

    dispatch(
      cotizacionPriceChangeApproval({
        Lines: approvedLines,
      })
    )
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleTooltip = bool => {
    setIsOpenTooltip(bool)
  }

  const handleSaveCotizacion = item => {
    if (item.id) {
      dispatch(
        guardarTypeDocument({
          HeaderStatusId: item.id,
          DocHeaderStatusDescription: item.value,
          DocHeaderStatusAction: item.valueState,
        })
      )
      if (!direccion) {
        const modifiedLines = cart.map(element => {
          const l = {
            ...element,
            FulfillmentPlanId: element.FulfillmentPlanId,
            FulfillmentPlanDescription: element.FulfillmentPlanDescription,
            WarehouseId: selectedAddress?.WarehouseId,
            WarehouseDescription: selectedAddress?.Description,
            WarehouseAddress: selectedAddress?.WarehouseAddress,
            WarehouseCode: selectedAddress?.Code,
          }
          return l
        })
        dispatch(addSalesAgentFullName(userName))
        dispatch(agregarPDV(modifiedLines))
        dispatch(agregarCotizacion({ Lines: modifiedLines }))
        if (canChangePrice && cotizacion.NegotiatedReasonId) {
          if (isCommercialSeller) {
            !hasPriceUnderCost && handlePriceChange(modifiedLines)
          } else {
            handlePriceChange(modifiedLines)
          }
        }
      } else {
        const modifiedLines = cart.map(element => {
          const l = {
            ...element,
            FulfillmentPlanId: element.FulfillmentPlanId,
            FulfillmentPlanDescription: element.FulfillmentPlanDescription,
            WarehouseId: selectedAddress?.WarehouseId,
            WarehouseDescription: selectedAddress?.Description,
            WarehouseAddress: selectedAddress?.WarehouseAddress,
            WarehouseCode: selectedAddress?.Code,
          }
          return l
        })
        dispatch(addSalesAgentFullName(userName))
        dispatch(agregarPDV(modifiedLines))
        dispatch(agregarCotizacion({ Lines: modifiedLines }))
        if (canChangePrice && cotizacion.NegotiatedReasonId) {
          if (isCommercialSeller) {
            !hasPriceUnderCost && handlePriceChange(modifiedLines)
          } else {
            handlePriceChange(modifiedLines)
          }
        }
      }
      dispatch(mostrarDescuento(true))
      history.push(ROUTES.generateQuote)
    } else {
      handleTooltip(false)
    }
  }

  useEffect(() => {
    dispatch(emptyCart())
    dispatch({ type: 'COTIZACION_REMOVE' })
    dispatch(cotizacionReStart())
    dispatch(addSalesAgent(userId))
  }, [])

  const handleCustomerCounted = () => {
    dispatch(
      agregarCliente({
        id: null,
        customerType: null,
        cliente: customerCounted,
        correo: null,
        externalCode: null,
        priceListId: null,
      })
    )
    setClient({ FullName: customerCounted })
    setDefaultWarehouseToQuotation()
    setShowModalCustomerCounted(false)
  }

  useEffect(() => {
    dispatch(
      updateCotizacionCompany({
        CompanyCode: selectedWarehouse.CompanyCode,
        CompanyName: selectedWarehouse.CompanyName,
        CompanyId: selectedWarehouse.CompanyId,
        WarehouseCode: selectedWarehouse.WarehouseCode,
        WarehouseId: selectedWarehouse.WarehouseId,
        WarehouseDescription: selectedWarehouse.WarehouseDescription,
        WarehouseName: selectedWarehouse.WarehouseName,
        WarehouseAddress: selectedWarehouse.WarehouseAddress,
        DefaultWarehouse: selectedWarehouse.DefaultWarehouse,
      })
    )
  }, [selectedWarehouse])

  return (
    <>
      <Navigation />
      <div className="product_sale_body" ref={contentModal}>
        <ModalAlertCart preQuote={false} shopping={false} saleProduct={true} />
        <Row className="product_sale_container">
          <Col xs={12} lg={3} className="product-sale-info">
            <CustomerSection
              client={client}
              hasDefaultWarehouse={hasDefaultWarehouse}
              selectedAddress={selectedAddress}
              setSelectedAddress={setSelectedAddress}
              setDireccion={setDireccion}
              direccion={direccion}
              setCustomerCounted={setCustomerCounted}
              setClient={setClient}
              customerCounted={customerCounted}
              setShowModalCustomerCounted={setShowModalCustomerCounted}
              setDefaultWarehouseToQuotation={setDefaultWarehouseToQuotation}
            />
          </Col>

          <Col xs={12} lg={9} className="single-product-search">
            {visibleSummaryOrder ? (
              <>
                <div className="product-sale-search-product">
                  <InstantSearch
                    indexName="DB_Cochez"
                    searchClient={searchClient}
                    onStateChange={({ uiState, setUiState }) => {
                      if (uiState?.DB_Cochez?.query?.length) {
                        setUiState({
                          ...uiState,
                          query: '',
                          page: 0,
                        })
                      }
                    }}>
                    <InfiniteHits
                      SearchBox={
                        <CustomSearchBox
                          placeholder={t(
                            'SELL_BY_PROD_T.SEARCHBOX_PLACEHOLDER'
                          )}
                        />
                      }
                      client={client}
                      hitComponent={Hit}
                      setProductDetails={product => setProductDetails(product)}
                      setIsOpen={bool => setIsOpen(bool)}
                      setOpenWithInventario={bool =>
                        setOpenWithInventario(bool)
                      }
                    />
                  </InstantSearch>
                </div>

                <div className="product-sale-cart">
                  <h2>
                    {' '}
                    {t('SELL_BY_PROD_T.ORDER_RESUME') +
                      '   (' +
                      cart.length +
                      ')'}
                  </h2>

                  <SingleProductCart />

                  <p
                    className="texto-Summary-Order"
                    onClick={cerrarInstaSearch}>
                    {' '}
                    {visibleSummaryOrder & (cart.length > 5)
                      ? 'Ver Resumen Completo'
                      : null}
                  </p>
                </div>
                <div className="product-sale-button">
                  <ClickAwayListener onClickAway={() => handleTooltip(true)}>
                    <>
                      <LightTooltip
                        title={cotizationType.map((item, index) => {
                          return (
                            <button
                              disabled={item.disabled}
                              key={index}
                              className="products-cart-button-tooltip"
                              onClick={() => {
                                handleSaveCotizacion(item)
                              }}>
                              {item.name}
                            </button>
                          )
                        })}
                        PopperProps={{
                          disablePortal: true,
                        }}
                        placement="top"
                        arrow={true}
                        open={isOpenTooltip}
                        onClose={() => handleTooltip(false)}
                        disableFocusListener
                        disableTouchListener>
                        {visibleSummaryOrder && (
                          <button
                            onClick={() => {
                              handleTooltip(true)
                            }}
                            className="btn"
                            disabled={disabledButton()}>
                            {t('SELL_BY_PROD_T.GENERATE_QUOTATION')}{' '}
                          </button>
                        )}
                      </LightTooltip>
                    </>
                  </ClickAwayListener>
                </div>
              </>
            ) : (
              <div className="product-SummerOrder">
                <h2>
                  {' '}
                  {t('SELL_BY_PROD_T.ORDER_RESUME') +
                    '   (' +
                    cart.length +
                    ')'}
                  <img
                    className="img-Close-Summary-Order"
                    src={No}
                    onClick={cerrarInstaSearch}
                    alt="close"
                  />
                </h2>

                <SummaryOrderCart />
              </div>
            )}
          </Col>
          <ModalCustomerCounted
            showModalCustomerCounted={showModalCustomerCounted}
            setShowModalCustomerCounted={setShowModalCustomerCounted}
            customerCounted={customerCounted}
            setCustomerCounted={setCustomerCounted}
            addCustomerCounted={handleCustomerCounted}
          />
          <Modal
            show={isOpen}
            onHide={handleClose}
            container={contentModal}
            backdrop={false}
            enforceFocus={false}
            autoFocus={false}
            className="modal-clase-producto-cotizacion">
            <ProductDetail
              id={productDetails.ProductId}
              producto_final={productDetails}
              price={productDetails.precio}
              cost={productDetails?.Cost || productDetails?.AvgCost || 0.0}
              openWithInventario={openWithInventario}
              handleClose={handleClose}
            />
          </Modal>
        </Row>
      </div>
    </>
  )
}

export default VentaPorProducto
