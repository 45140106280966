import React, { useState, useRef } from 'react'
import './branchPicker.css'
import { useSelector } from 'react-redux'
import search from '../../Assets/Images/BuscarCotizacion/search.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'

function BranchPicker({
  showMB,
  setShowMB,
  home,
  company,
  setCurrentWarehouse,
  setShowAlertModalBranch,
  setSelectedWarehouse,
}) {
  // Get the current path
  const location = useLocation()
  const currentPath = location.pathname

  const warehouses = useSelector(
    state => state?.login?.user?.CustomerInfo?.Warehouses
  )
  const filterWarehouses = companyCode => {
    return warehouses?.filter(warehouse => {
      return warehouse?.CompanyCode === companyCode
    })
  }

  const [currentWarehouses, setCurrentWarehouses] = useState(
    filterWarehouses(company)
  )

  const searchBranch = event => {
    const value = event.target.value.toLowerCase()
    setCurrentWarehouses(
      warehouses?.filter(warehouse => {
        return (
          warehouse?.WarehouseName.toLowerCase().includes(value) &&
          warehouse.CompanyCode === company
        )
      })
    )
  }
  const searchBranchText = value => {
    setCurrentWarehouses(
      warehouses?.filter(warehouse => {
        return (
          warehouse?.WarehouseName.toLowerCase().includes(value) &&
          warehouse.CompanyCode === company
        )
      })
    )
  }
  const searchTextRef = useRef(null)

  const handleAlert = warehouse => {
    if (
      currentPath.includes('editar_cotizacion') ||
      currentPath.includes('crear_cotizacion') ||
      currentPath.includes('cotizar_producto')
    ) {
      setShowAlertModalBranch(true)
      setCurrentWarehouse(warehouse)
    } else {
      setCurrentWarehouse(warehouse)
      setSelectedWarehouse(warehouse)
    }
    setShowMB(false)
  }

  return (
    <div
      className={showMB ? 'branchpicker-menu col' : 'branchpicker-menu-off '}
      style={{ left: home ? '440px' : '500px' }}>
      <div>
        <div className="row icon-arrow">
          <FontAwesomeIcon
            className="sp open icon-branch"
            icon={faAnglesLeft}
            onClick={() => setShowMB(false)}
          />
        </div>

        <div>
          <input
            ref={searchTextRef}
            onChange={searchBranch}
            onKeyDown={searchBranch}
            type="text"
            className="sucursal-search"
            placeholder="Buscar"
          />
          <img
            src={search}
            className="suc-search-icon"
            alt="search"
            onClick={() => searchBranchText(searchTextRef.current.value)}
          />
        </div>
        <div>
          <ul className="list-branches">
            {currentWarehouses.map((row, index) => {
              return (
                <li key={index} className="action-table-cell-branch">
                  <span onClick={() => handleAlert(row)}>
                    {row.WarehouseName}
                  </span>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default BranchPicker
