import React from 'react'
import calculatorBlue from '../../Assets/Images/Home/calculadora.svg'
import CalculatorProcess from '../CalculatorProcess'
import { t } from 'i18next'

function FloatingCalculator({ setChangeModal, calcType, setCalcType }) {
  const handleCalcAction = newCalc => {
    setCalcType(newCalc)
    setChangeModal(true)
  }

  return (
    <>
      {!calcType ? (
        <>
          <div className="modal-calculadora-options">
            <div
              className="calculator-option"
              onClick={() => handleCalcAction('cieloRaso')}>
              <img src={calculatorBlue} alt="calculadora" />
              <h2>{t('MODAL_CALCULATOR_T.CALCULATE_ROOF_MATERIALS_TITLE')}</h2>
            </div>
            <div
              className="calculator-option"
              onClick={() => handleCalcAction('pvc')}>
              <img src={calculatorBlue} alt="calculadora" />
              <h2>{t('MODAL_CALCULATOR_T.CALCULATE_PVC_ROOF_TITLE')}</h2>
            </div>
            <div
              className="calculator-option"
              onClick={() => handleCalcAction('paredGypsum')}>
              <img src={calculatorBlue} alt="calculadora" />
              <h2>{t('MODAL_CALCULATOR_T.CALCULATE_GYPSUM_WALL_TITLE')}</h2>
            </div>
            <div
              className="calculator-option"
              onClick={() => handleCalcAction('techoGypsum')}>
              <img src={calculatorBlue} alt="calculadora" />
              <h2>{t('MODAL_CALCULATOR_T.CALCULATE_GYPSUM_ROOF_TITLE')}</h2>
            </div>
          </div>
        </>
      ) : (
        <CalculatorProcess
          calcType={calcType}
          setCalcType={setCalcType}
          setChangeModal={setChangeModal}
        />
      )}
    </>
  )
}

export default FloatingCalculator
