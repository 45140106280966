import React from 'react'
import { images } from 'Assets/Images'
import {
  CheckCircleFill,
  PencilSquare,
  XCircleFill,
} from 'react-bootstrap-icons'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)
const currentMonth = new Date().toLocaleString('es-ES', { month: 'long' })
const DEFAULT_VALUE = `Promoción válida del 1 al 30 de ${capitalize(
  currentMonth
)} 2024. Precio no incluye ITBMS`

export const Square1NOffer = ({ products, i }) => {
  const { currentPage, allTemplatesContent, setAllTemplatesContent } =
    usePrinterContext()

  const currentContent = allTemplatesContent.find(
    content => content.page === currentPage
  )
  const currentTerms = currentContent?.content?.terms || DEFAULT_VALUE

  const [isEditing, setIsEditing] = React.useState(false)
  const [terms, setTerms] = React.useState(currentTerms)

  const prod = products?.content?.products?.[0]

  const handleEditTerms = () => {
    const updatedContent = allTemplatesContent.map(content => {
      if (content.page === currentPage) {
        return {
          ...content,
          content: {
            ...content.content,
            terms,
          },
        }
      }
      return content
    })
    setAllTemplatesContent(updatedContent)
    setIsEditing(false)
  }

  return (
    <div
      id="printer-preview-left-canvas"
      className="printer-preview-left-canvas">
      <div className="printer-preview-left-content-body">
        <p className="printer-preview-left-content-body-title">
          {prod?.ProductName || ''}
        </p>
        <p className="printer-preview-left-content-body-sku">
          {prod?.Sku || ''}
        </p>
        {prod?.Price && (
          <div className="printer-preview-left-content-body-price-wrapper">
            <p className="printer-preview-mini-text">$</p>
            <p
              className="printer-preview-left-content-body-price"
              style={{ fontSize: '170px', marginTop: '60px' }}>
              {prod?.Price?.toString()?.split('.')?.[0] || ''}.
            </p>
            <p className="printer-preview-mini-text">
              {prod?.Price?.toString()?.split('.')?.[1] || '00'}
            </p>
          </div>
        )}
        {prod?.BasePrice && (
          <div
            style={{
              position: 'absolute',
              bottom: '-84px',
              right: 0,
              width: '170px',
              height: '1px',
              background: 'red',
              transform: 'rotate(-6deg)',
            }}
          />
        )}
        {prod?.BasePrice && (
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              gap: '10px',
              position: 'absolute',
              bottom: '-100px',
              right: '0',
            }}>
            <p
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                marginBottom: '-5px',
              }}>
              Antes
            </p>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <p
                className="printer-preview-mini-text"
                style={{ color: '#000', fontSize: '20px', marginTop: '-12px' }}>
                $
              </p>
              <p
                className="printer-preview-left-content-body-price"
                style={{ color: '#000', fontSize: '45px', marginTop: 0 }}>
                {prod?.BasePrice?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p
                className="printer-preview-mini-text"
                style={{ color: '#000', fontSize: '20px', marginTop: '-12px' }}>
                {prod?.BasePrice?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="printer-preview-left-content-footer">
        <div className="printer-preview-content-footer-input-wrapper">
          {isEditing && (
            <textarea
              className="printer-preview-left-content-footer-legal-1"
              type="text"
              value={terms}
              disabled={!isEditing}
              onChange={e => setTerms(e.target.value)}
              style={{
                border: isEditing ? '1px solid #000' : 'none',
                textAlign: 'end',
                background: 'transparent',
              }}
            />
          )}
          {!isEditing && (
            <p
              className="printer-preview-left-content-footer-legal-1"
              style={{
                border: isEditing ? '1px solid #000' : 'none',
                textAlign: 'end',
                background: 'transparent',
              }}>
              {terms}
            </p>
          )}
          <div className="printer-terms-edit-wrapper-1">
            {isEditing && (
              <CheckCircleFill
                onClick={handleEditTerms}
                className="printer-preview-content-check"
              />
            )}
            {isEditing && (
              <XCircleFill
                onClick={() => {
                  setIsEditing(false)
                  setTerms(DEFAULT_VALUE)
                }}
                className="printer-preview-content-circle"
              />
            )}
            {!isEditing && (
              <PencilSquare
                onClick={() => setIsEditing(true)}
                className="printer-preview-content-pencil"
              />
            )}
          </div>
        </div>
      </div>
      <img
        className="printer-preview-left-image"
        src={images.printer.templates.novey.offer1}
        alt="template-preview"
      />
    </div>
  )
}
