import React from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import './errorHandler.css'
import { ROUTES } from 'Utils/constants'

function ErroHandler({ error }) {
  return (
    <Modal show={true} centered>
      <div className="error-handler-modal">
        {error === 'Not Found' ? (
          <h1>Esta página no existe.</h1>
        ) : (
          <h1>UPS! Ha ocurrido un error</h1>
        )}
        <Link to={ROUTES.home}>
          <button className="quotes-btn">Regresar a home</button>
        </Link>
      </div>
    </Modal>
  )
}

export default ErroHandler
