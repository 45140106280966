import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CompanyPicker from '../CompanyPicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import cochez from '../../Assets/Images/Logos/cochez.png'
import novey from '../../Assets/Images/Logos/novey.png'
import marker from '../../Assets/Images/MetodoEntrega/geo-alt-fill.svg'
import Loading from '../Loading/index'
import Submenu from '../UserSubMenu/index'
import './horizontalNav.css'
import BranchPicker from 'Components/BranchPicker'
import { Box, IconButton, Modal } from '@mui/material'
import GenericModal from 'Components/GenericModal'
import { setWareHouse } from 'redux/actions/companyAction'
import { t } from 'i18next'
import { refreshShoppingCart } from 'Utils/functions'
import MenuIcon from '@mui/icons-material/Menu'
import styles from './horizontalNav.module.css'

function HorizontalNav({ home, handleDrawer }) {
  // USER STATE REDUX
  const { user } = useSelector(state => state.login)
  const customerId = useSelector(
    state => state.cotizacion?.QuoteHeader?.CustomerId
  )
  const warehouses = useSelector(
    state => state?.login?.user?.CustomerInfo?.Warehouses
  )
  const company = useSelector(state => state?.company?.CompanyCode)
  const menuRef = useRef()

  const [open, setOpen] = useState(false)
  const [companyOpen, setCompanyOpen] = useState(false)
  const [branchOpen, setBranchOpen] = useState(false)
  const currentWarehouseState = useSelector(state => state?.company)

  const [currentWarehouse, setCurrentWarehouse] = useState(
    currentWarehouseState
  )
  const [showAlertModalBranch, setShowAlertModalBranch] = useState(false)

  const [logo, setLogo] = useState(
    window.localStorage.getItem('company') === 'Cochez' ? cochez : novey
  )
  const cart = useSelector(state => state.cart.cart)

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleCompany = () => {
    setCompanyOpen(!companyOpen)
  }
  const handleBranch = () => {
    setBranchOpen(!branchOpen)
  }
  const dispatch = useDispatch()

  const setSelectedWarehouse = () => {
    dispatch(setWareHouse(currentWarehouse))
    setBranchOpen(false)
    setShowAlertModalBranch(false)
    refreshShoppingCart({ cart, customerId, dispatch })
  }

  const setSelectedWarehouseBranch = warehouse => {
    dispatch(setWareHouse(warehouse))
    setBranchOpen(false)
    setShowAlertModalBranch(false)
    refreshShoppingCart({ cart, customerId, dispatch })
  }

  return (
    <div data-testid="horizontal-nav-wrapper">
      {user ? (
        <nav className="horizontal-navbar">
          <Box
            component="ul"
            className="horizontal-icons-container"
            sx={{
              paddingLeft: {
                xs: '36px',
                sm: '106px',
              },
            }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawer}
              sx={{ display: { xs: 'flex', sm: 'none' }, fontSize: '30px' }}>
              <MenuIcon
                sx={{
                  fontSize: '20px',
                }}
              />
            </IconButton>
            <li>
              <Box
                component="div"
                sx={{
                  maxWidth: {
                    xs: '130px',
                    sm: '221.81px',
                  },
                }}
                className="h-nav-icon tenant icon-branch-div"
                onClick={handleCompany}>
                <img className="display-image" src={logo} alt="logo" />

                <FontAwesomeIcon
                  className="down-chev"
                  icon={faChevronDown}></FontAwesomeIcon>
              </Box>
            </li>
            <li>
              <div className="h-nav-icon tenant row" onClick={handleBranch}>
                <div className="col-2">
                  <img src={marker} alt="marcador" />
                </div>

                <div className="col-10">
                  <span className="row">
                    {' '}
                    {t('CHANGE_BRANCH.SEE_THE_INVENTARY')}
                  </span>

                  <strong data-testid="warehouse-description" className="row">
                    {currentWarehouseState?.WarehouseDescription}
                  </strong>

                  {!!warehouses.length && (
                    <span className={`row ${styles.changeBranchText}`}>
                      {t('CHANGE_BRANCH.CHOSE_ANOTHER_BRANCH')}
                    </span>
                  )}
                </div>
              </div>
            </li>
            <li>
              <div
                className="h-nav-icon user"
                onClick={handleOpen}
                data-testid="user-menu"
                ref={menuRef}>
                <p data-testid="profile-miniature" className="profile-pic">
                  {user.CustomerInfo.FirstName[0]}
                  {user.CustomerInfo.LastName[0]}
                </p>
                <Box
                  component="p"
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'block',
                    },
                  }}
                  data-testid="username"
                  className="username">
                  {user.CustomerInfo.FirstName} {user.CustomerInfo.LastName}
                </Box>
              </div>
            </li>
          </Box>
          <Submenu show={open} setShow={setOpen} menuRef={menuRef} />
          <div>
            <Modal
              open={companyOpen}
              onClose={() => {
                setCompanyOpen(false)
              }}
              BackdropProps={{
                style: { backgroundColor: 'rgba(0, 0, 0, 0)' },
              }}>
              <Box>
                <CompanyPicker
                  home={home}
                  show={companyOpen}
                  setShow={setCompanyOpen}
                  setLogo={setLogo}
                  logo={logo}
                />
              </Box>
            </Modal>
            <Modal
              onClose={handleBranch}
              open={branchOpen}
              BackdropProps={{
                style: { backgroundColor: 'rgba(0, 0, 0, 0)' },
              }}
              className="modal-up">
              <Box>
                <BranchPicker
                  home={home}
                  showMB={branchOpen}
                  setShowMB={setBranchOpen}
                  company={company}
                  setShowAlertModalBranch={setShowAlertModalBranch}
                  setCurrentWarehouse={setCurrentWarehouse}
                  setSelectedWarehouse={setSelectedWarehouseBranch}
                />
              </Box>
            </Modal>
          </div>
        </nav>
      ) : (
        <Loading />
      )}
      <Modal
        open={showAlertModalBranch}
        onClose={() => {
          setShowAlertModalBranch(false)
        }}>
        <Box>
          <GenericModal
            hideCheck={true}
            showCaution={true}
            setConfirm={setSelectedWarehouse}
            setIsOpen={() => setShowAlertModalBranch(false)}
            text={t('GENERIC_MODAL.ARE_SURE_TO_CHANGE')}
            mostrarButtons={true}
            text2={t('GENERIC_MODAL.WANT_TO_CONTINUE')}></GenericModal>
        </Box>
      </Modal>
    </div>
  )
}

export default HorizontalNav
