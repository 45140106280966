import { useContext } from 'react'
import { useFormik } from 'formik'
import {
  VAPermissions,
  customerTypeAllowedRoles,
  customerTypeId,
  documentTypeIds,
  regexCompanyName,
  telephoneTypeIds,
} from 'Utils/constants'
import { t } from 'i18next'
import { VAContext } from 'Context/VAContext'
import moment from 'moment'
import {
  LEGAL_INITIAL_VALUES,
  NATURAL_INITIAL_VALUES,
} from '../constants/initialValues'
import { useValidations } from './useValidations'
import { useValidationSchema } from './useValidationSchema'
import useValidatePermission from 'Hooks/useValidatePermission'
import { CustomerContext } from 'state/context/CustomerFormContext'
import { useValidateRoleAndPermission } from 'Hooks/useValidateRoleAndPermission'

export const useFormLogic = ({
  valueStateSelectCustomerGroup,
  validateAndSetAddress,
  updateClient,
  saveClient,
}) => {
  const { clientSelected } = useContext(VAContext)
  const { mandatoryAddress } = CustomerContext()
  const isEditing = clientSelected?.edit

  const canUseNaturalCustomerType = useValidatePermission(
    VAPermissions.customerType
  )

  const canUseLegalCustomerType = useValidateRoleAndPermission(
    Object.values(customerTypeAllowedRoles),
    VAPermissions.customerType
  )

  const {
    validateDocument,
    validatePhoneNumber,
    validateEmail,
    validateAddress,
    validateGenderId,
  } = useValidations()
  const { naturalValidationSchema, legalValidationSchema } =
    useValidationSchema()

  function updateGeneralTelephone(values, telephoneTypeIds) {
    const isLegalDocument = values.generalStore
    const generalTelephone = isLegalDocument
      ? values.generalStore[0].generalTelephone[0]?.telephoneTypeId?.toLowerCase()
      : values.generalTelephone[0]?.telephoneTypeId?.toLowerCase()
    const telephoneTypeToField = {
      [telephoneTypeIds.house]: 'defaultHome',
      [telephoneTypeIds.office]: 'defaultWork',
      [telephoneTypeIds.cellPhone]: 'defaultMobile',
    }
    const updatedGeneralTelephone = isLegalDocument
      ? { ...values.generalStore[0].generalTelephone[0] }
      : { ...values.generalTelephone[0] }

    for (const field of Object.values(telephoneTypeToField)) {
      updatedGeneralTelephone[field] = false
    }

    if (telephoneTypeToField[generalTelephone]) {
      updatedGeneralTelephone[telephoneTypeToField[generalTelephone]] = true
    }

    isLegalDocument
      ? (values.generalStore[0].generalTelephone[0] = updatedGeneralTelephone)
      : (values.generalTelephone[0] = updatedGeneralTelephone)
  }

  const formikLegal = useFormik({
    initialValues: LEGAL_INITIAL_VALUES,
    validationSchema: legalValidationSchema,
    validate: async values => {
      const errors = {}

      validatePhoneNumber({ values, errors })
      validateEmail({ values, errors })
      validateDocument({ values, errors })
      !isEditing && validateAddress({ values, errors })

      if (!values.generalGroup[0]?.customerGroupId && canUseLegalCustomerType) {
        errors.generalGroup = t('CREATE_CLIENT_T.INTRODUCE_CUSTOMER_GROUP')
      }

      if (!values.legalName || !regexCompanyName.test(values.legalName)) {
        errors.legalName = t('CREATE_CLIENT_T.INTRODUCE_VALID_COMPANY_NAME')
      }

      return errors
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)

      updateGeneralTelephone(values, telephoneTypeIds)

      const legalName = values.legalName.toUpperCase()
      values.legalName = legalName
      values.generalStore[0].name = legalName
      values.customerTypeId = customerTypeId.legal
      values.customerStatusId = '081e533e-be36-4b79-87a9-d6336eab5aaf'
      const addressData = await validateAndSetAddress()
      values.generalStore[0].generalAddress = addressData
        ? [
            {
              ...addressData,
              Latitude: values?.latitude,
              Longitude: values?.longitude,
            },
          ]
        : null
      isEditing && delete values.generalStore?.[0].generalAddress
      if (!values.generalGroup?.[0]?.customerGroupId) {
        delete values.generalGroup
      }
      isEditing ? updateClient(values) : saveClient(values)
    },
  })

  const formikNatural = useFormik({
    initialValues: NATURAL_INITIAL_VALUES,
    validationSchema: naturalValidationSchema,
    validate: async values => {
      const errors = {}
      validatePhoneNumber({ values, errors })
      validateEmail({ values, errors })
      validateDocument({ values, errors })
      if (
        !values.generalGroup[0]?.customerGroupId &&
        canUseNaturalCustomerType
      ) {
        errors.generalGroup = t('CREATE_CLIENT_T.INTRODUCE_CUSTOMER_GROUP')
      }
      !isEditing && validateGenderId({ values, errors })
      !isEditing && mandatoryAddress && validateAddress({ values, errors })
      return errors
    },

    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)

      updateGeneralTelephone(values, telephoneTypeIds)
      values.fullName = `${values.firstName.toUpperCase()} ${values.lastName.toUpperCase()}`
      values.firstName = `${values.firstName.toUpperCase()}`
      values.lastName = `${values.lastName.toUpperCase()}`
      values.birthdate = !values?.birthdate
        ? null
        : moment(values.birthdate).format('YYYY-MM-DD')
      values.customerTypeId = customerTypeId.natural
      values.customerStatusId = documentTypeIds.natural.customerStatusId

      const addressData = await validateAndSetAddress()
      values.generalAddress = addressData
        ? [
            {
              ...addressData,
              Latitude: values?.latitude,
              Longitude: values?.longitude,
            },
          ]
        : null
      isEditing && delete values.generalAddress
      !mandatoryAddress && delete values.generalAddress
      if (!values.generalGroup?.[0]?.customerGroupId) {
        delete values.generalGroup
      }
      isEditing ? updateClient(values) : saveClient(values)
    },
  })
  return { formikLegal, formikNatural }
}
