import { t } from 'i18next'
import {
  documentTypeIds,
  regexCellPhone,
  regexEmail,
  regexGenderId,
  regexNT,
  regexPanamaId,
  regexPassport,
  regexPhone,
  regexRUC,
  telephoneTypeIds,
} from 'Utils/constants'

export const useValidations = () => {
  const validateDocument = ({ values, errors }) => {
    const documentTypeId = values.documentTypeId?.toLowerCase()
    const isLegalDocument = values.generalStore

    const documentErrorMessage = isLegalDocument
      ? {
          [documentTypeIds.legal.identificationCard]: t(
            'CREATE_CLIENT_T.INTRODUCE_VALID_IDENTIFICATION'
          ),
          [documentTypeIds.legal.NT]: t(
            'CREATE_CLIENT_T.INTRODUCE_VALID_NT_FORMAT'
          ),
          [documentTypeIds.legal.NTGovernment]: t(
            'CREATE_CLIENT_T.INTRODUCE_VALID_NT_INFO'
          ),
          [documentTypeIds.legal.RUC]: t(
            'CREATE_CLIENT_T.INTRODUCE_VALID_RUC_NAME'
          ),
        }[documentTypeId]
      : {
          [documentTypeIds.natural.identificationCard]: t(
            'CREATE_CLIENT_T.INTRODUCE_VALID_IDENTIFICATION'
          ),
          [documentTypeIds.natural.passport]: t(
            'CREATE_CLIENT_T.INTRODUCE_VALID_PASSPORT'
          ),
          [documentTypeIds.natural.NT]: t(
            'CREATE_CLIENT_T.INTRODUCE_VALID_NT_FORMAT'
          ),
        }[documentTypeId]

    const naturalDVValidation =
      !isLegalDocument &&
      documentTypeId === documentTypeIds.natural.NT &&
      !values.DV
    const legalDVValidation =
      isLegalDocument &&
      (documentTypeId === documentTypeIds.legal.RUC ||
        documentTypeId === documentTypeIds.legal.identificationCard ||
        documentTypeId === documentTypeIds.legal.NT ||
        documentTypeId === documentTypeIds.legal.NTGovernment) &&
      !values.dv

    if (naturalDVValidation) errors.DV = t('CREATE_CLIENT_T.INVALID_DV')
    if (legalDVValidation) errors.dv = t('CREATE_CLIENT_T.INVALID_DV')

    const idNumber = isLegalDocument ? values.ruc : values.idNumber

    if (!idNumber) {
      isLegalDocument
        ? (errors.ruc = documentErrorMessage)
        : (errors.idNumber = documentErrorMessage)
    } else {
      const documentTypeValidation = {
        [documentTypeIds.natural.identificationCard]: regexPanamaId,
        [documentTypeIds.natural.passport]: regexPassport,
        [documentTypeIds.natural.NT]: regexNT,
        [documentTypeIds.legal.identificationCard]: regexPanamaId,
        [documentTypeIds.legal.NT]: regexNT,
        [documentTypeIds.legal.NTGovernment]: regexNT,
        [documentTypeIds.legal.RUC]: regexRUC,
      }[documentTypeId]

      if (documentTypeValidation && !documentTypeValidation.test(idNumber)) {
        isLegalDocument
          ? (errors.ruc = documentErrorMessage)
          : (errors.idNumber = documentErrorMessage)
      }
    }
  }

  const validateEmail = ({ values, errors }) => {
    const email = values.generalStore
      ? values.generalStore[0].generalEmail[0]?.address
      : values.generalEmail[0]?.address

    if (!email) {
      errors.generalEmail = [
        { address: t('CREATE_CLIENT_T.INTRODUCE_VALID_EMAIL') },
      ]
    } else if (!regexEmail.test(email)) {
      errors.generalEmail = [
        { address: t('CREATE_CLIENT_T.INTRODUCE_VALID_EMAIL') },
      ]
    }
  }
  const validateAddress = ({ values, errors }) => {
    const hasCountryId = values?.countryId
    const hasProvinceId = values?.provinceId
    const hasDistrictId = values?.districtId
    const hasTownShipId = values?.townShipId
    const hasAddressDetail = values?.addressDetail?.trim()
    const hasAddressStreet = values?.addressStreet?.trim()
    if (!hasAddressDetail) {
      errors.addressDetail = t('CREATE_CLIENT_T.INTRODUCE_VALID_ADDRESS_DETAIL')
    }
    if (!hasAddressStreet) {
      errors.addressStreet = t('CREATE_CLIENT_T.INTRODUCE_VALID_STREET')
    }
    if (!hasCountryId) {
      errors.countryId = t('CREATE_CLIENT_T.SELECT_COUNTRY')
    }
    if (!hasProvinceId) {
      errors.provinceId = t('CREATE_CLIENT_T.SELECT_PROVINCE')
    }
    if (!hasDistrictId) {
      errors.districtId = t('CREATE_CLIENT_T.SELECT_DISTRICT')
    }
    if (!hasTownShipId) {
      errors.townShipId = t('CREATE_CLIENT_T.SELECT_TOWNSHIP')
    }
  }
  const validateGenderId = ({ values, errors }) => {
    const genderId = values?.genderId
    if (!genderId || !regexGenderId.test(genderId)) {
      errors.genderId = t('CREATE_CLIENT_T.INTRODUCE_VALID_GENDER')
    }
  }

  const validatePhoneNumber = ({ values, errors }) => {
    const phoneNumber = values.generalStore
      ? values.generalStore[0]?.generalTelephone[0]?.number
      : values.generalTelephone[0]?.number
    const telephoneType = values.generalStore
      ? values.generalStore[0]?.generalTelephone[0]?.telephoneTypeId?.toLowerCase()
      : values.generalTelephone[0]?.telephoneTypeId?.toLowerCase()
    const phoneErrorMessage =
      telephoneType === telephoneTypeIds.cellPhone
        ? t('CREATE_CLIENT_T.INTRODUCE_VALID_CELLPHONE')
        : t('CREATE_CLIENT_T.INTRODUCE_VALID_PHONE_FORMAT')

    if (!phoneNumber) {
      errors.generalTelephone = [{ number: phoneErrorMessage }]
    } else {
      const phoneNumberValidation = {
        [telephoneTypeIds.house]: regexPhone,
        [telephoneTypeIds.office]: regexPhone,
        [telephoneTypeIds.cellPhone]: regexCellPhone,
      }[telephoneType]

      if (phoneNumberValidation && !phoneNumberValidation.test(phoneNumber)) {
        errors.generalTelephone = [{ number: phoneErrorMessage }]
      }
    }
  }
  return {
    validatePhoneNumber,
    validateEmail,
    validateDocument,
    validateAddress,
    validateGenderId,
  }
}
