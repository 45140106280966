import React from 'react'
import { images } from 'Assets/Images'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'

const DEFAULT_VALUE =
  'La tarjeta de crédito CMF es emitida por CM Financiera, S.A., sujeto a previa aprobación de esa entidad. Para más información ingresa a cmfinanciera.com. Cuotas calculadas con tasa de interés nominal del 57% anual. La responsabilidad de CM Financiera se limita exclusivamente a aspectos propios del crédito otorgado y no por los productos o servicios adquiridos mediante el uso del crédito.'

export const Square3ToPrint = ({ products, i }) => {
  const { templateRef } = usePrinterContext()

  const terms = products?.content?.terms || DEFAULT_VALUE

  const prod1 = products?.content?.products?.[0]
  const prod2 = products?.content?.products?.[1] || {}
  const prod3 = products?.content?.products?.[2] || {}
  const prod4 = products?.content?.products?.[3] || {}

  return (
    <div ref={el => (templateRef.current[i] = el)}>
      <div
        id="printer-preview-left-canvas-square-3"
        className="printer-preview-left-canvas-square-3">
        <div
          className="printer-preview-left-canvas-square-3-box"
          style={{
            height: '100%',
            top: '0',
          }}>
          <div className="printer-preview-left-content-body-square-3">
            <p className="printer-preview-left-content-body-square-3-title">
              {prod1?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-3-sku">
              {prod1?.Sku || ''}
            </p>
            {prod1?.BasePrice && (
              <div className="printer-preview-left-content-body-square-3-price-wrapper">
                <p className="printer-preview-mini-text-square-3">$</p>
                <p className="printer-preview-left-content-body-square-3-price">
                  {prod1?.BasePrice?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p className="printer-preview-mini-text-square-3">
                  {prod1?.BasePrice?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
          </div>
          <div className="printer-preview-left-content-footer-square-3">
            <div className="printer-preview-left-content-footer-square-3-quotes">
              <p></p>
              <p className="printer-preview-left-content-footer-square-3-quote-text">
                36 <br /> cuotas
              </p>
              <p className="printer-preview-left-content-footer-square-3-quote-text">
                ${prod1?.cmfCredit || ''}
              </p>
            </div>
            <div className="printer-preview-content-footer-input-wrapper">
              <p
                className="printer-preview-left-content-footer-legal-3"
                style={{ border: 'none' }}>
                {terms}
              </p>
            </div>
          </div>
        </div>
        <div
          className="printer-preview-left-canvas-square-3-box"
          style={{
            height: '100%',
            top: '0',
            left: '50%',
          }}>
          <div className="printer-preview-left-content-body-square-3">
            <p className="printer-preview-left-content-body-square-3-title">
              {prod2?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-3-sku">
              {prod2?.Sku || ''}
            </p>
            {prod2?.BasePrice && (
              <div className="printer-preview-left-content-body-square-3-price-wrapper">
                <p className="printer-preview-mini-text-square-3">$</p>
                <p className="printer-preview-left-content-body-square-3-price">
                  {prod2?.BasePrice?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p className="printer-preview-mini-text-square-3">
                  {prod2?.BasePrice?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
          </div>
          <div className="printer-preview-left-content-footer-square-3">
            <div className="printer-preview-left-content-footer-square-3-quotes">
              <p></p>
              <p className="printer-preview-left-content-footer-square-3-quote-text">
                36 <br /> cuotas
              </p>
              <p className="printer-preview-left-content-footer-square-3-quote-text">
                ${prod2?.cmfCredit || ''}
              </p>
            </div>
            <div className="printer-preview-content-footer-input-wrapper">
              <p
                className="printer-preview-left-content-footer-legal-3"
                style={{ border: 'none' }}>
                {terms}
              </p>
            </div>
          </div>
        </div>
        <div
          className="printer-preview-left-canvas-square-3-box"
          style={{
            height: '50%',
            top: '50%',
            // background: 'green',
          }}>
          <div className="printer-preview-left-content-body-square-3-2">
            <p className="printer-preview-left-content-body-square-3-title-2">
              {prod3?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-3-sku-2">
              {prod3?.Sku || ''}
            </p>
            {prod3?.BasePrice && (
              <div className="printer-preview-left-content-body-square-3-price-wrapper">
                <p className="printer-preview-mini-text-square-3">$</p>
                <p className="printer-preview-left-content-body-square-3-price-2">
                  {prod3?.BasePrice?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p className="printer-preview-mini-text-square-3">
                  {prod3?.BasePrice?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
          </div>
          <div className="printer-preview-left-content-footer-square-3-2">
            <div className="printer-preview-left-content-footer-square-3-quotes">
              <p></p>
              <p className="printer-preview-left-content-footer-square-3-quote-text">
                36 <br /> cuotas
              </p>
              <p className="printer-preview-left-content-footer-square-3-quote-text">
                ${prod3?.cmfCredit || ''}
              </p>
            </div>
            <div className="printer-preview-content-footer-input-wrapper">
              <p
                className="printer-preview-left-content-footer-legal-3"
                style={{ border: 'none' }}>
                {terms}
              </p>
            </div>
          </div>
        </div>
        <div
          className="printer-preview-left-canvas-square-3-box"
          style={{
            height: '50%',
            top: '50%',
            left: '50%',
            // background: 'green',
          }}>
          <div className="printer-preview-left-content-body-square-3-2">
            <p className="printer-preview-left-content-body-square-3-title-2">
              {prod4?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-3-sku-2">
              {prod4?.Sku || ''}
            </p>
            {prod4?.BasePrice && (
              <div className="printer-preview-left-content-body-square-3-price-wrapper">
                <p className="printer-preview-mini-text-square-3">$</p>
                <p className="printer-preview-left-content-body-square-3-price-2">
                  {prod4?.BasePrice?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p className="printer-preview-mini-text-square-3">
                  {prod4?.BasePrice?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
          </div>
          <div className="printer-preview-left-content-footer-square-3-2">
            <div className="printer-preview-left-content-footer-square-3-quotes">
              <p></p>
              <p className="printer-preview-left-content-footer-square-3-quote-text">
                36 <br /> cuotas
              </p>
              <p className="printer-preview-left-content-footer-square-3-quote-text">
                ${prod4?.cmfCredit || ''}
              </p>
            </div>
            <div className="printer-preview-content-footer-input-wrapper">
              <p
                className="printer-preview-left-content-footer-legal-3"
                style={{ border: 'none' }}>
                {terms}
              </p>
            </div>
          </div>
        </div>
        <img
          className="printer-preview-left-image"
          src={images.printer.templates.cmf3}
          alt="template-preview"
          style={{ opacity: 0 }}
        />
      </div>
    </div>
  )
}
