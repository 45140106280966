import { SET_COMPANY, SET_WAREHOUSE } from '../types'

export const setCompany = code => ({
  type: SET_COMPANY,
  payload: code,
})

export const setWareHouse = payload => ({
  type: SET_WAREHOUSE,
  payload,
})
