import React, { useRef, useEffect } from 'react'
import './submenu.css'
import tienda from '../../Assets/Images/Navbar/shop.svg'
import diagrama from '../../Assets/Images/Navbar/diagrama.svg'
import { t } from 'i18next'
import { useLogout } from 'Hooks/useLogout'

function Submenu({ show, setShow, menuRef }) {
  const { logout } = useLogout()

  const subMenuRef = useRef()

  useEffect(() => {
    const handler = e => {
      if (!subMenuRef.current.contains(e.target)) setShow(false)
    }

    document.addEventListener('mousedown', handler)

    return () => {
      document.removeEventListener('mousedown', handler)
    }
  }, [])

  return (
    <div ref={subMenuRef} className={show ? 'user-menu' : 'user-menu-off'}>
      <ul>
        <li className="user-nav-item">
          <img src={tienda} alt="tienda" />
          <div className="user-nav-text">
            <p className="user-nav-title">Tienda</p>
            <p className="user-nav-subtext">
              {window.localStorage.getItem('company')}
            </p>
          </div>
        </li>
        <li className="user-nav-item">
          <img src={diagrama} alt="diagrama" />
          <div className="user-nav-text">
            <p className="user-nav-title">Entorno</p>
            <p className="user-nav-subtext">
              {process.env.REACT_APP_ENVIRONMENT}
            </p>
          </div>
        </li>
        <li className="user-nav-item">
          <div className="user-nav-text">
            <p className="user-nav-title">Versión</p>
            <p data-testid="menu-version" className="user-nav-subtext">
              {t('LOGIN_T.VERSION_LABEL')}
            </p>
          </div>
        </li>
        <li className="user-nav-item">
          <p className="user-nav-title">Términos y políticas</p>
        </li>
      </ul>
      <div className="user-nav-button">
        <button onClick={() => logout()}>Cerrar Sesión</button>
      </div>
    </div>
  )
}

export default Submenu
