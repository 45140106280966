import React from 'react'
import ReactInputMask from 'react-input-mask'
import { useProductQuantity } from 'Components/CartProduct/hooks/useProductQuantity'

export const QuantityColumn = ({ cell }) => {
  const { handleProdQuantity, quantity, allowFractionalQty } =
    useProductQuantity(cell)
  return (
    <ReactInputMask
      mask={allowFractionalQty ? /^\d{1,6}(\.\d{1,2})?$/ : /^\d{1,6}?$/}
      maskChar=""
      value={quantity}
      onChange={handleProdQuantity}
      className="cantidad"
      inputMode="decimal"
    />
  )
}
