import React, { useContext, createContext, useState } from 'react'
import { creditAccountsSearchService } from 'redux/services/clientsServices'

const CustomerFormContext = createContext({})

export const CustomerFormContextProvider = ({ children }) => {
  const [mandatoryAddress, setMandatoryAddress] = useState(false)
  const [showCreditAccounts, setShowCreditAccounts] = useState(false)
  const [creditAccounts, setCreditAccounts] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const [hasCreditAccounts, setHasCreditAccounts] = useState(false)

  const getCreditAccounts = async (customerId, text = '') => {
    try {
      setIsSearching(true)
      const {
        data: { Data },
      } = await creditAccountsSearchService({
        CustomerId: customerId,
        SearchText: text || null,
        PageNumber: 1,
        PageSize: 20,
      })
      if (Data.length > 0) {
        setHasCreditAccounts(true)
        setCreditAccounts(Data)
      } else {
        setCreditAccounts([])
        !text && setHasCreditAccounts(false)
      }
    } catch (error) {
      console.log(error)
      setCreditAccounts([])
      !text && setHasCreditAccounts(false)
    } finally {
      setIsSearching(false)
    }
  }

  return (
    <CustomerFormContext.Provider
      value={{
        mandatoryAddress,
        setMandatoryAddress,
        showCreditAccounts,
        creditAccounts,
        setShowCreditAccounts,
        getCreditAccounts,
        isSearching,
        hasCreditAccounts,
      }}>
      {children}
    </CustomerFormContext.Provider>
  )
}

export const CustomerContext = () => {
  return useContext(CustomerFormContext)
}
