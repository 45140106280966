import React, { useState, useMemo } from 'react'
import Navigation from '../../Components/Navigation'
import TableOrdenDeDespacho from '../../Components/TableOrdenDeDespacho'
import { Row, Col, Container } from 'react-bootstrap'
import './OrdenDeDespacho.css'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { t } from 'i18next'
import { formatDate, generateColorStatus } from 'Utils/functions'
import { useParams } from 'react-router-dom'
import { searchSalesDocumentDetail } from 'redux/actions/cotizacionActions'
import {
  closeStatusDocument,
  docHeaderStatusDescription,
  documentTypeId,
  fulfillmentPlans,
  pendingStatusDocument,
  processStatusDocument,
} from 'Utils/constants'
import MiniLoading from 'Components/MiniLoading'
import RelationalDocLabel from 'Components/RelationalDocLabel'

function OrdenDeDespacho(props) {
  const dispatch = useDispatch()
  const [comment, setComment] = useState('')
  const [loadingData, setLoadingData] = useState(false)
  const param = useParams()

  const useSelectorProducts = useSelector(
    state => state.sellOrder.sellOrderObject
  )

  const orderDispatchPostData = useSelector(
    state => state.sellOrder.orderDispatchPostData?.Data
  )

  const [formatSelectedDate, setFormatSelectedDate] = useState('')

  const colorsStatus = {
    process: '#81E7CB',
    pending: '#FF96A0',
    close: '#FFDF94',
  }
  const hasHomeDelivery = useMemo(
    () =>
      orderDispatchPostData?.SalesDocumentInfo?.Lines?.some(line =>
        fulfillmentPlans.homeDelivery.some(
          plan => plan.fulfillmentPlanId === line.FulfillmentPlanId
        )
      ),
    [orderDispatchPostData]
  )

  const setTableData = data => {
    if (!data) return []
    const products = data.Lines?.map((product, index) => {
      return {
        referencia: product?.SystemCode,
        descripción: product?.ProductName,
        pendiente: product?.sellOrderTableValues?.pendiente,
        cantidad: product?.Quantity,
        precio_unitario: product?.Amount / product?.Quantity || 0,
        Descuento: product?.DiscountAmount,
        precio_total: product?.Amount,
        solicitud_despacho: product?.Quantity,
        pending_date: formatSelectedDate,
        send_address:
          orderDispatchPostData?.SalesDocumentInfo?.Address_AddressDetail,
      }
    })
    return products
  }

  const productsData = useMemo(
    () => setTableData(orderDispatchPostData?.SalesDocumentInfo),
    [orderDispatchPostData, formatSelectedDate]
  )

  const columns = useMemo(
    () => [
      {
        Header: t('DISPATCH_ORDER_T.REFERENCE_HEADER'),
        accessor: t('DISPATCH_ORDER_T.REFERENCE_ACCESOR'),
        width: '10%',
        arrowOrderEnabled: true,
      },
      {
        Header: t('DISPATCH_ORDER_T.DESCRIPTION_LABEL'),
        accessor: t('DISPATCH_ORDER_T.DESCRIPTION_ACCESOR'),
        width: '50%',
        arrowOrderEnabled: true,
      },
      {
        Header: t('DISPATCH_ORDER_T.DISPATCH_QTY'),
        align: 'center',
        accessor: t('DISPATCH_ORDER_T.DISPATCH_QTY_ACCESOR'),
        width: '10%',
      },
      {
        Header: t('DISPATCH_ORDER_T.UNITY_PRICE_HEADER'),
        accessor: t('DISPATCH_ORDER_T.UNITY_PRICE_ACCESOR'),
        width: '5%',
        arrowOrderEnabled: true,
      },
      {
        Header: t('DISPATCH_ORDER_T.PRECIO_TOTAL_HEADER'),
        accessor: t('DISPATCH_ORDER_T.PRECIO_TOTAL_ACCESOR'),
        width: '5%',
        arrowOrderEnabled: true,
      },
      {
        Header: t('DISPATCH_ORDER_T.ORDER_DATE_HEADER'),
        accessor: t('DISPATCH_ORDER_T.ORDER_DATE_ACCESOR'),
        width: '10%',
      },
      {
        Header: t('DISPATCH_ORDER_T.DELIVERY_DIRECTION'),
        accessor: t('DISPATCH_ORDER_T.DELIVERY_DIRECTION_ACCESOR'),
        width: '15%',
      },
    ],
    []
  )

  const fetchDocument = async (
    DocumentTypeId = null,
    id = null,
    header = null
  ) => {
    setLoadingData(true)
    try {
      const payload = {
        DocumentTypeId,
        DocumentHeaderId: id,
        DocumentNumber: header,
        PageNumber: 1,
        PageSize: 1,
      }
      const data = await dispatch(searchSalesDocumentDetail(payload))
      if (data && data.Status.Code === 200) {
        if (DocumentTypeId.toLowerCase() === documentTypeId.SD.toLowerCase()) {
          const dataFormated = {
            Data: {
              SalesDocumentInfo: {
                ...data.Data,
              },
            },
          }
          dispatch({
            type: 'DISPATCH_ORDER_POST_RESULT',
            payload: dataFormated,
          })
          setFormatSelectedDate(
            format(
              new Date(dataFormated.Data.SalesDocumentInfo.DeliveryDate),
              'dd/MM/yyyy'
            )
          )
          // get index on RelationDoc array when RelationDoc.DocumentTypeId is equal to DocumentTypeId.Quotation
          const index = data.Data?.RelationDoc?.findIndex(
            item =>
              item.DocumentTypeId.toLowerCase() ===
              documentTypeId.QUOTATION.toLowerCase()
          )
          const { DocHeaderId, DocumentNumber, DocumentTypeId } =
            data.Data?.RelationDoc?.[index]
          await fetchDocument(DocumentTypeId, DocHeaderId, DocumentNumber)
        }
        if (
          DocumentTypeId.toLowerCase() ===
          documentTypeId.QUOTATION.toLowerCase()
        ) {
          dispatch({
            type: 'SET_SELL_ORDER_OBJECT',
            payload: data.Data,
          })
          setLoadingData(false)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const isDocumentCanceledOrClosed =
    orderDispatchPostData?.SalesDocumentInfo?.DescriptionStatus ===
      docHeaderStatusDescription.canceled ||
    orderDispatchPostData?.SalesDocumentInfo?.DescriptionStatus ===
      docHeaderStatusDescription.closed ||
    orderDispatchPostData?.SalesDocumentInfo?.DescriptionStatus ===
      closeStatusDocument.fullPicking

  useMemo(() => {
    if (param?.id) {
      fetchDocument(documentTypeId.SD, param?.id)
    }
  }, [])

  return (
    <>
      <Navigation />
      {loadingData ? (
        <MiniLoading full={true} noMargin={true} />
      ) : (
        <div className="sell-order-generada-container">
          <div className="sell-order-generada">
            <div className="sell-order-editar-header">
              <h2 className="titulo-sell-order">
                {t('DISPATCH_ORDER_T.DISPATCH_TITLE')}
              </h2>
            </div>
            <div className="dispatch-order-info-gen">
              <div className="dispatch-order-info-gen-cliente">
                <p className="dispatch-order-info-gen-text">
                  Orden N°:{' '}
                  {orderDispatchPostData?.SalesDocumentInfo.DocumentNumber}
                </p>
                <p className="dispatch-order-info-gen-text">
                  {`Cliente: ${useSelectorProducts?.CustomerNameDocument}`}
                </p>
                <p className="dispatch-order-info-gen-text">
                  {t('DISPATCH_ORDER_T.EMAIL_LABEL', {
                    email:
                      useSelectorProducts?.CustomerEmail ||
                      useSelectorProducts?.EmailAddress ||
                      useSelectorProducts?.CustomerEmail ||
                      t('DISPATCH_ORDER_T.NO_INFO_LABEL'),
                  })}
                </p>
                <p className="dispatch-order-info-gen-text">
                  {t('DISPATCH_ORDER_T.PHONE_LABEL', {
                    phone:
                      useSelectorProducts?.Cellphone_Number ||
                      useSelectorProducts?.Telephone_Number ||
                      t('DISPATCH_ORDER_T.NO_INFO_LABEL'),
                  })}
                </p>
                <p className="sell-order-info-gen-text">
                  {t('CREDIT_ACCOUNT', {
                    account:
                      orderDispatchPostData?.SalesDocumentInfo
                        ?.CreditAccountNumber ||
                      t('SELL_ORDER_T.NO_INFO_LABEL'),
                  })}
                </p>
              </div>
              <div className="dispatch-order-info-gen-retiro">
                {hasHomeDelivery ? (
                  <>
                    <p className="dispatch-order-info-gen-text">
                      {t('DISPATCH_ORDER_T.DELIVERY_HOME')}
                    </p>
                    <p className="dispatch-order-info-gen-text">
                      {t('DISPATCH_ORDER_T.ADDRESS_LABEL', {
                        address:
                          orderDispatchPostData?.SalesDocumentInfo
                            ?.Address_Street,
                      })}
                    </p>
                    <p className="dispatch-order-info-gen-text">
                      {
                        orderDispatchPostData?.SalesDocumentInfo
                          ?.Address_BuildingName
                      }
                    </p>
                    <p className="dispatch-order-info-gen-text">{`Alias: ${orderDispatchPostData?.SalesDocumentInfo?.Address_AddressDetail}`}</p>
                  </>
                ) : (
                  <>
                    <p className="dispatch-order-info-gen-text">
                      {t('DISPATCH_ORDER_T.BRANCH_RECOLECTION_LABEL')}
                    </p>
                    <p className="dispatch-order-info-gen-text">
                      {t('DISPATCH_ORDER_T.ADDRESS_LABEL', {
                        address:
                          orderDispatchPostData?.SalesDocumentInfo
                            ?.WarehouseAddress,
                      })}
                    </p>
                    <p className="dispatch-order-info-gen-text">
                      {
                        orderDispatchPostData?.SalesDocumentInfo
                          ?.WarehouseDescription
                      }
                    </p>
                  </>
                )}
                <p className="dispatch-order-info-gen-text">
                  {t('DISPATCH_ORDER_T.DISPATCH_TYPE', {
                    dispatch_type:
                      orderDispatchPostData?.SalesDocumentInfo
                        ?.FulfillmentPlanDescription,
                  })}
                </p>
              </div>
              <div className="dispatch-order-info-gen-vendedor">
                <p className="dispatch-order-info-gen-text">
                  {/* {t("DISPATCH_ORDER_T.SELLER_LABEL")} */}
                  {useSelectorProducts?.SalesAgentFullName}
                </p>
                <p className="dispatch-order-info-gen-text">
                  {t('DISPATCH_ORDER_T.DATE_LABEL', {
                    date: useSelectorProducts?.CreatedDate.slice(0, 19).replace(
                      'T',
                      ' '
                    ),
                  })}
                </p>
                <p className="dispatch-order-info-gen-text">
                  {t('DISPATCH_ORDER_T.QUOTATION_LABEL', {
                    quotation: useSelectorProducts?.DocumentNumber,
                  })}
                </p>
                <p className="dispatch-order-info-gen-text">
                  {t('DISPATCH_ORDER_T.CHANNEL_USED_LABEL', {
                    channel_used: useSelectorProducts?.Channel || '',
                  })}
                </p>
                {orderDispatchPostData?.SalesDocumentInfo
                  ?.DescriptionStatus && (
                  <p
                    className="status"
                    style={{
                      backgroundColor: generateColorStatus(
                        orderDispatchPostData?.SalesDocumentInfo
                          ?.DescriptionStatus,
                        pendingStatusDocument,
                        processStatusDocument,
                        closeStatusDocument,
                        colorsStatus
                      ),
                    }}>
                    {
                      orderDispatchPostData?.SalesDocumentInfo
                        ?.DescriptionStatus
                    }
                  </p>
                )}
              </div>
            </div>
            {orderDispatchPostData?.SalesDocumentInfo.LogTypeError && (
              <p className="despacho-nota">
                {orderDispatchPostData.SalesDocumentInfo.LogTypeError.toLowerCase() ===
                'oracle'
                  ? t('DISPATCH_ORDER_T.MESSAGE_ALERT_ERROR.ORACLE')
                  : orderDispatchPostData.SalesDocumentInfo.LogTypeError.toLowerCase() ===
                    'savetransactionv2'
                  ? t('DISPATCH_ORDER_T.MESSAGE_ALERT_ERROR.SAVETRANSACTION')
                  : null}
              </p>
            )}

            <TableOrdenDeDespacho
              data={productsData}
              columns={columns}
              hideFooter={true}
              isProductPage={false}
              isInventory={false}
            />
            {useSelectorProducts?.DocumentDueDate ? (
              <div className="date-time-updated mt-5">
                <p>Modificado por: {useSelectorProducts?.SalesAgentFullName}</p>
                <p>
                  Fecha de última modificación:{' '}
                  {formatDate(useSelectorProducts?.CreatedDate)}
                </p>
              </div>
            ) : null}
          </div>

          <Container className="footer-container">
            <Row className="footer-info">
              <Col>
                <Row className="footer-left-text-title">
                  {t('DISPATCH_ORDER_T.RELATED_DOCS')}
                </Row>
                {orderDispatchPostData?.SalesDocumentInfo?.RelationDoc?.map(
                  (item, index) => {
                    return (
                      <Row className="footer-left-text" key={index}>
                        <RelationalDocLabel
                          value={item}
                          document={orderDispatchPostData?.SalesDocumentInfo}
                        />
                      </Row>
                    )
                  }
                )}
              </Col>
              <Col>
                <Row className="footer-right-text-title">
                  {t('DISPATCH_ORDER_T.COMMENT')}
                </Row>

                <Row className="footer-right-row-container">
                  <textarea
                    className="footer-textarea-style"
                    value={comment || ''}
                    disabled={isDocumentCanceledOrClosed}
                    onChange={e => setComment(e.target.value)}
                  />
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  )
}

export default OrdenDeDespacho
