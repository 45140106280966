import grid from '../../../Assets/Images/Home/grid.svg'
import grid2 from '../../../Assets/Images/Home/grid2.svg'
import personasLogo from '../../../Assets/Images/Home/personas.svg'
import listaLogo from '../../../Assets/Images/Home/lista.svg'
import promises from '../../../Assets/Images/Home/promises.svg'
import { ROUTES } from 'Utils/constants'

export const options = [
  {
    image: grid,
    title: 'HOME_T.PRODUCT_SEARCH_TITLE',
    text: 'HOME_T.PRODUCT_SEARCH_DESCRIPTION',
    link: { pathname: ROUTES.createQuote, isdepartment: true },
    disabled: false,
  },
  {
    image: grid2,
    title: 'HOME_T.PRODUCT_QUOTATION_TITLE',
    text: 'HOME_T.PRODUCT_QUOTATION_DESCRIPTION',
    link: { pathname: ROUTES.quoteProduct },
    disabled: false,
  },
  {
    image: personasLogo,
    title: 'HOME_T.CREATE_CLIENT_TITLE',
    text: 'HOME_T.CREATE_CLIENT_DESCRIPTION',
    link: ROUTES.customers,
    disabled: false,
  },
  {
    image: listaLogo,
    title: 'HOME_T.RAPID_PRODUCT_LISTS_TITLE',
    text: 'HOME_T.RAPID_PRODUCT_LISTS_DESCRIPTION',
    link: { pathname: ROUTES.list, isdepartment: false },
    disabled: false,
    hiddenByClient: true,
  },
  {
    image: promises,
    title: 'HOME_T.PROMISES_TITLE',
    text: 'HOME_T.PROMISES_DESCRIPTION',
    link: ROUTES.offers,
    disabled: false,
    hiddenByClient: true,
  },
]
