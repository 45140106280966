const initState = {
  loadingFullfilment: false,
  loadingCreditAccounts: false,
  fullfilmentResponse: null,
  creditAccountsResponse: null,
  supplierProduct: [],
  loadingSupplierProducts: false,
}

export default function catalogReducer(state = initState, action) {
  switch (action.type) {
    case 'LOADING_FULLFILMENT':
      return {
        ...state,
        loadingFullfilment: action.payload,
      }
    case 'FULLFILMENT_RESPONSE':
      return {
        ...state,
        fullfilmentResponse: action.payload,
      }
    case 'SUPPLIER_RESPONSE':
      return {
        ...state,
        supplierProduct: action.payload,
      }
    case 'LOADING_SUPPLIER':
      return {
        ...state,
        loadingSupplierProducts: action.payload,
      }
    case 'LOADING_CREDIT_ACCOUNTS':
      return {
        ...state,
        loadingCreditAccounts: action.payload,
      }
    case 'CREDIT_ACC_RESPONSE':
      return {
        ...state,
        creditAccountsResponse: action.payload,
      }
    default:
      return state
  }
}
