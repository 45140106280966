import { useDispatch } from 'react-redux'
import {
  uploadDocumentHistoryAction,
  uploadFileAction,
} from 'redux/actions/cotizacionActions'

export const useUploadOdcFile = () => {
  const dispatch = useDispatch()

  const uploadFile = async ({ file, docData }) => {
    const formData = new FormData()
    formData.append('FormFile', file)
    formData.append('TenantId', 2)
    formData.append('EntityType', 'Document')
    formData.append('InternalCode', docData.DocumentNumber)
    formData.append('Date', new Date().toISOString())
    formData.append('EntityId', docData.DocumentNumber)
    const resultFile = await dispatch(uploadFileAction(formData))

    if (resultFile?.Data?.length) {
      const payloadUploadDocument = {
        DocumentId: docData.DocHeaderId,
        DocumentTypeId: docData.DocumentTypeId,
        DocumentUrl: resultFile.Data[0].FileUrl,
        FileName: file.name,
        FileType: file.type === 'application/pdf' ? 'document' : 'image',
        documentHistoryAttributeId: 'FF6C623B-5523-4BE9-80D8-47918DA6295D', // quitar tt
      }
      await dispatch(uploadDocumentHistoryAction(payloadUploadDocument))
    }
  }

  return { uploadFile }
}
