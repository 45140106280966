import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './PrincipalSearchCustomers.css'
import {
  agregarCliente,
  // agregarSucursal,
  cotizacionRemoveClient,
  quitarDireccion,
} from '../../../redux/actions/cotizacionActions.js'
import SearchCustomer from '../../SearchCustomer'
import { deleteCustomers } from 'redux/actions/clientsActions'
import ModalCustomerCounted from 'Components/ModalCustomerCounted'

const PrincipalSearchCustomers = ({
  handleModal,
  setClienteCotizacion,
  resetAddressStatus,
  setDefaultWarehouseToQuotation,
}) => {
  const [registers] = useState(null)
  const pageSize = 5
  const pageNums = []
  const totalPages = Math.ceil(registers / pageSize)
  for (let i = 1; i <= totalPages; i++) {
    pageNums.push(i)
  }

  const [clientSelectedDelete] = useState('')
  const [confirmDeleteClient, setConfirmDeleteClient] = useState(false)
  const dispatch = useDispatch()

  const [showModalClienteContado, setShowModalClienteContado] = useState(false)
  const [clienteContado, setClienteContado] = useState('')

  const company = useSelector(state => state.company)

  const addClienteContado = () => {
    if (clienteContado.length > 0) {
      resetAddressStatus()
      dispatch(cotizacionRemoveClient())
      dispatch(quitarDireccion())
      clienteSelect(null, null, clienteContado, null, null)
      handleModal(false)
      if (company?.DefaultWarehouse) {
        setDefaultWarehouseToQuotation()
      }
    }
  }

  const deleteCustomer = async () => {
    const Data = await dispatch(deleteCustomers(clientSelectedDelete))
    if (Data && Data?.CustomerInfo) {
      setConfirmDeleteClient(false)
    } else {
      throw new Error(Data)
    }
  }

  useEffect(() => {
    if (confirmDeleteClient) {
      deleteCustomer()
    }
  }, [confirmDeleteClient])

  const clienteSelect = (id, customerType, cliente, correo, externalCode) => {
    dispatch(
      agregarCliente({ id, customerType, cliente, correo, externalCode })
    )
    setClienteCotizacion({
      CustomerInfo: {
        FirstName: cliente,
        Email: correo,
      },
    })
  }
  return (
    <>
      <SearchCustomer
        inModal={true}
        setShowModalClienteContado={setShowModalClienteContado}
        setClienteCotizacion={setClienteCotizacion}
        handleModal={handleModal}
        resetAddressStatus={resetAddressStatus}
        setDefaultWarehouseToQuotation={setDefaultWarehouseToQuotation}
      />

      <ModalCustomerCounted
        showModalCustomerCounted={showModalClienteContado}
        setShowModalCustomerCounted={setShowModalClienteContado}
        customerCounted={clienteContado}
        setCustomerCounted={setClienteContado}
        addCustomerCounted={addClienteContado}
      />
    </>
  )
}

export default PrincipalSearchCustomers
