/* eslint-disable no-useless-escape */
import home from '../Assets/Images/Navbar/home.svg'
import grid from '../Assets/Images/Navbar/grid.svg'
import grid2 from '../Assets/Images/Navbar/grid-1x2.svg'
import document from '../Assets/Images/Navbar/journal-text.svg'
import list from '../Assets/Images/Navbar/list-columns-reverse-new.svg'
import list2 from '../Assets/Images/Navbar/list-task2.svg'
import users from '../Assets/Images/Navbar/people.svg'
import calcIcon from '../Assets/Images/Navbar/calc-menu.svg'
import outlet from '../Assets/Images/Navbar/outlet.svg'
import printer from '../Assets/Images/Navbar/printer.svg'
// import reports from '../Assets/Images/Navbar/reports.svg'

import { createBrowserHistory } from 'history'

export const googleMapsApiKey = 'AIzaSyAljoiI2fdf5JnkxBysitiwu0vSb3y-4lk'

export const elements = [
  {
    title: 'Home',
    image: home,
    link: '/home',
  },
  {
    title: 'Productos',
    image: grid,
    link: { pathname: '/crear_cotizacion', isdepartment: true },
  },
  {
    title: 'Cotizador rápido',
    image: grid2,
    link: '/cotizar_producto',
  },
  {
    title: 'Listas rápidas',
    image: list,
    link: '/listas',
    hiddenByClient: true,
  },
  {
    title: 'Impresión etiquetas',
    image: printer,
    link: '/printer',
    hiddenByClient: true,
  },
  {
    title: 'Documentos creados',
    image: document,
    isdepartment: false,
    children: [
      {
        title: 'Cotizaciones',
        link: '/buscar_cotizacion',
      },
      {
        title: 'Ordenes de Venta',
        link: '/buscar_orden_venta',
      },
      {
        title: 'Despachos',
        link: '/buscar_despacho',
      },
    ],
  },
  {
    title: 'Clientes',
    image: users,
    link: '/clientes',
  },
  {
    title: 'Mis tareas',
    image: list2,
    link: '/mis_tareas',
    hiddenByClient: true,
  },
  {
    title: 'Calculadora de materiales',
    image: calcIcon,
  },
  {
    title: 'Promociones',
    image: outlet,
    link: '/ofertas',
    hiddenByClient: true,
  },
  // {
  //   title: 'Reportes',
  //   image: reports,
  //   link: '/reports',
  // },
]

export const documentTypeId = {
  ODV: '502983D8-15CC-421C-AFBA-22BF488DA064',
  QUOTATION: 'EF171AD1-2A4B-4ABA-9421-782FA687C547',
  SD: '534B4247-B8FD-4CF9-95AB-C82AAE47B428',
  INVOICE: 'EFB7D434-EE6E-401B-B43B-0D509DBD5209',
  ODC: '6342B482-CC4D-48D0-8C75-0CA60469B3DA',
}

export const dispatchTypeId = {
  domicilio: 'B109566E-3150-4BC6-AADF-148B38D44B68',
  retiroTienda: 'E452353D-998B-46BA-9841-7506AFC156AE',
}

export const dispatchHeaderStatusId = {
  StatusClose: '4A8839F9-B873-4F36-81D4-E07AE04441C8',
  StatusPartial: 'B59303A9-A705-4504-A83E-51807BFDE0F0',
  StatusPending: '19296272-B710-4001-9E8E-BF931BAADA64',
  StatusProcess: '53C60AD3-EA4B-4B25-B774-C66145AE44ED',
}

export const salesOrderHeaderStatusId = {
  StatusCreada: '2CAC34C2-CCD3-4D2E-BEB6-084322B03450',
  StatusProceso: '83D564AE-CA2B-4348-95A5-2F561D0B1EFC',
  StatusCerrada: 'B654CA47-2438-4117-9249-3AFC2D759955',
}

export const statusFilter = {
  salesOrder: [
    {
      HeaderStatusId: '2CAC34C2-CCD3-4D2E-BEB6-084322B03450',
      Description: 'Creada',
    },
    {
      HeaderStatusId: '83D564AE-CA2B-4348-95A5-2F561D0B1EFC',
      Description: 'En proceso',
    },
    {
      HeaderStatusId: 'B654CA47-2438-4117-9249-3AFC2D759955',
      Description: 'Cerrada',
    },
  ],
  dispatch: [
    {
      HeaderStatusId: '4A8839F9-B873-4F36-81D4-E07AE04441C8',
      Description: 'Piqueo completo',
    },
    {
      HeaderStatusId: 'B59303A9-A705-4504-A83E-51807BFDE0F0',
      Description: 'Piqueo parcial',
    },
    {
      HeaderStatusId: '19296272-B710-4001-9E8E-BF931BAADA64',
      Description: 'En proceso',
    },
    {
      HeaderStatusId: '53C60AD3-EA4B-4B25-B774-C66145AE44ED',
      Description: 'Creada',
    },
  ],
  quote: [
    {
      HeaderStatusId: '042BD8AF-889C-462E-B804-161F428DD420',
      Description: 'Abierta',
    },
    {
      HeaderStatusId: '6DDC83ED-1584-4F7B-962C-EF4FDABA32C5',
      Description: 'Borrador',
    },
    {
      HeaderStatusId: '82F4B30E-996B-4F20-987B-2BCDFF0BEDAA',
      Description: 'Cancelada',
    },
    {
      HeaderStatusId: '2AB53DE9-1D2C-429E-AA00-7C71F23A09F0',
      Description: 'Pendiente de autorización',
    },
    {
      HeaderStatusId: '444045A9-08DB-4A16-9FD3-700ED1DB382C',
      Description: 'Expirada',
    },
    {
      HeaderStatusId: 'E06EF592-45AA-464F-BC88-F1EEE37582FE',
      Description: 'Cerrada',
    },
  ],
}

export const fullfilmentIds = {
  homeDelivery: {
    fulfillmentPlanId: 'C15B501B-E075-4843-8F17-FF964A83D9A7',
    fulfillmentPlanDescription: 'ENTREGA ACARREO',
  },
  branchDelivery: {
    fulfillmentPlanId: '75963CA6-D4C7-43BD-9342-4C3FF372A37B',
    fulfillmentPlanDescription: 'ENTREGA EN PDV',
  },
}

export const fulfillmentPlans = {
  homeDelivery: [
    {
      fulfillmentPlanId: '13CEDD9C-A262-4728-AD0E-78BF6565A978',
      fulfillmentPlanDescription: 'ENTREGA DIRECTA D1',
    },
    {
      fulfillmentPlanId: 'E775A02F-E3D0-49B7-90B4-AB7C54166171',
      fulfillmentPlanDescription: 'ENTREGA DIRECTA PROVEEDOR',
    },
    {
      fulfillmentPlanId: 'C15B501B-E075-4843-8F17-FF964A83D9A7',
      fulfillmentPlanDescription: 'ENTREGA ACARREO',
    },
    {
      fulfillmentPlanId: '1C4C3401-7998-4C67-B370-CF7D4CC14B00',
      fulfillmentPlanDescription: 'INVENTARIO PROVEEDOR',
    },
  ],
  branchDelivery: [
    {
      fulfillmentPlanId: '75963CA6-D4C7-43BD-9342-4C3FF372A37B',
      fulfillmentPlanDescription: 'ENTREGA EN PDV',
    },
    {
      FulfillmentPlanId: 'A81D2750-4D8D-44EF-AC48-A4FC49E56333',
      fulfillmentPlanDescription: 'ENTREGA BODEGA',
    },
    {
      FulfillmentPlanId: '1C4C3401-7998-4C67-B370-CF7D4CC14B00',
      fulfillmentPlanDescription: 'INVENTARIO PROVEEDOR',
    },
  ],
}

export const salesOrderTypeIds = {
  credito_comercial: '6342B482-CC4D-48D0-8C75-0CA60469B3DA',
  pago_contado: '1ECC1105-30F2-4AE6-97FC-73AEE5EE4D90',
}

export const defaultCountryId = {
  // Data for panama
  countryId: '2d3d33c2-3401-40a1-858b-ae0140b0d376',
  countryName: 'PANAMÁ',
}

export const docHeaderStatusId = {
  open: '042BD8AF-889C-462E-B804-161F428DD420',
  draft: '6DDC83ED-1584-4F7B-962C-EF4FDABA32C5',
  cancel: '82F4B30E-996B-4F20-987B-2BCDFF0BEDAA',
  expired: '444045A9-08DB-4A16-9FD3-700ED1DB382C',
  quoteClosed: 'E06EF592-45AA-464F-BC88-F1EEE37582FE',
  parcialDispatch: 'B59303A9-A705-4504-A83E-51807BFDE0F0',
  completeDispatch: '4A8839F9-B873-4F36-81D4-E07AE04441C8',
  dispatchInProgress: '83D564AE-CA2B-4348-95A5-2F561D0B1EFC',
  created: '2CAC34C2-CCD3-4D2E-BEB6-084322B03450',
  odvClosed: 'B654CA47-2438-4117-9249-3AFC2D759955',
}

export const salesPerson = 'SALES PERSON'

export const VAPermissions = {
  createOrder: 'so.enabled',
  createDispatch: 'admin.create.dispatchrequest',
  createQuote: 'quote.enabled',
  seeCostProd: 'admin.cost',
  createNaturalClient: 'customer.create.enabled',
  createComercialClient: 'admin.create.customercommercial',
  seeWareHouse_D1_MP: 'admin.view.warehouse',
  seeMargenProd: 'salesperson.margin.product',
  deliveryMethod: 'admin.deliverymethodslist',
  aproveDiscount: 'salesperson.margin.discount',
  customerType: 'customer.customertype',
  priceOverrideAutho: 'price.override.auth',
  changeSeller: 'admin.switch.sales',
  returnsPermission: 'credit.memo.enabled',
}

export const customerTypeAllowedRoles = {
  superAdmin: 'Super Admin',
  callCenter: 'Call Center',
  comercialSellerConstruction: 'Commercial Seller Construction',
  comercialSellerDistribution: 'commercial seller distribution',
  supervGerent: 'gerent/superv',
  supervCommercial: 'gerent/superv/commercial',
  supervSales: 'Sales Supervisor',
  superv: 'Supervisor',
  supervCaja: 'Supervisor de Caja',
  supervStore: 'Supervisor de tienda',
  supervVA: 'Supervisor VA',
  supervTracking: 'Tracking Supervisor',
  supervCallcenter: 'Supervisor Call Center',
  supervGerentStore: 'Gerente/Supervisor Tienda',
  comercialSuperv: 'Gerente/Supervisor Comercial',
  comercialSellerConst: 'Vendedor Comercial Construcción',
  comercialSellerDist: 'Vendedor Comercial Distribución',
}

export const salesRoles = {
  customer: 'Cliente',
  salesPerson: 'Sales Person',
  vendedor: 'Vendedor',
  salespersonaMDL11: 'Sales Person MDL11',
  supervisor: 'gerent/superv',
  superAdmin: 'Super Admin',
  callCenter: 'Call Center',
  supervisorCallcenter: 'Supervisor Call Center',
  supervisorTienda: 'Supervisor de tienda',
  gerenteTienda: 'Gerente de tienda',
  gerenteSupervTienda: 'Gerente/Supervisor Tienda',
  ...customerTypeAllowedRoles,
}

export const companiesCode = {
  novey: 'NO',
  cochez: 'CO',
}

export const customerTypeId = {
  natural: '41acbcf4-be83-4f03-8b5e-5760fc92b59b',
  legal: '52acbcf4-be83-4f03-8b5e-5760fc92b59b',
}

export const documentTypeIds = {
  natural: {
    identificationCard: '62f94b64-d874-4b74-91d1-2c623ec93016',
    passport: '5a22221d-285a-443b-ab85-f38df45ae3b6',
    NT: '340eeed7-cba8-4849-a631-9f29e689657a',
    emailType: '32ACBCF4-BE83-4F03-8B5E-5760FC92B59B',
    customerStatusId: '081e533e-be36-4b79-87a9-d6336eab5aaf',
  },
  legal: {
    RUC: '17a5711b-cd08-4da8-a1ab-f6d27fc95e00',
    identificationCard: '4d582f7f-c981-4ed4-9364-708452802303',
    NTGovernment: 'e5a473db-17af-4d45-b99d-41ac9c6bc98c',
    NT: 'd40b2f69-81ff-4601-954b-432699c51a71',
    emailType: '32ACBCF4-BE83-4F03-8B5E-5760FC92B59B',
  },
}

export const docHeaderStatusDescription = {
  open: 'Abierta',
  closed: 'Cerrada',
  draft: 'Borrador',
  fullyBilled: 'Facturada Completamente',
  partiallyInvoiced: 'Facturada Parcialmente',
  canceled: 'Cancelada',
  expired: 'Expirada',
}

export const closeStatusDocument = {
  created: 'Creada',
  fullPicking: 'Piqueo Completo',
  closed: 'Cerrada',
}

export const pendingStatusDocument = {
  draft: 'Borrador',
  cancel: 'Cancelada',
  inProgress: 'En proceso',
  parcialPicking: 'Piqueo parcial',
  processDispatch: 'En proceso de despacho',
}

export const processStatusDocument = {
  open: 'Abierta',
  partiallyInv: 'Facturada Parcialmente',
  completeInv: 'Facturada Completamente',
}

export const genderIds = {
  male: '55acbcf4-be83-4f03-8b5e-5760fc92b59b',
  female: '41ecbcf4-be83-4f03-8b5e-5760fc92b59b',
}

export const telephoneTypeIds = {
  house: '26acbcf4-be83-4f03-8b5e-5760fc92b59b',
  cellPhone: '34acbcf4-be83-4f03-8b5e-5760fc92b59b',
  office: '83acbcf4-be83-4f03-8b5e-5760fc92b59b',
}
export const materialsCalculatorTypeIds = {
  ceiling: '28757626-7d2d-44c3-8dc8-cc6ec8a9049f',
  suspended: 'CF1EA21D-97DF-4103-BE33-46A558C1DD5F',
  flat: '3D931889-3B4C-445F-96F0-1E39A4885418',
}

export const inputFilterable = ['modelo', 'dimensionesCuadricula']

export const calculatorMaterialType = {
  category: {
    ceiling: {
      id: 'FF2878F9-2A6C-431D-B4FF-75722BA8954B',
      subCategory: {
        gypsum: '25BC9EB5-0F45-4D6C-89A4-FC47091B08BF',
        abestosCement: '520D8B33-5D58-441B-B8E2-3311C5686DF8',
        foam: 'B4B49399-77D6-483A-9771-1A87DA60F7A4',
        pvc: '5879745F-F7B2-479C-AFF7-19811711EF05',
        fibermineral: 'BD3AC27D-1A65-4F85-8098-0F8E8F0DBD5C',
      },
    },
    floors: {
      id: '8FA7E8A0-D5CF-43F0-93D6-3F7D1E186DCB',
      subCategory: {
        ceramics: 'CD08AC6D-02B2-4C10-8CCD-E9B0D6568326',
        clay: '6FBCBB0E-EBD1-4B4E-A4C7-CC303D6081CD',
        porcelain: '037756E5-08DB-4047-8711-42E62F22AA39',
        pvc: '1362F301-A8D9-43CE-A594-50B1778FD707',
        linoleum: '68DCCE9F-B195-4948-8291-305FC386CFA2',
        vinyl: '70FDC217-D1E4-45A5-A08A-2C7C9B0F1FC6',
        concrete: '1B801883-79D4-44D4-BFEE-4E4BED80CC32',
      },
    },
    walls: {
      id: 'FCABD600-0A0B-4462-A926-9813D391EB20',
      subCategory: {
        ceramics: '78A07AE8-B8BB-4063-A263-A89DD023FDFE',
        clay: '8402FCBE-2975-4062-B596-1D14F56E19FC',
        porcelain: '79628C64-54A0-4FF0-8474-68DA4CB5973D',
        concrete: '44C8F122-F9D8-47E2-BC8D-E563EB280996',
        mosaic: '368E5C6E-70F3-45DF-85B0-5D15381A51B3',
        naturalFiber: '1AB90B82-3965-4B94-965F-36127045F09B',
        PVC: '7DF6D764-0C7E-4306-BCDA-3C1BE211E146',
        plasticWood: 'FD75FE39-C0E7-4E6C-8D91-0F30AF8562C2',
        naturalStone: 'ADA5F520-190B-4480-89B9-E646FD2118BC',
      },
    },
  },
}

export const calcOptions = [
  {
    value: '0.2',
  },
  {
    value: '0.25',
  },
  {
    value: '0.3',
  },
]

export const statusCalculator = {
  pvcCeiling: 'pvcceiling',
  ceiling: 'ceiling',
  gypsumWall: 'gypsumwall',
  gypsumCeiling: 'gypsumceiling',
}

export const ROUTES = {
  login: '/',
  home: '/home',
  myTasks: '/mis_tareas',
  printer: '/printer',
  printerPreview: '/printer/preview',
  searchQuote: '/buscar_cotizacion',
  searchSaleOrder: '/buscar_orden_venta',
  searchDispatch: '/buscar_despacho',
  createQuote: '/crear_cotizacion',
  generateQuote: '/generar_cotizacion',
  generateQuoteTypeDocument: '/generar_cotizacion/:type_document',
  offers: '/ofertas',
  reports: '/reports',
  reportsByDepartment: '/reportsbydepartment',
  reportsByCredit: '/reportsbycredit',
  editQuoteHeaderId: '/editar_cotizacion/',
  createQuoteFromList: '/crear_cotizacion/from_list',
  departments: '/departamentos',
  customers: '/clientes',
  list: '/listas',
  quoteProduct: '/cotizar_producto',
  salePreorder: '/preorden_de_venta',
  salePreorderValidate: '/orden_de_venta/',
  orderDispatch: '/orden_de_despacho/',
  notFound: '/*',
  salesOrder: '/orden_de_venta/created/',
  saleOrderAprove: '/orden_de_venta/aprobacion_de_precio/',
  materialsPreOrder: '/materials_preorden',
}

export const relationalDoc = {
  [documentTypeId.ODV]: { name: 'Orden de venta', path: ROUTES.salesOrder },
  [documentTypeId.QUOTATION]: {
    name: 'Cotizacion',
    path: ROUTES.editQuoteHeaderId,
  },
  [documentTypeId.SD]: {
    name: 'Orden de despacho',
    path: ROUTES.orderDispatch,
  },
  [documentTypeId.INVOICE]: { name: 'Factura', path: '' },
  [documentTypeId.ODC]: { name: 'Orden de compra', path: '' },
}

export const colorsStatus = {
  process: '#81E7CB',
  pending: '#FF96A0',
  close: '#FFDF94',
  default: '#FFFFFF',
}

export const onlyNumberRegex = /^[0-9]+$/
export const formatMoney = /(\d)(?=(\d{3})+(?!\d))/g
export const constFormatterCurrency = /\B(?=(\d{3})+(?!\d))/g
export const searchTitle = /\w\S*/g

// Client Regexs
export const regexName =
  /^(?!.*\d)^(?![!@#$%^&*()_+\-=\[\]{};:\"\\|,.<>\/?])^(?!.* {2})[a-zA-ZáéíóúÁÉÍÓÚüÜñÑÄËÏÖäëïö\s.]*$/
export const regexPassport = /^[a-zA-Z0-9]+$/
export const regexPanamaId =
  /^P$|^(?:PE|E|N|[23456789]|[23456789](?:A|P)?|1[0123]?|1[0123]?(?:A|P)?)$|^(?:PE|E|N|[23456789]|[23456789](?:AV|PI)?|1[0123]?|1[0123]?(?:AV|PI)?)-?$|^(?:PE|E|N|[23456789](?:AV|PI)?|1[0123]?(?:AV|PI)?)-(?:\d{1,4})-?$|^(PE|E|N|[23456789](?:AV|PI)?|1[0123]?(?:AV|PI)?)-(\d{1,4})-(\d{1,6})$/i
export const regexCellPhone = /^6[0-9]{7}$/
export const regexPhone = /^[2-5789][0-9]{6}$/
export const regexDV = /^[0-9]{2}$/
export const regexNT = /^(1[0-3]|0?[1-9])-NT-\d{1}-\d{1,8}$/
export const regexGenderId =
  /^([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})$/
/**
 * Regex para la validacion de nombre de un comercio
 */
export const regexCompanyName =
  /^[a-zA-Z0-9ÁÉÍÓÚÜáéíóúü'&.,;()@`~\-]+( [a-zA-Z0-9ÁÉÍÓÚÜáéíóúü'&.,;()@`~\-]+)*$/

export const regexEmail = /^[\w\-\.]+@([\w-]+\.)+[\w-]{2,6}$/
export const regexRUC = /^\d+-\d+-\d+$/

export const history = createBrowserHistory()
