/**
 * @param {Object} param0
 * @param {Function} param0.service
 * @param {Array} param0.payload
 * @param {Number} param0.chunkSize
 * @returns {Array}
 *
 * @example
 * const response = await getFragmentedService({
 *   service: payload => callbackService(payload),
 *   payload: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
 *   chunkSize: 2,
 * })
 */
export const getFragmentedService = async ({
  service,
  payload,
  chunkSize = 99,
}) => {
  function chunkArray(array, size) {
    const result = []
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size))
    }
    return result
  }

  const chunks = chunkArray(payload, chunkSize)

  const allResponses = []

  for (const chunk of chunks) {
    const chunkPayload = chunk
    const response = await service(chunkPayload)
    response && allResponses.push(...response)
  }

  return allResponses
}
