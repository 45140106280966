import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './GenericMultipleSelectForm.css'
import { Row, Col } from 'react-bootstrap'

const renderGenericMultipleSelectForm = (
  title,
  showState,
  setShowState,
  valueState,
  setValueState,
  mappedInfo,
  placeholder,
  controlValue,
  boldTitle,
  auxiliarMappedValue
) => {
  return (
    <Row className="no-margin-nopadding generic-multiple-address-father-container">
      <div
        className={
          boldTitle
            ? 'generic-multiple-address-title-text-bold'
            : 'generic-multiple-address-title-text'
        }>
        {title}
      </div>
      <Col className="no-margin-nopadding generic-multiple-address-search-filter">
        <div
          className="generic-multiple-address-filter-content"
          onClick={() => setShowState(!showState)}>
          <h2
            className={
              valueState?.description ||
              valueState[auxiliarMappedValue] ||
              valueState.Description
                ? 'generic-multiple-address-label-text'
                : 'generic-multiple-address-label-placeholder'
            }>
            {valueState?.description
              ? valueState.description
              : valueState[auxiliarMappedValue]
              ? valueState.CreditAccountNumber +
                ' - ' +
                valueState[auxiliarMappedValue]
              : placeholder}
          </h2>
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
        <ul
          className={
            showState
              ? 'generic-multiple-address-filter-options'
              : 'generic-multiple-address-filter-options-hidden'
          }>
          {mappedInfo?.length > 0 &&
            mappedInfo?.map((item, index) => {
              return (
                <li
                  className="generic-multiple-address-filter-option"
                  key={controlValue + index}>
                  <input
                    type="radio"
                    className="radio"
                    id={controlValue + index.toString()}
                    name="distrito"
                    value={
                      item.description
                        ? item.description
                        : item[auxiliarMappedValue]
                    }
                    onClick={values => {
                      setValueState(item)
                      setShowState(false)
                    }}
                  />
                  <label htmlFor={controlValue + index.toString()}>
                    {item.description
                      ? item.description
                      : (item?.CreditAccountNumber || '') +
                        ' - ' +
                        item[auxiliarMappedValue]}
                  </label>
                </li>
              )
            })}
        </ul>
      </Col>
    </Row>
  )
}

export default renderGenericMultipleSelectForm
