import React from 'react'
import CrearCotizacion from '../CrearCotizacion'

function EditarCarrito() {
  return (
    <>
      <CrearCotizacion edit={true} />
    </>
  )
}

export default EditarCarrito
