import { customerTypeId } from 'Utils/constants'

export const getUpdateClientPayload = (clientDetails, updateClientValues) => {
  const isNaturalClient =
    clientDetails.customerTypeId.toLowerCase() ===
    customerTypeId.natural.toLowerCase()
  const payload = isNaturalClient
    ? {
        ...clientDetails,
        generalEmail: [
          {
            ...clientDetails.generalEmail?.[0],
            address: updateClientValues.email,
          },
        ],
        generalTelephone: [
          {
            ...clientDetails.generalTelephone?.[0],
            number: updateClientValues.phone,
          },
        ],
      }
    : {
        ...clientDetails,
        email: updateClientValues.email,
        generalStore: [
          {
            ...clientDetails.generalStore?.[0],
            generalEmail: [
              {
                ...clientDetails.generalStore?.[0].generalEmail?.[0],
                address: updateClientValues.email,
              },
            ],
            generalTelephone: [
              {
                ...clientDetails.generalStore?.[0].generalTelephone?.[0],
                number: updateClientValues.phone,
              },
            ],
          },
        ],
      }

  if (!payload?.generalGroup?.[0]?.customerGroupId) {
    delete payload.generalGroup
  }
  return payload
}
