import React, { useEffect } from 'react'
import { images } from 'Assets/Images'
import {
  CheckCircleFill,
  PencilSquare,
  XCircleFill,
} from 'react-bootstrap-icons'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useGetCmfCredit } from '../../../hooks/useGetCmfCredit'

const DEFAULT_VALUE =
  'La tarjeta de crédito CMF es emitida por CM Financiera, S.A., sujeto a previa aprobación de esa entidad. Para más información ingresa a cmfinanciera.com. Cuotas calculadas con tasa de interés nominal del 57% anual. La responsabilidad de CM Financiera se limita exclusivamente a aspectos propios del crédito otorgado y no por los productos o servicios adquiridos mediante el uso del crédito.'

export const Square1NCmfOffer = ({ products, i }) => {
  const { currentPage, allTemplatesContent, setAllTemplatesContent } =
    usePrinterContext()

  const currentContent = allTemplatesContent.find(
    content => content.page === currentPage
  )
  const currentTerms = currentContent?.content?.terms || DEFAULT_VALUE

  const { fetchCmfCredit, loading } = useGetCmfCredit()
  const [isEditing, setIsEditing] = React.useState(false)
  const [terms, setTerms] = React.useState(currentTerms)

  const prod = products?.content?.products?.[0]

  const handleEditTerms = () => {
    const updatedContent = allTemplatesContent.map(content => {
      if (content.page === currentPage) {
        return {
          ...content,
          content: {
            ...content.content,
            terms,
          },
        }
      }
      return content
    })
    setAllTemplatesContent(updatedContent)
    setIsEditing(false)
  }

  useEffect(() => {
    if (prod?.Sku && !prod?.cmfCredit) {
      fetchCmfCredit(prod?.Price, prod?.Sku)
    }
  }, [prod?.Sku])

  return (
    <div
      id="printer-preview-left-canvas"
      className="printer-preview-left-canvas">
      <div className="printer-preview-left-content-body">
        <p className="printer-preview-left-content-body-title">
          {prod?.ProductName || ''}
        </p>
        <p className="printer-preview-left-content-body-sku">
          {prod?.Sku || ''}
        </p>
        {prod?.Price && (
          <div className="printer-preview-left-content-body-price-wrapper">
            <p className="printer-preview-mini-text">$</p>
            <p className="printer-preview-left-content-body-price">
              {prod?.Price?.toString()?.split('.')?.[0] || ''}.
            </p>
            <p className="printer-preview-mini-text">
              {prod?.Price?.toString()?.split('.')?.[1] || '00'}
            </p>
          </div>
        )}
        {prod?.BasePrice && (
          <div
            style={{
              position: 'absolute',
              bottom: '-22px',
              right: 0,
              width: '130px',
              height: '1px',
              background: 'red',
              transform: 'rotate(-6deg)',
            }}
          />
        )}
        {prod?.BasePrice && (
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              gap: '4px',
              position: 'absolute',
              bottom: '-38px',
              right: '0',
            }}>
            <p
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                marginBottom: '0px',
                color: '#000',
              }}>
              Antes
            </p>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <p
                className="printer-preview-mini-text"
                style={{ color: '#000', fontSize: '16px', marginTop: '-12px' }}>
                $
              </p>
              <p
                className="printer-preview-left-content-body-price"
                style={{ color: '#000', fontSize: '38px', marginTop: 0 }}>
                {prod?.BasePrice?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p
                className="printer-preview-mini-text"
                style={{ color: '#000', fontSize: '16px', marginTop: '-12px' }}>
                {prod?.BasePrice?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          </div>
        )}
      </div>
      <div
        className="printer-preview-left-content-footer"
        style={{ bottom: 2 }}>
        <div className="printer-preview-left-content-footer-quotes">
          <p></p>
          <p className="printer-preview-left-content-footer-quote-text">
            36 <br /> cuotas
          </p>
          <p className="printer-preview-left-content-footer-quote-text">
            {!loading && '$' + (prod?.cmfCredit || '')}
            {loading && (
              <FontAwesomeIcon
                icon={faSpinner}
                className="spinner"
                style={{ width: 20 }}
              />
            )}
          </p>
        </div>
        <div className="printer-preview-content-footer-input-wrapper">
          {isEditing && (
            <textarea
              className="printer-preview-left-content-footer-legal-1"
              type="text"
              value={terms}
              disabled={!isEditing}
              onChange={e => setTerms(e.target.value)}
              style={{
                border: isEditing ? '1px solid #000' : 'none',
                fontSize: '9px',
                height: '40.5px',
              }}
            />
          )}
          {!isEditing && (
            <p
              className="printer-preview-left-content-footer-legal-1"
              style={{
                border: isEditing ? '1px solid #000' : 'none',
                fontSize: '9px',
                height: '40.5px',
              }}>
              {terms}
            </p>
          )}
          <div className="printer-terms-edit-wrapper-1">
            {isEditing && (
              <CheckCircleFill
                onClick={handleEditTerms}
                className="printer-preview-content-check"
              />
            )}
            {isEditing && (
              <XCircleFill
                onClick={() => {
                  setIsEditing(false)
                  setTerms(DEFAULT_VALUE)
                }}
                className="printer-preview-content-circle"
              />
            )}
            {!isEditing && (
              <PencilSquare
                onClick={() => setIsEditing(true)}
                className="printer-preview-content-pencil"
              />
            )}
          </div>
        </div>
      </div>
      <img
        className="printer-preview-left-image"
        src={images.printer.templates.novey.cmfOffer1}
        alt="template-preview"
      />
    </div>
  )
}
