/* eslint-disable no-useless-catch */
import {
  getSummaryTaskService,
  getTaskTypesService,
} from 'redux/services/taskServices'

export const getTaskTypesAction = () => async () => {
  try {
    const response = await getTaskTypesService()
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return response
    }
  } catch (err) {
    throw err
  }
}

export const getSummaryTaskAction = payload => async () => {
  try {
    const response = await getSummaryTaskService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return response
    }
  } catch (err) {
    throw err
  }
}
