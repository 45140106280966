import React, { useEffect, useState } from 'react'
import FormDireccion from '../FormDireccion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import marker from '../../Assets/Images/MetodoEntrega/geo-alt-fill.svg'
import { useDispatch, useSelector } from 'react-redux'
import { agregarDireccion } from '../../redux/actions/cotizacionActions.js'
import { getCustomerById } from 'redux/actions/clientsActions'
import { customerTypeId } from 'Utils/constants'

const Direcciones = ({
  setSelectedAddress,
  setDireccion,
  handleMetodoEntrega,
  insideForm,
  selectedAddress,
  validateOnSuccessCallAddress,
}) => {
  const clienteId = useSelector(
    state => state?.cotizacion?.QuoteHeader?.CustomerId
  )

  const [clienteAddresses, setClienteAddresses] = useState([])

  const fetchCustomer = async currentCustomerId => {
    const customerInfo = await dispatch(getCustomerById(currentCustomerId))
    if (customerInfo?.CustomerType?.CustomerTypeId === customerTypeId.natural) {
      setClienteAddresses(customerInfo.AddressList)
    } else {
      setClienteAddresses(customerInfo?.AdministrativeStoreInfo?.AddressList)
    }
  }

  useEffect(() => {
    if (clienteId) {
      fetchCustomer(clienteId)
    }
    setMostrarFormDireccion(false)
  }, [validateOnSuccessCallAddress])

  const [optionSelect, setOptionSelect] = useState(selectedAddress || null)
  // MOSTRAR FORMULARIO DE DIRECCIONs
  const [mostrarFormDireccion, setMostrarFormDireccion] = useState(false)

  const dispatch = useDispatch()

  const selectAddress = address => {
    setOptionSelect(address)
    if (address) {
      setSelectedAddress(address)
      setDireccion(true)
      const finalAddress = {
        Address_AddressDetail:
          address.AddressInfo?.Name || address.AddressInfo?.AddressDetail,
        Address_AddressId: address.AddressInfo?.AddressId,
        Address_AddressTypeId: address.AddressInfo?.AddressType
          ? address.AddressInfo?.AddressType?.AddressTypeId
          : null,
        Address_BuildingId: address.AddressInfo?.BuildingId,
        Address_BuildingName: null,
        Address_BuildingType: address.AddressInfo?.BuildingType,
        Address_BuildingTypeId: address.AddressInfo?.BuildingTypeId,
        Address_CountryId: address.AddressInfo?.Country.CountryId,
        Address_CountryName: address.AddressInfo?.Country.Name,
        Address_DistrictId: address.AddressInfo?.District.DistrictId,
        Address_DistrictName: address.AddressInfo?.District.Name,
        Address_HomeOrFloorNumber: address.AddressInfo?.HomeOrFloorNumber,
        Address_Latitude: address.AddressInfo?.Latitude,
        Address_Longitude: address.AddressInfo?.Longitude,
        Address_NeighborhoodId: address.AddressInfo?.NeighborhoodId,
        Address_NeighborhoodName: address.AddressInfo?.Neighborhood,
        Address_ProvinceId: address.AddressInfo?.Province?.ProvinceId,
        Address_ProvinceName: address.AddressInfo.Province?.Name,
        Address_Street: address.AddressInfo?.Street,
        Address_TownshipId: address.AddressInfo?.Township.TownshipId,
        Address_TownshipName: address.AddressInfo?.Township.Name,
      }
      dispatch(agregarDireccion(finalAddress))
      handleMetodoEntrega(false)
    }
  }

  return (
    <>
      {mostrarFormDireccion ? (
        <FormDireccion
          setSelectedAddress={setSelectedAddress}
          setDireccion={setDireccion}
          handleMetodoEntrega={handleMetodoEntrega}
        />
      ) : (
        <form
          className="content-domicilio"
          style={{ padding: insideForm && 0 }}>
          <h2>Seleccione una dirección</h2>
          <ul className="address-options">
            {clienteAddresses &&
              clienteAddresses.map((direccion, index) => {
                return (
                  <li
                    key={index}
                    className={
                      (optionSelect?.AddressInfo?.AddressId ===
                        direccion.AddressInfo?.AddressId ||
                        optionSelect?.Address_AddressId ===
                          direccion.AddressInfo?.AddressId) &&
                      (direccion.AddressInfo?.Name !== undefined ||
                        direccion.AddressInfo?.AddressDetail !== undefined)
                        ? 'domicilio-option selected'
                        : 'domicilio-option'
                    }>
                    <div className="name-domicilio">
                      <img src={marker} alt="marcador" />
                      <label htmlFor={direccion?.AddressInfo?.AddressId}>
                        {direccion.AddressInfo?.Name ||
                          direccion.AddressInfo?.AddressDetail}
                      </label>
                    </div>
                    <input
                      type="radio"
                      id={direccion?.AddressInfo?.AddressId}
                      name="selected-address"
                      onClick={() => {
                        selectAddress(direccion)
                      }}
                    />
                    <FontAwesomeIcon icon={faCheck} />
                  </li>
                )
              })}
            <li
              className="domicilio-option add-domicilio"
              onClick={() => setMostrarFormDireccion(!mostrarFormDireccion)}>
              <label htmlFor="">Agregar nueva dirección</label>
              <div className="add-domicilio">+</div>
            </li>
          </ul>
        </form>
      )}
    </>
  )
}

export default Direcciones
