/* eslint-disable no-useless-catch */
import {
  getCmfPriceService,
  getInventoryService,
  getProductDetaillService,
  getRefererCreateService,
  getSpecificCampaignService,
  getWarrantyService,
  getcmfCampaignsService,
} from 'redux/services/productsServices'
import {
  ADD_FACET_FILTER,
  REMOVE_FACET_FILTERS,
  SINGLE_FACET_FILTER,
  ADD_CATEGORIES,
  REMOVE_CATEGORIES,
  REMOVE_SINGLE_CATEGORY,
  ADD_BRANDS,
  REMOVE_BRANDS,
  REMOVE_SINGLE_BRAND,
  SHOW_PRODUCTS,
} from '../types'

const addFacetFilter = payload => {
  return {
    type: ADD_FACET_FILTER,
    payload,
  }
}

const removeFacetFilters = () => {
  return {
    type: REMOVE_FACET_FILTERS,
  }
}

const singleFacetFilter = payload => {
  return {
    type: SINGLE_FACET_FILTER,
    payload,
  }
}

const addCategories = payload => {
  return {
    type: ADD_CATEGORIES,
    payload,
  }
}

const addBrands = payload => {
  return {
    type: ADD_BRANDS,
    payload,
  }
}

const removeCategories = () => {
  return {
    type: REMOVE_CATEGORIES,
  }
}

const removeBrands = () => {
  return {
    type: REMOVE_BRANDS,
  }
}

const removeSingleCategory = payload => {
  return {
    type: REMOVE_SINGLE_CATEGORY,
    payload,
  }
}
const removeSingleBrand = payload => {
  return {
    type: REMOVE_SINGLE_BRAND,
    payload,
  }
}

const showProductsList = payload => {
  return {
    type: SHOW_PRODUCTS,
    payload,
  }
}

export {
  addFacetFilter,
  removeFacetFilters,
  singleFacetFilter,
  addCategories,
  addBrands,
  removeCategories,
  removeBrands,
  removeSingleCategory,
  removeSingleBrand,
  showProductsList,
}

export const getCmfPriceAction = payload => async () => {
  try {
    const response = await getCmfPriceService(payload)
    const { data, status } = response
    if (status === 200 && data) {
      return response
    }
  } catch (err) {
    throw err
  }
}

export const getProductDetaillAction = payload => async () => {
  try {
    const response = await getProductDetaillService(payload)
    const { data, status } = response
    if (status === 200 && data) {
      return response
    }
  } catch (err) {
    throw err
  }
}

export const getInventoryAction = payload => async () => {
  try {
    const response = await getInventoryService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return response
    }
  } catch (err) {
    throw err
  }
}

export const getRefererCreateAction = payload => async () => {
  try {
    const response = await getRefererCreateService(payload)
    const { data, status } = response
    if (status === 200 && data) {
      return response
    }
  } catch (err) {
    throw err
  }
}

export const getcmfCampaignsAction = payload => async () => {
  try {
    const response = await getcmfCampaignsService(payload)
    const { data, status } = response
    if (status === 200 && data) {
      return response
    }
  } catch (err) {
    throw err
  }
}

export const getSpecificCampaignAction = payload => async () => {
  try {
    const response = await getSpecificCampaignService(payload)
    const { data, status } = response
    if (status === 200 && data) {
      return response
    }
  } catch (err) {
    throw err
  }
}

export const getWarrantyAction = () => async () => {
  try {
    const response = await getWarrantyService()
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return response
    }
  } catch (err) {
    throw err
  }
}
