import { useSelector } from 'react-redux'
import { t } from 'i18next'
import {
  customerTypeAllowedRoles,
  docHeaderStatusId,
  VAPermissions,
} from 'Utils/constants'
import useValidatePermission from 'Hooks/useValidatePermission'
import { hasChangePriceApprove, validateRoles } from 'Utils/functions'
import { useHasProductWithPriceUnderCost } from 'Components/CartProduct/hooks/useHasProductWithPriceUnderCost'

export const useCotizationLogic = () => {
  const { isCommercialSeller, hasPriceUnderCost } =
    useHasProductWithPriceUnderCost()
  const isCustomerUser = useSelector(state => state.login.isCustomer)
  const cart = useSelector(state => state.cart.cart)
  const loginInfo = useSelector(state => state.login?.user?.AuthenticationInfo)
  const isSupervisorUser = validateRoles(
    loginInfo.UserRoles,
    customerTypeAllowedRoles.supervGerent
  )
  const isCommercialSupervisorUser = validateRoles(
    loginInfo.UserRoles,
    customerTypeAllowedRoles.comercialSuperv
  )

  const onProductPriceChange = () =>
    isSupervisorUser || isCommercialSupervisorUser
      ? false
      : !hasPriceUnderCost && isCommercialSeller
      ? false
      : hasChangePriceApprove(cart)

  const createQuote = useValidatePermission(VAPermissions.createQuote)

  const cotizationType = [
    {
      id: '',
      name: t('SELL_BY_PROD_T.CANCEL_NAME'),
      value: t('SELL_BY_PROD_T.CANCEL_VALUE'),
      valueState: 'CANCEL',
      disabled: false,
    },
    {
      id: docHeaderStatusId.draft,
      name: t('SELL_BY_PROD_T.DRAFT_NAME'),
      value: t('SELL_BY_PROD_T.DRAFT_VALUE'),
      valueState: 'DRAFT',
      disabled: false,
    },
    {
      id: docHeaderStatusId.open,
      name: t('SELL_BY_PROD_T.SAVE_QUOTATION_NAME'),
      value: t('SELL_BY_PROD_T.SAVE_QUOTATION_VALUE'),
      valueState: 'OPEN',
      disabled: isCustomerUser || onProductPriceChange() || !createQuote,
    },
  ]
  return { cotizationType }
}
