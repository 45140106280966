import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import './table.css'
import { UpdateApprovalSalesOrder } from 'redux/services/cotizacionServices'
import { CircularProgress } from '@mui/material'
import No from '../../Assets/Images/Login/no.svg'

const ApprovalMagin = ({
  modalApprovalVisible,
  setModalApprovalVisible,
  setIsApproved,
}) => {
  const useSelectorQuoteObject = useSelector(state => state.cotizacion)
  const [isLoading, setIsLoading] = useState(false)

  const productsWithNegativeMargin = useMemo(
    () =>
      useSelectorQuoteObject?.QuoteHeader?.Lines?.filter(
        line =>
          line?.Price < (line?.FinalPrices?.[1]?.FinalPrice || line?.FinalPrice)
      ),
    [useSelectorQuoteObject?.QuoteHeader?.Lines]
  )
  const updateApprovalSaleOrder = async () => {
    try {
      setIsLoading(true)
      await UpdateApprovalSalesOrder({
        DocumentHeaderId: useSelectorQuoteObject.QuoteHeader.RelationDoc.find(
          doc => doc.DocumentNumber.includes('ODV')
        )?.DocHeaderId,
        Approve: true,
      })
      setIsApproved(true)
      setModalApprovalVisible(false)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <>
      <div>
        <img
          src={No}
          alt="close"
          onClick={() => {
            setModalApprovalVisible(false)
          }}
          style={{
            position: 'absolute',
            top: '20px',
            right: '26px',
            cursor: 'pointer',
          }}
        />
      </div>
      <div>
        <p className="title-center">
          Productos con precio por debajo del margen
        </p>
      </div>
      <div>
        {productsWithNegativeMargin.length > 0 && (
          <table className="single-product-cart inventory-table products">
            <thead>
              <tr>
                <th>Referencia</th>
                <th>Descripción</th>
              </tr>
            </thead>
            <tbody>
              {productsWithNegativeMargin.map(item => (
                <tr key={item.SystemCode}>
                  <td>{item.SystemCode}</td>
                  <td>{item.ProductName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div className="bottons-position">
          <button
            className="botton-disapproved"
            onClick={() => {
              setModalApprovalVisible(false)
            }}>
            Denegar Costos
          </button>
          <button
            className="botton-approved"
            disabled={isLoading}
            onClick={() => {
              updateApprovalSaleOrder()
            }}>
            {isLoading ? <CircularProgress size={20} /> : 'Aprobar Costos'}
          </button>
        </div>
      </div>
    </>
  )
}

export default ApprovalMagin
