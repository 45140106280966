import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import './loading.css'

function Loading({ full = false }) {
  const style = full ? 'loading-box full' : 'loading-box'
  return (
    <Box
      data-testid="loader-box"
      className={style}
      sx={{ '& .MuiCircularProgress-svg': { color: '#003082' } }}>
      <CircularProgress />
    </Box>
  )
}

export default Loading
