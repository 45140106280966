import { getChannelsService } from 'redux/services/cotizacionServices'
import {
  creditAccountsService,
  fullfilmentPlanByDeliveryService,
  fullfilmentPlanService,
  supplierServices,
} from 'redux/services/clientsServices'

export const fullfilmentPlanAction = () => {
  return async dispatch => {
    dispatch({ type: 'LOADING_FULLFILMENT', payload: true })

    try {
      const res = await fullfilmentPlanService()
      const { data, status } = res
      if (status === 200 && data) {
        dispatch({ type: 'FULLFILMENT_RESPONSE', payload: data })
        return data
      } else {
        dispatch({
          type: 'FULLFILMENT_RESPONSE',
          payload: { message: 'ERROR' },
        })
        return { message: 'ERROR' }
      }
    } catch (err) {
      console.error('Error fullfilmentPlanAction', err)
      return err
    }
  }
}

export const fullfilmentPlanActionByDelivery = payload => {
  return async dispatch => {
    dispatch({ type: 'LOADING_FULLFILMENT', payload: true })

    try {
      const res = await fullfilmentPlanByDeliveryService(payload)
      const { data, status } = res
      if (status === 200 && data) {
        dispatch({ type: 'FULLFILMENT_RESPONSE', payload: data })
        return data
      } else {
        dispatch({
          type: 'FULLFILMENT_RESPONSE',
          payload: { message: 'ERROR' },
        })
        return { message: 'ERROR' }
      }
    } catch (err) {
      console.error('Error getChannelsAction', err)
      dispatch({ type: 'FULLFILMENT_RESPONSE', payload: { message: 'ERROR' } })
      return { message: 'ERROR' }
    }
  }
}

export const supplierAction = products => {
  return async dispatch => {
    dispatch({ type: 'LOADING_SUPPLIER', payload: true })
    try {
      const response = await supplierServices(products)
      const { data, status } = response
      if (status === 200 && data?.Data && data.Data.length > 0) {
        dispatch({ type: 'SUPPLIER_RESPONSE', payload: data.Data })
        dispatch({ type: 'LOADING_SUPPLIER', payload: false })
        return data.Data
      } else {
        dispatch({
          type: 'SUPPLIER_RESPONSE',
          payload: [],
        })
        dispatch({ type: 'LOADING_SUPPLIER', payload: false })
        return []
      }
    } catch (err) {
      console.error('Error getChannelsAction', err)
      dispatch({
        type: 'SUPPLIER_RESPONSE',
        payload: [],
      })
      dispatch({ type: 'LOADING_SUPPLIER', payload: false })
      return []
    }
  }
}

export const creditAccountsAction = ({ customerId, companyId }) => {
  return async dispatch => {
    dispatch({ type: 'LOADING_CREDIT_ACCOUNTS', payload: true })
    try {
      const response = await creditAccountsService({ customerId, companyId })
      const { data, status } = response
      if (status === 200 && data?.Data && data.Data.length > 0) {
        dispatch({ type: 'CREDIT_ACC_RESPONSE', payload: data })
        return data
      } else {
        dispatch({
          type: 'CREDIT_ACC_RESPONSE',
          payload: { message: 'ERROR' },
        })
        return { message: 'ERROR' }
      }
    } catch (err) {
      console.error('Error getChannelsAction', err)
      dispatch({
        type: 'CREDIT_ACC_RESPONSE',
        payload: { message: 'ERROR' },
      })
      return { message: 'ERROR' }
    }
  }
}

export const getChannelsAction = () => async () => {
  try {
    const response = await getChannelsService()
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    } else {
      return 'Error: ' + response
    }
  } catch (err) {
    console.error('Error getChannelsAction', err)
    return err
  }
}
