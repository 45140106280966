import React from 'react'
import No from '../../Assets/Images/Login/no.svg'
import './genericWarningModal.css'
import { Modal } from 'react-bootstrap'
import { Cart } from 'react-bootstrap-icons'

function GenericWarningModal({
  validateIsCartEmpty,
  setValidateIsCartEmpty,
  textSelected,
}) {
  return (
    <Modal show={validateIsCartEmpty} centered>
      <div className="box-generacion">
        <div className="box-generacion-header">
          <img
            src={No}
            alt="cerrar-modal"
            onClick={() => {
              setValidateIsCartEmpty(false)
            }}
          />
        </div>
        <div className="box-generacion-body">
          <Cart style={{ fontSize: '20px' }} />
          <p>{textSelected}</p>
        </div>
      </div>
    </Modal>
  )
}

export default GenericWarningModal
