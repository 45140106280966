/* eslint-disable no-case-declarations */
/** @format */

import {
  ADD_FACET_FILTER,
  REMOVE_FACET_FILTERS,
  SINGLE_FACET_FILTER,
  ADD_CATEGORIES,
  ADD_BRANDS,
  REMOVE_CATEGORIES,
  REMOVE_SINGLE_CATEGORY,
  REMOVE_SINGLE_BRAND,
  REMOVE_BRANDS,
  SHOW_PRODUCTS,
} from '../types'

const initialState = {
  facetFilters: [
    { key: 'department', value: [] },
    { key: 'category', value: [] },
    { key: 'brand', value: [] },
  ],
  categories: [],
  brands: [],
  productsToPickup: [],
  showProductsList: true,
}

export default (state = initialState, action) => {
  switch (action.type) {
    // Product Filtering
    case ADD_FACET_FILTER:
      // eslint-disable-next-line no-case-declarations
      const facetFilterArray = state.facetFilters
      facetFilterArray.forEach(item => {
        if (item.key === action.payload.key) {
          item.value.push(action.payload.value)
        }
      })
      return {
        ...state,
        facetFilters: [...facetFilterArray],
      }
    case SINGLE_FACET_FILTER:
      return {
        ...state,
        facetFilters: [...state.facetFilters, action.payload],
      }
    case REMOVE_FACET_FILTERS:
      return {
        facetFilters: [
          { key: 'department', value: [] },
          { key: 'category', value: [] },
          { key: 'brand', value: [] },
        ],
        categories: [],
        brands: [],
        showProductsList: true,
      }
    case ADD_CATEGORIES:
      return {
        ...state,
        categories: [...state.categories, action.payload],
      }
    case ADD_BRANDS:
      return {
        ...state,
        brands: [...state.brands, action.payload],
      }
    case REMOVE_CATEGORIES:
      return {
        ...state,
        categories: [],
      }
    case REMOVE_BRANDS:
      return {
        ...state,
        brands: [],
      }
    case REMOVE_SINGLE_CATEGORY:
      const categories = [...state.categories]
      const facetCategory = [...state.facetFilters[1].value]
      const newFacetFilters = [...state.facetFilters]
      facetCategory.forEach((item, key) => {
        if (item.slice(9) === action.payload) {
          return facetCategory.splice(key, 1)
        }
      })
      newFacetFilters[1].value = [...facetCategory]
      const categoryToRemove = action.payload
      const categoryIndex = categories.indexOf(
        category => categoryToRemove === category
      )
      // const facetFiltersCategory = state.facetFilters.filter(
      //   state => state.key === 'category'
      // )
      categories.splice(categoryIndex, 1)
      return {
        ...state,
        categories: [...categories],
        facetFilters: [...newFacetFilters],
      }
    case REMOVE_SINGLE_BRAND:
      const brands = [...state.brands]
      const facetBrand = [...state.facetFilters[2].value]
      const newFacetFiltersBrand = [...state.facetFilters]
      facetBrand.forEach((item, key) => {
        if (item.slice(6) === action.payload) {
          return facetBrand.splice(key, 1)
        }
      })
      newFacetFiltersBrand[2].value = [...facetBrand]
      const brandToRemove = action.payload
      const brandIndex = brands.indexOf(brand => brandToRemove === brand)
      // const facetFiltersBrand = state.facetFilters.filter(
      //   state => state.key === 'brand'
      // )
      brands.splice(brandIndex, 1)
      return {
        ...state,
        brands: [...brands],
        facetFilters: [...newFacetFiltersBrand],
      }

    case SHOW_PRODUCTS:
      return {
        ...state,
        showProductsList: action.payload,
      }

    case 'SET_PRODUCTS_TO_PICKUP':
      return {
        ...state,
        productsToPickup: action.payload,
      }

    default:
      return state
  }
}
