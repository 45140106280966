import acabados from '../Assets/Images/Departamentos/dept-images/acabados.svg'
import aireLibre from '../Assets/Images/Departamentos/dept-images/aire-l.svg'
import auto from '../Assets/Images/Departamentos/dept-images/auto.svg'
import bath from '../Assets/Images/Departamentos/dept-images/bath.svg'
import kitchen from '../Assets/Images/Departamentos/dept-images/kitchen.svg'
import construction from '../Assets/Images/Departamentos/dept-images/construccion.svg'
import electrico from '../Assets/Images/Departamentos/dept-images/electrico.svg'
import electronica from '../Assets/Images/Departamentos/dept-images/electronica.svg'
import school from '../Assets/Images/Departamentos/dept-images/school.svg'
import ferreteria from '../Assets/Images/Departamentos/dept-images/ferreteria.svg'
import tools from '../Assets/Images/Departamentos/dept-images/tools.svg'
import iluminacion from '../Assets/Images/Departamentos/dept-images/iluminacion.svg'
import lineaB from '../Assets/Images/Departamentos/dept-images/linea-b.svg'
import jardineria from '../Assets/Images/Departamentos/dept-images/jardineria.svg'
import limpieza from '../Assets/Images/Departamentos/dept-images/limpieza.svg'
import mascotas from '../Assets/Images/Departamentos/dept-images/mascotas.svg'
import muebles from '../Assets/Images/Departamentos/dept-images/muebles.svg'
import pintura from '../Assets/Images/Departamentos/dept-images/pintura.svg'
import puertas from '../Assets/Images/Departamentos/dept-images/puertas.svg'
import plomeria from '../Assets/Images/Departamentos/dept-images/plomeria.svg'
import recamara from '../Assets/Images/Departamentos/dept-images/recamara.svg'
import organizacion from '../Assets/Images/Departamentos/dept-images/organizacion.svg'

const depts = [
  {
    title: 'Acabados',
    image: acabados,
  },
  {
    title: 'Aire libre y recreación',
    image: aireLibre,
  },
  {
    title: 'Automóvil',
    image: auto,
  },
  {
    title: 'Baño',
    image: bath,
  },
  {
    title: 'Cocina',
    image: kitchen,
  },
  {
    title: 'Construcción',
    image: construction,
  },
  {
    title: 'Eléctrico',
    image: electrico,
  },
  {
    title: 'Electrónica',
    image: electronica,
  },
  {
    title: 'Escolar y oficina',
    image: school,
  },
  {
    title: 'Ferretería',
    image: ferreteria,
  },
  {
    title: 'Herramientas',
    image: tools,
  },
  {
    title: 'Iluminación y Ventilación',
    image: iluminacion,
  },
  {
    title: 'Línea Blanca',
    image: lineaB,
  },
  {
    title: 'Jardinería',
    image: jardineria,
  },
  {
    title: 'Limpieza',
    image: limpieza,
  },
  {
    title: 'Mascotas',
    image: mascotas,
  },
  {
    title: 'Muebles',
    image: muebles,
  },
  {
    title: 'Organización',
    image: organizacion,
  },
  {
    title: 'Pintura',
    image: pintura,
  },
  {
    title: 'Puertas y ventanas',
    image: puertas,
  },
  {
    title: 'Plomeria',
    image: plomeria,
  },
  {
    title: 'Recámara',
    image: recamara,
  },
]

export default depts
