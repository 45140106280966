import { ROUTES, history } from 'Utils/constants'
import { useDispatch } from 'react-redux'
import analytics from 'shared/Analytics'

export const useLogout = () => {
  const dispatch = useDispatch()

  const logout = async () => {
    dispatch({ type: 'LOGOUT_USER' })
    dispatch({ type: 'COTIZACION_REMOVE' })
    await analytics.pause()
    history.push(ROUTES.login)
  }

  return { logout }
}
