import React from 'react'
import { images } from 'Assets/Images'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'

const DEFAULT_VALUE =
  'La tarjeta de crédito CMF es emitida por CM Financiera, S.A., sujeto a previa aprobación de esa entidad. Para más información ingresa a cmfinanciera.com. Cuotas calculadas con tasa de interés nominal del 57% anual. La responsabilidad de CM Financiera se limita exclusivamente a aspectos propios del crédito otorgado y no por los productos o servicios adquiridos mediante el uso del crédito.'

export const Square1ToPrintCCmfOffer = ({ products, i }) => {
  const { templateRef } = usePrinterContext()

  const prod = products?.content?.products?.[0]
  const terms = products?.content?.terms || DEFAULT_VALUE

  return (
    <div ref={el => (templateRef.current[i] = el)}>
      <div
        id="printer-preview-left-canvas"
        className="printer-preview-left-canvas">
        <div className="printer-preview-left-content-body">
          <p className="printer-preview-left-content-body-title">
            {prod?.ProductName || ''}
          </p>
          <p className="printer-preview-left-content-body-sku">
            {prod?.Sku || ''}
          </p>
          {prod?.Price && (
            <div className="printer-preview-left-content-body-price-wrapper">
              <p
                className="printer-preview-mini-text"
                style={{ color: '#000' }}>
                $
              </p>
              <p
                className="printer-preview-left-content-body-price"
                style={{ color: '#000' }}>
                {prod?.Price?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p
                className="printer-preview-mini-text"
                style={{ color: '#000' }}>
                {prod?.Price?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
          {prod?.BasePrice && (
            <div
              style={{
                position: 'absolute',
                bottom: '-22px',
                right: 0,
                width: '130px',
                height: '1px',
                background: 'red',
                transform: 'rotate(-6deg)',
              }}
            />
          )}
          {prod?.BasePrice && (
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                gap: '4px',
                position: 'absolute',
                bottom: '-38px',
                right: '0',
              }}>
              <p
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  marginBottom: '0px',
                  color: '#000',
                }}>
                Antes
              </p>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <p
                  className="printer-preview-mini-text"
                  style={{
                    color: '#000',
                    fontSize: '16px',
                    marginTop: '-12px',
                  }}>
                  $
                </p>
                <p
                  className="printer-preview-left-content-body-price"
                  style={{ color: '#000', fontSize: '38px', marginTop: 0 }}>
                  {prod?.BasePrice?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p
                  className="printer-preview-mini-text"
                  style={{
                    color: '#000',
                    fontSize: '16px',
                    marginTop: '-12px',
                  }}>
                  {prod?.BasePrice?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            </div>
          )}
        </div>
        <div
          className="printer-preview-left-content-footer"
          style={{ bottom: 2 }}>
          <div
            className="printer-preview-left-content-footer-quotes"
            style={{ opacity: prod?.cmfCredit ? 1 : 0 }}>
            <p></p>
            <p
              className="printer-preview-left-content-footer-quote-text"
              style={{ color: '#000' }}>
              36 <br /> cuotas
            </p>
            <p
              className="printer-preview-left-content-footer-quote-text"
              style={{ color: '#000' }}>
              ${prod?.cmfCredit || ''}
            </p>
          </div>
          <div className="printer-preview-content-footer-input-wrapper">
            <p
              className="printer-preview-left-content-footer-legal-1"
              style={{
                border: 'none',
                color: '#000',
                fontSize: '9px',
                background: 'transparent',
                height: '40.5px',
              }}>
              {terms}
            </p>
          </div>
        </div>
        <img
          className="printer-preview-left-image"
          src={images.printer.templates.cochez.cmfOffer1}
          alt="template-preview"
          style={{ opacity: 0 }}
        />
      </div>
    </div>
  )
}
