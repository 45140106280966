import React, { useEffect } from 'react'
import { images } from 'Assets/Images'
import {
  CheckCircleFill,
  PencilSquare,
  XCircleFill,
} from 'react-bootstrap-icons'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useGetCmfCredit } from '../../../hooks/useGetCmfCredit'

const DEFAULT_VALUE =
  'La tarjeta de crédito CMF es emitida por CM Financiera, S.A., sujeto a previa aprobación de esa entidad. Para más información ingresa a cmfinanciera.com. Cuotas calculadas con tasa de interés nominal del 57% anual. La responsabilidad de CM Financiera se limita exclusivamente a aspectos propios del crédito otorgado y no por los productos o servicios adquiridos mediante el uso del crédito.'

export const Square3NCmfOffer = ({ products }) => {
  const { currentPage, allTemplatesContent, setAllTemplatesContent } =
    usePrinterContext()

  const currentContent = allTemplatesContent.find(
    content => content.page === currentPage
  )
  const currentTerms = currentContent?.content?.terms || DEFAULT_VALUE

  const { fetchCmfCredit, loading } = useGetCmfCredit()
  const [isEditing, setIsEditing] = React.useState(false)
  const [terms, setTerms] = React.useState(currentTerms)

  const prod1 = products?.content?.products?.[0]
  const prod2 = products?.content?.products?.[1] || {}
  const prod3 = products?.content?.products?.[2] || {}
  const prod4 = products?.content?.products?.[3] || {}

  const handleEditTerms = () => {
    const updatedContent = allTemplatesContent.map(content => {
      if (content.page === currentPage) {
        return {
          ...content,
          content: {
            ...content.content,
            terms,
          },
        }
      }
      return content
    })
    setAllTemplatesContent(updatedContent)
    setIsEditing(false)
  }

  useEffect(() => {
    if (prod1?.Sku && !prod1?.cmfCredit) {
      fetchCmfCredit(prod1?.Price, prod1?.Sku)
    }
    if (prod2?.Sku && !prod2?.cmfCredit) {
      fetchCmfCredit(prod2?.Price, prod2?.Sku)
    }
    if (prod3?.Sku && !prod3?.cmfCredit) {
      fetchCmfCredit(prod3?.Price, prod3?.Sku)
    }
    if (prod4?.Sku && !prod4?.cmfCredit) {
      fetchCmfCredit(prod4?.Price, prod4?.Sku)
    }
  }, [products])

  return (
    <div
      id="printer-preview-left-canvas-square-3"
      className="printer-preview-left-canvas-square-3">
      <div
        className="printer-preview-left-canvas-square-3-box"
        style={{
          height: '100%',
          top: '0',
        }}>
        <div className="printer-preview-left-content-body-square-3">
          <p className="printer-preview-left-content-body-square-3-title">
            {prod1?.ProductName || ''}
          </p>
          <p className="printer-preview-left-content-body-square-3-sku">
            {prod1?.Sku || ''}
          </p>
          {prod1?.Price && (
            <div className="printer-preview-left-content-body-square-3-price-wrapper">
              <p className="printer-preview-mini-text-square-3">$</p>
              <p className="printer-preview-left-content-body-square-3-price">
                {prod1?.Price?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p className="printer-preview-mini-text-square-3">
                {prod1?.Price?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
        </div>
        <div
          className="printer-preview-left-content-footer-square-3"
          style={{ top: 'calc(50% - 84px)' }}>
          <div className="printer-preview-left-content-footer-square-3-quotes">
            <p></p>
            <p className="printer-preview-left-content-footer-square-3-quote-text">
              36 <br /> cuotas
            </p>
            <p className="printer-preview-left-content-footer-square-3-quote-text">
              {!loading && '$' + (prod1?.cmfCredit || '')}
              {loading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="spinner"
                  style={{ width: 20 }}
                />
              )}
            </p>
          </div>
          <div className="printer-preview-content-footer-input-wrapper">
            <textarea
              className="printer-preview-left-content-footer-square-2-legal"
              type="text"
              rows={5}
              value={terms}
              disabled={!isEditing}
              onChange={e => setTerms(e.target.value)}
              style={{ border: isEditing ? '1px solid #000' : 'none' }}
            />
            <div className="printer-terms-edit-wrapper-1">
              {isEditing && (
                <CheckCircleFill
                  onClick={handleEditTerms}
                  size={12}
                  className="printer-preview-content-check"
                />
              )}
              {isEditing && (
                <XCircleFill
                  size={12}
                  onClick={() => {
                    setIsEditing(false)
                    setTerms(DEFAULT_VALUE)
                  }}
                  className="printer-preview-content-circle"
                />
              )}
              {!isEditing && (
                <PencilSquare
                  size={12}
                  onClick={() => setIsEditing(true)}
                  className="printer-preview-content-pencil"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="printer-preview-left-canvas-square-3-box"
        style={{
          height: '100%',
          top: '0',
          left: '50%',
        }}>
        <div className="printer-preview-left-content-body-square-3">
          <p className="printer-preview-left-content-body-square-3-title">
            {prod2?.ProductName || ''}
          </p>
          <p className="printer-preview-left-content-body-square-3-sku">
            {prod2?.Sku || ''}
          </p>
          {prod2?.Price && (
            <div className="printer-preview-left-content-body-square-3-price-wrapper">
              <p className="printer-preview-mini-text-square-3">$</p>
              <p className="printer-preview-left-content-body-square-3-price">
                {prod2?.Price?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p className="printer-preview-mini-text-square-3">
                {prod2?.Price?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
        </div>
        <div
          className="printer-preview-left-content-footer-square-3"
          style={{ top: 'calc(50% - 84px)' }}>
          <div className="printer-preview-left-content-footer-square-3-quotes">
            <p></p>
            <p className="printer-preview-left-content-footer-square-3-quote-text">
              36 <br /> cuotas
            </p>
            <p className="printer-preview-left-content-footer-square-3-quote-text">
              {!loading && '$' + (prod2?.cmfCredit || '')}
              {loading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="spinner"
                  style={{ width: 20 }}
                />
              )}
            </p>
          </div>
          <div className="printer-preview-content-footer-input-wrapper">
            <textarea
              className="printer-preview-left-content-footer-square-2-legal"
              type="text"
              rows={5}
              value={terms}
              disabled={!isEditing}
              onChange={e => setTerms(e.target.value)}
              style={{ border: isEditing ? '1px solid #000' : 'none' }}
            />
            <div className="printer-terms-edit-wrapper-1">
              {isEditing && (
                <CheckCircleFill
                  onClick={handleEditTerms}
                  size={12}
                  className="printer-preview-content-check"
                />
              )}
              {isEditing && (
                <XCircleFill
                  size={12}
                  onClick={() => {
                    setIsEditing(false)
                    setTerms(DEFAULT_VALUE)
                  }}
                  className="printer-preview-content-circle"
                />
              )}
              {!isEditing && (
                <PencilSquare
                  size={12}
                  onClick={() => setIsEditing(true)}
                  className="printer-preview-content-pencil"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="printer-preview-left-canvas-square-3-box"
        style={{
          height: '50%',
          top: '50%',
        }}>
        <div className="printer-preview-left-content-body-square-3-2">
          <p className="printer-preview-left-content-body-square-3-title-2">
            {prod3?.ProductName || ''}
          </p>
          <p className="printer-preview-left-content-body-square-3-sku-2">
            {prod3?.Sku || ''}
          </p>
          {prod3?.Price && (
            <div className="printer-preview-left-content-body-square-3-price-wrapper">
              <p className="printer-preview-mini-text-square-3">$</p>
              <p className="printer-preview-left-content-body-square-3-price-2">
                {prod3?.Price?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p className="printer-preview-mini-text-square-3">
                {prod3?.Price?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
        </div>
        <div
          className="printer-preview-left-content-footer-square-3-2"
          style={{ top: 'calc(100% - 84px)' }}>
          <div className="printer-preview-left-content-footer-square-3-quotes">
            <p></p>
            <p className="printer-preview-left-content-footer-square-3-quote-text">
              36 <br /> cuotas
            </p>
            <p className="printer-preview-left-content-footer-square-3-quote-text">
              {!loading && '$' + (prod3?.cmfCredit || '')}
              {loading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="spinner"
                  style={{ width: 20 }}
                />
              )}
            </p>
          </div>
          <div className="printer-preview-content-footer-input-wrapper">
            <textarea
              className="printer-preview-left-content-footer-square-2-legal"
              type="text"
              rows={5}
              value={terms}
              disabled={!isEditing}
              onChange={e => setTerms(e.target.value)}
              style={{ border: isEditing ? '1px solid #000' : 'none' }}
            />
            <div className="printer-terms-edit-wrapper-1">
              {isEditing && (
                <CheckCircleFill
                  onClick={handleEditTerms}
                  size={12}
                  className="printer-preview-content-check"
                />
              )}
              {isEditing && (
                <XCircleFill
                  size={12}
                  onClick={() => {
                    setIsEditing(false)
                    setTerms(DEFAULT_VALUE)
                  }}
                  className="printer-preview-content-circle"
                />
              )}
              {!isEditing && (
                <PencilSquare
                  size={12}
                  onClick={() => setIsEditing(true)}
                  className="printer-preview-content-pencil"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="printer-preview-left-canvas-square-3-box"
        style={{
          height: '50%',
          top: '50%',
          left: '50%',
        }}>
        <div className="printer-preview-left-content-body-square-3-2">
          <p className="printer-preview-left-content-body-square-3-title-2">
            {prod4?.ProductName || ''}
          </p>
          <p className="printer-preview-left-content-body-square-3-sku-2">
            {prod4?.Sku || ''}
          </p>
          {prod4?.Price && (
            <div className="printer-preview-left-content-body-square-3-price-wrapper">
              <p className="printer-preview-mini-text-square-3">$</p>
              <p className="printer-preview-left-content-body-square-3-price-2">
                {prod4?.Price?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p className="printer-preview-mini-text-square-3">
                {prod4?.Price?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
        </div>
        <div
          className="printer-preview-left-content-footer-square-3-2"
          style={{ top: 'calc(100% - 84px)' }}>
          <div className="printer-preview-left-content-footer-square-3-quotes">
            <p></p>
            <p className="printer-preview-left-content-footer-square-3-quote-text">
              36 <br /> cuotas
            </p>
            <p className="printer-preview-left-content-footer-square-3-quote-text">
              {!loading && '$' + (prod4?.cmfCredit || '')}
              {loading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="spinner"
                  style={{ width: 20 }}
                />
              )}
            </p>
          </div>
          <div className="printer-preview-content-footer-input-wrapper">
            <textarea
              className="printer-preview-left-content-footer-square-2-legal"
              type="text"
              rows={5}
              value={terms}
              disabled={!isEditing}
              onChange={e => setTerms(e.target.value)}
              style={{ border: isEditing ? '1px solid #000' : 'none' }}
            />
            <div className="printer-terms-edit-wrapper-1">
              {isEditing && (
                <CheckCircleFill
                  onClick={handleEditTerms}
                  size={12}
                  className="printer-preview-content-check"
                />
              )}
              {isEditing && (
                <XCircleFill
                  size={12}
                  onClick={() => {
                    setIsEditing(false)
                    setTerms(DEFAULT_VALUE)
                  }}
                  className="printer-preview-content-circle"
                />
              )}
              {!isEditing && (
                <PencilSquare
                  size={12}
                  onClick={() => setIsEditing(true)}
                  className="printer-preview-content-pencil"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <img
        className="printer-preview-left-image"
        src={images.printer.templates.novey.cmfOffer3}
        alt="template-preview"
      />
    </div>
  )
}
