import React, { useState } from 'react'
import no from '../../../Assets/Images/Login/no.svg'
import check from '../../../Assets/Images/Login/check.png'
import './eliminarCliente.css'

const EliminarCliente = ({
  setShowModalDeleteClient,
  handleConfirmDeleteClient,
}) => {
  // STATE PARA MOSTRAR DIFERENTES MENSAJES.
  const [confirmDelete, setConfirmDelete] = useState(false)

  // CONFIRMAR QUE SE ELIMINA
  const confirmState = () => {
    // CONFIRMAR QUE SE ELIMINA PARA MOSTRAR OTRO MENSAJE
    setConfirmDelete(!confirmDelete)
    handleConfirmDeleteClient()
    setTimeout(() => {
      setShowModalDeleteClient(false)
    }, 5000)
  }

  const closeModalDeleteClient = () => {
    setShowModalDeleteClient(false)
  }

  return (
    <div className="modal-box-delete-client">
      {confirmDelete ? (
        <div className="delete-confirm-container">
          <div className="confirmation-header-modal">
            <span onClick={closeModalDeleteClient} className="close-modal">
              <img src={no} alt="close" />
            </span>
          </div>
          <div className="confirmation-message">
            <img src={check} alt="check" />
            <p className="confirmation-text-delete">
              El cliente ha sido eliminado
              <br />
              exitosamente
            </p>
          </div>
        </div>
      ) : (
        <div className="delete-confirm-container">
          <div className="confirmation-header">
            <span onClick={closeModalDeleteClient} className="close-modal">
              <img src={no} alt="close" />
            </span>
          </div>
          <div>
            <div className="confirmation-message">
              <p className="confirmation-text-delete">
                Estás seguro que deseas
                <br />
                eliminar este cliente?
              </p>
            </div>
            <div className="modal-buttons">
              <button onClick={confirmState} className="btn modal-si">
                Si
              </button>
              <button onClick={closeModalDeleteClient} className="btn modal-no">
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default EliminarCliente
