import { aludraApi } from 'Api/axios'

const mdl7 = {
  tasksURL: 'mdl07/dashBoard/summaryTaskType',
  fetchTasksURL: 'mdl07/dashBoard/summaryTask',
}

export const getTaskTypesService = async => {
  return aludraApi.get(mdl7.tasksURL)
}

export const getSummaryTaskService = async payload => {
  return aludraApi.post(mdl7.fetchTasksURL, payload)
}
