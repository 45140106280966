import {
  customerTypeId,
  documentTypeIds,
  telephoneTypeIds,
} from 'Utils/constants'

export const LEGAL_INITIAL_VALUES = {
  tenant: 2,
  aludraAPP: true,
  customerId: null,
  customerTypeId: customerTypeId.legal,
  password: '',
  customerStatus: 'active',
  customerStatusId: '081E533E-BE36-4B79-87A9-D6336EAB5AAF',
  ruc: '',
  dv: '',
  documentTypeId: documentTypeIds.legal.RUC,
  legalName: '',
  comercialName: '',
  generalStore: [
    {
      StoreId: null,
      AdministrativeLocation: true,
      generalTelephone: [
        {
          telephoneId: null,
          number: '',
          telephoneTypeId: telephoneTypeIds.house,
        },
      ],
      generalEmail: [
        {
          DefaultEmail: true,
          address: '',
          emailTypeId: documentTypeIds.legal.emailType,
        },
      ],
      generalAddress: null,
    },
  ],
  generalGroup: [
    {
      customerGroupId: '',
    },
  ],
  rolesName: ['CUSTOMER'],
}

export const NATURAL_INITIAL_VALUES = {
  tenant: 2,
  aludraAPP: true,
  firstName: '',
  lastName: '',
  fullName: '',
  idNumber: '',
  birthdate: '',
  genderId: '',
  DV: '',
  nationalityId: '',
  documentTypeId: documentTypeIds.natural.identificationCard,
  customerTypeId: customerTypeId.natural,
  generalTelephone: [
    {
      telephoneId: null,
      number: '',
      telephoneTypeId: telephoneTypeIds.house,
    },
  ],
  generalEmail: [
    {
      address: '',
      emailTypeId: documentTypeIds.natural.emailType,
      defaultEmail: true,
    },
  ],
  generalGroup: [
    {
      customerGroupId: '',
    },
  ],
  generalAddress: null,
  rolesName: ['CUSTOMER'],
}
