// SETUP AXIOS HEADERS
const headers = {
  'Ocp-Apim-Subscription-Key': 'f718d6d8af9848008b8f6a6f516cb7ba', // QA
  'app-type': 'va',
  'Content-Type': 'application/json',
}

const algoliaHeaders = {
  'X-Algolia-Application-Id': 'ZCZRBTYD8I',
  'X-Algolia-API-Key': '6c9235a951666253b5b003e1cf34d9ce',
}

export { headers, algoliaHeaders }
