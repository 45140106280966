import React, { useEffect, useRef, useState } from 'react'
import { useTable, useGlobalFilter } from 'react-table'
// import { useSelector, useDispatch } from "react-redux";
import { Filter, EyeSlash, Eye } from 'react-bootstrap-icons'
import { TableContent } from './components/TableContent/index'

function SingleProductFilteringTable({
  sentinelRef,
  SearchBox,
  data,
  columns,
  isProductPage,
  topButtons,
  hasButtons,
  hideFooter,
  isInventory,
  addToCart,
  setProductDetails,
  setIsOpen,
  setOpenWithInventario,
}) {
  const tableInstance = useTable({ columns, data }, useGlobalFilter)
  // const dispatch = useDispatch();
  // const showDiscountOption = useSelector(
  //   state => state.cotizacion.QuoteHeader.ShowDiscountOption
  // );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    allColumns,
    getToggleHideAllColumnsProps,
  } = tableInstance

  // TABLE LOGIC
  const [showInventoryFilters, setShowInventoryFilters] = useState(false)
  const inventoryFilter = useRef(null)

  // GLOBAL FILTER
  //   function GlobalFilter({
  //     globalFilter,
  //     setGlobalFilter,
  //   }) {
  //     return (
  //       <div className="inventory-search">

  //         <input
  //           value={globalFilter || ""}
  //           onChange={e => {
  //             setGlobalFilter(e.target.value)
  //           }}
  //           autoFocus
  //           placeholder="Buscar tienda por nombre o código"
  //         />
  //         <img className="inv-search-icon" src={Search} alt="Search icon" />
  //       </div>
  //     )
  //   }

  useEffect(() => {
    const handler = e => {
      if (!inventoryFilter?.current?.contains(e.target)) {
        setShowInventoryFilters(false)
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  }, [])

  // MOSTRAR TODAS CHECKBOX FILTERS
  // eslint-disable-next-line react/display-name
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return (
        <>
          <input id="inv_todas" type="checkbox" ref={resolvedRef} {...rest} />
          <label htmlFor="inv_todas">Mostrar Todas</label>
          <EyeSlash />
        </>
      )
    }
  )

  const showModalProducts = (type, product) => {
    setProductDetails(product)
    if (type === 'inventary') {
      setOpenWithInventario(true)
    } else {
      setOpenWithInventario(false)
    }
    setIsOpen(true)
  }

  return (
    <>
      <div className="inventory-topbar">
        <div className="inventory-filter-options">
          <button
            className="inventory-filter-button"
            onClick={() => setShowInventoryFilters(!showInventoryFilters)}>
            <Filter />
          </button>
          {showInventoryFilters && (
            <div ref={inventoryFilter} className="inventory-filters">
              <div className="inventory-filter-option">
                <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />
              </div>
              {allColumns.map(column =>
                column.hideEnabled &&
                column.id !== 'Tienda' &&
                column.id !== 'Código' &&
                column.id !== 'Disponible' ? (
                  <div className="inventory-filter-option" key={column.id}>
                    <input
                      id={column.id}
                      type="checkbox"
                      {...column.getToggleHiddenProps()}
                    />
                    <label htmlFor={column.id}>{column.id}</label>
                    {column.isVisible ? <Eye /> : <EyeSlash />}
                  </div>
                ) : null
              )}
              <br />
            </div>
          )}
        </div>
        {isProductPage ? null : SearchBox}
        {hasButtons ? topButtons : null}
      </div>
      <div className="tableContainer-sp">
        <table className="inventory-table products" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th key={i} {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            <TableContent
              rows={rows}
              addToCart={addToCart}
              prepareRow={prepareRow}
              sentinelRef={sentinelRef}
              showModalProducts={showModalProducts}
            />
          </tbody>
          {hideFooter ? null : (
            <tfoot
              className={isInventory ? 'stickyFooter' : null}
              style={{
                borderBottom: isProductPage && 'none',
                borderLeft: isProductPage && 'none',
                borderRight: isProductPage && 'none',
              }}>
              {footerGroups.map((group, i) => (
                <tr key={i} {...group.getFooterGroupProps()}>
                  {group.headers.map((column, i) => (
                    <td
                      key={i}
                      style={{ border: isProductPage && 'none' }}
                      {...column.getFooterProps()}>
                      {column.render('Footer')}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>
    </>
  )
}

export default SingleProductFilteringTable
