import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'i18next'
import React, { useState } from 'react'

import No from '../../../../../Assets/Images/Login/no.svg'
import search from '../../../../../Assets/Images/BuscarCotizacion/search.svg'
import styles from './styles.module.css'
import { usePriceListLogic } from './hooks/usePriceListLogic'

export const DisplayListPrices = ({ valueState, setValueState }) => {
  const { searchTextRef, listPrices, searchListPrice, searchListPriceText } =
    usePriceListLogic()

  const [showState, setShowState] = useState(false)

  const validateChecked = PriceListId => {
    const validateCotains = valueState.filter(
      list => list.PriceListId.toUpperCase() === PriceListId.toUpperCase()
    )
    return validateCotains?.length > 0
  }

  return (
    <div className="no-margin-nopadding generic-address-search-filter list-prices-component">
      <button
        type="button"
        id="generic-address-filter-content-list-price"
        className="generic-address-filter-content-list-price"
        onClick={() => setShowState(!showState)}>
        {valueState && valueState.length > 0 ? (
          valueState.map((value, index) => (
            <h2 className={'generic-price-list-label-text'} key={index}>
              {value.Description || value.PriceListName}
              <button
                type="button"
                className={styles.priceListCloseButton}
                onClick={() => {
                  const val = valueState.filter(
                    list => list.Description !== value.Description
                  )
                  setValueState(val)
                }}>
                <img
                  className="icon-list-prices"
                  src={No}
                  id={value.Description || value.PriceListName}
                  alt="close"
                />
              </button>
            </h2>
          ))
        ) : (
          <p className={'generic-price-list-label-text-unselect'}>
            {t('CREATE_CLIENT_T.PRICE_LISTS')}
          </p>
        )}
        <FontAwesomeIcon
          icon={faChevronDown}
          className="generic-list-price-down-icon"
        />
      </button>
      <ul
        className={
          showState
            ? 'generic-address-filter-options ul-price-list'
            : 'generic-address-filter-options-hidden'
        }
        onMouseLeave={() => setShowState(false)}>
        <li className="item-search-box-list-price">
          <div className="item-list-price-li-search">
            <input
              ref={searchTextRef}
              onChange={searchListPrice}
              type="text"
              className="list-price-search"
              placeholder="Buscar"
            />
            <img
              src={search}
              className="list-price-search-icon"
              alt="search"
              onClick={() => searchListPriceText(searchTextRef.current.value)}
            />
          </div>
        </li>
        {listPrices?.map((item, index) => {
          return (
            <li className="item-list-price-li" key={index}>
              <input
                type="checkbox"
                className="checkbox-item-list-price"
                id={item?.PriceListId}
                name={item?.PriceListId}
                value={item.Description}
                checked={validateChecked(item.PriceListId)}
                disabled={
                  valueState?.length === 3 && !validateChecked(item.PriceListId)
                }
                onClick={values => {
                  if (valueState?.length < 3) {
                    if (values.currentTarget?.checked) {
                      valueState.push(item)
                      setValueState(valueState)
                      setShowState(false)
                    } else if (!values.currentTarget?.checked) {
                      const val = valueState.filter(
                        list => list.Description !== item.Description
                      )
                      setValueState(val)
                      setShowState(false)
                    }
                  } else {
                    if (!values.currentTarget?.checked) {
                      const val = valueState.filter(
                        list => list.Description !== item.Description
                      )
                      setValueState(val)
                      setShowState(false)
                    } else {
                      values.preventDefault()
                      setShowState(false)
                    }
                  }
                }}
              />
              <label
                htmlFor={item?.PriceListId}
                className="label-item-list-price">
                {item?.Description}
              </label>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
