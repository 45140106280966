import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { ExclamationTriangleFill } from 'react-bootstrap-icons'
import No from '../../Assets/Images/Login/no.svg'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'

const ModalAlertCart = ({ preQuote, shopping, saleProduct }) => {
  const cart = useSelector(state => state.cart.cart)
  const client = useSelector(
    state => state.cotizacion?.QuoteHeader?.CustomerNameDocument
  )
  const [isPromptVisible, setIsPromptVisible] = useState(false)
  const [nextLocation, setNextLocation] = useState(null)
  const history = useHistory()

  useEffect(() => {
    const handleBeforeUnload = (nextLocation, event) => {
      if (!isPromptVisible && (cart.length > 0 || client)) {
        if (
          !nextLocation.search &&
          shopping &&
          !(
            nextLocation.pathname === '/generar_cotizacion' ||
            nextLocation.pathname === '/generar_cotizacion/sales_order' ||
            nextLocation.pathname === '/materials_preorden'
          )
        ) {
          if (window.scrollY > 0) {
            scroll.scrollToTop({
              delay: 0,
              smooth: 'linear',
              duration: 1,
            })
          }
          setNextLocation(nextLocation)
          setIsPromptVisible(true)
          return false
        } else if (
          preQuote &&
          !(
            nextLocation.pathname.startsWith('/editar_cotizacion') ||
            nextLocation.pathname.startsWith('/orden_de_venta/created')
          )
        ) {
          if (window.scrollY > 0) {
            scroll.scrollToTop({
              delay: 0,
              smooth: 'linear',
              duration: 1,
            })
          }
          setNextLocation(nextLocation)
          setIsPromptVisible(true)
          return false
        } else if (
          saleProduct &&
          !(nextLocation.pathname === '/generar_cotizacion' && event === 'PUSH')
        ) {
          if (window.scrollY > 0) {
            scroll.scrollToTop({
              delay: 0,
              smooth: 'linear',
              duration: 1,
            })
          }
          setNextLocation(nextLocation)
          setIsPromptVisible(true)
          return false
        }
      }
      return true
    }

    const unblock = history.block(handleBeforeUnload)

    const handleWindowBeforeUnload = event => {
      if (!isPromptVisible && (cart.length > 0 || client)) {
        event.preventDefault()
        event.returnValue = ''
        setIsPromptVisible(true)
      }
    }

    window.addEventListener('beforeunload', handleWindowBeforeUnload)

    return () => {
      unblock()
      window.removeEventListener('beforeunload', handleWindowBeforeUnload)
    }
  }, [cart, client, history, isPromptVisible])

  const handleConfirmAction = () => {
    setIsPromptVisible(false)
    if (nextLocation && nextLocation.pathname) {
      history.push(nextLocation.pathname)
    } else {
      window.location.reload()
    }
  }

  const handleCancelAction = () => {
    setIsPromptVisible(false)
  }

  return (
    <Modal
      show={isPromptVisible}
      backdrop={'static'}
      style={{ zIndex: 99999 }}
      className="modal-clase-price-change"
      centered>
      <div className="modal-price-change-allow">
        <span onClick={handleCancelAction}>
          <img src={No} alt="close" />
        </span>
        <div className="d-flex justify-content-center">
          <ExclamationTriangleFill className="check-costs-page-warning-icon" />
        </div>
        <div className="alert-cart-text">
          <h2>Tienes artículos en tu resumen de pedido.</h2>
          <h2> Si sales ahora, podrías perderlos.</h2>
          <h2>¿Deseas continuar?</h2>
        </div>
        <div className="price-allow-buttons">
          <button className="price-allow-true" onClick={handleConfirmAction}>
            Si
          </button>
          <button className="price-allow-false" onClick={handleCancelAction}>
            No
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalAlertCart
