import React from 'react'
import './miniLoading.css'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

/**
 * MiniLoading
 * @param {boolean} productsLoading - loading state
 * @param {boolean} full - full screen
 * @param {boolean} noMargin - no margin
 * @param {string} align - start | end | left | right | center | justify | match-parent
 **/
function MiniLoading({
  productsLoading = null,
  full = null,
  noMargin = false,
  align = 'center',
}) {
  return (
    <>
      <Box
        className={!productsLoading ? 'mini-loading-box' : null}
        sx={{
          '& .MuiCircularProgress-svg': { color: '#003082' },
          textAlign: align,
          marginTop: noMargin ? '0px' : '40px',
          height: full ? '100vh' : '',
        }}>
        <CircularProgress />
      </Box>
    </>
  )
}

export default MiniLoading
