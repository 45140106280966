import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Modal from 'react-bootstrap/Modal'
import no from '../../Assets/Images/Login/no.svg'
import { ExclamationTriangleFill } from 'react-bootstrap-icons'
import { CircularProgress } from '@mui/material'
import './routerPrompt.css'
import styles from './routerPrompt.module.css'

function RouterPrompt(props) {
  const {
    show,
    onOK,
    onCancel,
    title,
    okText,
    cancelText,
    handleSave,
    address,
    showWithoutNavigation,
    setShowHasChangesModal,
  } = props

  const history = useHistory()

  const [showPrompt, setShowPrompt] = useState(false)
  const [currentPath, setCurrentPath] = useState('')
  const [loading, setLoading] = useState(false)

  const handleOK = useCallback(async () => {
    if (onOK) {
      setLoading(true)
      const canRoute = await Promise.resolve(onOK())

      if (canRoute) {
        await handleSave()
        setLoading(false)
        setShowPrompt(false)
        setShowHasChangesModal(false)

        currentPath && history.block(() => {})
        currentPath && history.push(currentPath)
      }
    }
  }, [currentPath, history, onOK])

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onOK()) // It has the same onOK as handle OK because we want it to send us to the screen we click on, the difference is that it will not save changes. handleCancel is kept only in case you decide to do something different with the prompt.
      if (canRoute && currentPath) {
        history.block(() => {})
        history.push(currentPath)
      }
    }
    setShowPrompt(false)
    setShowHasChangesModal(false)
  }, [currentPath, history, onCancel])

  useEffect(() => {
    if (show) {
      history.block(prompt => {
        setCurrentPath(prompt?.pathname)
        setShowPrompt(true)
        return 'true'
      })
    } else {
      history.block(() => {})
    }

    return () => {
      history.block(() => {})
    }
  }, [history, show, address])

  useEffect(() => {
    if (showWithoutNavigation) {
      setShowPrompt(true)
    }
  }, [showWithoutNavigation])

  if (!showPrompt) return null

  return (
    <Modal show={showPrompt} centered>
      <div className="leave-modal-box">
        <div className="leave-modal-close">
          <img
            src={no}
            alt="close-modal"
            onClick={() => {
              if (loading) return
              setShowPrompt(false)
              setShowHasChangesModal(false)
            }}
          />
        </div>
        <div className="leave-page-container">
          <div className="leave-page-header">
            <ExclamationTriangleFill
              style={{
                fontSize: '40px',
                color: '#FF2A40',
                marginBottom: '19px',
              }}
            />
            <h2>{title}</h2>
          </div>
        </div>
        <div className="leave-page-body">
          <p>De no guardarlos, se perderán los cambios que hayas realizado.</p>
          <div className="leave-page-buttons">
            {loading ? (
              <button disabled className={styles.loadingButton}>
                <CircularProgress size={26} />
              </button>
            ) : (
              <button disabled={loading} onClick={handleOK}>
                {okText}
              </button>
            )}
            <button disabled={loading} onClick={handleCancel}>
              {cancelText}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default RouterPrompt
