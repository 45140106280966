import {
  AddUpdateSalesDocumentsService,
  getReasonCancellationService,
  updateQuantityOrdered,
} from 'redux/services/cotizacionServices'
import { validateAmountOnCommercialCreditServices } from 'redux/services/mdl14Services'

export const generateSellOrderAction = payload => {
  return async (dispatch, getState) => {
    dispatch({ type: 'SELL_ORDER_POST_LOADING', payload: true })

    try {
      const response = await AddUpdateSalesDocumentsService(payload)
      const { data, status } = response
      if (status === 200 && data?.Data) {
        if (data.Data !== null) {
          dispatch({ type: 'SELL_ORDER_POST_RESULT', payload: data })
        }
        dispatch({ type: 'SELL_ORDER_POST_LOADING', payload: false })
        return data
      } else {
        dispatch({
          type: 'SELL_ORDER_POST_RESULT',
          payload: { message: 'ERROR' },
        })
        dispatch({ type: 'SELL_ORDER_POST_LOADING', payload: false })
        return { message: 'ERROR' }
      }
    } catch (err) {
      console.error('Error getChannelsAction', err)
      dispatch({
        type: 'SELL_ORDER_POST_RESULT',
        payload: { message: 'ERROR' },
      })
      dispatch({ type: 'SELL_ORDER_POST_LOADING', payload: false })
      return { message: 'ERROR' }
    }
  }
}

export const generateDispatchOrderAction = payload => {
  return async (dispatch, getState) => {
    dispatch({ type: 'DISPATCH_ORDER_POST_LOADING', payload: true })

    try {
      const response = await AddUpdateSalesDocumentsService(payload)
      const { data, status } = response
      if (status === 200 && data) {
        dispatch({ type: 'DISPATCH_ORDER_POST_RESULT', payload: data })
        dispatch({ type: 'DISPATCH_ORDER_POST_LOADING', payload: false })
        return data
      } else {
        dispatch({
          type: 'DISPATCH_ORDER_POST_RESULT',
          payload: { message: 'ERROR' },
        })
        dispatch({ type: 'DISPATCH_ORDER_POST_LOADING', payload: false })
        return { message: 'ERROR' }
      }
    } catch (err) {
      console.error('Error getChannelsAction', err)
      dispatch({
        type: 'DISPATCH_ORDER_POST_RESULT',
        payload: { message: 'ERROR' },
      })
      dispatch({ type: 'DISPATCH_ORDER_POST_LOADING', payload: false })
      return { message: 'ERROR' }
    }
  }
}
export const updateQuantityOrderedODV = payload => {
  return async (dispatch, getState) => {
    dispatch({ type: 'LOADING_UPDATE_QUANTITY_ORDERED', payload: true })

    try {
      const response = await updateQuantityOrdered(payload)
      const { data, status } = response
      if (status === 200 && data) {
        dispatch({ type: 'UPDATE_QUANTITY_ORDERED_RESULT', payload: data })
        dispatch({ type: 'LOADING_UPDATE_QUANTITY_ORDERED', payload: false })
        return data
      } else {
        dispatch({
          type: 'UPDATE_QUANTITY_ORDERED_RESULT',
          payload: { message: 'ERROR' },
        })
        dispatch({ type: 'LOADING_UPDATE_QUANTITY_ORDERED', payload: false })
        return { message: 'ERROR' }
      }
    } catch (err) {
      console.error('Error getChannelsAction', err)
      dispatch({
        type: 'UPDATE_QUANTITY_ORDERED_RESULT',
        payload: { message: 'ERROR' },
      })
      dispatch({ type: 'LOADING_UPDATE_QUANTITY_ORDERED', payload: false })
      return { message: 'ERROR' }
    }
  }
}

export const validateAmountOnCommercialCredit = payload => async () => {
  // eslint-disable-next-line no-useless-catch
  try {
    const res = await validateAmountOnCommercialCreditServices(payload)
    const { data } = res
    if (data) {
      if (data.Data.cuentas || data.Result.toUpperCase() === 'OK') {
        if (data.Data.cuentas[0].bloqueado === '') {
          return data.Data.cuentas[0]
        } else {
          // The account is bloqued
          return { message: 'ERROR' + res.toString() }
        }
      } else {
        return { message: 'ERROR' + res.toString() }
      }
    } else {
      return { message: 'ERROR' + res.toString() }
    }
  } catch (err) {
    throw err
  }
}

export const validateAmountOnCommercialCreditAction = payload => async () => {
  try {
    const res = await validateAmountOnCommercialCreditServices(payload)
    const { data } = res
    if (data.Status?.Code === 200 || data.Result === 'Ok') {
      return data
    } else {
      return { message: 'ERROR' + res.toString() }
    }

    // return aludraApi
    //   .post(urlValidateCommercialCredit, payload)
    //   .then(res => {
    //     const { data } = res;

    //     if (data) {
    //       if (data.Status?.Code === 200 || data.Result === "Ok") {
    //         return data;
    //       } else {
    //         return { message: "ERROR" };
    //       }
    //     } else {
    //       return { message: "ERROR" };
    //     }
    //   })
    //   .catch(err => {
    //     return { message: "ERROR" };
    //   });
  } catch (err) {
    return { message: 'ERROR,' + err.message }
    // throw err;
  }
}

export const getReasonCancelDispatch = () => async () => {
  try {
    const response = await getReasonCancellationService()
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    }
  } catch (err) {}
}
