import axios from 'axios'
import store from 'redux/store'
import { algoliaHeaders, headers } from 'Utils/StageHandler'

export const aludraApi = axios.create({
  baseURL: process.env.REACT_APP_ALUDRA_API_BASE_URL,
})

export const algoliaApi = axios.create({
  baseURL: 'https://ZCZRBTYD8I-dsn.algolia.net/1/indexes/',
})

aludraApi.interceptors.request.use(config => {
  const token = store.getState().login.user?.AuthenticationInfo?.Token
  const apimKey = store.getState().login?.checkCompany[0]?.APIMSubscriptionKey

  const subscriptionKey = process.env.REACT_APP_SUBSCRIPTION_KEY || apimKey
  const apiKey = process.env.REACT_APP_API_KEY

  if (token && apimKey) {
    config.headers = {
      ...config.headers,
      ...headers,
      'Api-Key': apiKey,
      'Ocp-Apim-Subscription-Key': subscriptionKey,
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    }
  }
  return config
})

algoliaApi.interceptors.request.use(config => {
  config.headers = {
    ...algoliaHeaders,
  }
  return config
})
