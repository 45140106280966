import React, { useState, useMemo } from 'react'
import { useTable, useGlobalFilter } from 'react-table'
import './table.css'
import {
  ArrowDownUp,
  Check,
  CurrencyDollar,
  ExclamationTriangleFill,
  X,
} from 'react-bootstrap-icons'
import { TextField, Box, Modal } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSpinner,
  faSquarePen,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons'
import { FormatMoney } from 'Utils/functions'
import { useDispatch, useSelector } from 'react-redux'
import ApprovalMagin from './ApprovalMagin'
import { t } from 'i18next'
import No from '../../Assets/Images/Login/no.svg'
import { VAPermissions } from 'Utils/constants'
import { updateQuantityOrderedODV } from 'redux/actions/sellOrderActions'
import check from '../../Assets/Images/Login/check.png'
import useValidatePermission from 'Hooks/useValidatePermission'

function TableOrdenDeVenta({
  data,
  columns,
  isProductPage,
  hideFooter,
  isInventory,
  hiddenColumns,
  changeSelectedStatus,
  selectAllProducts,
  setChangeQty,
  onDeleteProductCallback,
  isApproved,
  setIsApproved,
}) {
  const isCustomer = useSelector(state => state.login.isCustomer)

  const [modalApprovalVisible, setModalApprovalVisible] = useState(false)
  const [editPending, setEditPending] = useState(false)
  const [newValuePending, setNewValuePending] = useState(null)
  const [messageNewValuePending, setMessageNewValuePending] = useState(null)
  const [errorNewValuePending, setErrorMessageNewValuePending] = useState(false)
  const [messageChangeQuantity, setMessageChangeQuantity] = useState({
    message: '',
    status: '',
  })
  const [productSelectededPending, setProductSelectededPending] =
    useState(false)

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns,
      },
    },
    useGlobalFilter
  )

  const dispatch = useDispatch()

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = tableInstance

  const validateAllChecked = () => {
    let isAllChecked = true

    data.forEach((item, i) => {
      if (!item.isSelected) {
        isAllChecked = false
      }
    })

    return isAllChecked
  }

  const handleQtyChange = (value, index, product) => {
    let newValue = value.target.value
    const cantidad =
      data[index].cantidad - data[index].qtyCanceled - data[index].qtyDelivered

    let newPendiente = cantidad - newValue

    if (value.target.value === '') {
      const updatedData = {
        ...data[index],
        solicitud_despacho: newValue,
        pendiente: FormatMoney(newPendiente, false, 4).replace(/,/g, ''),
      }
      setChangeQty([
        ...data.slice(0, index),
        updatedData,
        ...data.slice(index + 1),
      ])
      return
    }

    const newProduct = useSelectorQuoteObject?.QuoteHeader?.Lines.find(
      p => p.ProductId === product.productId
    )

    if (!newProduct) return

    // Verifica si el producto permite cantidades fraccionarias
    const allowFractionalQty = newProduct.AllowFractionalQty

    // Actualiza la expresión regular para validar el valor
    const regex = allowFractionalQty ? /^\d*\.?\d{0,4}$/ : /^\d{0,6}$/

    if (!regex.test(newValue)) return

    if (!isNaN(newValue) || newValue === '') {
      if (newValue >= cantidad) {
        newPendiente = 0
        newValue = cantidad
      }
      const updatedData = {
        ...data[index],
        solicitud_despacho: newValue,
        pendiente: FormatMoney(newPendiente, false, 4).replace(/,/g, ''),
      }
      setChangeQty([
        ...data.slice(0, index),
        updatedData,
        ...data.slice(index + 1),
      ])
    }
  }

  const useSelectorQuoteObject = useSelector(state => state.cotizacion)
  const {
    loadingUpdateQuantityOrdered,
    sellOrderPostData,
    updateQuantityOrderedData,
  } = useSelector(state => state.sellOrder)
  const canReturnsProducts = useValidatePermission(
    VAPermissions.returnsPermission
  )

  const allProductsWithNegativeMargin = useMemo(
    () =>
      useSelectorQuoteObject?.QuoteHeader?.Lines?.filter(
        line =>
          line?.Price <
            (line?.FinalPrices?.[1]?.FinalPrice || line?.FinalPrice) &&
          line?.NegotiatedReasonId !== null
      ),
    [useSelectorQuoteObject?.QuoteHeader?.Lines]
  )
  const productsWithNegativeMargin = useMemo(
    () =>
      allProductsWithNegativeMargin?.filter(line => {
        const product = data?.find(
          product => product?.productId === line?.ProductId
        )
        if (
          product?.cantidad - product?.qtyDelivered - product?.qtyCanceled >
          0
        ) {
          return true
        }
        return false
      }),
    [allProductsWithNegativeMargin]
  )
  const aproveDiscount = useValidatePermission(VAPermissions.aproveDiscount)

  const handleValidateNewPending = async (value, productId, pending, index) => {
    const newValue = Number(value)
    const newPending = Number(pending)

    const searchProduct =
      sellOrderPostData.Data.SalesDocumentInfo?.Lines.filter(
        line => line.ProductId === productId
      )

    const docLineId = searchProduct[0].DocLineId
    const quantity = searchProduct[0].Quantity

    if (!isNaN(newValue)) {
      if (newValue > quantity || newValue < newPending) {
        setErrorMessageNewValuePending(true)

        if (newValue > quantity) {
          setMessageNewValuePending(
            'SELL_ORDER_T.ALERT_ERROR_NEW_PENDING_BIGGER'
          )
          return
        }
        if (newValue < newPending) {
          setMessageNewValuePending('SELL_ORDER_T.ALERT_ERROR_NEW_PENDING_LESS')
        }
      } else {
        const qtyOrdered = quantity - newValue
        const payload = {
          QtyOrdered: Number.isInteger(qtyOrdered)
            ? qtyOrdered
            : Number(FormatMoney(qtyOrdered, false, 4).replace(/,/g, '')),
          DocLineId: docLineId,
        }
        await dispatch(updateQuantityOrderedODV(payload))

        if (updateQuantityOrderedData?.Data.length > 0) {
          setMessageChangeQuantity({
            message: 'SELL_ORDER_T.UPDATE_QUANTITY_PENDING_SUCCESS',
            status: 'success',
          })
          setEditPending(false)

          const newValue = Number(value)

          const updatedData = {
            ...data[index],
            pendiente: FormatMoney(newValue, false, 4).replace(/,/g, ''),
            qtyDelivered: parseFloat(quantity - newValue),
            solicitud_despacho: '',
          }
          setChangeQty([
            ...data.slice(0, index),
            updatedData,
            ...data.slice(index + 1),
          ])
        } else {
          setMessageChangeQuantity({
            message: 'SELL_ORDER_T.UPDATE_QUANTITY_PENDING_ERROR',
            status: 'error',
          })
        }
      }
    } else {
      setErrorMessageNewValuePending(true)
      setMessageNewValuePending('SELL_ORDER_T.ALERT_ERROR_REQUIRED')
    }
  }

  const handleProdQuantity = (e, product) => {
    let value = e.target.value

    const newProduct = useSelectorQuoteObject?.QuoteHeader?.Lines.find(
      p => p.ProductId === product.productId
    )
    const allowFractionalQty = newProduct[0].AllowFractionalQty

    // Actualiza la expresión regular para validar el valor
    const regex = allowFractionalQty ? /^\d*\.?\d{0,4}$/ : /^\d{0,6}$/

    // Si el producto no permite cantidades fraccionarias y se ingresa un punto, lo eliminamos
    if (!allowFractionalQty && value.includes('.')) {
      value = value.replace('.', '')
    }

    // Si el producto no permite cantidades fraccionarias, limitamos la cantidad de dígitos a 6
    if (!allowFractionalQty) {
      value = value.slice(0, 6)
    }

    if (!regex.test(value)) return

    setNewValuePending(value)
  }

  return (
    <>
      <Modal open={modalApprovalVisible}>
        <Box className="modal-box-message">
          <ApprovalMagin
            modalApprovalVisible={modalApprovalVisible}
            setModalApprovalVisible={setModalApprovalVisible}
            setIsApproved={setIsApproved}
          />
        </Box>
      </Modal>
      <Modal open={errorNewValuePending || messageChangeQuantity.status}>
        <Box className="modal-box-message">
          <div className="generic-closing-button modal-pending">
            <img
              src={No}
              onClick={() => {
                setErrorMessageNewValuePending(false)
                setMessageChangeQuantity({
                  message: '',
                  status: '',
                })
              }}
              alt="close"
            />
          </div>
          <div className="modal-content-penging">
            {messageChangeQuantity.status === 'success' ? (
              <>
                <img src={check} alt="check" />

                <p className="title-center">
                  {t(messageChangeQuantity.message)}
                </p>
              </>
            ) : messageChangeQuantity.status === 'error' ? (
              <>
                <ExclamationTriangleFill className="check-costs-page-warning-icon" />

                <p className="title-center">
                  {t(messageChangeQuantity.message)}
                </p>
              </>
            ) : (
              <>
                <ExclamationTriangleFill className="check-costs-page-warning-icon" />

                <p className="title-center">{t(messageNewValuePending)}</p>
              </>
            )}
          </div>
        </Box>
      </Modal>
      <div className="table-sell-order-topbar">
        <div className="table-sell-order-filter-options"></div>
        <div className="top-action-table-buttons">
          {aproveDiscount &&
            !isApproved &&
            useSelectorQuoteObject?.QuoteHeader.DocHeaderStatusDescription !==
              'cerrada' &&
            productsWithNegativeMargin.length > 0 && (
              <button
                style={{
                  backgroundColor: '#FF2A40',
                  color: '#FFFFFF',
                }}
                disabled={false}
                onClick={() => {
                  setModalApprovalVisible(true)
                }}>
                <CurrencyDollar />
              </button>
            )}
        </div>
      </div>
      <table className="table-sell-order-table-style" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th
                  key={i}
                  {...column.getHeaderProps({
                    style: {
                      width: column.width,
                    },
                  })}>
                  {column.Header !== 'check' ? (
                    <div className="order-header-table-container">
                      <div className="order-header-text">
                        {column.render('Header')}
                      </div>
                      {column.arrowOrderEnabled ? (
                        <ArrowDownUp className="arrow-order-style" />
                      ) : null}
                    </div>
                  ) : (
                    <div className="table-order-check-style">
                      <input
                        type="checkbox"
                        onClick={() => {
                          selectAllProducts(validateAllChecked())
                        }}
                        readOnly={true}
                        checked={validateAllChecked()}
                        disabled={!isApproved || isCustomer}
                      />
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row)
            return (
              <tr
                key={index}
                {...row.getRowProps({
                  style:
                    rows.length <= index + 1
                      ? { borderBottom: '1px solid #E1E1E1' }
                      : { borderBottom: 'none' },
                })}>
                {row.cells.map((cell, i) => (
                  <td
                    key={i}
                    {...cell.getCellProps({
                      style: {
                        width: cell.column.width,
                        padding: !editPending ? '21px 23px' : '21px 2px',
                      },
                    })}>
                    {editPending &&
                    cell.column.Header === 'Pendiente' &&
                    cell.row.original.productId === productSelectededPending ? (
                      <span className="edit-pending-span">
                        <input
                          className="edit-pending"
                          type="number"
                          disabled={loadingUpdateQuantityOrdered}
                          value={newValuePending}
                          name="cliente-contado"
                          onChange={e =>
                            handleProdQuantity(e, cell.row.original)
                          }
                        />
                        <div className="edit-pending-buttons">
                          {loadingUpdateQuantityOrdered ? (
                            <FontAwesomeIcon
                              icon={faSpinner}
                              className="spinner"
                              style={{ fontSize: 20 }}
                            />
                          ) : (
                            <>
                              <Check
                                onClick={() =>
                                  handleValidateNewPending(
                                    newValuePending,
                                    cell.row.original.productId,
                                    cell.value,
                                    index
                                  )
                                }
                              />
                              <X
                                onClick={() => {
                                  setNewValuePending(null)
                                  setEditPending(false)
                                }}
                              />
                            </>
                          )}
                        </div>
                      </span>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                        {cell.column.Header === 'check' ? (
                          <div className="table-order-check-style">
                            <input
                              type="checkbox"
                              onClick={() => {
                                changeSelectedStatus(index)
                              }}
                              readOnly={true}
                              checked={cell.row.values.isSelected}
                              value={cell.row.values.isSelected}
                              disabled={
                                (cell.row.original?.negativeMargen &&
                                  !isApproved) ||
                                isCustomer
                              }
                            />
                          </div>
                        ) : cell.column.Header === 'Solicitud despacho' &&
                          data.length > 0 &&
                          cell.row.original?.qtyDelivered +
                            cell.row.original?.qtyCanceled <
                            cell.row.original?.cantidad ? (
                          <TextField
                            value={data[index]?.solicitud_despacho || ''}
                            inputProps={{ style: { fontSize: 14 } }} // font size of input text
                            InputLabelProps={{ style: { fontSize: 14 } }} // font size of input label
                            disabled={
                              cell.row.original?.negativeMargen && !isApproved
                            }
                            onChange={value => {
                              handleQtyChange(value, index, cell.row.original)
                            }}
                          />
                        ) : cell.column.Header === 'Costo' &&
                          data.length > 0 ? (
                          data[index].costModifier ? (
                            <TextField
                              value={data[index].costo}
                              inputProps={{ style: { fontSize: 14 } }} // font size of input text
                              InputLabelProps={{ style: { fontSize: 14 } }} // font size of input label
                              onChange={value => {
                                setChangeQty([
                                  ...data.slice(0, index),
                                  {
                                    ...data[index],
                                    costo: value.target.value,
                                  },
                                  ...data.slice(index + 1),
                                ])
                              }}
                            />
                          ) : (
                            <div className="order-table-text">
                              {'$'}
                              {cell.render('Cell')}
                            </div>
                          )
                        ) : cell.column.Header === 'Acciones' ? (
                          <div className="table-sell-order-inventory-actions">
                            {cell.row.original?.qtyDelivered +
                              cell.row.original?.qtyCanceled <
                            cell.row.original?.cantidad ? (
                              <FontAwesomeIcon
                                className="table-sell-order-trash-icon-size"
                                icon={faTrashCan}
                                style={{
                                  display:
                                    cell.row.original?.qtyDelivered +
                                      cell.row.original?.qtyCanceled <
                                    cell.row.original?.cantidad
                                      ? 'block'
                                      : 'none',
                                }}
                                onClick={() => {
                                  onDeleteProductCallback(cell.row.original)
                                }}
                              />
                            ) : null}
                          </div>
                        ) : cell.column.Header === 'Precio total' ||
                          cell.column.Header === 'Precio unitario' ? (
                          <div className="order-table-text">
                            {'$ ' +
                              (cell.value
                                ? FormatMoney(cell.value, true, 2)
                                : '0.00')}
                          </div>
                        ) : (
                          <div className="order-table-text">
                            {cell.render('Cell')}
                          </div>
                        )}
                        {cell.column.Header === 'Pendiente' &&
                          canReturnsProducts &&
                          cell.row.original?.qtyDelivered > 0 && (
                            <FontAwesomeIcon
                              style={{ fontSize: '18px', marginLeft: 5 }}
                              onClick={() => {
                                setEditPending(true)
                                setNewValuePending(null)
                                setProductSelectededPending(
                                  cell.row.original.productId
                                )
                              }}
                              icon={faSquarePen}
                              title={t('Edit')}
                              className="square-pen-edit"
                            />
                          )}
                      </div>
                    )}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
        {hideFooter ? null : (
          <tfoot
            className={isInventory ? 'stickyFooter' : null}
            style={{
              borderBottom: isProductPage && 'none',
              borderLeft: isProductPage && 'none',
              borderRight: isProductPage && 'none',
            }}>
            {footerGroups.map((group, i) => (
              <tr key={i} {...group.getFooterGroupProps()}>
                {group.headers.map((column, i) => (
                  <td
                    key={i}
                    style={{ border: isProductPage && 'none' }}
                    {...column.getFooterProps()}>
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
    </>
  )
}

export default TableOrdenDeVenta
