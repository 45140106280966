import React from 'react'
import { images } from 'Assets/Images'
import {
  CheckCircleFill,
  PencilSquare,
  XCircleFill,
} from 'react-bootstrap-icons'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)
const currentMonth = new Date().toLocaleString('es-ES', { month: 'long' })
const DEFAULT_VALUE = `Promoción válida del 1 al 30 de ${capitalize(
  currentMonth
)} 2024. Precio no incluye ITBMS`

export const Square3COffer = ({ products }) => {
  const { currentPage, allTemplatesContent, setAllTemplatesContent } =
    usePrinterContext()

  const currentContent = allTemplatesContent.find(
    content => content.page === currentPage
  )
  const currentTerms = currentContent?.content?.terms || DEFAULT_VALUE

  const [isEditing, setIsEditing] = React.useState(false)
  const [terms, setTerms] = React.useState(currentTerms)

  const prod1 = products?.content?.products?.[0]
  const prod2 = products?.content?.products?.[1] || {}
  const prod3 = products?.content?.products?.[2] || {}
  const prod4 = products?.content?.products?.[3] || {}

  const handleEditTerms = () => {
    const updatedContent = allTemplatesContent.map(content => {
      if (content.page === currentPage) {
        return {
          ...content,
          content: {
            ...content.content,
            terms,
          },
        }
      }
      return content
    })
    setAllTemplatesContent(updatedContent)
    setIsEditing(false)
  }

  return (
    <div
      id="printer-preview-left-canvas-square-3"
      className="printer-preview-left-canvas-square-3">
      <div
        className="printer-preview-left-canvas-square-3-box"
        style={{
          height: '100%',
          top: '0',
        }}>
        <div className="printer-preview-left-content-body-square-3">
          <p className="printer-preview-left-content-body-square-3-title">
            {prod1?.ProductName || ''}
          </p>
          <p className="printer-preview-left-content-body-square-3-sku">
            {prod1?.Sku || ''}
          </p>
          {prod1?.BasePrice && (
            <div
              className="printer-preview-left-content-body-square-3-price-wrapper"
              style={{ marginTop: '20px' }}>
              <p
                className="printer-preview-mini-text-square-3"
                style={{ color: '#000' }}>
                $
              </p>
              <p
                className="printer-preview-left-content-body-square-3-price"
                style={{ color: '#000', fontSize: '82px', marginTop: '40px' }}>
                {prod1?.BasePrice?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p
                className="printer-preview-mini-text-square-3"
                style={{ color: '#000' }}>
                {prod1?.BasePrice?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
        </div>
        <div
          className="printer-preview-left-content-footer-square-3"
          style={{
            marginTop: '60px',
          }}>
          <div className="printer-preview-content-footer-input-wrapper">
            <textarea
              className="printer-preview-left-content-footer-square-2-legal"
              type="text"
              rows={5}
              value={terms}
              disabled={!isEditing}
              onChange={e => setTerms(e.target.value)}
              style={{
                border: isEditing ? '1px solid #000' : 'none',
                background: 'transparent',
                textAlign: 'end',
              }}
            />
            <div className="printer-terms-edit-wrapper-1">
              {isEditing && (
                <CheckCircleFill
                  onClick={handleEditTerms}
                  size={12}
                  className="printer-preview-content-check"
                />
              )}
              {isEditing && (
                <XCircleFill
                  size={12}
                  onClick={() => {
                    setIsEditing(false)
                    setTerms(DEFAULT_VALUE)
                  }}
                  className="printer-preview-content-circle"
                />
              )}
              {!isEditing && (
                <PencilSquare
                  size={12}
                  onClick={() => setIsEditing(true)}
                  className="printer-preview-content-pencil"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="printer-preview-left-canvas-square-3-box"
        style={{
          height: '100%',
          top: '0',
          left: '50%',
        }}>
        <div className="printer-preview-left-content-body-square-3">
          <p className="printer-preview-left-content-body-square-3-title">
            {prod2?.ProductName || ''}
          </p>
          <p className="printer-preview-left-content-body-square-3-sku">
            {prod2?.Sku || ''}
          </p>
          {prod2?.BasePrice && (
            <div
              className="printer-preview-left-content-body-square-3-price-wrapper"
              style={{ marginTop: '20px' }}>
              <p
                className="printer-preview-mini-text-square-3"
                style={{ color: '#000' }}>
                $
              </p>
              <p
                className="printer-preview-left-content-body-square-3-price"
                style={{ color: '#000', fontSize: '82px', marginTop: '40px' }}>
                {prod2?.BasePrice?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p
                className="printer-preview-mini-text-square-3"
                style={{ color: '#000' }}>
                {prod2?.BasePrice?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
        </div>
        <div
          className="printer-preview-left-content-footer-square-3"
          style={{
            marginTop: '60px',
          }}>
          <div className="printer-preview-content-footer-input-wrapper">
            <textarea
              className="printer-preview-left-content-footer-square-2-legal"
              type="text"
              rows={5}
              value={terms}
              disabled={!isEditing}
              onChange={e => setTerms(e.target.value)}
              style={{
                border: isEditing ? '1px solid #000' : 'none',
                background: 'transparent',
                textAlign: 'end',
              }}
            />
            <div className="printer-terms-edit-wrapper-1">
              {isEditing && (
                <CheckCircleFill
                  onClick={handleEditTerms}
                  size={12}
                  className="printer-preview-content-check"
                />
              )}
              {isEditing && (
                <XCircleFill
                  size={12}
                  onClick={() => {
                    setIsEditing(false)
                    setTerms(DEFAULT_VALUE)
                  }}
                  className="printer-preview-content-circle"
                />
              )}
              {!isEditing && (
                <PencilSquare
                  size={12}
                  onClick={() => setIsEditing(true)}
                  className="printer-preview-content-pencil"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="printer-preview-left-canvas-square-3-box"
        style={{
          height: '50%',
          top: '50%',
        }}>
        <div className="printer-preview-left-content-body-square-3-2">
          <p className="printer-preview-left-content-body-square-3-title-2">
            {prod3?.ProductName || ''}
          </p>
          <p className="printer-preview-left-content-body-square-3-sku-2">
            {prod3?.Sku || ''}
          </p>
          {prod3?.BasePrice && (
            <div
              className="printer-preview-left-content-body-square-3-price-wrapper"
              style={{ marginTop: '20px' }}>
              <p
                className="printer-preview-mini-text-square-3"
                style={{ color: '#000' }}>
                $
              </p>
              <p
                className="printer-preview-left-content-body-square-3-price-2"
                style={{ color: '#000', fontSize: '82px', marginTop: '40px' }}>
                {prod3?.BasePrice?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p
                className="printer-preview-mini-text-square-3"
                style={{ color: '#000' }}>
                {prod3?.BasePrice?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
        </div>
        <div
          className="printer-preview-left-content-footer-square-3-2"
          style={{
            marginTop: '55px',
          }}>
          <div className="printer-preview-content-footer-input-wrapper">
            <textarea
              className="printer-preview-left-content-footer-square-2-legal"
              type="text"
              rows={5}
              value={terms}
              disabled={!isEditing}
              onChange={e => setTerms(e.target.value)}
              style={{
                border: isEditing ? '1px solid #000' : 'none',
                background: 'transparent',
                textAlign: 'end',
              }}
            />
            <div className="printer-terms-edit-wrapper-1">
              {isEditing && (
                <CheckCircleFill
                  onClick={handleEditTerms}
                  size={12}
                  className="printer-preview-content-check"
                />
              )}
              {isEditing && (
                <XCircleFill
                  size={12}
                  onClick={() => {
                    setIsEditing(false)
                    setTerms(DEFAULT_VALUE)
                  }}
                  className="printer-preview-content-circle"
                />
              )}
              {!isEditing && (
                <PencilSquare
                  size={12}
                  onClick={() => setIsEditing(true)}
                  className="printer-preview-content-pencil"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="printer-preview-left-canvas-square-3-box"
        style={{
          height: '50%',
          top: '50%',
          left: '50%',
        }}>
        <div className="printer-preview-left-content-body-square-3-2">
          <p className="printer-preview-left-content-body-square-3-title-2">
            {prod4?.ProductName || ''}
          </p>
          <p className="printer-preview-left-content-body-square-3-sku-2">
            {prod4?.Sku || ''}
          </p>
          {prod4?.BasePrice && (
            <div
              className="printer-preview-left-content-body-square-3-price-wrapper"
              style={{ marginTop: '20px' }}>
              <p
                className="printer-preview-mini-text-square-3"
                style={{ color: '#000' }}>
                $
              </p>
              <p
                className="printer-preview-left-content-body-square-3-price-2"
                style={{ color: '#000', fontSize: '82px', marginTop: '40px' }}>
                {prod4?.BasePrice?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p
                className="printer-preview-mini-text-square-3"
                style={{ color: '#000' }}>
                {prod4?.BasePrice?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
        </div>
        <div
          className="printer-preview-left-content-footer-square-3-2"
          style={{
            marginTop: '55px',
          }}>
          <div className="printer-preview-content-footer-input-wrapper">
            <textarea
              className="printer-preview-left-content-footer-square-2-legal"
              type="text"
              rows={5}
              value={terms}
              disabled={!isEditing}
              onChange={e => setTerms(e.target.value)}
              style={{
                border: isEditing ? '1px solid #000' : 'none',
                background: 'transparent',
                textAlign: 'end',
              }}
            />
            <div className="printer-terms-edit-wrapper-1">
              {isEditing && (
                <CheckCircleFill
                  onClick={handleEditTerms}
                  size={12}
                  className="printer-preview-content-check"
                />
              )}
              {isEditing && (
                <XCircleFill
                  size={12}
                  onClick={() => {
                    setIsEditing(false)
                    setTerms(DEFAULT_VALUE)
                  }}
                  className="printer-preview-content-circle"
                />
              )}
              {!isEditing && (
                <PencilSquare
                  size={12}
                  onClick={() => setIsEditing(true)}
                  className="printer-preview-content-pencil"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <img
        className="printer-preview-left-image"
        src={images.printer.templates.cochez.offer3}
        alt="template-preview"
      />
    </div>
  )
}
