import React, { useState, useEffect, useRef } from 'react'
import Navigation from '../../Components/Navigation'
import ShoppingCart from '../../Components/ShoppingCart'
import { useHistory } from 'react-router-dom'
import './crearCotizacion.css'
import { Row, Col } from 'react-bootstrap'
import MetodoEntrega from '../../Components/MetodoEntrega'
import { useSelector, useDispatch } from 'react-redux'
import Calculator from '../../Components/Calculator'
import {
  updateCotizacionCompany,
  addSalesAgent,
  addPriceList,
} from '../../redux/actions/cotizacionActions'
import { emptyCart } from '../../redux/actions/cartActions'
import ProductSearch from '../../Components/ProductSearch'
import MaterialsCalculator from 'Components/MaterialsCalculator/MaterialCalculator'

function CrearCotizacion(props) {
  const productsRef = useRef(null)
  const dispatch = useDispatch()
  const history = useHistory()
  // ALGOLIA FILTERING VARIABLES
  // const [page, setPage] = useState(0)
  // VARIABLE PARA MOSTRAR METODO DE ENTREGA
  const [mostrarMetodoEntrega, setMostrarMetodoEntrega] = useState(false)
  const [showMaterialsCalculatorPDP, setShowMaterialsCalculatorPDP] =
    useState(false)
  const [productDetail, setProductDetail] = useState(null)
  const [direccion, setDireccion] = useState(null)
  const [productCart] = useState([])
  // CLIENT TO COTIZACION
  const [clienteMetodoEntrega, setClienteMetodoEntrega] = useState(null)
  const salesAgent = useSelector(
    state => state.login.user.CustomerInfo.CustomerId
  )

  const company = useSelector(state => state.company)
  const client = useSelector(
    state => state.cotizacion?.QuoteHeader?.CustomerNameDocument
  )
  const selectedWarehouse = useSelector(state => state?.company)
  // CHOOSEN ADDRESS
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [isList] = useState(history?.location?.state?.from === 'Mis Listas')

  // HANDLE PARA CAMBIAR MOSTRAR METODO ENTREGA
  const handleMetodoEntrega = () => {
    setMostrarMetodoEntrega(true)
  }
  const handleMetodoEntregaTwo = () => {
    setMostrarMetodoEntrega(false)
  }
  const quoteHeader = useSelector(state => state?.cotizacion?.QuoteHeader)

  useEffect(async () => {
    if (!props.edit && !isList) {
      dispatch(emptyCart())
    }

    if (props.edit) {
      // set warehouseid form quotation if want to put the lines put Lines[0]?.WarehouseId
      const warehouse = {
        WarehouseId: quoteHeader?.WarehouseId,
        WarehouseDescription: quoteHeader?.WarehouseDescription,
        WarehouseAddress: quoteHeader?.WarehouseAddress,
        WarehouseCode: quoteHeader?.WarehouseCode,
        WarehouseName: quoteHeader?.WarehouseName,
        Description: quoteHeader?.WarehouseDescription,
      }
      setSelectedAddress(warehouse)
    }
  }, [])

  useEffect(() => {
    const isCreation = window.location.pathname.includes('crear_cotizacion')
    if (client && isCreation) {
      const warehouse = {
        WarehouseId: company?.WarehouseId,
        WarehouseDescription: company?.WarehouseDescription,
        WarehouseAddress: company?.WarehouseAddress,
        WarehouseCode: company?.WarehouseCode,
        WarehouseName: company?.WarehouseName,
        Description: company?.WarehouseDescription,
      }
      setSelectedAddress(warehouse)
      setDireccion(false)
    }
  }, [client, company])

  useEffect(async () => {
    if (!props.edit) {
      dispatch(addPriceList(undefined))
      dispatch(addSalesAgent(salesAgent))
    }
  }, [])

  useEffect(() => {
    if (!props.edit) {
      dispatch(
        updateCotizacionCompany({
          CompanyCode: selectedWarehouse.CompanyCode,
          CompanyName: selectedWarehouse.CompanyName,
          CompanyId: selectedWarehouse.CompanyId,
          WarehouseCode: selectedWarehouse.WarehouseCode,
          WarehouseId: selectedWarehouse.WarehouseId,
          WarehouseDescription: selectedWarehouse.WarehouseDescription,
          WarehouseName: selectedWarehouse.WarehouseName,
          WarehouseAddress: selectedWarehouse.WarehouseAddress,
          DefaultWarehouse: selectedWarehouse.DefaultWarehouse,
        })
      )
    }
  }, [selectedWarehouse])

  return (
    <>
      <Navigation />

      <div className="crear-cotizacion-main">
        <Row>
          {mostrarMetodoEntrega && clienteMetodoEntrega ? (
            <Col xl={8} lg={8} md={8} sm={12} xs={12}>
              <MetodoEntrega
                clienteMetodoEntrega={clienteMetodoEntrega}
                setSelectedAddress={setSelectedAddress}
                selectedAddress={selectedAddress}
                productsSelected={productCart}
                handleMetodoEntrega={handleMetodoEntregaTwo}
                setDireccion={setDireccion}
              />
            </Col>
          ) : (
            <Col
              xl={8}
              lg={7}
              md={12}
              sm={12}
              className={
                showMaterialsCalculatorPDP
                  ? 'productos-cotizacion-left blur'
                  : 'productos-cotizacion-left'
              }
              style={{ position: 'relative' }}
              ref={productsRef}>
              <ProductSearch
                productsRef={productsRef}
                setShowMaterialsCalculatorPDP={setShowMaterialsCalculatorPDP}
                setProductDetail={setProductDetail}
              />
            </Col>
          )}
          <Col
            xl={4}
            lg={5}
            md={12}
            sm={12}
            className={
              showMaterialsCalculatorPDP ? 'scrollable-container' : ''
            }>
            {showMaterialsCalculatorPDP ? (
              <MaterialsCalculator
                showCalculator={showMaterialsCalculatorPDP}
                setShowCalculator={setShowMaterialsCalculatorPDP}
                productDetail={productDetail}
              />
            ) : (
              <ShoppingCart
                handleMetodoEntrega={handleMetodoEntrega}
                setClienteMetodoEntrega={setClienteMetodoEntrega}
                setSelectedAddress={setSelectedAddress}
                selectedAddress={selectedAddress}
                direccion={direccion}
                setDireccion={setDireccion}
                edit={props.edit}
                productsRef={productsRef}
                editarCotizacion={() => {
                  setMostrarMetodoEntrega(true)
                  setClienteMetodoEntrega(true)
                }}
                setShowMaterialsCalculatorPDP={setShowMaterialsCalculatorPDP}
              />
            )}
          </Col>
        </Row>

        <Calculator />
      </div>
    </>
  )
}

export default CrearCotizacion
