import React, { useState, useEffect } from 'react'
import { GoogleMap, Autocomplete, Marker } from '@react-google-maps/api'
import MiniLoading from '../MiniLoading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import '../Map/mapa.css'
import {
  fetchDistrictAction,
  fetchGeneralsAction,
  fetchProvincesByCountryAction,
  fetchTownshipAction,
} from 'redux/actions/clientsActions'
import { useDispatch } from 'react-redux'
import { defaultCountryId } from 'Utils/constants'
import styles from './styles.module.css'
import { CustomerContext } from 'state/context/CustomerFormContext'

function NewCustomerMap({
  setHasAddress,
  hasAddress,
  setCountryCode,
  setProvinceCode,
  setDistrictCode,
  setTownshipCode,
  street,
  setStreet,
  name,
  setName,
  mapKey,
  personType,
  formik,
}) {
  // MAP SIZE
  const mapContainerStyle = {
    height: '230px',
    width: '100%',
  }

  const dispatch = useDispatch()
  const { mandatoryAddress, setMandatoryAddress } = CustomerContext()
  const showAddress = personType !== 'NAT' ? true : mandatoryAddress

  // COUNTRY
  const [selectedCountry, setSelectedCountry] = useState('PÁIS')

  // PROVINCE
  const [provinces, setProvinces] = useState([])
  const [selectedProvince, setSelectedProvince] = useState('PROVINCIA')

  // DISTRICT
  const [districts, setDistricts] = useState([])
  const [selectedDistrict, setSelectedDistrict] = useState('DISTRITO')
  // TOWNSHIP
  const [townships, setTownships] = useState([])
  const [selectedTownship, setSelectedTownship] = useState('CORREGIMIENTO')
  // DROPDOWN LOGIC
  const [showCountries, setShowCountries] = useState(false)
  const [showProvinces, setShowProvinces] = useState(false)
  const [showDistricts, setShowDistricts] = useState(false)
  const [showTownships, setShowTownships] = useState(false)

  //  MAP CONFIG
  const [lat, setLat] = useState(
    parseFloat(window.localStorage.getItem('lat')) || 8.98
  )
  const [lng, setLng] = useState(
    parseFloat(window.localStorage.getItem('long')) || -79.51
  )
  const [autocomplete, setAutocomplete] = useState(null)
  const [formIsLoading, setFormIsLoading] = useState(false)

  const onLoad = autocom => {
    setAutocomplete(autocom)
  }

  const onPlaceChanged = () => {
    if (autocomplete !== null && autocomplete.getPlace().geometry) {
      setLat(autocomplete.getPlace().geometry.location.lat())
      setLng(autocomplete.getPlace().geometry.location.lng())
      fetchGenerals(
        autocomplete.getPlace().geometry.location.lat(),
        autocomplete.getPlace().geometry.location.lng()
      )
    } else {
      setSelectedCountry('')
      setCountryCode('')
      formik.setFieldValue('countryId', '')

      setSelectedProvince('')
      setProvinceCode('')
      formik.setFieldValue('provinceId', '')

      setSelectedDistrict('')
      setDistrictCode('')
      fetchDistrict('')
      formik.setFieldValue('districtId', '')

      setSelectedTownship('')
      setTownshipCode('')
      fetchTownship('')
      formik.setFieldValue('townShipId', '')
      setStreet('')
      formik.setFieldValue('addressStreet', '')
      setName('')
      formik.setFieldValue('addressDetail', '')

      setHasAddress({
        country: false,
        province: false,
        district: false,
        township: false,
        street: false,
        detail: true,
      })
    }
  }

  const handleMarker = e => {
    setLat(e.latLng.lat())
    setLng(e.latLng.lng())
    fetchGenerals(e.latLng.lat(), e.latLng.lng())
  }

  // COUNTRIES FUNCTIONS
  const handleCountries = () => {
    setShowCountries(!showCountries)
  }

  const handleCountry = e => {
    const trimId = e.target.id.slice(0, -3)
    setSelectedCountry(e.target.value)
    setCountryCode(trimId)
    setHasAddress({ ...hasAddress, country: true })
    setShowCountries(!showCountries)
    formik.setFieldValue('countryId', trimId)
  }

  // PROVINCE FUNCTIONS
  const handleProvinces = () => {
    setShowProvinces(!showProvinces)
  }

  const handleProvince = e => {
    const trimId = e.target.id.slice(0, -3)
    setSelectedProvince(e.target.value)
    setProvinceCode(trimId)
    setShowProvinces(!showProvinces)
    fetchDistrict(trimId)
    setHasAddress({ ...hasAddress, province: true })
    setSelectedDistrict('DISTRITO')
    setSelectedTownship('CORREGIMIENTO')
    formik.setFieldValue('districtId', null)
    formik.setFieldValue('townShipId', null)
    formik.setFieldValue('provinceId', trimId)
  }

  // DISTRICT FUNCTIONS
  const handleDistricts = () => {
    setShowDistricts(!showDistricts)
  }

  const handleDistrict = e => {
    const trimId = e.target.id.slice(0, -3)
    setSelectedDistrict(e.target.value)
    setDistrictCode(trimId)
    setShowDistricts(!showDistricts)
    fetchTownship(trimId)
    setHasAddress({ ...hasAddress, district: true })
    setSelectedTownship('CORREGIMIENTO')
    formik.setFieldValue('townShipId', null)
    formik.setFieldValue('districtId', trimId)
  }

  // TOWNSHIP FUNCTIONS
  const handleTownships = () => {
    setShowTownships(!showTownships)
  }

  const handleTownship = e => {
    const trimId = e.target.id.slice(0, -3)
    setSelectedTownship(e.target.value)
    setTownshipCode(trimId)
    setHasAddress({ ...hasAddress, township: true })
    setShowTownships(!showTownships)
    formik.setFieldValue('townShipId', trimId)
  }

  // LOCATION ENDPOINTS
  const fetchGenerals = async (latitud, longitude) => {
    setFormIsLoading(true)
    setHasAddress({
      ...hasAddress,
      country: false,
      province: false,
      district: false,
      township: false,
    })
    formik.setFieldValue('countryId', null)
    formik.setFieldValue('provinceId', null)
    formik.setFieldValue('districtId', null)
    formik.setFieldValue('townShipId', null)
    formik.setFieldValue('latitude', null)
    formik.setFieldValue('longitude', null)
    const payload = {
      Latitude: latitud,
      Longitude: longitude,
    }
    const Data = await dispatch(fetchGeneralsAction(payload))
    if (Data && Data?.length > 0) {
      const locationData = Data[0]
      formik.setFieldValue('latitude', latitud)
      formik.setFieldValue('longitude', longitude)
      // COUNTRY
      setSelectedCountry(locationData.Country)
      setCountryCode(locationData.CountryId)
      formik.setFieldValue('countryId', locationData.CountryId)

      // PROVINCE
      setSelectedProvince(locationData.Province)
      setProvinceCode(locationData.ProvinceId)
      formik.setFieldValue('provinceId', locationData.ProvinceId)

      // DISTRICT
      setSelectedDistrict(locationData.District)
      setDistrictCode(locationData.DistrictId)
      fetchDistrict(locationData.ProvinceId)
      formik.setFieldValue('districtId', locationData.DistrictId)

      // TOWNSHIP
      setSelectedTownship(locationData.Township)
      setTownshipCode(locationData.TownshipId)
      fetchTownship(locationData.DistrictId)
      formik.setFieldValue('townShipId', locationData.TownshipId)

      setHasAddress({
        ...hasAddress,
        country: true,
        province: true,
        district: true,
        township: true,
      })
    } else {
      console.error('Error getting Generals: ', Data)
    }
    setFormIsLoading(false)
  }

  const fetchProvinces = async () => {
    const Data = await dispatch(
      fetchProvincesByCountryAction(defaultCountryId.countryId)
    )
    if (Data && Data?.length > 0) {
      setProvinces(Data)
    } else {
      console.error('Error getting Provinces: ', Data)
    }
  }

  const fetchDistrict = async provinceCode => {
    const Data = await dispatch(fetchDistrictAction(provinceCode))
    if (Data && Data?.length > 0) {
      setDistricts(Data)
    } else {
      console.error('Error getting districts: ', Data)
    }
  }

  const fetchTownship = async districtCode => {
    const Data = await dispatch(fetchTownshipAction(districtCode))
    if (Data && Data?.length > 0) {
      setTownships(Data)
    } else {
      console.error('Error getting TownShips: ', Data)
    }
  }

  useEffect(() => {
    fetchProvinces()
  }, [mapKey])

  return (
    <div className={styles.formAddressContainer}>
      {personType === 'NAT' && (
        <div className="address-requiered-question-wrapper">
          <p>¿Desea agregar una dirección de envío?</p>
          <div className="address-checkbox-wrapper">
            <div className="custom-radio">
              <input
                type="radio"
                id="addressCheckboxYes"
                checked={mandatoryAddress}
                onChange={() => setMandatoryAddress(true)}
              />
              <label
                className="custom-control-label"
                htmlFor="addressCheckboxYes">
                Si
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="addressCheckboxNo"
                checked={!mandatoryAddress}
                onChange={() => setMandatoryAddress(false)}
              />
              <label
                className="custom-control-label"
                htmlFor="addressCheckboxNo">
                No
              </label>
            </div>
          </div>
        </div>
      )}
      <div className={showAddress ? '' : styles.hidden}>
        <div className={styles.inputContainer}>
          <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
            <input
              type="text"
              name="addressMap"
              placeholder="Ingresa la dirección de envío*"
              autoComplete="off"
              className={styles.inputMapAddress}
              onKeyDown={e => e.key !== 'Enter'}
            />
          </Autocomplete>
        </div>
        <GoogleMap
          id="search-map-build"
          mapContainerStyle={mapContainerStyle}
          zoom={15}
          onClick={handleMarker}
          options={{
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            zoomControl: true,
            disableDoubleClickZoom: true,
          }}
          center={{
            lat,
            lng,
          }}>
          <Marker
            position={{
              lat,
              lng,
            }}
          />
        </GoogleMap>
        {!formIsLoading ? (
          <form>
            <div className="form-group-select">
              <div className={styles.selecContainer}>
                <div className="address-search-filter">
                  <div
                    className="address-filter-content"
                    onClick={handleCountries}>
                    <h2>{`${selectedCountry}*`}</h2>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                  <ul
                    className={
                      showCountries
                        ? 'address-filter-options'
                        : 'address-filter-options-hidden'
                    }>
                    <li className="address-filter-option">
                      <input
                        type="radio"
                        className="radio"
                        id={'2d3d33c2-3401-40a1-858b-ae0140b0d376' + personType}
                        name="pais"
                        value="PANAMA"
                        onClick={handleCountry}
                      />
                      <label
                        htmlFor={
                          '2d3d33c2-3401-40a1-858b-ae0140b0d376' + personType
                        }>
                        PANAMA
                      </label>
                    </li>
                  </ul>
                </div>
                <div className={styles.errorMessage}>
                  {formik?.errors?.countryId && (
                    <strong>{formik?.errors?.countryId}</strong>
                  )}
                </div>
              </div>
              <div className={styles.selecContainer}>
                <div className="address-search-filter">
                  <div
                    className="address-filter-content"
                    onClick={handleProvinces}>
                    <h2>{`${selectedProvince}*`}</h2>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                  <ul
                    className={
                      showProvinces
                        ? 'address-filter-options'
                        : 'address-filter-options-hidden'
                    }>
                    {provinces.map(province => {
                      return (
                        <li
                          className="address-filter-option"
                          key={province.ProvinceId + personType}>
                          <input
                            type="radio"
                            className="radio"
                            id={province.ProvinceId + personType}
                            name="provincia"
                            value={province.Name}
                            onClick={e => {
                              handleProvince(e)
                            }}
                          />
                          <label htmlFor={province.ProvinceId + personType}>
                            {province.Name}
                          </label>
                        </li>
                      )
                    })}
                  </ul>
                </div>
                <div className={styles.errorMessage}>
                  {formik?.errors?.provinceId && (
                    <strong>{formik?.errors?.provinceId}</strong>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group-select">
              <div className={styles.selecContainer}>
                <div className="address-search-filter">
                  <div
                    className="address-filter-content"
                    onClick={handleDistricts}>
                    <h2>{`${selectedDistrict}*`}</h2>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                  <ul
                    className={
                      showDistricts
                        ? 'address-filter-options'
                        : 'address-filter-options-hidden'
                    }>
                    {districts.map(district => {
                      return (
                        <li
                          className="address-filter-option"
                          key={district.DistrictId + personType}>
                          <input
                            type="radio"
                            className="radio"
                            id={district.DistrictId + personType}
                            name="distrito"
                            value={district.Name}
                            onClick={handleDistrict}
                          />
                          <label htmlFor={district.DistrictId + personType}>
                            {district.Name}
                          </label>
                        </li>
                      )
                    })}
                  </ul>
                </div>
                <div className={styles.errorMessage}>
                  {formik?.errors?.districtId && (
                    <strong>{formik?.errors?.districtId}</strong>
                  )}
                </div>
              </div>
              <div className={styles.selecContainer}>
                <div className="address-search-filter">
                  <div
                    className="address-filter-content"
                    onClick={handleTownships}>
                    <h2>{`${selectedTownship}*`}</h2>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                  <ul
                    className={
                      showTownships
                        ? 'address-filter-options'
                        : 'address-filter-options-hidden'
                    }>
                    {townships.map(township => {
                      return (
                        <li
                          className="address-filter-option"
                          key={township.TownshipId + personType}>
                          <input
                            type="radio"
                            className="radio"
                            id={township.TownshipId + personType}
                            name="corregimiento"
                            value={township.Name}
                            onClick={handleTownship}
                          />
                          <label htmlFor={township.TownshipId + personType}>
                            {township.Name}
                          </label>
                        </li>
                      )
                    })}
                  </ul>
                </div>
                <div className={styles.errorMessage}>
                  {formik?.errors?.townShipId && (
                    <strong>{formik?.errors?.townShipId}</strong>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.inputContainer}>
              <input
                type="text"
                placeholder="Ingresa la dirección indicando nombre de edificio, piso, apartamento o número de casa*"
                name="addressStreet"
                maxLength={80}
                onChange={e => {
                  setStreet(e.target.value)
                  formik.setFieldValue('addressStreet', e.target.value)
                  if (e.target.value.length >= 1) {
                    setHasAddress({
                      ...hasAddress,
                      street: true,
                    })
                  } else {
                    setHasAddress({
                      ...hasAddress,
                      street: false,
                    })
                  }
                }}
                value={street}
                required
              />
              <div className={styles.errorMessage}>
                {formik?.errors?.addressStreet && (
                  <strong>{formik?.errors?.addressStreet ?? 'error'}</strong>
                )}
              </div>
            </div>
            <div className={styles.inputContainer}>
              <input
                type="text"
                placeholder="Alias. Ej. Casa, oficina*"
                name="addressDetail"
                maxLength={60}
                value={name}
                onChange={e => {
                  setName(e.target.value)
                  formik.setFieldValue('addressDetail', e.target.value)
                  if (e.target.value.length >= 1) {
                    setHasAddress({
                      ...hasAddress,
                      detail: true,
                    })
                  } else {
                    setHasAddress({
                      ...hasAddress,
                      detail: false,
                    })
                  }
                }}
                required
              />
              <div className={styles.errorMessage}>
                {formik?.errors?.addressDetail && (
                  <strong>{formik?.errors?.addressDetail ?? 'error'}</strong>
                )}
              </div>
            </div>
          </form>
        ) : (
          <MiniLoading />
        )}
      </div>
    </div>
  )
}

export default NewCustomerMap
