import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import MiniLoading from '../../Components/MiniLoading'
import Loading from '../../Components/Loading'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEyeSlash,
  faCheck,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import check from '../../Assets/Images/Login/check.png'
import no from '../../Assets/Images/Login/no.svg'
import './login.css'

// Redux
import { useDispatch, useSelector } from 'react-redux'
// Actions de Redux
import { loginUser } from '../../redux/actions/loginActions'
import { t } from 'i18next'
import { RESET_LOGIN_USER } from 'redux/types'
import { restorePasswordAction } from 'redux/actions/clientsActions'

function Login(props) {
  // SHOW PASSWORD
  const [showPassword, setShowPassword] = useState(false)

  // PASSWORD RESTORE MODAL STATE
  const [showModal, setShowModal] = useState(false)

  // RESTORE STATE (TEMP)
  const [restore, setRestore] = useState(false)
  const [restoreErrorMessage, setRestoreErrorMessage] = useState('')
  const [restoreEmail, setRestoreEmail] = useState('')
  const [isRestoreLoading, setIsRestoreLoading] = useState(false)

  // TENANT NUMBER STATE
  const [tenantValue, setTenantValue] = useState('')

  // LOGIN STATE (Loading/Not Loading)
  const [isLoading] = useState(false)

  // ERROR MESSAGE STATE (Retrive user data from API)
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')

  // REDUX
  const dispatch = useDispatch()
  const cargando = useSelector(state => state.login.loading)
  const error = useSelector(state => state.login.error)
  const errorModalStore = useSelector(state => state.login.errorStore)

  // TOGGLE PASSWORD VISIBILITY
  const toggleVisibility = () => {
    setShowPassword(!showPassword)
  }

  // OPEN MODAL
  const modalState = () => {
    setShowModal(!showModal)
  }

  useEffect(() => {
    dispatch({ type: RESET_LOGIN_USER, payload: false })
  }, [])

  // PASSWORD RESTORE PASSWORD (TEMP)
  const handleRestoreChange = e => {
    const value = e.target.value
    setRestoreEmail(value)
  }

  const handleRestore = async e => {
    e.preventDefault()
    // SOLO COCHEZ
    setIsRestoreLoading(true)

    const payload = {
      Tenant: 2,
      Email: restoreEmail,
    }
    const Data = await dispatch(restorePasswordAction(payload))
    if (Data && Data?.Email) {
      setRestore(true)
      setTimeout(() => {
        setShowModal(false)
        setRestore(false)
      }, 5000)
    } else {
      console.error('Error:', Data.toString())
      setRestoreErrorMessage(Data)
    }
    setIsRestoreLoading(false)
  }

  // USER LOGIN REDUX
  const login = (user, pass, company) => {
    dispatch(loginUser(user, pass, company, props))
  }

  const submitLogin = e => {
    e.preventDefault()
    login(email, password, tenantValue)
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="login-container" data-testid="login-wrapper">
          <div className="login-container-content">
            <div
              className="logo"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <img
                className="img-fluid"
                src={require('../../Assets/Images/Login/ventas_asistida.png')}
                alt=""
              />
            </div>
            <section className="content">
              <p className="bottom-text center">
                {t('LOGIN_T.LOGIN_DIRECTIONS_DESCRIPTION')}
                <br />
                {t('LOGIN_T.CONTINUE_TEXT')}{' '}
              </p>
              <div className="main-info">
                <form className="login-form" onSubmit={submitLogin}>
                  <div className="input-field form-group">
                    <input
                      type="text"
                      name="tenant"
                      className="form-control"
                      placeholder={t('LOGIN_T.CHOOSE_ENTERPRISE_LABEL')}
                      onChange={e => setTenantValue(e.target.value)}
                      required
                      data-testid="tenant-field"
                    />
                    <span
                      className="tenant-validation"
                      style={{
                        display:
                          tenantValue.toLowerCase() === 'cochez'
                            ? 'block'
                            : 'none',
                      }}>
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                  </div>
                  <div className="input-field form-group">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder={t('LOGIN_T.EMAIL_PLACEHOLDER')}
                      onChange={e => setEmail(e.target.value)}
                      value={email}
                      required
                      data-testid="email-field"
                    />
                  </div>
                  <div className="input-field form-group">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      placeholder={t('LOGIN_T.PASSWORD_PLACEHOLDER')}
                      className="form-control"
                      onChange={e => setPassword(e.target.value)}
                      value={password}
                      required
                      data-testid="password-field"
                    />
                    <span onClick={toggleVisibility} className="toggle-pass">
                      <FontAwesomeIcon icon={faEyeSlash} />
                    </span>
                  </div>
                  <button
                    type="submit"
                    className="btn-submit"
                    data-testid="btn-submit">
                    {cargando ? (
                      <FontAwesomeIcon icon={faSpinner} className="spinner" />
                    ) : (
                      t('LOGIN_T.CONTINUE_BUTTON')
                    )}
                  </button>
                  <p
                    className="restore"
                    data-testid="btn-restore"
                    onClick={modalState}>
                    {t('LOGIN_T.REESTABLISH_PASSWORD')}{' '}
                  </p>
                  {error && (
                    <p className="error-message" data-testid="btn-error">
                      {error}
                    </p>
                  )}
                  {/* MODAL PASSWORD RESTORE */}
                  <Modal
                    open={showModal}
                    aria-labelledby={t(
                      'LOGIN_T.RESTORE_PASSWORD_MODAL_LABELED'
                    )}
                    aria-describedby={t(
                      'LOGIN_T.RESTORE_PASSWORD_MODAL_DESCRIPTION'
                    )}>
                    <Box className="modal-box">
                      {isRestoreLoading && <MiniLoading />}
                      {restore && (
                        <div className="restore-container">
                          <div className="confirmation-header">
                            <span onClick={modalState} className="close-modal">
                              <img src={no} alt="close" />
                            </span>
                          </div>
                          <div className="confirmation-message">
                            <img src={check} alt="check" />
                            <p className="confirmation-text">
                              {t('LOGIN_T.RECEIVE_MESSAGE_TEXT')}{' '}
                            </p>
                          </div>
                        </div>
                      )}
                      {!restore && !isRestoreLoading && (
                        <div className="restore-container">
                          <div className="modal-header-restore">
                            <h2>{t('LOGIN_T.REESTABLISH_PASSWORD')}</h2>
                            <span onClick={modalState} className="close-modal">
                              <img src={no} alt="close" />
                            </span>
                          </div>
                          <div>
                            <input
                              type="email"
                              name="email-restore"
                              data-testid="email-restore"
                              placeholder={t('LOGIN_T.ENTER_EMAIL_PLACEHOLDER')}
                              onChange={handleRestoreChange}
                            />
                            {restoreErrorMessage && (
                              <p className="center restore-error-message">
                                {restoreErrorMessage + '.'}
                              </p>
                            )}
                            <div className="modal-buttons">
                              <button
                                onClick={modalState}
                                className="btn modal-cancel">
                                {t('LOGIN_T.CANCEL_BUTTON_MODAL')}{' '}
                              </button>
                              <button
                                onClick={handleRestore}
                                className="btn modal-continue">
                                {t('LOGIN_T.CONTINUE_BUTTON_MODAL')}{' '}
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </Box>
                  </Modal>

                  {/* MODAL PASSWORD RESTORE */}
                  <Modal open={errorModalStore}>
                    <Box className="modal-box">
                      <div className="restore-container">
                        <div className="modal-header-restore">
                          <h2></h2>
                          <span
                            onClick={() => {
                              dispatch({
                                type: 'COMPANY_ERROR_STORE',
                                payload: false,
                              })
                            }}
                            className="close-modal">
                            <img src={no} alt="close" />
                          </span>
                        </div>
                        <div>
                          <div
                            className="box-generacion-body"
                            style={{ marginTop: '35px' }}>
                            <h2>
                              {t('LOGIN_T.USER_BRANCH_NOT_FOUND_MESSAGE')}{' '}
                              <br /> asignada
                            </h2>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Modal>
                </form>
              </div>
              <div className="version-info-login">
                <p className="center version">{t('LOGIN_T.VERSION_LABEL')}</p>
                <p className="center hnl">{t('LOGIN_T.FOOTER_INFO_LABEL')}</p>
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  )
}

export default withRouter(Login)
