import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getCounterImpressionsService } from 'redux/services/printerServices'

const LoaderText = ({ loading, value }) => (
  <span className="span-text-with-loader">
    {loading ? (
      <FontAwesomeIcon
        icon={faSpinner}
        style={{ width: 24 }}
        className="spinner"
        data-testid="spinner"
      />
    ) : (
      value
    )}
  </span>
)

export const CounterImpressions = () => {
  const { WarehouseId } = useSelector(state => state.company)
  const [loading, setLoading] = React.useState(true)
  const [counterImpressions, setCounterImpressions] = React.useState({
    OfferToday: 0,
    OfferLastWeek: 0,
    OfferPrintToday: 0,
  })

  const fetchCounterImpressions = async () => {
    try {
      setLoading(true)
      const response = await getCounterImpressionsService({
        WarehouseId,
      })
      if (response?.[0]) setCounterImpressions(response[0])
    } catch (error) {
      console.log('fetchCounterImpressions -> error', error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchCounterImpressions()
  }, [WarehouseId])

  return (
    <div className="printer-stats-wrapper">
      <div className="printer-stats">
        <div>
          <p className="printer-stats-title">Ofertas hoy</p>
          <p className="printer-stats-sub-title">
            {counterImpressions.OfferToday === 0 ? 'Sin impresión' : ''}
          </p>
        </div>
        <LoaderText loading={loading} value={counterImpressions.OfferToday} />
      </div>
      <div className="printer-stats">
        <div>
          <p className="printer-stats-title">Ofertas últimos 7 días</p>
          <p className="printer-stats-sub-title">
            {counterImpressions.OfferLastWeek === 0 ? 'Sin impresión' : ''}
          </p>
        </div>
        <LoaderText
          loading={loading}
          value={counterImpressions.OfferLastWeek}
        />
      </div>
      <div className="printer-stats">
        <div>
          <p className="printer-stats-title">Ofertas Impresas</p>
          <p className="printer-stats-sub-title">
            {counterImpressions.OfferPrintToday === 0 ? 'Sin impresión' : ''}
          </p>
        </div>
        <LoaderText
          loading={loading}
          value={counterImpressions.OfferPrintToday}
        />
      </div>
    </div>
  )
}
