import { CircularProgress } from '@mui/material'
import { documentTypeId, relationalDoc } from 'Utils/constants'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { downloadPdfAction } from 'redux/actions/cotizacionActions'
import { getOdcDocument } from 'redux/services/cotizacionServices'

const RelationalDocLabel = ({ value, document }) => {
  const [isLoadingPDF, setIsLoadingPDF] = useState(false)
  const dispatch = useDispatch()
  const isInvoice = value?.DocumentTypeId === documentTypeId.INVOICE
  const isODC = value?.DocumentTypeId === documentTypeId.ODC
  const isQuotation = value?.DocumentTypeId === documentTypeId.QUOTATION

  const name = relationalDoc[value?.DocumentTypeId]?.name
  const basePath = relationalDoc[value?.DocumentTypeId]?.path
  const path = isQuotation
    ? `${value?.DocHeaderId}/${value?.DocumentNumber}`
    : value?.DocHeaderId

  const generateODCPDF = async () => {
    try {
      const odcDocument = await getOdcDocument({
        documentTypeId: document?.DocumentTypeId,
        documentId: document?.DocHeaderId,
      })
      const pdfUrl = odcDocument?.data?.Data?.find(
        item => item?.DocumentUrl !== null
      )?.DocumentUrl
      if (pdfUrl) window.open(pdfUrl, '_blank', 'noopener,noreferrer')
      return pdfUrl
    } catch (error) {
      console.log('generateODCPDF -->', error)
    }
  }

  const generateInvoicePDF = async event => {
    if (isInvoice || isODC) {
      const odcHeaderId = document?.QuoteHeaderId ?? document?.DocHeaderId
      event.preventDefault()
      const payload = {
        DocumentTypeId: value?.DocumentTypeId,
        DocHeaderId: isODC ? odcHeaderId : value?.DocHeaderId,
      }
      setIsLoadingPDF(true)

      try {
        if (isInvoice) {
          return dispatch(downloadPdfAction(payload, setIsLoadingPDF))
        }
        const pdfUrl = await generateODCPDF()
        if (!pdfUrl) dispatch(downloadPdfAction(payload, setIsLoadingPDF))
        pdfUrl && setIsLoadingPDF(false)
      } catch (error) {
        console.log('generateInvoicePDF -->', error)
      }
    }
  }

  const element = isLoadingPDF ? (
    <CircularProgress size="1.5rem" style={{ marginLeft: 10 }} />
  ) : (
    <a
      href={isODC ? null : `${basePath}${path}`}
      target="_blank"
      rel="noreferrer"
      onClick={isODC ? () => null : generateInvoicePDF}>
      {value?.DocumentNumber}
    </a>
  )

  return (
    <span>
      {name}: {element}
    </span>
  )
}

export default RelationalDocLabel
