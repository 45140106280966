import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  agregarSucursal,
  quitarDireccion,
  updatePlanIdLineas,
  updateWarehouseIdLineas,
} from '../../redux/actions/cotizacionActions.js'
import './sucursal.css'
import { getWharehouseInfo } from 'redux/actions/clientsActions'
import { fullfilmentIds } from 'Utils/constants.js'

const Sucursales = ({
  setSelectedAddress,
  handleMetodoEntrega,
  setDireccion,
  insideForm,
  selectedAddress,
  direccion,
}) => {
  // URL DE PICKUP
  const initialSucursal = {
    Description: 'Selecciona una sucursal',
    Disabled: true,
    Selected: true,
  }
  const [sucursales, setSucursales] = useState([initialSucursal])
  const cart = useSelector(state => state.cart.cart)

  // SUCURSAL SELECCIONADA
  const [sucursalSelected, setSucursalSelected] = useState(
    selectedAddress || ''
  )
  // const [show, setShow] = useState(false)
  const companyCode = useSelector(state => state.company.CompanyCode)
  const companyId = useSelector(state => state.company.CompanyId)
  const dispatch = useDispatch()

  // HANDLE PARA SELECCIONAR SUCURSAL
  const handleSucursalSelected = e => {
    const sucursal = JSON.parse(e.target.value)
    setSucursalSelected(sucursal)
    e.preventDefault()
    if (sucursal) {
      const warehouse = {
        WarehouseId: sucursal?.WarehouseId,
        WarehouseDescription: sucursal?.Description,
        WarehouseAddress: sucursal?.WarehouseAddress,
        WarehouseCode: sucursal?.Code,
        WarehouseName: sucursal?.Description,
      }
      dispatch(quitarDireccion())
      dispatch(agregarSucursal(warehouse))
      dispatch(
        updateWarehouseIdLineas({
          WarehouseId: warehouse.WarehouseId,
          WarehouseCode: warehouse.WarehouseCode,
          WarehouseDescription: warehouse.WarehouseDescription,
        })
      )

      setDireccion(false)
      handleMetodoEntrega()
      setSelectedAddress(sucursal) // ESTO SE PUEDE QUITAR HACIENDO UN CAMBIO DE LOGICA EN SHOPPING CART
      dispatch(
        updatePlanIdLineas({
          cart,
          fulfillmentPlanId: fullfilmentIds.branchDelivery.fulfillmentPlanId,
          fulfillmentPlanDescription:
            fullfilmentIds.branchDelivery.fulfillmentPlanDescription,
        })
      )
    }
  }

  const fetchWarehouse = async () => {
    const payload = {
      companyId,
      tenantId: 2,
      SearchText: null,
      PageNumber: 1,
      PageSize: 100,
    }
    const warehouseInfo = await dispatch(getWharehouseInfo(payload))
    if (warehouseInfo) setSucursales([initialSucursal])
    const warehouses = warehouseInfo
      .map(branch => {
        const composedWarehouse = {
          ...branch.Warehouses[0],
          WarehouseAddress: branch.Address_AddressDetail,
        }
        return composedWarehouse
      })
      .filter(warehouse => {
        return warehouse.Description !== undefined
      })
    setSucursales(prevState => [...prevState, ...warehouses])
  }

  useEffect(() => {
    fetchWarehouse()
  }, [companyCode])

  return (
    <div className="content-sucursal" style={{ padding: insideForm && '0' }}>
      <h2>Seleccione una sucursal</h2>
      <div className="sucursales-options">
        <div className="select-wrapper">
          <select onChange={handleSucursalSelected}>
            {sucursales.map((sucursal, index) => {
              return (
                <option
                  key={index}
                  value={JSON.stringify(sucursal)}
                  selected={
                    sucursalSelected && !direccion
                      ? sucursalSelected?.WarehouseId === sucursal.WarehouseId
                      : sucursal.Selected
                  }
                  disabled={sucursal.Disabled}>
                  {sucursal.Description}
                </option>
              )
            })}
          </select>
        </div>
      </div>
    </div>
  )
}

export default Sucursales
