import React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

export const CustomSelector = ({
  placeholder,
  options,
  disabled = false,
  handleOption,
  selectedOption,
}) => {
  const [showOptions, setShowOptions] = React.useState(false)

  return (
    <button
      className="printer-option"
      disabled={disabled}
      onClick={() => setShowOptions(!showOptions)}>
      <p className="printer-option-text">
        {selectedOption.value || placeholder}
      </p>
      <KeyboardArrowDownIcon style={{ width: 30, height: 30 }} />
      {showOptions && (
        <div className="printer-options">
          {options.map(option => (
            <button
              key={option}
              className={`printer-options-value ${
                selectedOption === option ? 'selected' : ''
              }`}
              onClick={() => handleOption(option)}>
              <p className="printer-option-text">{option.value}</p>
            </button>
          ))}
        </div>
      )}
    </button>
  )
}
