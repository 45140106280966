import CardDeal from 'Components/CardDeal'
import VAContainer from 'Components/VAContainer'
import React, { useMemo, useState } from 'react'
import NoImage from '../../Assets/Images/Logos/no-image.png'
import './ofertas.css'
import { useDispatch } from 'react-redux'
import { getPromotions } from 'redux/actions/promotionActions'

const Deals = () => {
  const [promotions, setPromotions] = useState([])
  const dispatch = useDispatch()

  const getDeals = async () => {
    const promotions = await dispatch(getPromotions())
    if (promotions?.length > 0) {
      formmatedPromotions(promotions)
    }
  }

  const formmatedPromotions = promotions => {
    const formmatedPromotions = promotions.map(promotion => {
      return {
        image: promotion?.PromotionMedia[0]?.FileUrl || NoImage,
        title: promotion.Name,
        copy: promotion.Description,
        link: null,
      }
    })
    setPromotions(formmatedPromotions)
  }
  useMemo(() => {
    getDeals()
  }, [])

  return (
    <VAContainer title={'Promociones'}>
      <div className="vad-cards-deals__container">
        {promotions?.length > 0 &&
          promotions.map((deal, index) => (
            <CardDeal
              key={index}
              image={deal.image}
              title={deal.title}
              copy={deal.copy}
              link={deal.link}
            />
          ))}
      </div>
    </VAContainer>
  )
}

export default Deals
