import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import MiniLoading from '../../Components/MiniLoading'
import Slider from '../../Assets/Images/CrearCotizacion/sliders.svg'
import search from '../../Assets/Images/BuscarCotizacion/search.svg'
import ProductList from '../../Components/ProductList'
import no from '../../Assets/Images/Product/No.svg'
import Departments from '../../Views/Departments'
import { useSelector, useDispatch } from 'react-redux'
import './productSearch.css'
import {
  addFacetFilter,
  addCategories,
  addBrands,
  removeSingleCategory,
  removeFacetFilters,
  removeSingleBrand,
  showProductsList,
} from '../../redux/actions/productActions'
import { clearList } from '../../redux/actions/listActions'
import { t } from 'i18next'
import {
  algoliaQueryAction,
  getPriceInventoryGeneral,
} from 'redux/actions/priceProductsAction'
import { fetchPriceInventory } from 'Utils/functions'

function ProductSearch({
  productsRef,
  inListScreen,
  setShowMaterialsCalculatorPDP,
  setProductDetail,
}) {
  const [productSearchText, setProductSearchText] = useState('')
  const [isSearchable, setIsSearchable] = useState(false)
  const [productSearch, setProductSearch] = useState('')
  const [products, setProducts] = useState([])
  const [newCategories, setNewCategories] = useState(false)
  const currentPage = useRef(0)

  const [showDepartments, setShowDepartments] = useState(true)
  const dispatch = useDispatch()

  const facetFiltersState = useSelector(state => state.products.facetFilters)
  const CompanyCode = useSelector(state => state?.company?.CompanyCode)
  let facetFilters = []
  let categoriesRef = []
  let brandsRef = []
  let priceFilters = ''
  const priceChecked = useRef(0)
  const [priceFiltersState, setPriceFiltersState] = useState('')
  const [priceFilterTrigger, setPriceFilterTrigger] = useState(false)
  const [minInput, setMinInput] = useState('')
  const [maxInput, setMaxInput] = useState('')
  const [showFullCategories, setShowFullCategories] = useState(false)
  const [showFullBrands, setShowFullBrands] = useState(false)
  const [page, setPage] = useState(0)
  const currentNbPages = useRef(0)

  const checkBoxRef = []
  const brandsCheckBoxRef = []
  const bodyInitialState = {
    query: '',
    page,
    hitsPerPage: 20,
    facets: [],
    facetFilters,
    filters: priceFilters,
    getRankingInfo: true,
    analytics: false,
    enableABTest: false,
    attributesToRetrieve: '*',
    maxValuesPerFacet: 100,
    explain: '*',
  }

  const [searchTrigger, setSearchTrigger] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [categorySearchTrigger, setCategorySearchTrigger] = useState(false)
  const [brandSearchTrigger, setBrandSearchTrigger] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [successAlgoliaHits, setSuccessAlgoliaHits] = useState(true)
  const [categories, setCategories] = useState([])
  const [currentCategory, setCurrentCategory] = useState([])
  const [currentBrand, setCurrentBrand] = useState([])
  const [brands, setBrands] = useState([])
  const [currentDepartment, setCurrentDepartment] = useState(null)
  const [productsLoading, setProductsLoading] = useState(false)
  const CompanyId = useSelector(state => state?.company?.CompanyId)

  const warehouse = useSelector(state => state.company.WarehouseId)

  const algoliaBody = bodyInitialState

  const addToRef = (element, ref) => {
    if (element && !checkBoxRef.includes(element)) {
      checkBoxRef.push(element)
    }
  }

  const addToBrandRef = (element, ref) => {
    if (element && !brandsRef.includes(element)) {
      brandsCheckBoxRef.push(element)
    }
  }

  const handleDepartmentSearch = (state, facetFilter) => {
    setShowDepartments(state)
    setCurrentDepartment(facetFilter.name)
    dispatch(addFacetFilter(facetFilter))
    setNewCategories(true)
    setIsSearchable(true)
    setSearchTrigger(true)
  }

  const handleSlider = () => {
    setIsActive(!isActive)
  }

  const handleCategory = e => {
    if (e.target.checked) {
      const facetFilterPayload = {
        key: 'category',
        value: `Category:${e.target.name}`,
      }
      setCurrentCategory([...currentCategory, e.target.name])
      dispatch(addCategories(e.target.name))
      dispatch(addFacetFilter(facetFilterPayload))
      setProducts([])
      setCategorySearchTrigger(true)
      setIsSearchable(true)
    } else {
      handleRemoveCategory(e.target.name)
    }
  }

  const handleBrands = e => {
    if (e.target.checked) {
      const facetFilterPayload = {
        key: 'brand',
        value: `Brand:${e.target.name}`,
      }
      setCurrentBrand([...currentBrand, e.target.name])
      dispatch(addBrands(e.target.name))
      dispatch(addFacetFilter(facetFilterPayload))
      setProducts([])
      setProductSearch('')
      setProductSearchText('')
      setBrandSearchTrigger(true)
      setIsSearchable(true)
    } else {
      handleRemoveBrand(e.target.name)
    }
  }

  const handleRemovePriceFilter = () => {
    priceFilters = ''
    setPriceFiltersState('')
    setPriceFilterTrigger(false)
  }

  const handlePriceFilter = e => {
    switch (e.target.value) {
      case t('PRODUCTS_SEARCH_T.FINAL_PRICE_CATALOG.FINAL_PRICE_0_19'):
        priceChecked.current = 1
        break
      case t('PRODUCTS_SEARCH_T.FINAL_PRICE_CATALOG.FINAL_PRICE_20_199'):
        priceChecked.current = 2
        break
      case t('PRODUCTS_SEARCH_T.FINAL_PRICE_CATALOG.FINAL_PRICE_200_499'):
        priceChecked.current = 3
        break
      case t('PRODUCTS_SEARCH_T.FINAL_PRICE_CATALOG.FINAL_PRICE_500_799'):
        priceChecked.current = 4
        break
      case t('PRODUCTS_SEARCH_T.FINAL_PRICE_CATALOG.FINAL_PRICE_800'):
        priceChecked.current = 5
        break
      case t('PRODUCTS_SEARCH_T.DISCOUNT_CATALOG.DISCOUNT_1_19'):
        priceChecked.current = 6
        break
      case t('PRODUCTS_SEARCH_T.DISCOUNT_CATALOG.DISCOUNT_20_29'):
        priceChecked.current = 7
        break
      case t('PRODUCTS_SEARCH_T.DISCOUNT_CATALOG.DISCOUNT_30'):
        priceChecked.current = 8
        break
      default:
        priceChecked.current = 0
    }
    let priceFilterPayload = ''
    if (e.target.checked) {
      priceFilterPayload = `${e.target.value}`
      priceFilters = priceFilterPayload
      setProducts([])
      setMinInput('')
      setMaxInput('')
      setPriceFiltersState(priceFilters)
      setPriceFilterTrigger(true)
      setIsSearchable(true)
    } else if (!e.target.checked) {
      handleRemovePriceFilter()
      setProducts([])
      setProductSearch('')
      setProductSearchText('')
      setPriceFiltersState(priceFilters)
      setPriceFilterTrigger(true)
      setIsSearchable(true)
      priceChecked.current = 0
    }
  }

  const handleRemoveCategory = categoryToRemove => {
    if (
      currentBrand.length < 1 &&
      currentCategory.length === 1 &&
      currentDepartment === null
    ) {
      handleRemoveDepartment()
    }
    const thisCategoryRef = checkBoxRef
    let indexRef = null
    thisCategoryRef.forEach((item, index) => {
      if (item.value === categoryToRemove) {
        indexRef = index
      }
    })
    checkBoxRef[indexRef].checked = false
    const currentCategoryArray = [...currentCategory]
    currentCategoryArray.splice(
      currentCategoryArray.indexOf(categoryToRemove),
      1
    )
    setCurrentCategory([...currentCategoryArray])
    dispatch(removeSingleCategory(categoryToRemove))
    setProducts([])
    setProductSearch('')
    currentPage.current = 0
    setCategorySearchTrigger(true)
  }

  const handleRemoveBrand = brandToRemove => {
    if (
      currentBrand.length === 1 &&
      currentCategory.length < 1 &&
      currentDepartment === null
    ) {
      handleRemoveDepartment()
    }
    const thisBrandRef = brandsCheckBoxRef
    let indexRef = null
    thisBrandRef.forEach((item, index) => {
      if (item.value === brandToRemove) {
        indexRef = index
      }
    })
    brandsCheckBoxRef[indexRef].checked = false
    const currentBrandArray = [...currentBrand]
    currentBrandArray.splice(currentBrandArray.indexOf(brandToRemove), 1)
    setCurrentBrand([...currentBrandArray])
    dispatch(removeSingleBrand(brandToRemove))
    setProducts([])
    setProductSearch('')
    currentPage.current = 0
    setBrandSearchTrigger(true)
  }

  const handlePage = () => {
    setPage(page + 1)
    currentPage.current++
  }

  const handleMinMax = e => {
    let priceFilterPayload = ''
    if (e.keyCode === 13) {
      if (minInput !== '' && maxInput === '') {
        priceFilterPayload = `${t(
          'PRODUCTS_SEARCH_T.COMPANY_FILTER_LABEL'
        )} >= ${minInput}`
      } else if (minInput === '' && maxInput !== '') {
        priceFilterPayload = `${t(
          'PRODUCTS_SEARCH_T.COMPANY_FILTER_LABEL'
        )} <= ${maxInput}`
      } else if (minInput !== '' && maxInput !== '') {
        priceFilterPayload = `${t(
          'PRODUCTS_SEARCH_T.COMPANY_FILTER_LABEL'
        )}${':'} ${minInput} ${t('PRODUCTS_T.TO_LABEL')} ${maxInput}`
      }
      if (priceFilterPayload !== '') {
        priceFilters = priceFilterPayload
        setProducts([])
        setPriceFiltersState(priceFilters)
        setPriceFilterTrigger(true)
        setIsSearchable(true)
      }
    }
  }

  const getPrices = async PriceInventoryPayload => {
    setProductsLoading(true)
    try {
      const response = await dispatch(
        getPriceInventoryGeneral(PriceInventoryPayload)
      )
      setProductsLoading(false)
      return response.data?.Data
    } catch (error) {
      console.error('Error:', error)
      setProductsLoading(false)
      throw error
    }
  }

  const AlgoliaFetchProducts = async () => {
    try {
      setProductsLoading(true)
      const finalFacetCategories = []
      const finalFacetBrands = []
      // eslint-disable-next-line array-callback-return
      facetFiltersState[1].value.map(item => {
        finalFacetCategories.push(item)
      })
      // eslint-disable-next-line array-callback-return
      facetFiltersState[2].value.map(item => {
        finalFacetBrands.push(item)
      })
      const finalFacetFilters = [
        [...facetFiltersState[0].value],
        [...finalFacetCategories],
        [...finalFacetBrands],
        [`Company.Code:${CompanyCode}`],
      ]
      await facetFiltersState?.forEach(item => {
        item.value.forEach(object => {
          facetFilters.push([object])
        })
      })
      const currentBody = { ...algoliaBody }
      currentBody.page = currentPage.current
      if (priceFiltersState !== '') {
        currentBody.filters = priceFiltersState
      }
      if (facetFilters) {
        currentBody.facetFilters = finalFacetFilters
        currentBody.facets = '*'
      }
      if (productSearch) {
        currentBody.query = productSearch
      }

      const response = await dispatch(algoliaQueryAction(currentBody))
      if (response && response.data) {
        const hasAlgoliaHits = !!response?.data?.hits?.length
        currentNbPages.current = response.data?.nbPages
        setSuccessAlgoliaHits(hasAlgoliaHits)
        if (response.data?.facets?.Category && facetFilters && newCategories) {
          categoriesRef = Object.keys(response.data?.facets?.Category)?.map(
            key => [String(key), response.data?.facets?.Category[key]]
          )
          setCategories(categoriesRef)
        } else {
          categoriesRef = categories
        }
        if (response.data?.facets?.Brand) {
          brandsRef = Object.keys(response.data?.facets?.Brand).map(key => [
            String(key),
            response.data?.facets?.Brand[key],
          ])
          setBrands(brandsRef)
        } else {
          brandsRef = brands
        }
        hasAlgoliaHits &&
          (await fetchPriceInventory({
            hits: response?.data?.hits || [],
            warehouse,
            companyCode: CompanyCode,
            companyId: CompanyId,
            getPrices,
            t,
          })
            .then(response => {
              if (currentPage.current === 0) {
                setProducts([...response])
              } else {
                setProducts([...products, ...response])
              }
              setNewCategories(false)
            })
            .catch(_err => {
              setNewCategories(true)
            })
            .finally(() => {
              setProductsLoading(false)
            }))
      } else {
        console.error('Error:', response.toString())
      }
    } catch (error) {
      console.error('Error getting products:', error)
    } finally {
      setProductsLoading(false)
    }
  }

  useEffect(async () => {
    currentPage.current = 0
  }, [CompanyCode])

  useEffect(async () => {
    setHasMore(false)
    if (
      (productSearch && isSearchable && !showDepartments) ||
      (searchTrigger && isSearchable && !showDepartments) ||
      (categories && isSearchable && !showDepartments) ||
      (brands && isSearchable && !showDepartments) ||
      (priceFiltersState !== '' &&
        categorySearchTrigger &&
        isSearchable &&
        !showDepartments)
    ) {
      await AlgoliaFetchProducts()
      const currentPagination = currentPage.current
      if (currentNbPages.current > currentPagination + 1) {
        setHasMore(true)
      }
    }
  }, [
    productSearch,
    page,
    searchTrigger,
    categorySearchTrigger,
    currentCategory,
    priceFiltersState,
    priceFilterTrigger,
    brandSearchTrigger,
    currentBrand,
    CompanyCode,
  ])

  useEffect(() => {
    dispatch(showProductsList(true))
    dispatch(clearList())
  }, [])

  const splitCategories = categoriesToSlice => {
    const splitCategoryArray = categoriesToSlice.sort().slice(0, 8)

    return (
      splitCategoryArray?.length &&
      splitCategoryArray.sort().map(category => {
        return (
          <div className="product-category" key={category}>
            <input
              ref={addToRef}
              type="checkbox"
              name={category[0]}
              id={category[0]}
              value={category[0]}
              onClick={handleCategory}
            />
            <label htmlFor={category[0]} className="category-label">
              {category[0]} ({category[1]})
            </label>
          </div>
        )
      })
    )
  }

  const fullCategories = fullCategories => {
    return (
      fullCategories?.length &&
      fullCategories.sort().map(category => {
        return (
          <div className="product-category" key={category}>
            <input
              ref={addToRef}
              type="checkbox"
              name={category[0]}
              id={category[0]}
              value={category[0]}
              onClick={handleCategory}
            />
            <label htmlFor={category[0]} className="category-label">
              {category[0]} ({category[1]})
            </label>
          </div>
        )
      })
    )
  }

  const fullBrands = fullBrands => {
    return (
      fullBrands?.length &&
      fullBrands.sort().map(brand => {
        return (
          <div className="product-category" key={brand}>
            <input
              ref={addToBrandRef}
              type="checkbox"
              name={brand[0]}
              id={brand[0]}
              value={brand[0]}
              onClick={handleBrands}
            />
            <label htmlFor={brand[0]} className="category-label">
              {brand[0]} ({brand[1]})
            </label>
          </div>
        )
      })
    )
  }

  const splitBrands = brandsToSlice => {
    const splitBrandsArray = brandsToSlice.sort().slice(0, 8)
    return (
      splitBrandsArray?.length &&
      splitBrandsArray.sort().map(brand => {
        return (
          <div className="product-category" key={brand}>
            <input
              ref={addToBrandRef}
              type="checkbox"
              name={brand[0]}
              id={brand[0]}
              value={brand[0]}
              onClick={handleBrands}
            />
            <label htmlFor={brand[0]} className="category-label">
              {brand[0]} ({brand[1]})
            </label>
          </div>
        )
      })
    )
  }

  const handleSearchText = e => {
    setProductSearchText(e.target.value)
  }

  const handleSearch = e => {
    if (e.keyCode === 13 && productSearchText.trim().length) {
      if (e.target.value && e.target.value !== productSearch) {
        handleRemoveDepartment(false)
        setShowDepartments(false)
        setProducts([])
        currentPage.current = 0
        setNewCategories(true)
        setProductSearch(e.target.value)
      } else if (e.target.value === '' && productSearch !== '') {
        setShowDepartments(false)
        setProducts([])
        currentPage.current = 0
        setNewCategories(true)
        setProductSearch(e.target.value)
      }
      setIsSearchable(true)
    }
  }

  const searchTextRef = useRef(null)

  const handleSearchTextInput = value => {
    if (value !== '' && productSearchText.trim().length) {
      if (value && value !== productSearch) {
        handleRemoveDepartment(false)
        setShowDepartments(false)
        setProducts([])
        currentPage.current = 0
        setNewCategories(true)
        setProductSearch(value)
      } else if (value === '' && productSearch !== '') {
        setShowDepartments(false)
        setProducts([])
        currentPage.current = 0
        setNewCategories(true)
        setProductSearch(value)
      }
      setIsSearchable(true)
    }
  }

  const handleRemoveDepartment = textOverride => {
    if (textOverride) {
      setProductSearch('')
      setProductSearchText('')
    }
    setCurrentDepartment(null)
    setCurrentCategory([])
    setCategories([])
    dispatch(removeFacetFilters())
    setNewCategories(true)
    setBrands([])
    setShowDepartments(true)
    setSearchTrigger(false)
    setProducts([])
    setShowFullCategories(false)
    setShowFullBrands(false)
    setCurrentBrand([])
    setPriceFiltersState('')
    setSuccessAlgoliaHits(true)
    setIsSearchable(false)
    priceChecked.current = 0
    currentPage.current = 0

    facetFilters = [
      { key: 'department', value: [] },
      { key: 'category', value: [] },
      { key: 'brand', value: [] },
    ]
  }

  return (
    <div
      className={
        inListScreen
          ? 'productos-cotizacion-listScreen'
          : 'productos-cotizacion'
      }>
      <div className="productos-cotizacion-header">
        <div className="productos-cotizacion-search">
          <div className="search-bar-input">
            <input
              ref={searchTextRef}
              className="search-product"
              type="text"
              disabled={productsLoading}
              value={productSearchText}
              onChange={handleSearchText}
              onKeyDown={handleSearch}
              placeholder="Busca un producto ingresando su descripción, código, marca o número de parte"
            />
            <img
              src={search}
              className="cotizacion-search-icon"
              alt="search"
              onClick={() => handleSearchTextInput(searchTextRef.current.value)}
            />
          </div>
          {!showDepartments && (
            <button
              className={
                !isActive || !currentCategory.length ? 'active-slider' : ''
              }
              disabled={productsLoading}
              onClick={handleSlider}>
              <img
                className={isActive || currentCategory.length ? 'blue-svg' : ''}
                src={Slider}
                alt="slider"
              />
            </button>
          )}
          <div
            className={'filtering-options'}
            style={{
              display: isActive ? '' : 'none',
              overflowY: 'scroll',
              height: 'calc(93vh - 200px)',
            }}
            onMouseLeave={() => setIsActive(false)}>
            <div
              style={{
                marginBottom: '11px',
                border: '1px solid #DBDBDB',
                padding: '29px 50px',
                backgroundColor: '#fff',
              }}>
              <h3>Categorías</h3>
              {categories?.length && !showFullCategories
                ? splitCategories(categories)
                : categories?.length && showFullCategories
                ? fullCategories(categories)
                : null}
              {categories?.length > 7 && (
                <Link
                  to="#"
                  className="categories-ver-todas"
                  onClick={() => setShowFullCategories(!showFullCategories)}>
                  {showFullCategories ? 'Ver menos' : 'Ver Todas'}
                </Link>
              )}
            </div>
            <div
              style={{
                marginBottom: '11px',
                border: '1px solid #DBDBDB',
                padding: '29px 50px',
                backgroundColor: '#fff',
              }}>
              <h3>Marcas</h3>
              {brands?.length && !showFullBrands
                ? splitBrands(brands)
                : brands?.length && showFullBrands
                ? fullBrands(brands)
                : null}
              {brands?.length > 7 && (
                <Link
                  to="#"
                  className="categories-ver-todas"
                  onClick={() => setShowFullBrands(!showFullBrands)}>
                  {showFullBrands ? 'Ver menos' : 'Ver Todas'}
                </Link>
              )}
            </div>
            <div
              style={{
                marginBottom: '11px',
                border: '1px solid #DBDBDB',
                padding: '29px 50px',
                backgroundColor: '#fff',
              }}>
              <h3>Precios</h3>
              <div className="product-category">
                <input
                  type="checkbox"
                  name="priceFilterOption"
                  value="Company.FinalPrice: 0 TO 19.99"
                  onChange={() => {}}
                  onClick={handlePriceFilter}
                  checked={priceChecked.current === 1}
                />
                <label className="category-label">Desde $0 a $19.99</label>
              </div>
              <div className="product-category">
                <input
                  type="checkbox"
                  name="priceFilterOption"
                  value="Company.FinalPrice: 20 TO 199"
                  onChange={() => {}}
                  onClick={handlePriceFilter}
                  checked={priceChecked.current === 2}
                />
                <label className="category-label">Desde $20 a $199</label>
              </div>
              <div className="product-category">
                <input
                  type="checkbox"
                  name="priceFilterOption"
                  value="Company.FinalPrice: 200 TO 499"
                  onChange={() => {}}
                  onClick={handlePriceFilter}
                  checked={priceChecked.current === 3}
                />
                <label className="category-label">Desde $200 a $499</label>
              </div>
              <div className="product-category">
                <input
                  type="checkbox"
                  name="priceFilterOption"
                  value="Company.FinalPrice: 500 TO 799"
                  checked={priceChecked.current === 4}
                  onChange={() => {}}
                  onClick={handlePriceFilter}
                />
                <label className="category-label">Desde $500 a $799</label>
              </div>
              <div className="product-category">
                <input
                  type="checkbox"
                  name="priceFilterOption"
                  checked={priceChecked.current === 5}
                  value="Company.FinalPrice >= 800"
                  onChange={() => {}}
                  onClick={handlePriceFilter}
                />
                <label className="category-label">$800+</label>
              </div>
              <div>
                <input
                  className="min-max"
                  placeholder="$ Min"
                  value={minInput}
                  onChange={e => setMinInput(e.target.value)}
                  onKeyDown={handleMinMax}></input>
                <p
                  style={{
                    fontSize: '20px',
                    display: 'inline-block',
                    marginRight: '10px',
                    marginLeft: '10px',
                  }}>
                  -
                </p>
                <input
                  className="min-max"
                  placeholder="$ Max"
                  value={maxInput}
                  onChange={e => setMaxInput(e.target.value)}
                  onKeyDown={handleMinMax}></input>
              </div>
            </div>
          </div>
        </div>
        <div
          className="current-filtering-options"
          style={{
            display: currentCategory ? 'flex' : 'none',
            flexWrap: 'nowrap',
            overflowX: 'auto',
          }}>
          {currentDepartment && (
            <div className="current-filtering-option">
              <span>Departamento: {currentDepartment}</span>
              <img
                src={no}
                alt="close"
                onClick={() => handleRemoveDepartment(true)}
              />
            </div>
          )}
          {currentCategory?.length > 0 &&
            currentCategory.map(category => {
              return (
                <div className="current-filtering-option" key={category}>
                  <span>{category}</span>
                  <img
                    src={no}
                    alt="close"
                    onClick={() => handleRemoveCategory(category)}
                  />
                </div>
              )
            })}
          {currentBrand?.length > 0 &&
            currentBrand.map(brand => {
              return (
                <div className="current-filtering-option" key={brand}>
                  <span>{brand}</span>
                  <img
                    src={no}
                    alt="close"
                    onClick={() => handleRemoveBrand(brand)}
                  />
                </div>
              )
            })}
        </div>
      </div>
      <div
        id="scrollableDiv"
        className={
          showDepartments
            ? 'productos-cotizacion-departments'
            : isSearchable
            ? successAlgoliaHits
              ? 'products-cotizacion-content-container'
              : 'cotizacion-content-message'
            : 'cotizacion-content-message'
        }>
        {showDepartments ? (
          <>
            <Departments
              handleDepartmentSearch={handleDepartmentSearch}
              setShowDepartments={setShowDepartments}
            />
          </>
        ) : isSearchable ? (
          <>
            <ProductList
              successAlgoliaHits={successAlgoliaHits}
              products={products}
              setPage={setPage}
              productsRef={productsRef}
              productsLoading={productsLoading}
              hasMore={hasMore}
              handlePage={handlePage}
              inListScreen={inListScreen}
              currentCategory={currentCategory}
              productSearchText={productSearchText}
              setShowMaterialsCalculatorPDP={setShowMaterialsCalculatorPDP}
              setProductDetail={setProductDetail}
            />
          </>
        ) : (
          <div className="add-products-quote-message">
            <MiniLoading productsLoading={productsLoading} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductSearch
