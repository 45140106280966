import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import PrincipalModal from '../Clientes/PrincipalModal'
import { VAContext } from '../../Context/VAContext'
import { PencilSquare, TrashFill, Cart } from 'react-bootstrap-icons'
import EliminarCliente from '../Clientes/EliminarCliente'
import AgregarCliente from '../Clientes/AgregarCliente'
import { useHistory } from 'react-router-dom'
import No from '../../Assets/Images/Login/no.svg'
import CrearCliente from '../../Views/Clientes/CrearCliente'
import {
  FormatMoney,
  hasChangePriceApprove,
  marginTotal,
  toTitleCase,
  validateRoles,
} from '../../Utils/functions'
import { useDispatch, useSelector } from 'react-redux'
import {
  quitarDireccion,
  cotizacionRemoveClient,
  addAmounts,
  guardarTypeDocument,
  agregarCotizacion,
  mostrarDescuento,
  cotizacionPriceChangeApproval,
  addPriceList,
  cotizacionReStart,
  addSalesAgentFullName,
  agregarSucursal,
  agregarPDV,
  activeModalCustomer,
} from '../../redux/actions/cotizacionActions.js'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { animateScroll as scroll } from 'react-scroll'
import LightTooltip from '../LightTooltip'
import { t } from 'i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import CustomerInfo from 'Components/CustomerInfo'
import {
  ROUTES,
  VAPermissions,
  customerTypeAllowedRoles,
  docHeaderStatusId,
} from 'Utils/constants'
import useValidatePermission from 'Hooks/useValidatePermission'
import { COTIZACION_REMOVE_CLIENT } from 'redux/types'
import MiniLoading from 'Components/MiniLoading'
import { useHasProductWithPriceUnderCost } from 'Components/CartProduct/hooks/useHasProductWithPriceUnderCost'
import ModalAlertCart from 'Components/ModalAlertCart/ModalAlertCart'
import CostMarginVisibility from 'Components/CostMarginVisibility/CostMarginVisibility'
import './CalculatorSummaryCart.css'

function CalculatorSummaryCart({
  handleMetodoEntrega,
  setClienteMetodoEntrega,
  setSelectedAddress,
  selectedAddress,
  direccion,
  setDireccion,
  editarCotizacion,
  productsRef,
}) {
  const [showModalEditClient, setShowModalEditClient] = useState(false)
  const [subtotal, setSubtotal] = useState(0)
  const [totalMargin, setTotalMargin] = useState(0)
  const dispatch = useDispatch()
  const history = useHistory()
  const [direccionState, setDireccionState] = useState(null)
  const [, setProductsChanged] = useState([])
  const { isCommercialSeller, hasPriceUnderCost } =
    useHasProductWithPriceUnderCost()
  // Check if user has create order role and permission
  const isUserAllowed = useValidatePermission(VAPermissions.createOrder)

  const seeMargenProd = useValidatePermission(VAPermissions.seeMargenProd)
  const canChangePrice = useValidatePermission(VAPermissions.priceOverrideAutho)

  // State Cotizacion
  const client = useSelector(
    state => state.cotizacion?.QuoteHeader?.CustomerNameDocument
  )

  const clientEmail = useSelector(
    state => state.cotizacion?.QuoteHeader?.EmailAddress
  )
  const address = useSelector(
    state => state.cotizacion?.QuoteHeader?.Address_Street
  )
  const cotizacion = useSelector(state => state.cotizacion?.QuoteHeader)
  const customerId = useSelector(
    state => state.cotizacion?.QuoteHeader?.CustomerId
  )

  const [warehousePickUp, setWarehousePickUp] = useState(null)

  // En caso de que se edite la cotización
  const lines = useSelector(state => state?.cotizacion?.QuoteHeader?.Lines)
  const [fulfillmentPlan, setFulfillmentPlan] = useState(null)

  // CLIENT TO COTIZACION
  const [clienteMetodoEntrega, setClienteCotizacion] = useState(null)

  // CLIENT VIEW STATE (CONTEXT API)
  const { showModalCreateClient, selectClient, clientSelected } =
    useContext(VAContext)

  // STATES CLIENT MODALS
  const [showModalClient, setShowModalClient] = useState(false)
  const [showModalDeleteClient, setShowModalDeleteClient] = useState(false)
  const [confirmDeleteClient, setConfirmDeleteClient] = useState(false)
  const [showModalAddClient, setShowModalAddClient] = useState(false)
  const [open, setOpen] = useState(false)
  const [modalInfoClient, setModalInfoClient] = useState(false)
  const [itbms, setItbms] = useState(0)
  const [quantity, setQuantiy] = useState(0)
  const [total, setTotal] = useState(0)

  // Error en generacion
  const [generacionError, setGeneracionError] = useState(false)

  const isCustomerUser = useSelector(state => state.login.isCustomer)
  const userId = useSelector(
    state => state?.login?.user?.CustomerInfo?.CustomerId
  )
  const userName = useSelector(
    state => state?.login?.user?.CustomerInfo?.FullName
  )

  const { isLoadingProduct } = useSelector(state => state?.cart)

  const cart = useSelector(state => state.cart.cart)

  const loginInfo = useSelector(state => state.login?.user?.AuthenticationInfo)
  const isSupervisorUser = validateRoles(
    loginInfo.UserRoles,
    customerTypeAllowedRoles.supervGerent
  )
  const isCommercialSupervisorUser = validateRoles(
    loginInfo.UserRoles,
    customerTypeAllowedRoles.comercialSuperv
  )

  // get company data to know if have default warehouse
  const company = useSelector(state => state.company)

  const handlePriceChange = lines => {
    const approvedLines = lines.map(line => {
      const newLine = {
        ...line,
        AuthorizedPriceBy: userId,
        AuthorizedPriceByName: userName,
      }
      return newLine
    })

    dispatch(
      cotizacionPriceChangeApproval({
        Lines: approvedLines,
      })
    )
  }

  const handleShowModalClient = () => {
    setShowModalClient(!showModalClient)
    dispatch(activeModalCustomer(true))
    showModalCreateClient(0)
  }

  const clientToEdit = CustomerId => {
    setShowModalClient(!showModalEditClient)
    dispatch(activeModalCustomer(false))
    showModalCreateClient(0)
  }

  const handleModalDelete = () => {
    setClienteMetodoEntrega(false)
    setFulfillmentPlan(null)
    setWarehousePickUp(false)
    setSelectedAddress(null)
    setDireccion(null)
    setDireccionState(null)
    setConfirmDeleteClient(true)
    dispatch({ type: COTIZACION_REMOVE_CLIENT })
  }

  const handleModalAdd = () => {
    setShowModalAddClient(!showModalAddClient)
  }

  const showMetodoEntrega = () => {
    setClienteMetodoEntrega(true)
    handleMetodoEntrega()
  }

  const handleGeneracion = () => {
    if (cart < 1) {
      setGeneracionError(true)
    }
  }
  const createQuote = useValidatePermission(VAPermissions.createQuote)
  useEffect(() => {
    if (confirmDeleteClient) {
      dispatch(cotizacionRemoveClient())
      dispatch(quitarDireccion())
      dispatch(addPriceList(undefined))
      setClienteCotizacion(null)
      setConfirmDeleteClient(false)
    }
  }, [confirmDeleteClient])

  useEffect(() => {
    if (!showModalClient && clientSelected.customerID !== '') {
      selectClient('', false, false)
    } else {
      setClienteMetodoEntrega(false)
    }
  }, [showModalClient])

  useEffect(() => {
    if (subtotal > 0) {
      dispatch(
        addAmounts({
          Quantity: parseFloat(quantity),
          Amount: FormatMoney(subtotal, false, 6),
          Tax: FormatMoney(itbms, false, 6),
          AmountWithTax: FormatMoney(subtotal + itbms, false, 6),
        })
      )
    }
  }, [subtotal])

  useEffect(() => {
    const productsWithPriceChange = lines.filter(
      line =>
        line?.NegotiatedReasonId &&
        line?.NegotiatedReasonId !== null &&
        line.NegotiatedReasonId !== ''
    )
    // remove approved products
    const productsWithPriceChangeFiltered = productsWithPriceChange.filter(
      line => line.AuthorizedPriceByName === null
    )
    setProductsChanged(productsWithPriceChangeFiltered)

    let totalTax = 0
    let totalPrice = 0
    let totalCost = 0
    let Quantity = 0
    let cost = 0
    cart.forEach(item => {
      totalTax += item.Tax
      totalPrice += item.Price * item.Quantity
      cost = item.Cost || item.AvgCost || 0.0
      totalCost += cost * item.Quantity
      Quantity = parseFloat(Quantity) + parseFloat(item?.Quantity)
    })
    setQuantiy(Quantity)
    setItbms(totalTax)
    setTotal(
      Number(FormatMoney(totalPrice, true, 2).replace(/,/g, '')) +
        Number(FormatMoney(totalTax, true, 2).replace(/,/g, ''))
    )
    setSubtotal(totalPrice)

    const totalMargin = marginTotal(totalCost, totalPrice)

    setTotalMargin(totalMargin)
  }, [cart])

  const onProductPriceChange = () =>
    isSupervisorUser || isCommercialSupervisorUser
      ? false
      : !hasPriceUnderCost && isCommercialSeller
      ? false
      : hasChangePriceApprove(cart)

  useEffect(() => {
    dispatch(cotizacionReStart())
    dispatch(cotizacionRemoveClient())
    dispatch(quitarDireccion())
  }, [])

  const cotizationType = [
    {
      id: '',
      name: t('PRODUCTS_T.COTIZATION_TYPE.CANCEL_NAME'),
      value: t('PRODUCTS_T.COTIZATION_TYPE.CANCEL_VALUE'),
      valueState: 'CANCEL',
      disabled: false,
      className: 'products-cart-button-tooltip',
    },
    {
      id: docHeaderStatusId.draft,
      name: t('PRODUCTS_T.COTIZATION_TYPE.DRAFT_NAME'),
      value: t('PRODUCTS_T.COTIZATION_TYPE.DRAFT_VALUE'),
      valueState: 'DRAFT',
      disabled: false,
      className: 'products-cart-button-tooltip',
    },
    {
      id: docHeaderStatusId.open,
      name: t('PRODUCTS_T.COTIZATION_TYPE.OPEN_NAME'),
      value: t('PRODUCTS_T.COTIZATION_TYPE.OPEN_VALUE'),
      valueState: 'OPEN',
      disabled: isCustomerUser || onProductPriceChange() || !createQuote,
      className: 'products-cart-button-tooltip',
    },
    {
      id: 'SALES_ORDER',
      name: t('PRODUCTS_T.COTIZATION_TYPE.SALES_ORDER_NAME'),
      value: '',
      valueState: '',
      disabled:
        isCustomerUser ||
        !customerId ||
        onProductPriceChange() ||
        !isUserAllowed,
      className: 'products-cart-button-tooltip',
    },
  ]

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const scrollToTop = () => {
    scroll.scrollToTop({
      delay: 0,
      smooth: 'linear',
      duration: 1,
    })
  }
  const resetAddressStatus = () => {
    setSelectedAddress(null)
    setDireccion(null)
    setDireccionState(null)
    setWarehousePickUp(false)
    dispatch(quitarDireccion())
    setFulfillmentPlan(null)
  }
  // set default Warehouse to quotation
  const setDefaultWarehouseToQuotation = () => {
    const warehouse = {
      WarehouseId: company?.WarehouseId,
      WarehouseDescription: company?.WarehouseDescription,
      WarehouseAddress: company?.WarehouseAddress,
      WarehouseCode: company?.WarehouseCode,
      WarehouseName: company?.WarehouseName,
      Description: company?.WarehouseDescription,
    }
    dispatch(agregarSucursal(warehouse))
    setDireccion(false)
    setSelectedAddress(warehouse)
    setWarehousePickUp(true)
  }

  return (
    <Container className="container-shopping-cart">
      <Row style={{ padding: 0, paddingTop: 10 }}>
        <Col className="productos-cotizacion-right">
          <div className="products-cart">
            {isLoadingProduct ? (
              <MiniLoading />
            ) : cart?.length > 0 ? (
              <>
                <h2 className="container-summary">{'Totales'}</h2>
                <div className="container-summary">
                  <div className="product-cart-totals">
                    <div className="product-cart-total-text">
                      <p>{t('PRODUCTS_T.SUBTOTAL_LABEL')}</p>
                      <p>{t('PRODUCTS_T.ITBMS_LABEL')}</p>
                      <p>
                        <strong>{t('PRODUCTS_T.TOTAL_LABEL')}</strong>
                      </p>
                      {seeMargenProd && (
                        <p>{t('PRODUCTS_T.TOTAL_MARGIN_LABEL')}</p>
                      )}
                    </div>
                    <div className="product-summary-cart-total-numbers">
                      <p>{'$' + FormatMoney(subtotal, true, 2)}</p>
                      <p>{'$' + FormatMoney(itbms, true, 2)}</p>
                      <p>
                        <strong>{'$' + FormatMoney(total, true, 2)}</strong>
                      </p>
                      {seeMargenProd && (
                        <CostMarginVisibility
                          seeMargenProd={seeMargenProd}
                          simple
                          isMargin
                          margin={totalMargin.toFixed(2)}
                          secondaryColor
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="product-cart-inner">
                <h2>{t('PRODUCTS_T.ADD_PRODUCTS_LABEL')}</h2>
                <Cart style={{ fontSize: '20px' }} />
              </div>
            )}
          </div>

          <div
            className={
              clienteMetodoEntrega !== null
                ? 'customer-cotizacion-visible'
                : 'customer-cotizacion-none'
            }>
            <div className="customer-cart">
              <div className="customer-cart-items">
                <h4>Cliente: {client}</h4>
                <h4>
                  {t('PRODUCTS_T.EMAIL_LABEL')}{' '}
                  {clientEmail || t('PRODUCTS_T.NO_INFORMATION_LABEL')}
                </h4>
              </div>
              <div className="icons">
                <div
                  onClick={() => {
                    clientToEdit(clienteMetodoEntrega?.CustomerID)
                  }}>
                  <PencilSquare />
                </div>
                {clienteMetodoEntrega?.CustomerID && (
                  <div
                    onClick={() => {
                      setModalInfoClient(true)
                    }}>
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ color: '#11D29E' }}
                    />
                  </div>
                )}
                <div onClick={() => setShowModalDeleteClient(true)}>
                  <TrashFill style={{ color: '#fb5c3c' }} />
                </div>
              </div>
            </div>
          </div>

          {(selectedAddress || fulfillmentPlan) && (
            <div
              className="shopping-address-display"
              style={{
                display:
                  clienteMetodoEntrega !== null && direccion !== null
                    ? 'flex'
                    : 'none',
              }}>
              {!direccion && (
                <h2>
                  <span className="address-bold">
                    {t('PRODUCTS_T.RECEIVE_ON_SITE_LABEL')}
                  </span>
                  {' ' +
                    toTitleCase(
                      direccionState?.WarehouseDescription ||
                        selectedAddress?.Description
                    )}
                </h2>
              )}
              {direccion && direccionState && (
                <div>
                  <h2 className="complete-address">
                    <span className="address-bold">
                      {t('PRODUCTS_T.COUNTRY_LABEL')}{' '}
                    </span>
                    {direccionState.Address_CountryName
                      ? direccionState.Address_CountryName
                      : t('PRODUCTS_T.NO_INFORMATION_LABEL')}
                  </h2>
                  <h2 className="complete-address">
                    <span className="address-bold">
                      {t('PRODUCTS_T.PROVINCE_NAME')}{' '}
                    </span>
                    {direccionState.Address_ProvinceName
                      ? direccionState.Address_ProvinceName
                      : t('PRODUCTS_T.NO_INFORMATION_LABEL')}
                  </h2>
                  <h2 className="complete-address">
                    <span className="address-bold">
                      {t('PRODUCTS_T.DISTRICT_LABEL')}{' '}
                    </span>
                    {direccionState.Address_DistrictName
                      ? direccionState.Address_DistrictName
                      : t('PRODUCTS_T.NO_INFORMATION_LABEL')}
                  </h2>
                  <h2 className="complete-address">
                    <span className="address-bold">
                      {t('PRODUCTS_T.STATE_LABEL')}{' '}
                    </span>
                    {direccionState.Address_TownshipName
                      ? direccionState.Address_TownshipName
                      : t('PRODUCTS_T.NO_INFORMATION_LABEL')}
                  </h2>
                  <h2 className="complete-address">
                    <span className="address-bold">
                      {t('PRODUCTS_T.STREET_LABEL')}{' '}
                    </span>
                    {direccionState.Address_Street
                      ? direccionState.Address_Street
                      : t('PRODUCTS_T.NO_INFORMATION_LABEL')}
                  </h2>
                  <h2 className="complete-address">
                    <span className="address-bold">
                      {t('PRODUCTS_T.ALIAS_LABEL')}{' '}
                    </span>
                    {direccionState.Address_AddressDetail
                      ? direccionState.Address_AddressDetail
                      : t('PRODUCTS_T.NO_INFORMATION_LABEL')}
                  </h2>
                </div>
              )}
              {direccion && !direccionState && (
                <div>
                  <h2 className="complete-address">
                    <span className="address-bold">
                      {t('PRODUCTS_T.COUNTRY_LABEL')}{' '}
                    </span>
                    {selectedAddress?.AddressInfo?.Country?.Name
                      ? selectedAddress?.AddressInfo?.Country?.Name
                      : t('PRODUCTS_T.NO_INFORMATION_LABEL')}
                  </h2>
                  <h2 className="complete-address">
                    <span className="address-bold">
                      {t('PRODUCTS_T.PROVINCE_NAME')}{' '}
                    </span>
                    {selectedAddress?.AddressInfo?.Province?.Name
                      ? selectedAddress?.AddressInfo?.Province?.Name
                      : t('PRODUCTS_T.NO_INFORMATION_LABEL')}
                  </h2>
                  <h2 className="complete-address">
                    <span className="address-bold">
                      {t('PRODUCTS_T.DISTRICT_LABEL')}{' '}
                    </span>
                    {selectedAddress?.AddressInfo?.District?.Name
                      ? selectedAddress?.AddressInfo?.District?.Name
                      : t('PRODUCTS_T.NO_INFORMATION_LABEL')}
                  </h2>
                  <h2 className="complete-address">
                    <span className="address-bold">
                      {t('PRODUCTS_T.STATE_LABEL')}{' '}
                    </span>
                    {selectedAddress?.AddressInfo?.Township?.Name
                      ? selectedAddress?.AddressInfo?.Township?.Name
                      : t('PRODUCTS_T.NO_INFORMATION_LABEL')}
                  </h2>
                  <h2 className="complete-address">
                    <span className="address-bold">
                      {t('PRODUCTS_T.STREET_LABEL')}{' '}
                    </span>
                    {selectedAddress?.AddressInfo?.Street
                      ? selectedAddress?.AddressInfo?.Street
                      : t('PRODUCTS_T.NO_INFORMATION_LABEL')}
                  </h2>
                  <h2 className="complete-address">
                    <span className="address-bold">
                      {t('PRODUCTS_T.ALIAS_LABEL')}{' '}
                    </span>
                    {selectedAddress?.AddressInfo?.Name ||
                    selectedAddress?.AddressInfo?.AddressDetail
                      ? selectedAddress?.AddressInfo?.Name ||
                        selectedAddress?.AddressInfo?.AddressDetail
                      : t('PRODUCTS_T.NO_INFORMATION_LABEL')}
                  </h2>
                </div>
              )}
              <div className="icons">
                <div onClick={() => editarCotizacion()}>
                  <PencilSquare />
                </div>
                <div
                  onClick={() => {
                    setSelectedAddress(null)
                    setDireccion(null)
                    setDireccionState(null)
                    setWarehousePickUp(false)
                    dispatch(quitarDireccion())
                    setFulfillmentPlan(null)
                  }}>
                  <TrashFill />
                </div>
              </div>
            </div>
          )}
          <Row
            style={{
              marginTop: '10px',
            }}>
            <div className="btn-container">
              <button
                className="btn-action-create-quotationV1"
                onClick={handleShowModalClient}
                disabled={!!client}>
                <div className="products-cart-button-text">
                  <p>{t('PRODUCTS_T.SELECT_OR_CREATE_CLIENT_LABEL')}</p>
                </div>
              </button>
              <button
                className="btn-action-create-quotationV1"
                onClick={() => {
                  showMetodoEntrega()
                  scrollToTop()
                }}
                disabled={
                  !client ||
                  selectedAddress ||
                  address !== null ||
                  warehousePickUp
                }>
                <div className="products-cart-button-text">
                  <p>{t('PRODUCTS_T.SELECT_DELIVERY_METHOD_LABEL')}</p>
                </div>
              </button>

              <ClickAwayListener onClickAway={() => handleTooltipClose()}>
                <>
                  <LightTooltip
                    title={cotizationType
                      .filter(item => !item.disabled)
                      .map((item, index) => {
                        if (
                          (!isUserAllowed && item.id === 'SALES_ORDER') ||
                          (!createQuote && item.id === docHeaderStatusId.open)
                        ) {
                          return null
                        }

                        return (
                          <button
                            disabled={item.disabled}
                            key={index}
                            className={item.className}
                            onClick={() => {
                              if (cart.length === 0) {
                                handleGeneracion()
                              } else {
                                if (item.id) {
                                  dispatch(
                                    guardarTypeDocument({
                                      HeaderStatusId: item.id,
                                      DocHeaderStatusDescription: item.value,
                                      DocHeaderStatusAction: item.valueState,
                                    })
                                  )
                                  if (!direccion) {
                                    const modifiedLines = cart.map(element => {
                                      const l = {
                                        ...element,
                                        FulfillmentPlanId:
                                          element.FulfillmentPlanId,
                                        FulfillmentPlanDescription:
                                          element.FulfillmentPlanDescription,
                                        WarehouseId:
                                          selectedAddress?.WarehouseId,
                                        WarehouseDescription:
                                          selectedAddress?.Description,
                                        WarehouseAddress:
                                          selectedAddress?.WarehouseAddress,
                                        WarehouseCode: selectedAddress?.Code,
                                      }
                                      return l
                                    })
                                    dispatch(addSalesAgentFullName(userName))
                                    dispatch(agregarPDV(modifiedLines))
                                    dispatch(
                                      agregarCotizacion({
                                        Lines: modifiedLines,
                                      })
                                    )
                                    if (
                                      canChangePrice &&
                                      cotizacion.NegotiatedReasonId
                                    ) {
                                      if (isCommercialSeller) {
                                        !hasPriceUnderCost &&
                                          handlePriceChange(modifiedLines)
                                      } else {
                                        handlePriceChange(modifiedLines)
                                      }
                                    }
                                  } else {
                                    const modifiedLines = cart.map(element => {
                                      const l = {
                                        ...element,
                                        FulfillmentPlanId:
                                          element.FulfillmentPlanId,
                                        FulfillmentPlanDescription:
                                          element.FulfillmentPlanDescription,
                                        WarehouseId:
                                          selectedAddress?.WarehouseId,
                                        WarehouseDescription:
                                          selectedAddress?.Description,
                                        WarehouseAddress:
                                          selectedAddress?.WarehouseAddress,
                                        WarehouseCode: selectedAddress?.Code,
                                      }
                                      return l
                                    })
                                    dispatch(addSalesAgentFullName(userName))
                                    dispatch(agregarPDV(modifiedLines))
                                    dispatch(
                                      agregarCotizacion({
                                        Lines: modifiedLines,
                                      })
                                    )
                                    if (
                                      canChangePrice &&
                                      cotizacion.NegotiatedReasonId
                                    ) {
                                      if (isCommercialSeller) {
                                        !hasPriceUnderCost &&
                                          handlePriceChange(modifiedLines)
                                      } else {
                                        handlePriceChange(modifiedLines)
                                      }
                                    }
                                  }
                                  dispatch(mostrarDescuento(true))
                                  if (item.id === 'SALES_ORDER') {
                                    history.push(
                                      '/generar_cotizacion/sales_order'
                                    )
                                  } else {
                                    history.push(ROUTES.generateQuote)
                                  }
                                } else {
                                  setOpen(false)
                                }
                              }
                            }}>
                            {item.name}
                          </button>
                        )
                      })}
                    PopperProps={{
                      disablePortal: true,
                    }}
                    placement="top"
                    arrow={true}
                    open={open}
                    onClose={handleTooltipClose}
                    disableFocusListener
                    disableTouchListener>
                    <button
                      className="btn-action-create-quotationV1"
                      onClick={() => {
                        setOpen(true)
                      }}
                      disabled={
                        !!(
                          !client ||
                          (!selectedAddress &&
                            address === null &&
                            !warehousePickUp)
                        )
                      }>
                      <p>{t('PRODUCTS_T.CREATE_QUOTATION_LABEL')}</p>
                    </button>
                  </LightTooltip>
                </>
              </ClickAwayListener>
            </div>
          </Row>
        </Col>
      </Row>

      {/* Recordatorio para agregar productos */}
      <Modal show={generacionError} centered cla>
        <div className="box-generacion">
          <div className="box-generacion-header">
            <img
              src={No}
              alt="cerrar-modal"
              onClick={() => setGeneracionError(false)}
            />
          </div>
          <div className="box-generacion-body">
            <Cart style={{ fontSize: '20px' }} />
            <p>{t('PRODUCTS_T.ADD_PRODUCTS_CREATE_QUOTATION')}</p>
          </div>
        </div>
      </Modal>

      {/* MODAL PRINCIPAL VIEW CLIENT LIST */}
      <Modal
        show={showModalClient}
        className="modal-clase-choose-customer"
        backdrop={false}
        fullscreen={true}>
        <PrincipalModal
          handleShowModalClient={handleShowModalClient}
          handleModalAdd={handleModalAdd}
          setClienteCotizacion={setClienteCotizacion}
          resetAddressStatus={resetAddressStatus}
          setDefaultWarehouseToQuotation={setDefaultWarehouseToQuotation}
        />
      </Modal>

      <ModalAlertCart preQuote={false} shopping={true} saleProduct={false} />

      {/* MODAL CONFIRM DELETE CLIENT */}
      <Modal show={showModalDeleteClient} centered>
        <EliminarCliente
          setShowModalDeleteClient={() => setShowModalDeleteClient(false)}
          handleConfirmDeleteClient={handleModalDelete}
        />
      </Modal>

      <div className={modalInfoClient ? 'moda-background' : ''}>
        <CustomerInfo
          inModal={true}
          handleOpen={setModalInfoClient}
          open={modalInfoClient}
          customerId={customerId}
          setCustomerId={clienteMetodoEntrega?.CustomerID}
        />
      </div>

      {/* MODAL SUCCESS ADD CLIENT */}
      <Modal show={showModalAddClient}>
        <AgregarCliente handleModalAdd={handleModalAdd} />
      </Modal>

      {/* EDIT CLIENT */}
      <Modal show={showModalEditClient}>
        <div className="modal-box-client">
          <div className="restore-container">
            <div className="modal-header-restore">
              <h2>{t('PRODUCTS_T.EDIT_CLIENT_LABEL')}</h2>
              <img
                src={No}
                alt="close"
                onClick={() => {
                  setShowModalEditClient(false)
                }}
              />
            </div>
            <CrearCliente />
          </div>
        </div>
      </Modal>
    </Container>
  )
}

export default CalculatorSummaryCart
