import React from 'react'
import './departments.css'
import { Col, Row } from 'react-bootstrap'
import depts from '../../Utils/DeptImgHandler'
// import { useDispatch } from "react-redux";

function Departaments(props) {
  // const dispatch = useDispatch();
  const { handleDepartmentSearch } = props
  const handleSearch = (boolean, filter) => {
    handleDepartmentSearch(boolean, filter)
    // dispatch(addCategories(filter));
  }
  return (
    <Row className="g-2" style={{ padding: '0px 10px' }}>
      {depts.map(dept => {
        const facetFilter = {
          key: 'department',
          value: `Department:${dept.title}`,
          name: dept.title,
        }
        return (
          <Col xl={2} lg={4} md={6} sm={12} key={dept.title}>
            <div
              className="card"
              onClick={() => handleSearch(false, facetFilter)}>
              <div className="card-image " style={{ padding: '10px' }}>
                <img
                  className="filter-white img-fluid"
                  src={dept.image}
                  alt="acabados"
                />
              </div>
              <div className="card-text">
                <h3>{dept.title}</h3>
              </div>
            </div>
          </Col>
        )
      })}
    </Row>
  )
}

export default Departaments
