import FloatingCalculator from 'Components/FloatingCalculator'
import { t } from 'i18next'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import no from '../../Assets/Images/Login/no.svg'
import './calculatorModal.css'

const CalculatorModal = ({ openCalc, handleModal, isModal }) => {
  const [changeModal, setChangeModal] = useState(false)
  const [calcType, setCalcType] = useState('')

  return (
    <>
      {isModal ? (
        <Modal
          show={openCalc}
          onHide={handleModal}
          centered
          size={changeModal ? 'md' : 'lg'}
          container={document.body}
          style={{ marginTop: '37px' }}>
          <div>
            {!changeModal && (
              <div className="topbar-title">
                <h2 style={{ fontWeight: 600 }}>
                  {t('MODAL_CALCULATOR_T.CALCULATOR_TITLE')}
                </h2>
                <img
                  src={no}
                  alt="close"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleModal()
                    setCalcType('')
                  }}
                />
              </div>
            )}
          </div>
          <Modal.Body
            style={{
              width: 'auto',
              maxWidth: '90vw',
              maxHeight: '80vh',
              overflowY: 'auto',
            }}>
            <FloatingCalculator
              setChangeModal={setChangeModal}
              setCalcType={setCalcType}
              calcType={calcType}
            />
          </Modal.Body>
        </Modal>
      ) : (
        <FloatingCalculator
          setChangeModal={setChangeModal}
          setCalcType={setCalcType}
          calcType={calcType}
        />
      )}
    </>
  )
}

export default CalculatorModal
