import { updateAddressService } from 'redux/services/clientsServices'

export const useValidateLocation = () => {
  const getGeocode = async address => {
    const geocoder = new window.google.maps.Geocoder()

    return new Promise((resolve, reject) => {
      geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK') {
          const location = results?.[0]?.geometry?.location
          resolve({
            lat: location?.lat() || null,
            lng: location?.lng() || null,
          })
        } else {
          console.error(
            'Geocode was not successful for the following reason:',
            status
          )
          resolve({ lat: null, lng: null })
        }
      })
    })
  }

  const updateAddressWithLatLng = async ({ lat, lng, addressData }) => {
    const payload = {
      AddressId: addressData?.AddressId,
      customerId: addressData?.CustomerId,
      addressTypeId: '90ACBCF4-BE83-4F03-8B5E-5760FC92B59B',
      countryId: addressData?.CountryId,
      provinceId: addressData?.ProvinceId,
      districtId: addressData?.DistrictId,
      townshipId: addressData?.TownshipId,
      street: addressData?.street,
      buildingTypeId: '18272DEA-60DB-4F71-B292-1FD44E3A2B30',
      addressDetail: addressData?.AddressDetail,
      lastModifiedBy: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      name: addressData?.Name,
      phoneNumber: addressData?.PhoneNumber || null,
      latitude: lat || null,
      longitude: lng || null,
    }

    await updateAddressService(payload)
  }

  const checkSelectedLocation = async location => {
    if (location?.AddressInfo?.Latitude && location?.AddressInfo?.Longitude)
      return

    const addressData = {
      street: location.AddressInfo.Street || '',
      city: location.AddressInfo?.Township?.Name || '',
      state: location.AddressInfo?.Province?.Name || '',
      district: location.AddressInfo?.District?.Name || '',
      country: location.AddressInfo?.Country?.Name || '',
      AddressDetail: location.AddressInfo?.AddressDetail || '',
      CustomerId: location?.CustomerId || '',
      CountryId: location.AddressInfo?.CountryId || '',
      ProvinceId: location.AddressInfo?.ProvinceId || '',
      DistrictId: location.AddressInfo?.DistrictId || '',
      TownshipId: location.AddressInfo?.TownshipId || '',
      Name: location.AddressInfo?.Name || '',
      PhoneNumber: location.AddressInfo?.PhoneNumber || '',
      AddressId: location.AddressInfo?.AddressId || '',
    }
    const address = `${addressData.street}, ${addressData.city}, ${addressData.state}, ${addressData.district}, ${addressData.country}`
    const { lat, lng } = await getGeocode(address)
    if (!lat || !lng) return
    await updateAddressWithLatLng({ lat, lng, addressData })
  }

  return { checkSelectedLocation }
}
