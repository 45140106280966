/* eslint-disable no-prototype-builtins */
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { getCustomerGroupService } from 'redux/services/clientsServices'
import { companyTypes, personTypes } from './constants'

export const DisplayCustomerGroup = ({
  formik,
  setValueStateSelectCustomerGroup,
  valueStateSelectCustomerGroup,
  type,
}) => {
  const [customerGroup, setCustomerGroup] = useState([])
  const [showStateSelectCustomerGroup, setShowStateSelectCustomerGroup] =
    useState(false)

  const hasGeneralGroupError = formik.errors.hasOwnProperty('generalGroup')

  useEffect(() => {
    const listOfTypes = type === 'natural' ? personTypes : companyTypes
    const fetchGroupClient = async () => {
      try {
        const {
          data: { Data },
        } = await getCustomerGroupService({
          CustomerGroupId: null,
          SearchText: null,
          PageSize: 20,
          PageNumber: 1,
        })
        if (Data?.length) {
          const types = Data.filter(t => listOfTypes.includes(t.ExternalCode))
          setCustomerGroup(types)
        }
      } catch (error) {
        console.error('fetchGroupClientError ->', error)
      }
    }
    fetchGroupClient()
  }, [])

  return (
    <>
      <div className="no-margin-nopadding generic-address-search-filter list-prices-component">
        <button
          type="button"
          id="generic-address-filter-content-list-price"
          className="generic-address-filter-content-list-price"
          onClick={() =>
            setShowStateSelectCustomerGroup(!showStateSelectCustomerGroup)
          }>
          {valueStateSelectCustomerGroup &&
          valueStateSelectCustomerGroup.Description !== '' ? (
            <p className={''}>{valueStateSelectCustomerGroup.Description}</p>
          ) : (
            <>
              <p className={'generic-price-list-label-text-unselect'}>
                Tipo de cliente*
              </p>
            </>
          )}
          <FontAwesomeIcon
            icon={faChevronDown}
            className="generic-list-price-down-icon"
          />
        </button>
        <ul
          className={
            showStateSelectCustomerGroup
              ? 'generic-address-filter-options ul-price-list ul-filter-option-multiple'
              : 'generic-address-filter-options-hidden'
          }
          onMouseLeave={e => {
            setShowStateSelectCustomerGroup(false)
            if (
              valueStateSelectCustomerGroup?.Description === '' ||
              valueStateSelectCustomerGroup?.Description === 'SELECCIONAR' ||
              valueStateSelectCustomerGroup === null
            ) {
              formik.values.generalGroup[0].customerGroupId = ''
              formik.setFieldTouched('generalGroup', true)
              formik.handleChange(e)
            } else {
              if (formik.errors && formik.errors?.generalGroup) {
                const { errors, setErrors } = formik
                const updatedErrors = { ...errors }
                delete updatedErrors.generalGroup
                setErrors(updatedErrors)
              }
            }
          }}>
          {customerGroup?.map((item, index) => {
            return (
              <li
                className="customer-filter-option-multiple"
                id="generalGroup[0].customerGroupId"
                key={index}>
                <div
                  name="generalGroup[0].customerGroupId"
                  id="generalGroup[0].customerGroupId"
                  onClick={e => {
                    setShowStateSelectCustomerGroup(false)
                    setValueStateSelectCustomerGroup({
                      Description: item.Description,
                      customerGroupId: item.CustomerGroupId?.toUpperCase(),
                    })
                    formik.values.generalGroup = [
                      {
                        customerGroupId: item.CustomerGroupId?.toUpperCase(),
                      },
                    ]
                  }}>
                  {item.Description}
                </div>
              </li>
            )
          })}
        </ul>
      </div>

      {hasGeneralGroupError && (
        <div className="input-feedback">
          <strong>{formik.errors.generalGroup?.toString()}</strong>
        </div>
      )}
    </>
  )
}
