import { t } from 'i18next'
import CommonSearchDocument from 'Components/CommonSearchDocument/CommonSearchDocument'

function SearchCustomer({
  inModal,
  setShowModalClienteContado,
  setClienteCotizacion,
  handleModal,
  resetAddressStatus,
  setDefaultWarehouseToQuotation,
}) {
  const objColumns = [
    {
      Header: t('SEARCH_CLIENT_T.CLIENT_NAME'),
      accessor: t('SEARCH_CLIENT_T.CLIENT_NAME_ACCESOR'),
    },
    {
      Header: t('SEARCH_CLIENT_T.CLIENT_CODE_LABEL'),
      accessor: t('SEARCH_CLIENT_T.CLIENT_CODE_ACCESOR'),
    },
    {
      Header: t('SEARCH_CLIENT_T.IDENTIF_NUMBER_LABEL'),
      accessor: t('SEARCH_CLIENT_T.IDENTIF_NUMBER_ACCESOR'),
    },
    {
      Header: t('SEARCH_CLIENT_T.PHONE_LABEL'),
      accessor: t('SEARCH_CLIENT_T.PHONE_ACCESOR'),
    },
    {
      Header: t('SEARCH_CLIENT_T.EMAIL_LABEL'),
      accessor: t('SEARCH_CLIENT_T.EMAIL_ACCESOR'),
    },
    {
      Header: t('SEARCH_CLIENT_T.PRICE_LIST_LABEL'),
      accessor: t('SEARCH_CLIENT_T.PRICE_LIST_ACCESOR'),
    },
    {
      Header: t('SEARCH_CLIENT_T.CLIENT_TYPE_LABEL'),
      accessor: t('SEARCH_CLIENT_T.CLIENT_TYPE_ACCESOR'),
    },
  ]

  const setTableData = data => {
    const clients = data?.length
      ? data.map(client => {
          let priceList = ''
          if (client?.PriceList) {
            priceList = client?.PriceList?.filter(list => {
              return list.DefaultList === true
            })[0]?.PriceListName
            if (priceList === '' || priceList === undefined) {
              priceList = client?.PriceList[0]?.PriceListName
            }
          }
          return {
            CustomerID: client.CustomerId,
            'Nombre del cliente': client?.FullName,
            'Código del cliente': client?.SystemCode || t('UNKNOWN'),
            Identificador: client?.NumberId,
            Teléfono: client?.PhoneNumber,
            Email: client?.Email,
            'Tipo de cliente': client?.CustomerTypeName,
            'Lista de precios': priceList,
          }
        })
      : []
    return clients
  }

  return CommonSearchDocument({
    title: 'SEARCH_CLIENT_T.CREATE_CLIENT_TITLE',
    objColumns,
    setTableData,
    placeholder: 'ACTION_TABLE_T.ADD_CLIENT_PLACEHOLDER',
    isCustomer: true,
    inModal,
    setShowModalClienteContado,
    setClienteCotizacion,
    handleModal,
    resetAddressStatus,
    setDefaultWarehouseToQuotation,
  })
}

export default SearchCustomer
