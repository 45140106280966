import { aludraApi } from 'Api/axios'

const mdl11 = {
  counterImpressions: 'mdl11/CountOfferPriceImpressions',
  printedProducts: 'mdl11/SearchPrintPrice',
  productsToPrint: 'mdl11/SearchPrintPriceTemplate',
  updateProductPrinted: 'mdl11/AddUpdatePricePrint',
}
const mdl14 = {
  cmfCredit: 'mdl14/cmf/getCreditSimulated',
}

export const getCounterImpressionsService = async payload => {
  const { data } = await aludraApi.post(mdl11.counterImpressions, payload)
  const response = data?.Data || []
  return response
}

export const getPrintedProductsService = async payload => {
  const { data } = await aludraApi.post(mdl11.printedProducts, payload)
  const response = data?.Data || []
  return response
}

export const getProductsToPrintService = async payload => {
  const { data } = await aludraApi.post(mdl11.productsToPrint, payload)
  const response = data?.Data || []
  return response
}

export const updateProductPrinted = async payload => {
  const { data } = await aludraApi.post(mdl11.updateProductPrinted, payload)
  const response = data?.Data || []
  return response
}

export const getCmfCreditService = async payload => {
  const { data } = await aludraApi.post(mdl14.cmfCredit, payload)
  const response = data?.data || []
  return response
}
