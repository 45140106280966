import React, { useState, useRef, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import './verticalNav.css'
import { ROUTES, elements } from '../../Utils/constants'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import CalculatorModal from 'Components/CalculatorModal'

function VerticalNav(props) {
  const location = useLocation()
  const [openCalc, setOpenCalc] = useState(false)
  const revealRefs = useRef([])
  const [menuOpenItem, setMenuOpenItem] = useState([])
  revealRefs.current = []
  const isCustomer = useSelector(state => state.login.isCustomer)

  const handleModal = () => {
    setOpenCalc(!openCalc)
  }

  const textCheck = () => {
    if (props.isOpen) {
      revealRefs.current.map(element => {
        return (element.style.display = 'block')
      })
    } else {
      revealRefs.current.map(element => {
        return (element.style.display = 'none')
      })
    }
  }

  const addToRef = el => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el)
    }
  }

  const handleMenuOpenItem = (e, item) => {
    e.preventDefault()
    if (menuOpenItem.includes(item)) {
      setMenuOpenItem(menuOpenItem.filter(i => i !== item))
    } else {
      setMenuOpenItem([...menuOpenItem, item])
    }
  }

  useEffect(() => {
    textCheck()
  }, [props.isOpen])

  // get breakpoints frpm material ui

  return (
    <div data-testid="vertical-nav-wrapper">
      <Box
        className="vertical-navbar"
        component="nav"
        sx={{
          width: {
            xs: props.isOpen ? '200px' : '0px',
            sm: props.drawerWidth,
          },
          display: { xs: props.isOpen ? 'block' : 'none', sm: 'block' },
        }}>
        <ul className="nav-icons-container">
          <Box
            display="flex"
            justifyContent={props.isOpen ? 'flex-end' : 'center'}
            onClick={props.handleDrawer}
            className="v-nav-icon">
            <FontAwesomeIcon
              className="sp open"
              icon={props.isOpen ? faAnglesLeft : faAnglesRight}
            />
          </Box>
          {elements.map(element => {
            if (isCustomer && element.hiddenByClient) return null
            if (element.title === 'Impresión etiquetas') return null
            return element.children && element.children.length > 0 ? (
              <div
                key={element.title}
                className={
                  menuOpenItem.includes(element.title) && props.isOpen
                    ? 'v-nav-icon v-nav-icon-parent'
                    : 'v-nav-icon'
                }
                onClick={e => handleMenuOpenItem(e, element.title)}
                style={{
                  backgroundColor: element.children.some(
                    child => child.link === location.pathname
                  )
                    ? '#022767'
                    : 'transparent',
                }}>
                <li
                  className={
                    props.isOpen
                      ? 'v-nav-icon-content-open'
                      : 'v-nav-icon-content'
                  }>
                  <img
                    src={element.image}
                    alt="nav"
                    style={{
                      filter: element.children.some(
                        child => child.link === location.pathname
                      )
                        ? 'invert(53%) sepia(86%) saturate(459%) hue-rotate(140deg) brightness(107%) contrast(80%)'
                        : 'grayscale(100%)',
                    }}
                    onClick={props.handleDrawer}
                  />
                  <p
                    className="link-text"
                    ref={addToRef}
                    style={{
                      color:
                        element.children.some(
                          child => child.link === location.pathname
                        ) && '#21C5DF',
                    }}>
                    {element.title}
                  </p>
                </li>
                <ul
                  className="v-nav-icon-children"
                  style={{
                    // check if the item is open and if is included in the menuOpenItem array
                    display:
                      menuOpenItem.includes(element.title) && props.isOpen
                        ? 'block'
                        : 'none',
                  }}>
                  {element.children.map(child => {
                    return (
                      <Link
                        key={child.title}
                        to={child.link}
                        className="v-nav-icon">
                        <li
                          className={
                            props.isOpen
                              ? 'v-nav-icon-content-open'
                              : 'v-nav-icon-content'
                          }>
                          <p
                            className="link-text"
                            ref={addToRef}
                            style={{
                              color:
                                child.link === location.pathname && '#21C5DF',
                            }}>
                            {child.title}
                          </p>
                        </li>
                      </Link>
                    )
                  })}
                </ul>
              </div>
            ) : element.link ? (
              <Link
                key={element.title}
                to={element.link}
                className="v-nav-icon"
                style={{
                  backgroundColor:
                    element.link === location.pathname ||
                    (element.link.pathname === ROUTES.createQuote &&
                      location.pathname === ROUTES.createQuote)
                      ? '#022767'
                      : 'transparent',
                }}>
                <li
                  className={
                    props.isOpen
                      ? 'v-nav-icon-content-open'
                      : 'v-nav-icon-content'
                  }>
                  <img
                    src={element.image}
                    alt="nav"
                    style={{
                      filter:
                        element.link === location.pathname ||
                        (element.link.pathname === ROUTES.createQuote &&
                          location.pathname === ROUTES.createQuote)
                          ? 'invert(53%) sepia(86%) saturate(459%) hue-rotate(140deg) brightness(107%) contrast(80%)'
                          : 'grayscale(100%)',
                    }}
                  />
                  <p
                    className="link-text"
                    ref={addToRef}
                    style={{
                      color:
                        (element.link === location.pathname && '#21C5DF') ||
                        (element.link.pathname === ROUTES.createQuote &&
                          location.pathname === ROUTES.createQuote &&
                          '#21C5DF'),
                    }}>
                    {element.title}
                  </p>
                  {element.title === 'Impresión etiquetas' &&
                  props.isOpen &&
                  (menuOpenItem.length > 0 || props.isOpen) ? (
                    <p className="badge-color">Nuevo</p>
                  ) : null}
                  {element.title === 'Reportes' &&
                  (menuOpenItem.length > 0 || props.isOpen) ? (
                    <p
                      className="title-new"
                      style={{
                        color: openCalc && '#21C5DF',
                      }}>
                      Nuevo
                    </p>
                  ) : null}
                </li>
              </Link>
            ) : (
              <div
                key={element.title}
                to={element.link}
                className="v-nav-icon"
                style={{
                  backgroundColor: openCalc ? '#022767' : 'transparent',
                }}
                onClick={handleModal}>
                <li
                  className={
                    props.isOpen
                      ? 'v-nav-icon-content-open'
                      : 'v-nav-icon-content'
                  }>
                  <img
                    src={element.image}
                    alt="nav"
                    style={{
                      filter: openCalc
                        ? 'invert(53%) sepia(86%) saturate(459%) hue-rotate(140deg) brightness(107%) contrast(80%)'
                        : 'grayscale(100%)',
                    }}
                  />
                  <p
                    className="link-text"
                    ref={addToRef}
                    style={{
                      color: openCalc && '#21C5DF',
                    }}>
                    {element.title}
                  </p>
                </li>
              </div>
            )
          })}
        </ul>
      </Box>
      <CalculatorModal
        openCalc={openCalc}
        handleModal={handleModal}
        isModal={true}
      />
    </div>
  )
}

export default VerticalNav
