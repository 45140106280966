/** @format */

import { combineReducers } from 'redux'
import loginReducer from './loginReducer'
import cotizacionReducer from './cotizacionReducer'
import productReducer from './productReducer'
import companyReducer from './company.reducer'
import cartReducer from './cart.reducer'
import listReducer from './list.reducer'
import sellOrderReducer from './sellOrderReducer'
import catalogReducer from './catalogReducer'
import materialReducer from './materialsCalculatorReducer'

export default combineReducers({
  login: loginReducer,
  cotizacion: cotizacionReducer,
  products: productReducer,
  company: companyReducer,
  cart: cartReducer,
  list: listReducer,
  sellOrder: sellOrderReducer,
  catalog: catalogReducer,
  material: materialReducer,
})
