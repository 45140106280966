/* eslint-disable no-case-declarations */
import { docHeaderStatusId, documentTypeId } from 'Utils/constants'
import {
  COTIZACION_INIT,
  COTIZACION_UPDATE,
  COTIZACION_SUCCESS,
  COTIZACION_ERROR,
  COTIZACION_ADD_PRODUCT,
  COTIZACION_ADD_AMOUNT,
  COTIZACION_ADD_CLIENT,
  COTIZACION_REMOVE_CLIENT,
  COTIZACION_ADD_ADDRESS,
  COTIZACION_ADD_PDV,
  COTIZACION_QUITAR_ADDRESS,
  COTIZACION_CAMBIO_TIPO,
  MODIFICAR_CLIENTE_CONTADO,
  COTIZACION_GUARDAR_TYPO_DOCUMENT,
  COTIZACION_REMOVE,
  COTIZACION_ELIMINAR_PRODUCTO,
  COTIZACION_ADD_COMMENT,
  ADD_PRICE_CHANGE,
  COTIZACION_ADD_AMOUNT_EDIT,
  COTIZACION_ADD_SUCURSAL,
  ADD_COMPANY_COTIZACION,
  APPROVE_PRICE_CHANGE,
  ADD_SALES_AGENT,
  ADD_CHANNEL,
  MOSTRAR_DESCUENTO,
  SET_PRICE_LIST,
  COTIZACION_ADD_CREATED_BY_NAME,
  COTIZACION_UPDATE_PLANID_LINES,
  COTIZACION_UPDATE_WAREHOUSE_LINES,
  ACTION_MODAL_CUSTOMER,
} from '../types'

const initialState = {
  DocumentTypeId: documentTypeId.QUOTATION,
  QuoteHeader: {
    QuoteHeaderId: '00000000-0000-0000-0000-000000000000',
    HeaderStatusId: '',
    DocHeaderStatusDescription: '',
    DocHeaderStatusAction: '',
    ShowDiscount: true,
    ShowDiscountOption: true,
    AmountWithTax: 0,
    Tax: 0,
    Quantity: 0,
    Amount: 0,
    Discount: 0,
    CustomerId: null,
    CustomerNameDocument: '',
    CustomerExternalCode: null,
    SalesAgentFullName: '',
    CustomerTypeId: null,
    EmailAddress: null,
    PriceListId: null,
    Address_AddressDetail: null,
    Address_AddressId: null,
    Address_AddressType: null,
    Address_AddressTypeId: null,
    Address_BuildingId: null,
    Address_BuildingName: null,
    Address_BuildingType: null,
    Address_BuildingTypeId: null,
    Address_CountryId: null,
    Address_CountryName: null,
    Address_DistrictId: null,
    Address_DistrictName: null,
    Address_HomeOrFloorNumber: null,
    Address_Latitude: null,
    Address_Longitude: null,
    Address_NeighborhoodId: null,
    Address_NeighborhoodName: null,
    Address_ProvinceId: null,
    Address_ProvinceName: null,
    Address_Street: null,
    Address_TownshipId: null,
    Address_TownshipName: null,
    WarehouseId: '',
    WarehouseAddress: '',
    WarehouseCode: '',
    WarehouseDescription: '',
    CompanyId: '',
    CompanyName: '',
    CompanyCode: '',
    SalesAgentId: '',
    Origin: 'VA',
    DocLatitude: '+8.978007',
    DocLongitude: '-79.553470',
    Lines: [],
  },
  isModalCustomer: false,
}

const quotationReducer = (state = initialState, action) => {
  switch (action.type) {
    case COTIZACION_INIT:
      return initialState

    // AGREGAR PRODUCTOS A LA COTIZACION
    case COTIZACION_ADD_PRODUCT:
      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          ...(state.QuoteHeader.Lines = action.payload),
        },
      }

    case COTIZACION_SUCCESS:
      return {
        ...initialState,
        ...action.payload,
      }

    case COTIZACION_ERROR:
      return {
        ...state,
        ...(state = action.payload),
      }

    case ADD_COMPANY_COTIZACION:
      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          CompanyCode: action.payload.CompanyCode,
          CompanyId: action.payload.CompanyId,
          CompanyName: action.payload.CompanyName,
          WarehouseCode: action.payload.WarehouseCode,
          WarehouseId: action.payload.WarehouseId,
          WarehouseDescription: action.payload.WarehouseDescription,
          WarehouseName: action.payload.WarehouseName,
        },
      }

    // AGREGAR MONTOS A LA COTIZACION
    case COTIZACION_ADD_AMOUNT:
      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          Quantity: action.payload.Quantity,
          Amount: action.payload.Amount,
          AmountWithTax: action.payload.AmountWithTax,
          Tax: action.payload.Tax,
          DiscountAmount: action.payload.DiscountAmount,
          SubTotal: action.payload.SubTotal,
        },
      }

    case COTIZACION_ADD_AMOUNT_EDIT:
      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          Quantity: action.payload.Quantity,
          Amount: parseFloat(action.payload.Amount),
          AmountWithTax: parseFloat(action.payload.AmountWithTax),
          Tax: parseFloat(action.payload.Tax),
          DiscountAmount: parseFloat(action.payload.DiscountAmount),
        },
      }
    // AGREGAR CLIENTE A LA COTIZACION
    case COTIZACION_ADD_CLIENT:
      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          CustomerId: action.payload.CustomerId,
          CustomerNameDocument: action.payload.CustomerNameDocument,
          CustomerExternalCode: action.payload.CustomerExternalCode,
          CustomerTypeId: action.payload.CustomerTypeId,
          EmailAddress: action.payload.EmailAddress,
          CustomerEmail: action.payload.EmailAddress,
          SalesAgentCustomerStatusName:
            action.payload.SalesAgentCustomerStatusName,
          PriceListId: action.payload.PriceListId,
          Telephone: action.payload.Telephone,
        },
      }
    // AGREGAR COMMENT
    case COTIZACION_ADD_COMMENT:
      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          Comment: action.payload.comment,
        },
      }
    case ACTION_MODAL_CUSTOMER:
      return {
        ...state,
        isModalCustomer: action.payload,
      }
    // REMOVER CLIENTE DE LA COTIZACION
    case COTIZACION_REMOVE_CLIENT:
      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          CustomerId: null,
          CustomerNameDocument: null,
          CustomerExternalCode: null,
          CustomerTypeId: null,
          EmailAddress: null,
          Address_Street: null,
          ProductListId: null,
        },
      }

    // AGREGAR TIPO DE COTIZACION
    case COTIZACION_GUARDAR_TYPO_DOCUMENT:
      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          HeaderStatusId: action.payload.HeaderStatusId,
          DocHeaderStatusDescription: action.payload.DocHeaderStatusDescription,
          DocHeaderStatusAction: action.payload.DocHeaderStatusAction,
        },
      }

    // AGREGAR DIRECCION A LA COTIZACION
    case COTIZACION_ADD_ADDRESS:
      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          Address_AddressDetail: action.payload.Address_AddressDetail,
          AddressId: action.payload.Address_AddressId,
          Address_AddressId: action.payload.Address_AddressId,
          Address_AddressTypeId: action.payload.Address_AddressTypeId,
          Address_AddressType: action.payload.Address_AddressType,
          Address_BuildingId: action.payload.Address_BuildingId,
          Address_BuildingName: action.payload.Address_BuildingName,
          Address_BuildingType: action.payload.Address_BuildingType,
          Address_BuildingTypeId: action.payload.Address_BuildingTypeId,
          Address_CountryId: action.payload.Address_CountryId,
          Address_CountryName: action.payload.Address_CountryName,
          Address_DistrictId: action.payload.Address_DistrictId,
          Address_DistrictName: action.payload.Address_DistrictName,
          Address_HomeOrFloorNumber: action.payload.Address_HomeOrFloorNumber,
          Address_Latitude: action.payload.Address_Latitude,
          Address_Longitude: action.payload.Address_Longitude,
          Address_NeighborhoodId: action.payload.Address_NeighborhoodId,
          Address_NeighborhoodName: action.payload.Address_NeighborhoodName,
          Address_ProvinceId: action.payload.Address_ProvinceId,
          Address_ProvinceName: action.payload.Address_ProvinceName,
          Address_Street: action.payload.Address_Street,
          Address_TownshipId: action.payload.Address_TownshipId,
          Address_TownshipName: action.payload.Address_TownshipName,
        },
      }

    // ELIMINAR DIRECCION A LA COTIZACION
    case COTIZACION_QUITAR_ADDRESS:
      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          Address_AddressDetail: null,
          Address_AddressId: null,
          AddressId: null,
          Address_AddressType: null,
          Address_AddressTypeId: null,
          Address_BuildingId: null,
          Address_BuildingName: null,
          Address_BuildingType: null,
          Address_BuildingTypeId: null,
          Address_CountryId: null,
          Address_CountryName: null,
          Address_DistrictId: null,
          Address_DistrictName: null,
          Address_HomeOrFloorNumber: null,
          Address_Latitude: null,
          Address_Longitude: null,
          Address_NeighborhoodId: null,
          Address_NeighborhoodName: null,
          Address_ProvinceId: null,
          Address_ProvinceName: null,
          Address_Street: null,
          Address_TownshipId: null,
          Address_TownshipName: null,
        },
      }

    case COTIZACION_ADD_PDV:
      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          Lines: action.payload,
        },
      }

    case COTIZACION_ADD_CREATED_BY_NAME:
      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          SalesAgentFullName: action.payload,
        },
      }

    case COTIZACION_ADD_SUCURSAL:
      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          WarehouseId: action.payload.WarehouseId,
          WarehouseDescription: action.payload.WarehouseDescription,
          WarehouseAddress: action.payload.WarehouseAddress,
          WarehouseCode: action.payload.WarehouseCode,
          WarehouseName: action.payload.WarehouseName,
          DefaultWarehouse: action.payload.DefaultWarehouse || false,
          Address_AddressDetail: null,
          Address_AddressId: null,
          Address_BuildingType: null,
          Address_BuildingTypeId: null,
          Address_CountryId: null,
          Address_CountryName: null,
          Address_DistrictId: null,
          Address_DistrictName: null,
          Address_ProvinceId: null,
          Address_ProvinceName: null,
          Address_Street: null,
          Address_TownshipId: null,
          Address_TownshipName: null,
        },
      }

    case COTIZACION_UPDATE_PLANID_LINES:
      const fulfillmentPlanId = action.payload.fulfillmentPlanId
      const fulfillmentPlanDescription =
        action.payload.fulfillmentPlanDescription
      const currentLines = state.QuoteHeader.Lines.length
        ? state.QuoteHeader.Lines
        : action.payload.cart
      currentLines?.forEach(line => {
        line.FulfillmentPlanId = fulfillmentPlanId
        line.FulfillmentPlanDescription = fulfillmentPlanDescription
      })
      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          Lines: currentLines,
        },
      }

    case COTIZACION_UPDATE_WAREHOUSE_LINES:
      const currentLinesW = state.QuoteHeader.Lines
      const {
        WarehouseId,
        WarehouseCode,
        WarehouseDescription,
        WarehouseAddress,
      } = action.payload

      currentLinesW.forEach(line => {
        line.WarehouseId = WarehouseId
        line.WarehouseCode = WarehouseCode
        line.WarehouseDescription = WarehouseDescription
        line.WarehouseAddress = WarehouseAddress
      })

      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          Lines: currentLinesW,
        },
      }

    case COTIZACION_REMOVE:
      return {
        ...initialState,
      }

    case COTIZACION_UPDATE:
      return {
        ...state,
        QuoteHeader: {
          QuoteHeaderId: action.payload.QuoteHeaderId,
          ...action.payload.data,
        },
      }

    case COTIZACION_ELIMINAR_PRODUCTO:
      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          ...(state.QuoteHeader.Lines = state.QuoteHeader.Lines.splice(
            action.payload,
            1
          )),
        },
      }

    case ADD_PRICE_CHANGE:
      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          NegotiatedReasonId: action.payload.NegotiatedReasonId,
          NegotiatedReasonCode: action.payload.NegotiatedReasonCode,
          NegotiatedReason: action.payload.NegotiatedReason,
        },
      }

    case COTIZACION_CAMBIO_TIPO:
      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          HeaderStatusId: action.payload,
        },
      }

    case APPROVE_PRICE_CHANGE:
      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          DocHeaderStatusId: docHeaderStatusId.open,
          Lines: action.payload.Lines,
        },
      }

    case ADD_SALES_AGENT:
      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          SalesAgentId: action.payload,
        },
      }

    case ADD_CHANNEL:
      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          Channel: action.payload,
        },
      }

    case MODIFICAR_CLIENTE_CONTADO:
      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          CustomerNameDocument: action.payload,
        },
      }

    case MOSTRAR_DESCUENTO:
      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          ShowDiscountOption: action.payload,
          ShowDiscount: action.payload,
        },
      }

    case SET_PRICE_LIST:
      return {
        ...state,
        QuoteHeader: {
          ...state.QuoteHeader,
          PriceListId: action.payload,
        },
      }

    default:
      return state
  }
}

export default quotationReducer
