import React from 'react'
import { Highlight } from 'react-instantsearch-hooks-web'

export const Hit = ({ hit }) => {
  return (
    <tbody>
      <td>
        <Highlight attribute="ProductName" hit={hit} />
      </td>
    </tbody>
  )
}
