import React from 'react'
import { useTable, useGlobalFilter } from 'react-table'
import './table.css'
import { ArrowDownUp } from 'react-bootstrap-icons'
import { FormatMoney } from 'Utils/functions'

function TableOrdenDeDespacho({
  data,
  columns,
  isProductPage,
  hideFooter,
  isInventory,
}) {
  const tableInstance = useTable(
    {
      columns,
      data: data || [],
    },
    useGlobalFilter
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = tableInstance

  return (
    <div className="dispatch-table-container">
      <div className="dispatch-table-sell-order-topbar" />
      <table
        className="dispatch-table-sell-order-table-style"
        {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th
                  key={i}
                  {...column.getHeaderProps({
                    style: {
                      width: column.width,
                    },
                  })}>
                  <div className="dispatch-order-header-table-container">
                    <div className="dispatch-order-header-text">
                      {column.render('Header')}
                    </div>
                    {column.arrowOrderEnabled ? (
                      <ArrowDownUp className="dispatch-arrow-order-style" />
                    ) : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row)
            return (
              <tr
                key={index}
                {...row.getRowProps({
                  style:
                    rows.length <= index + 1
                      ? { borderBottom: '1px solid #E1E1E1' }
                      : { borderBottom: 'none' },
                })}>
                {row.cells.map((cell, i) => (
                  <td
                    key={i}
                    {...cell.getCellProps({
                      style: {
                        width: cell.column.width,
                        textAlign: cell.column.align,
                      },
                    })}>
                    {cell.column.Header === 'Precio total' ||
                    cell.column.Header === 'Precio unitario' ? (
                      <div className="sell-order-table-text">
                        {'$' + FormatMoney(cell.value)}
                      </div>
                    ) : (
                      <div className="sell-order-table-text">
                        {cell.render('Cell')}
                      </div>
                    )}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
        {hideFooter ? null : (
          <tfoot
            className={isInventory ? 'stickyFooter' : null}
            style={{
              borderBottom: isProductPage && 'none',
              borderLeft: isProductPage && 'none',
              borderRight: isProductPage && 'none',
            }}>
            {footerGroups.map((group, i) => (
              <tr key={i} {...group.getFooterGroupProps()}>
                {group.headers.map((column, i) => (
                  <td
                    key={i}
                    style={{ border: isProductPage && 'none' }}
                    {...column.getFooterProps()}>
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
    </div>
  )
}

export default TableOrdenDeDespacho
