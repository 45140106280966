import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Navigation from '../../Components/Navigation'
// import './printer.css'
import { Row, Col } from 'react-bootstrap'
import CalculatorSummary from 'Components/CalculatorSummary/CalculatorSummary'
import CalculatorSummaryCart from 'Components/CalculatorSummaryCart/CalculatorSummaryCart'
import MetodoEntrega from 'Components/MetodoEntrega'
import { useHistory } from 'react-router-dom' // Importa las acciones necesarias
import {
  updateCotizacionCompany,
  addSalesAgent,
  addPriceList,
} from '../../redux/actions/cotizacionActions'

const MaterialCalculatorSummary = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [mostrarMetodoEntrega, setMostrarMetodoEntrega] = useState(false)
  const [direccion, setDireccion] = useState(null)

  const [clienteMetodoEntrega, setClienteMetodoEntrega] = useState(null)

  const salesAgent = useSelector(
    state => state.login.user.CustomerInfo.CustomerId
  )
  const company = useSelector(state => state.company)
  const client = useSelector(
    state => state.cotizacion?.QuoteHeader?.CustomerNameDocument
  )
  const selectedWarehouse = useSelector(state => state?.company)
  // CHOOSEN ADDRESS
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [isList] = useState(history?.location?.state?.from === 'Mis Listas')
  const quoteHeader = useSelector(state => state?.cotizacion?.QuoteHeader)
  // HANDLE PARA CAMBIAR MOSTRAR METODO ENTREGA
  const handleMetodoEntrega = () => {
    setMostrarMetodoEntrega(true)
  }

  const handleMetodoEntregaTwo = () => {
    setMostrarMetodoEntrega(false)
  }
  useEffect(() => {
    // set warehouseid form quotation if want to put the lines put Lines[0]?.WarehouseId
    const warehouse = {
      WarehouseId: quoteHeader?.WarehouseId,
      WarehouseDescription: quoteHeader?.WarehouseDescription,
      WarehouseAddress: quoteHeader?.WarehouseAddress,
      WarehouseCode: quoteHeader?.WarehouseCode,
      WarehouseName: quoteHeader?.WarehouseName,
      Description: quoteHeader?.WarehouseDescription,
    }
    setSelectedAddress(warehouse)
  }, [isList, quoteHeader, dispatch])

  useEffect(() => {
    // const isCreation = window.location.pathname.includes('crear_cotizacion');
    if (client) {
      const warehouse = {
        WarehouseId: company?.WarehouseId,
        WarehouseDescription: company?.WarehouseDescription,
        WarehouseAddress: company?.WarehouseAddress,
        WarehouseCode: company?.WarehouseCode,
        WarehouseName: company?.WarehouseName,
        Description: company?.WarehouseDescription,
      }
      setSelectedAddress(warehouse)
      setDireccion(false)
    }
  }, [client, company])

  useEffect(() => {
    dispatch(addPriceList(undefined))
    dispatch(addSalesAgent(salesAgent))
  }, [dispatch, salesAgent])

  useEffect(() => {
    dispatch(
      updateCotizacionCompany({
        CompanyCode: selectedWarehouse.CompanyCode,
        CompanyName: selectedWarehouse.CompanyName,
        CompanyId: selectedWarehouse.CompanyId,
        WarehouseCode: selectedWarehouse.WarehouseCode,
        WarehouseId: selectedWarehouse.WarehouseId,
        WarehouseDescription: selectedWarehouse.WarehouseDescription,
        WarehouseName: selectedWarehouse.WarehouseName,
        WarehouseAddress: selectedWarehouse.WarehouseAddress,
        DefaultWarehouse: selectedWarehouse.DefaultWarehouse,
      })
    )
  }, [selectedWarehouse, dispatch])

  return (
    <>
      <Navigation />
      <section className="calculator-summary-main">
        <Row>
          <Col xs={11} lg={8}>
            {mostrarMetodoEntrega && clienteMetodoEntrega ? (
              <MetodoEntrega
                clienteMetodoEntrega={clienteMetodoEntrega}
                setSelectedAddress={setSelectedAddress}
                selectedAddress={selectedAddress}
                // productsSelected={productCart}
                handleMetodoEntrega={handleMetodoEntregaTwo}
                setDireccion={setDireccion}
              />
            ) : (
              <div>
                <CalculatorSummary />
              </div>
            )}
          </Col>
          <Col xs={12} lg={4} className="total">
            <CalculatorSummaryCart
              handleMetodoEntrega={handleMetodoEntrega}
              setClienteMetodoEntrega={setClienteMetodoEntrega}
              setSelectedAddress={setSelectedAddress}
              selectedAddress={selectedAddress}
              direccion={direccion}
              setDireccion={setDireccion}
              // products={products}
              editarCotizacion={() => {
                setMostrarMetodoEntrega(true)
                setClienteMetodoEntrega(true)
              }}
            />
          </Col>
        </Row>
      </section>
    </>
  )
}

export default MaterialCalculatorSummary
