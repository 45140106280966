import React from 'react'
import No from '../../Assets/Images/Login/no.svg'
import './genericModal.css'
import check from '../../Assets/Images/Login/check.png'
import { ExclamationTriangleFill } from 'react-bootstrap-icons'

function GenericModal({
  setIsOpen,
  text,
  setConfirm,
  mostrarButtons = true,
  mostrarImg = false,
  hideCheck = false,
  showCaution = false,
  text2 = '',
  closeLessPadding = false,
  gapTexts = false,
}) {
  return (
    <>
      <div
        className={
          showCaution && !text2
            ? 'modal-box-generic-modal-caution'
            : !showCaution && !text2
            ? 'modal-box-generic-modal'
            : 'modal-box-generic-modal-caution2'
        }>
        <div
          className={
            showCaution || closeLessPadding
              ? 'closing-button-less-padding'
              : 'generic-closing-button modal-client'
          }>
          <img src={No} onClick={() => setIsOpen(false)} alt="close" />
        </div>
        <div className="modal-content-text">
          {hideCheck ? null : (
            <img
              style={{ height: '30px', marginBottom: '19px' }}
              src={check}
              alt="check"
            />
          )}
          {showCaution ? (
            <ExclamationTriangleFill
              style={{
                fontSize: '40px',
                color: '#FF2A40',
                marginBottom: '19px',
              }}
            />
          ) : null}
          <h2 className="text1">{text}</h2>
          {gapTexts ? <br /> : null}
          {text2 !== '' ? <h2>{text2}</h2> : null}
        </div>
        <div
          className="modal-content-buttons"
          style={{ display: mostrarButtons ? 'flex' : 'none' }}>
          <button onClick={() => setConfirm(true)}>Si</button>
          <button onClick={() => setIsOpen(false)}>No</button>
        </div>
      </div>
    </>
  )
}

export default GenericModal
