import React, { useState, createContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import analytics from 'shared/Analytics'

export const VAContext = createContext()

export const selectedInitial = {
  customerID: '',
  delete: false,
  edit: false,
  quote: false,
}

export const VAContextProvider = ({ children }) => {
  const user = useSelector(state => state.login.user)
  // SHOW CLIENTS GENERAL VIEW MODAL
  const [showModalClient, setShowModalClient] = useState(false)
  const [showModalProductDetail, setShowModalProductDetail] = useState(false)
  const [modalClientCreate, setModalClientCreate] = useState(0)
  const [clientSelected, setClientSelected] = useState(selectedInitial)

  const changeClientView = () => {
    setShowModalClient(!showModalClient)
  }

  const showModalCreateClient = value => {
    setModalClientCreate(value)
  }

  const selectClient = (customerID, todelete, toedit, quote = false) => {
    setClientSelected({
      customerID,
      delete: todelete,
      edit: toedit,
      quote,
    })
  }

  useEffect(() => {
    if (user?.AuthenticationInfo?.UserId) {
      analytics.identify(user?.AuthenticationInfo?.UserId, {
        name: user?.CustomerInfo?.FullName,
        email: user?.AuthenticationInfo?.Email,
      })
      analytics.resume()
    }
  }, [user?.AuthenticationInfo?.UserId])

  return (
    <>
      <VAContext.Provider
        value={{
          showModalClient,
          showModalProductDetail,
          setShowModalProductDetail,
          changeClientView,
          modalClientCreate,
          showModalCreateClient,
          clientSelected,
          selectClient,
        }}>
        {children}
      </VAContext.Provider>
    </>
  )
}
