/**
 * @returns {string} - max available birthday date for a new client in format YYYY-MM-DD
 * @example
 * const maxAvailableBirthday = getMaxAvailableBirthday();
 * console.log(maxAvailableBirthday); // 1921-01-01 (if current date is 2021-01-01)
 */
export const getMaxAvailableBirthday = () => {
  const currentDate = new Date()
  const minBirthdayDate = new Date(
    currentDate.getFullYear() - 100,
    currentDate.getMonth(),
    currentDate.getDate()
  )

  const year = minBirthdayDate.getFullYear()
  const month = String(minBirthdayDate.getMonth() + 1).padStart(2, '0')
  const day = String(minBirthdayDate.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}
