import React from 'react'
import no from '../../../Assets/Images/Login/no.svg'
import './removeProduct.css'

const RemoveProduct = ({
  setShowModalRemoveProduct,
  setConfirmRemoveProduct,
}) => {
  // CONFIRMAR QUE SE ELIMINA
  const confirmState = () => {
    setConfirmRemoveProduct(true)
    setShowModalRemoveProduct(false)
  }

  const closeModalRemoveProduct = () => {
    setShowModalRemoveProduct(false)
  }

  return (
    <div className="modal-box-remove-product">
      <div className="delete-confirm-container">
        <div className="confirmation-header">
          <span onClick={closeModalRemoveProduct} className="close-modal">
            <img src={no} alt="close" />
          </span>
        </div>
        <div>
          <div className="confirmation-message">
            <p className="confirmation-text-delete">
              Estás seguro que deseas eliminar el producto de la lista
              <br />
              <br />
              ¿Deseas continuar?
            </p>
          </div>
          <div className="modal-buttons">
            <button onClick={confirmState} className="btn modal-si">
              Si
            </button>
            <button onClick={closeModalRemoveProduct} className="btn modal-no">
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RemoveProduct
