/** @format */

import React, { useEffect, useState } from 'react'
import Novey from '../../Assets/Images/Logos/novey.png'
import Cochez from '../../Assets/Images/Logos/cochez.png'
import './companyPicker.css'
import { useDispatch, useSelector } from 'react-redux'
import { setCompany } from '../../redux/actions/companyAction'
import { Box } from '@mui/material'
import { companiesCode } from 'Utils/constants'
import { useCheckFinalPrice } from 'Components/ActionTable/hooks/useCheckFinalPrice'

function CompanyPicker({ show, setShow, setLogo, logo, home }) {
  const { checkFinalPrice } = useCheckFinalPrice()
  const dispatch = useDispatch()
  // state comanies user
  const companies = useSelector(
    state => state?.login?.user?.CustomerInfo?.Companies
  )
  const warehouses = useSelector(
    state => state?.login?.user?.CustomerInfo?.Warehouses
  )
  // prevent change company is user doesn't have warehouses with company
  const showCochez = !!(
    companies.filter(company => company.Code === companiesCode.cochez).length >
      0 &&
    warehouses.filter(
      warehouse => warehouse.CompanyCode === companiesCode.cochez
    ).length > 0
  )
  const showNovey = !!(
    companies.filter(company => company.Code === companiesCode.novey).length >
      0 &&
    warehouses.filter(
      warehouse => warehouse.CompanyCode === companiesCode.novey
    ).length > 0
  )

  const [noveyWarehouse, setNoveyWarehouse] = useState({})
  const [cochezWarehouse, setCochezWarehouse] = useState({})
  const [defaultCochezWarehouse, setDefaultCochezWarehouse] = useState(false)
  const [defaultNoveyWarehouse, setDefaultNoveyWarehouse] = useState(false)

  const mainNoveyWarehouse = () => {
    const allNoveyWarehouses = warehouses.filter(warehouse => {
      return warehouse.CompanyCode === companiesCode.novey
    })
    if (allNoveyWarehouses.length === 1) setDefaultNoveyWarehouse(true)
    setNoveyWarehouse(allNoveyWarehouses[0])
  }

  const mainCochezWarehouse = () => {
    const allCochezWarehouses = warehouses.filter(warehouse => {
      return warehouse.CompanyCode === companiesCode.cochez
    })
    if (allCochezWarehouses.length === 1) setDefaultCochezWarehouse(true)
    setCochezWarehouse(allCochezWarehouses[0])
  }

  useEffect(() => {
    mainNoveyWarehouse()
    mainCochezWarehouse()
    // prevent change company is user doesn't have warehouses with company
    if (!showCochez || !showNovey) {
      setShow(false)
    }
  }, [])

  const setCochez = () => {
    window.localStorage.setItem('company', 'Cochez')
    setLogo(Cochez)
    setShow(false)
    dispatch(
      setCompany({
        CompanyCode: cochezWarehouse.CompanyCode,
        CompanyName: cochezWarehouse.CompanyName,
        CompanyId: cochezWarehouse.CompanyId,
        WarehouseCode: cochezWarehouse.WarehouseCode,
        WarehouseId: cochezWarehouse.WarehouseId,
        WarehouseDescription: cochezWarehouse.WarehouseDescription,
        WarehouseName: cochezWarehouse.WarehouseName,
        WarehouseAddress: cochezWarehouse.WarehouseAddress,
        DefaultWarehouse: defaultCochezWarehouse,
      })
    )
    checkFinalPrice({ newCompany: cochezWarehouse })
  }

  const setNovey = () => {
    window.localStorage.setItem('company', 'Novey')
    setLogo(Novey)
    setShow(false)
    dispatch(
      setCompany({
        CompanyCode: noveyWarehouse.CompanyCode,
        CompanyName: noveyWarehouse.CompanyName,
        CompanyId: noveyWarehouse.CompanyId,
        WarehouseCode: noveyWarehouse.WarehouseCode,
        WarehouseId: noveyWarehouse.WarehouseId,
        WarehouseDescription: noveyWarehouse.WarehouseDescription,
        WarehouseName: noveyWarehouse.WarehouseName,
        WarehouseAddress: noveyWarehouse.WarehouseAddress,
        DefaultWarehouse: defaultNoveyWarehouse,
      })
    )
    checkFinalPrice({ newCompany: noveyWarehouse })
  }

  return (
    <Box
      component="div"
      className={show ? 'companypicker-menu' : 'companypicker-menu-off'}
      sx={{
        left: {
          xs: '0',
          sm: home ? '3px' : '75px',
        },
      }}>
      <ul>
        {/* //prevent change company is user doesn't have warehouses with company */}
        {showNovey && (
          <li
            className="company-element"
            onClick={setNovey}
            style={{ display: logo === Cochez ? 'block' : 'none' }}>
            <img src={Novey} alt="novey-logo" />
          </li>
        )}
        {showCochez && (
          <li
            className="company-element"
            onClick={setCochez}
            style={{ display: logo === Novey ? 'block' : 'none' }}>
            <img src={Cochez} alt="cochez-logo" />
          </li>
        )}
      </ul>
    </Box>
  )
}

export default CompanyPicker
