import React, { useEffect, useState } from 'react'
import Navigation from '../../Components/Navigation'
import './printer.css'
import SearchIcon from '@mui/icons-material/Search'
import { images } from 'Assets/Images'
import { CustomSelector } from './components/CustomSelector'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { CounterImpressions } from './components/CounterImpressions'
import {
  getPrintedProductsService,
  getProductsToPrintService,
} from 'redux/services/printerServices'
import { TableProductListPrinter } from './components/TableProductListPrinter'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { TablePrintedProductList } from './components/TablePrintedProductList'
import { StringParam, useQueryParams } from 'use-query-params'
import { useSelector } from 'react-redux'

const convertDate = fechaISO => {
  const fecha = new Date(fechaISO)
  return format(fecha, 'dd/MM/yyyy')
}

export const PRINT_MANUAL_URL =
  'https://staludraqa.blob.core.windows.net/aludra-files/2/Asset/Models/06062024201928.pdf'
const columns = [
  {
    Header: 'Check',
    accessor: 'Check',
    hideEnabled: false,
  },
  {
    Header: 'Departamento',
    accessor: 'DepartmentName',
    hideEnabled: false,
  },
  {
    Header: 'Tipo',
    accessor: 'ListType',
    hideEnabled: false,
  },
  {
    Header: 'Referencia',
    accessor: 'Sku',
    hideEnabled: false,
  },
  {
    Header: 'Descripción',
    accessor: 'ProductName',
    hideEnabled: false,
  },
  {
    Header: 'Precio',
    accessor: 'Precio',
    children: [{ Header: 'Base' }, { Header: 'Oferta' }],
    hideEnabled: true,
  },
  {
    Header: 'Vigencia',
    accessor: 'Vigencia',
    children: [{ Header: 'Fecha inicio' }, { Header: 'Fecha fin' }],
    hideEnabled: true,
  },
]
const columnsPrinted = [
  {
    Header: 'Imagen',
    accessor: 'Imagen',
    hideEnabled: false,
  },
  {
    Header: 'Descripción',
    accessor: 'Descripción',
    hideEnabled: false,
  },
  {
    Header: 'Tipo',
    accessor: 'Tipo',
    hideEnabled: false,
  },
  {
    Header: 'Acciones',
    accessor: 'Acciones',
    hideEnabled: false,
  },
]

const PRINTED = 'PRINTED'
const PENDING = 'PENDING'
const defaultValue = {
  REGULAR_CMF: 'Precio Regular CMF',
  REGULAR: 'Precio Regular',
  OFFER: 'Precio Oferta',
  OFFER_CMF: 'Precio Oferta CMF',
}
const DEFAULT_TEMPLATES_CONTENT = [
  {
    page: 1,
    template: null,
    content: {
      products: [],
    },
  },
]

const Printer = () => {
  const { CompanyId, WarehouseId } = useSelector(state => state.company)
  const [searchParam, setSearchParam] = useQueryParams({ type: StringParam })
  const {
    setSelectedTemplateType,
    setSelectedProducts,
    setAllTemplatesContent,
    setCurrentPage,
  } = usePrinterContext()
  const [loading, setLoading] = useState(false)
  const defaultOption = {
    type: {
      value: 'Etiquetas pendientes por impresión',
      key: 'PENDING',
    },
    template: {
      value: defaultValue[searchParam?.type] || defaultValue.REGULAR_CMF,
      key: searchParam?.type || 'REGULAR_CMF',
    },
  }
  const [selectedOption, setSelectedOption] = React.useState(
    searchParam?.type
      ? defaultOption
      : { type: { key: '', value: '' }, template: { key: '', value: '' } }
  )

  const [data, setData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [pageSize, setPageSize] = useState(10)

  const handleOptionType = option => {
    setSearchText('')
    setSelectedOption({
      template: {
        key: '',
        value: '',
      },
      type: option,
    })
    setSelectedTemplateType({
      key: '',
      value: '',
    })
    setData([])
    setSearchParam({ type: undefined })
    setSelectedProducts([])
    setAllTemplatesContent(DEFAULT_TEMPLATES_CONTENT)
    setCurrentPage(1)
  }
  const handleOptionTemplate = option => {
    setAllTemplatesContent(DEFAULT_TEMPLATES_CONTENT)
    setSearchText('')
    setSelectedOption({ ...selectedOption, template: option })
    setSelectedTemplateType(option)
    setSearchParam({ type: undefined })
    setSelectedProducts([])
    setCurrentPage(1)
  }

  const fetchPrintedProducts = async ({ type, pageSize, currentPage }) => {
    try {
      setLoading(true)
      const response = await getPrintedProductsService({
        Type: type || 'ALL',
        PageNumber: currentPage || 1,
        PageSize: pageSize || 10,
      })
      const data = response.map(product => ({
        ...product,
        Imagen: product.ImageProduct,
        Descripción: product.Name,
        Tipo: product.ListType,
        Acciones: product.DocumentUrl,
        currentPage: currentPage || 1,
      }))
      setData(data)
    } catch (error) {
      console.log('fetchPrintedProducts -> error', error)
    } finally {
      setLoading(false)
    }
  }
  const fetchProductsToPrint = async ({
    type,
    searchText,
    pageSize,
    currentPage,
    StartDate,
    FinishDate,
  }) => {
    try {
      setLoading(true)
      const response = await getProductsToPrintService({
        WarehouseId,
        CompanyId,
        SearchText: searchText || '',
        Type: type || 'REGULAR',
        State: 'PENDING',
        PageNumber: currentPage || 1,
        PageSize: pageSize || 10,
        StartDate: StartDate || '',
        FinishDate: FinishDate || '',
      })
      const data = response.map(product => ({
        ...product,
        Check: '',
        DepartmentName: product.DepartmentName,
        ListType: product.ListType,
        Sku: product.Sku,
        ProductName: product.ProductName,
        Precio: [product.BasePrice, product.Price],
        Vigencia: [
          product.StartDate ? convertDate(product.StartDate) : '',
          product.FinishDate ? convertDate(product.FinishDate) : '',
        ],
        currentPage: currentPage || 1,
      }))
      setData(data)
    } catch (error) {
      console.log('fetchProductsToPrint -> error', error)
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = async () => {
    try {
      await fetchProductsToPrint({
        type: selectedOption.template.key,
        searchText,
      })
    } catch (error) {
      console.log('handleSearch -> error', error)
    }
  }

  useEffect(() => {
    if (selectedOption.type.key && selectedOption.template.key) {
      if (selectedOption.type.key === PRINTED) {
        fetchPrintedProducts({
          type: selectedOption.template.key,
        })
      }
      if (selectedOption.type.key === PENDING) {
        fetchProductsToPrint({
          type: selectedOption.template.key,
        })
      }
    }
  }, [selectedOption, CompanyId, WarehouseId])

  return (
    <>
      <Navigation />
      <section className="printer-screen-wrapper">
        <div className="printer-header-wrapper">
          <h2>Impresión de plantillas de precios</h2>
          <div className="printer-header-buttons">
            <button disabled className="printer-header-button">
              Impresión rápida
            </button>
            <button
              className="printer-header-button"
              onClick={() => window.open(PRINT_MANUAL_URL, '_blank')}>
              Manual de impresión
            </button>
          </div>
        </div>
        <div style={{ padding: '0 10px' }}>
          <CounterImpressions />
          <div className="printer-options-wrapper">
            <CustomSelector
              placeholder="Selecciona qué desea visualizar"
              options={[
                { value: 'Etiquetas pendientes por impresión', key: PENDING },
                { value: 'Etiquetas impresas', key: PRINTED },
              ]}
              selectedOption={selectedOption.type}
              handleOption={handleOptionType}
              disabled={loading}
            />
            <CustomSelector
              placeholder="Selecciona el tipo de plantilla"
              options={[
                { value: 'Precio Regular', key: 'REGULAR' },
                { value: 'Precio Oferta', key: 'OFFER' },
                { value: 'Precio Regular CMF', key: 'REGULAR_CMF' },
                { value: 'Precio Oferta CMF', key: 'OFFER_CMF' },
                ...(selectedOption.type.key === PRINTED
                  ? [{ value: 'Todos', key: 'ALL' }]
                  : []),
              ]}
              selectedOption={selectedOption.template}
              handleOption={handleOptionTemplate}
              disabled={!selectedOption.type.key || loading}
            />
            <div className="printer-option-input">
              <input
                className="printer-option-input-text"
                disabled={!selectedOption.type.key || loading}
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                onKeyDown={e => e.key === 'Enter' && handleSearch()}
                placeholder="Buscar por descripción del producto o código"
              />
              <SearchIcon
                style={{ width: 30, height: 30, marginRight: 10 }}
                onClick={handleSearch}
              />
            </div>
          </div>
          <div className="printer-table-wrapper">
            {loading && (
              <div className="printer-table-loader-wrapper">
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="spinner"
                  style={{ width: 50 }}
                />
                <p>
                  Esto puede tardar unos segundos, <br /> por favor espera un
                  momento
                </p>
              </div>
            )}
            {!!data?.length &&
              !loading &&
              selectedOption.type.key === PENDING && (
                <TableProductListPrinter
                  data={data}
                  columns={columns}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  handleGetData={val =>
                    fetchProductsToPrint({
                      type: selectedOption.template.key,
                      pageSize: val.pageSize,
                      currentPage: val.currentPage,
                      StartDate: val.StartDate,
                      FinishDate: val.FinishDate,
                    })
                  }
                />
              )}
            {!!data?.length &&
              !loading &&
              selectedOption.type.key === PRINTED && (
                <TablePrintedProductList
                  data={data}
                  columns={columnsPrinted}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  handleGetData={val =>
                    fetchPrintedProducts({
                      type: selectedOption.template.key,
                      pageSize: val.pageSize,
                      currentPage: val.currentPage,
                    })
                  }
                />
              )}
            {!data?.length && !loading && (
              <div className="printer-instructions-wrapper">
                <div className="printer-instructions-header">
                  <img
                    src={images.printer.note}
                    alt=""
                    className="printer-instructions-header-image"
                  />
                  <div className="printer-instructions-header-content">
                    <p className="printer-instructions-header-item-title">
                      Puedes consultar las plantillas de impresión
                    </p>
                    <p className="printer-instructions-header-item-sub-title">
                      Solo debes filtrar seleccionado:
                    </p>
                    <div className="printer-instructions-header-item">
                      <img
                        src={images.printer.checkIcon}
                        alt=""
                        className="printer-instructions-header-item-image"
                      />
                      <p className="printer-instructions-header-item-text">
                        Qué deseas visualizar
                      </p>
                    </div>
                    <div className="printer-instructions-header-item">
                      <img
                        src={images.printer.checkIcon}
                        alt=""
                        className="printer-instructions-header-item-image"
                      />
                      <p className="printer-instructions-header-item-text">
                        Tipo de plantilla
                      </p>
                    </div>
                  </div>
                </div>
                <div className="printer-instructions-footer-wrapper">
                  <p className="printer-instructions-footer-text">
                    Una vez que se muestre la lista según tu selección, se
                    activará el buscador.
                  </p>
                  <p className="printer-instructions-footer-text">
                    Puedes buscar por descripción del producto o código.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default Printer
