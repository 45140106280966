import React from 'react'
import { images } from 'Assets/Images'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'

const DEFAULT_VALUE =
  'La tarjeta de crédito CMF es emitida por CM Financiera, S.A., sujeto a previa aprobación de esa entidad. Para más información ingresa a cmfinanciera.com. Cuotas calculadas con tasa de interés nominal del 57% anual. La responsabilidad de CM Financiera se limita exclusivamente a aspectos propios del crédito otorgado y no por los productos o servicios adquiridos mediante el uso del crédito.'

export const Square3ToPrintCCmfOffer = ({ products, i }) => {
  const { templateRef } = usePrinterContext()

  const terms = products?.content?.terms || DEFAULT_VALUE

  const prod1 = products?.content?.products?.[0]
  const prod2 = products?.content?.products?.[1] || {}
  const prod3 = products?.content?.products?.[2] || {}
  const prod4 = products?.content?.products?.[3] || {}

  return (
    <div ref={el => (templateRef.current[i] = el)}>
      <div
        id="printer-preview-left-canvas-square-3"
        className="printer-preview-left-canvas-square-3">
        <div
          className="printer-preview-left-canvas-square-3-box"
          style={{
            height: '100%',
            top: '0',
          }}>
          <div className="printer-preview-left-content-body-square-3">
            <p className="printer-preview-left-content-body-square-3-title">
              {prod1?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-3-sku">
              {prod1?.Sku || ''}
            </p>
            {prod1?.Price && (
              <div
                className="printer-preview-left-content-body-square-3-price-wrapper"
                style={{ marginTop: '12px' }}>
                <p
                  className="printer-preview-mini-text-square-3"
                  style={{ color: 'black' }}>
                  $
                </p>
                <p
                  className="printer-preview-left-content-body-square-3-price"
                  style={{ color: 'black' }}>
                  {prod1?.Price?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p
                  className="printer-preview-mini-text-square-3"
                  style={{ color: 'black' }}>
                  {prod1?.Price?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
          </div>
          <div
            className="printer-preview-left-content-footer-square-3"
            style={{ top: 'calc(50% - 84px)', gap: '6px' }}>
            <div className="printer-preview-left-content-footer-square-3-quotes">
              <p></p>
              <p
                className="printer-preview-left-content-footer-square-3-quote-text"
                style={{ color: 'black' }}>
                36 <br /> cuotas
              </p>
              <p
                className="printer-preview-left-content-footer-square-3-quote-text"
                style={{ color: 'black' }}>
                {'$' + (prod1?.cmfCredit || '')}
              </p>
            </div>
            <div className="printer-preview-content-footer-input-wrapper">
              <p
                className="printer-preview-left-content-footer-legal-2"
                style={{
                  border: 'none',
                  color: '#000',
                  background: 'transparent',
                }}>
                {terms}
              </p>
            </div>
          </div>
        </div>
        <div
          className="printer-preview-left-canvas-square-3-box"
          style={{
            height: '100%',
            top: '0',
            left: '50%',
          }}>
          <div className="printer-preview-left-content-body-square-3">
            <p className="printer-preview-left-content-body-square-3-title">
              {prod2?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-3-sku">
              {prod2?.Sku || ''}
            </p>
            {prod2?.Price && (
              <div
                className="printer-preview-left-content-body-square-3-price-wrapper"
                style={{ marginTop: '12px' }}>
                <p
                  className="printer-preview-mini-text-square-3"
                  style={{ color: 'black' }}>
                  $
                </p>
                <p
                  className="printer-preview-left-content-body-square-3-price"
                  style={{ color: 'black' }}>
                  {prod2?.Price?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p
                  className="printer-preview-mini-text-square-3"
                  style={{ color: 'black' }}>
                  {prod2?.Price?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
          </div>
          <div
            className="printer-preview-left-content-footer-square-3"
            style={{ top: 'calc(50% - 84px)', gap: '6px' }}>
            <div className="printer-preview-left-content-footer-square-3-quotes">
              <p></p>
              <p
                className="printer-preview-left-content-footer-square-3-quote-text"
                style={{ color: 'black' }}>
                36 <br /> cuotas
              </p>
              <p
                className="printer-preview-left-content-footer-square-3-quote-text"
                style={{ color: 'black' }}>
                {'$' + (prod2?.cmfCredit || '')}
              </p>
            </div>
            <div className="printer-preview-content-footer-input-wrapper">
              <p
                className="printer-preview-left-content-footer-legal-2"
                style={{
                  border: 'none',
                  color: '#000',
                  background: 'transparent',
                }}>
                {terms}
              </p>
            </div>
          </div>
        </div>
        <div
          className="printer-preview-left-canvas-square-3-box"
          style={{
            height: '50%',
            top: '50%',
          }}>
          <div className="printer-preview-left-content-body-square-3-2">
            <p className="printer-preview-left-content-body-square-3-title-2">
              {prod3?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-3-sku-2">
              {prod3?.Sku || ''}
            </p>
            {prod3?.Price && (
              <div
                className="printer-preview-left-content-body-square-3-price-wrapper"
                style={{ marginTop: '12px' }}>
                <p
                  className="printer-preview-mini-text-square-3"
                  style={{ color: 'black' }}>
                  $
                </p>
                <p
                  className="printer-preview-left-content-body-square-3-price-2"
                  style={{ color: 'black' }}>
                  {prod3?.Price?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p
                  className="printer-preview-mini-text-square-3"
                  style={{ color: 'black' }}>
                  {prod3?.Price?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
          </div>
          <div
            className="printer-preview-left-content-footer-square-3-2"
            style={{ top: 'calc(100% - 84px)', gap: '6px' }}>
            <div className="printer-preview-left-content-footer-square-3-quotes">
              <p></p>
              <p
                className="printer-preview-left-content-footer-square-3-quote-text"
                style={{ color: 'black' }}>
                36 <br /> cuotas
              </p>
              <p
                className="printer-preview-left-content-footer-square-3-quote-text"
                style={{ color: 'black' }}>
                {'$' + (prod3?.cmfCredit || '')}
              </p>
            </div>
            <div className="printer-preview-content-footer-input-wrapper">
              <p
                className="printer-preview-left-content-footer-legal-2"
                style={{
                  border: 'none',
                  color: '#000',
                  background: 'transparent',
                }}>
                {terms}
              </p>
            </div>
          </div>
        </div>
        <div
          className="printer-preview-left-canvas-square-3-box"
          style={{
            height: '50%',
            top: '50%',
            left: '50%',
          }}>
          <div className="printer-preview-left-content-body-square-3-2">
            <p className="printer-preview-left-content-body-square-3-title-2">
              {prod4?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-3-sku-2">
              {prod4?.Sku || ''}
            </p>
            {prod4?.Price && (
              <div
                className="printer-preview-left-content-body-square-3-price-wrapper"
                style={{ marginTop: '12px' }}>
                <p
                  className="printer-preview-mini-text-square-3"
                  style={{ color: 'black' }}>
                  $
                </p>
                <p
                  className="printer-preview-left-content-body-square-3-price-2"
                  style={{ color: 'black' }}>
                  {prod4?.Price?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p
                  className="printer-preview-mini-text-square-3"
                  style={{ color: 'black' }}>
                  {prod4?.Price?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
          </div>
          <div
            className="printer-preview-left-content-footer-square-3-2"
            style={{ top: 'calc(100% - 84px)', gap: '6px' }}>
            <div className="printer-preview-left-content-footer-square-3-quotes">
              <p></p>
              <p
                className="printer-preview-left-content-footer-square-3-quote-text"
                style={{ color: 'black' }}>
                36 <br /> cuotas
              </p>
              <p
                className="printer-preview-left-content-footer-square-3-quote-text"
                style={{ color: 'black' }}>
                {'$' + (prod4?.cmfCredit || '')}
              </p>
            </div>
            <div className="printer-preview-content-footer-input-wrapper">
              <p
                className="printer-preview-left-content-footer-legal-2"
                style={{
                  border: 'none',
                  color: '#000',
                  background: 'transparent',
                }}>
                {terms}
              </p>
            </div>
          </div>
        </div>
        <img
          className="printer-preview-left-image"
          src={images.printer.templates.cochez.cmfOffer3}
          alt="template-preview"
          style={{ opacity: 0 }}
        />
      </div>
    </div>
  )
}
