import { aludraApi } from 'Api/axios'

const mdl04 = {
  productDetailURL: 'mdl04/appMobile/product/detail',
  inventarioURL: 'mdl04/getDetailsInventoryProduct/post',
  warantyURL: '/mdl04/extendedWarranty',
}
const mdl30 = {
  cmfCampaignsUrl: 'mdl30/campaign/getLinkAspNetUserByUserId?userid=',
  specificCampaignUrl: 'mdl30/campaign/getById?id=',
  referrerCreateURL: 'mdl30/referrer/create',
}
const mdl14 = {
  cmfPriceURL: 'mdl14/cmf/getCreditSimulated',
}

export const getCmfPriceService = async payload => {
  return aludraApi.post(mdl14.cmfPriceURL, payload)
}

export const getProductDetaillService = async payload => {
  return aludraApi.post(mdl04.productDetailURL, payload)
}

export const getInventoryService = async payload => {
  return aludraApi.post(mdl04.inventarioURL, payload)
}

export const getRefererCreateService = async payload => {
  return aludraApi.post(mdl30.referrerCreateURL, payload)
}

export const getcmfCampaignsService = async payload => {
  return aludraApi.get(mdl30.cmfCampaignsUrl + payload)
}

export const getSpecificCampaignService = async payload => {
  return aludraApi.get(mdl30.specificCampaignUrl + payload)
}

export const getWarrantyService = async => {
  return aludraApi.get(mdl04.warantyURL)
}
