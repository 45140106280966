import React, { useEffect } from 'react'
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined'
import { images } from 'Assets/Images'
import { ModalPrinterTemplates } from 'Views/Printer/components/ModalPrinterTemplates'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { useHistory } from 'react-router-dom'
import { useGeneratePrinter } from './hooks/useGeneratePrinter'

export const TemplatesSection = () => {
  const history = useHistory()
  const {
    selectedTemplates,
    selectedTemplateType,
    setAllTemplatesContent,
    allTemplatesContent,
    currentPage,
  } = usePrinterContext()
  const { generateMultiPagePDF, printedUrl } = useGeneratePrinter()

  const [openTemplateModal, setOpenTemplateModal] = React.useState(false)
  const handleClose = () => setOpenTemplateModal(false)
  const handleShow = () => setOpenTemplateModal(true)
  const hasSquareTemplate = selectedTemplates?.some(
    template => template === 'square-1'
  )
  const hasDoubleSquareTemplate = selectedTemplates?.some(
    template => template === 'square-2'
  )
  const hasSquare3Template = selectedTemplates?.some(
    template => template === 'square-3'
  )
  const hasSquare4Template = selectedTemplates?.some(
    template => template === 'square-4'
  )

  const handleSelectTemplate = template => {
    setAllTemplatesContent(prev => [
      ...prev.map(content => {
        if (content.page === currentPage) {
          return {
            ...content,
            content: { products: [] },
            template: [template],
          }
        }
        return content
      }),
    ])
  }

  useEffect(() => {
    if (!selectedTemplateType?.key) {
      history.push('/printer')
    }
    const hasAllTemplatesContent = allTemplatesContent?.find(
      content => content?.page === currentPage
    )?.template?.length
    if (!hasAllTemplatesContent) {
      handleSelectTemplate(selectedTemplates?.[0])
    }
  }, [selectedTemplateType])

  const printed = printedUrl !== ''

  return (
    <>
      <div className="printer-preview-right-templates-wrapper">
        {hasSquareTemplate && (
          <button
            disabled={printed}
            className="printer-modal-content-option"
            onClick={() => handleSelectTemplate('square-1')}>
            <div
              className="printer-modal-content-image-wrapper"
              style={{
                ...(allTemplatesContent
                  .find(content => content.page === currentPage)
                  ?.template?.includes('square-1') && {
                  border: '2px solid #022767',
                }),
              }}>
              <img
                src={images.printer.square}
                alt=""
                className="printer-modal-content-image"
              />
            </div>
            <p className="printer-modal-content-text">
              Tonga y Bin<br></br>
              {`11" x 8.5"`}
              <br></br>Horizontal
            </p>
          </button>
        )}
        {hasDoubleSquareTemplate && (
          <button
            disabled={printed}
            className="printer-modal-content-option"
            onClick={() => handleSelectTemplate('square-2')}>
            <div
              className="printer-modal-content-image-wrapper"
              style={{
                ...(allTemplatesContent
                  .find(content => content.page === currentPage)
                  ?.template?.includes('square-2') && {
                  border: '2px solid #022767',
                }),
              }}>
              <img
                src={images.printer.doubleSquare}
                alt=""
                className="printer-modal-content-image"
              />
            </div>
            <p className="printer-modal-content-text">
              Frente Góndola<br></br>
              {`8.5" x 5.5"`}
              <br></br>Horizontal
            </p>
          </button>
        )}
        {hasSquare3Template && (
          <button
            disabled={printed}
            className="printer-modal-content-option"
            onClick={() => handleSelectTemplate('square-3')}>
            <div
              className="printer-modal-content-image-wrapper"
              style={{
                ...(allTemplatesContent
                  .find(content => content.page === currentPage)
                  ?.template?.includes('square-3') && {
                  border: '2px solid #022767',
                }),
              }}>
              <img
                src={images.printer.squares}
                alt=""
                className="printer-modal-content-image"
              />
            </div>
            <p className="printer-modal-content-text">
              Frente Góndola<br></br>
              {`4" x 5"`}
              <br></br>Vertical
            </p>
          </button>
        )}
        {hasSquare4Template && (
          <button
            disabled={printed}
            className="printer-modal-content-option"
            onClick={() => handleSelectTemplate('square-4')}>
            <div
              className="printer-modal-content-image-wrapper"
              style={{
                ...(allTemplatesContent
                  .find(content => content.page === currentPage)
                  ?.template?.includes('square-4') && {
                  border: '2px solid #022767',
                }),
              }}>
              <img
                src={images.printer.squares}
                alt=""
                className="printer-modal-content-image"
              />
            </div>
            <p className="printer-modal-content-text">
              Frente Góndola<br></br>
              {`5.5" x 8.5"`}
              <br></br>Vertical
            </p>
          </button>
        )}
        <button
          onClick={handleShow}
          className="printer-modal-content-option-plus">
          <AddCircleOutlinedIcon style={{ width: 30, height: 30 }} />
        </button>
        <div className="printer-finish-button-wrapper">
          <button
            onClick={() => {
              printed && window.open(printedUrl, '_blank')
              !printed && generateMultiPagePDF()
            }}
            className="printer-finish-button">
            {printed ? 'Ver PDF' : 'Imprimir etiqueta de precio'}
          </button>
        </div>
      </div>
      <ModalPrinterTemplates
        show={openTemplateModal}
        onClose={handleClose}
        withNavigation={false}
      />
    </>
  )
}
