import React from 'react'
import Map from '../Map'

const FormDireccion = ({
  setSelectedAddress,
  setDireccion,
  handleMetodoEntrega,
  isModalScreen,
}) => {
  return (
    <Map
      setSelectedAddress={setSelectedAddress}
      setDireccion={setDireccion}
      handleMetodoEntrega={handleMetodoEntrega}
      isModalScreen={isModalScreen}
    />
  )
}

export default FormDireccion
