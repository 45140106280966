import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { addAmounts } from 'redux/actions/cotizacionActions'
import { FormatMoney, getMarginTotal } from 'Utils/functions'
import { VAPermissions } from 'Utils/constants'
import { FooterTextValue } from 'Views/VentaPorProducto/components/FooterTextValue'
import useValidatePermission from 'Hooks/useValidatePermission'

export const useTableData = () => {
  const dispatch = useDispatch()
  const seeCostProd = useValidatePermission(VAPermissions.seeCostProd)

  const seeMargenProd = useValidatePermission(VAPermissions.seeMargenProd)
  const cart = useSelector(state => state.cart.cart)
  const [cartColumns, setCartColumns] = useState([])
  const getDataCart = () => {
    let subtotal = 0
    let DiscountAmount = 0
    let Cost = 0
    let totalTax = 0
    let Quantity = 0

    cart?.length &&
      cart.forEach(item => {
        totalTax += item.Tax
        subtotal += item.FinalPrice * item.Quantity
        DiscountAmount += item.DiscountAmount
        Cost += item.Cost * item.Quantity
        Quantity += item.Quantity
      })

    const totalMargin = getMarginTotal(subtotal, Cost)
    const itbms = totalTax
    const total =
      Number(FormatMoney(subtotal, true, 2).replace(/,/g, '')) +
      Number(FormatMoney(totalTax, true, 2).replace(/,/g, ''))

    dispatch(
      addAmounts({
        Quantity,
        Amount: FormatMoney(subtotal, false, 6),
        Tax: FormatMoney(itbms, false, 6),
        AmountWithTax: FormatMoney(total, false, 6),
      })
    )

    return {
      subtotal,
      DiscountAmount,
      itbms,
      total,
      margin: totalMargin || 0,
    }
  }
  const getCartColumns = () => {
    const cartInfo = getDataCart()
    const footerValues =
      seeCostProd && seeMargenProd
        ? {
            cantidad: {
              value: cartInfo.subtotal,
              key: 'SELL_BY_PROD_T.FOOTER_CATALOG.SUBTOTAL_TYPE',
            },
            precio: {
              value: cartInfo.subtotal,
              key: 'SELL_BY_PROD_T.FOOTER_CATALOG.SUBTOTAL_TYPE',
            },
            total: {
              value: cartInfo?.DiscountAmount,
              key: 'SELL_BY_PROD_T.FOOTER_CATALOG.DISCOUNT_TYPE',
            },
            costo: {
              value: cartInfo?.itbms || null,
              key: 'SELL_BY_PROD_T.FOOTER_CATALOG.ITBMS_TYPE',
            },
            acciones: {
              value: cartInfo?.margin.toFixed(2) || null,
              key: 'SELL_BY_PROD_T.FOOTER_CATALOG.MARGIN_TYPE',
            },
            margen: {
              value: cartInfo?.total || null,
              key: 'SELL_BY_PROD_T.FOOTER_CATALOG.TOTAL_TYPE',
            },
          }
        : seeCostProd && !seeMargenProd
        ? {
            precio: {
              value: cartInfo.subtotal,
              key: 'SELL_BY_PROD_T.FOOTER_CATALOG.SUBTOTAL_TYPE',
            },
            total: {
              value: cartInfo?.DiscountAmount,
              key: 'SELL_BY_PROD_T.FOOTER_CATALOG.DISCOUNT_TYPE',
            },
            costo: {
              value: cartInfo?.itbms || null,
              key: 'SELL_BY_PROD_T.FOOTER_CATALOG.ITBMS_TYPE',
            },
            acciones: {
              value: cartInfo?.total || null,
              key: 'SELL_BY_PROD_T.FOOTER_CATALOG.TOTAL_TYPE',
            },
          }
        : !seeCostProd && seeMargenProd
        ? {
            cantidad: {
              value: cartInfo.subtotal,
              key: 'SELL_BY_PROD_T.FOOTER_CATALOG.SUBTOTAL_TYPE',
            },
            precio: {
              value: cartInfo?.DiscountAmount,
              key: 'SELL_BY_PROD_T.FOOTER_CATALOG.DISCOUNT_TYPE',
            },
            total: {
              value: cartInfo?.itbms || null,
              key: 'SELL_BY_PROD_T.FOOTER_CATALOG.ITBMS_TYPE',
            },
            margen: {
              value: cartInfo?.total || null,
              key: 'SELL_BY_PROD_T.FOOTER_CATALOG.TOTAL_TYPE',
            },
            acciones: {
              value: cartInfo?.margin.toFixed(2) || null,
              key: 'SELL_BY_PROD_T.FOOTER_CATALOG.MARGIN_TYPE',
            },
          }
        : {
            cantidad: {
              value: cartInfo.subtotal,
              key: 'SELL_BY_PROD_T.FOOTER_CATALOG.SUBTOTAL_TYPE',
            },
            precio: {
              value: cartInfo?.DiscountAmount,
              key: 'SELL_BY_PROD_T.FOOTER_CATALOG.DISCOUNT_TYPE',
            },
            total: {
              value: cartInfo?.itbms || null,
              key: 'SELL_BY_PROD_T.FOOTER_CATALOG.ITBMS_TYPE',
            },
            acciones: {
              value: cartInfo?.total || null,
              key: 'SELL_BY_PROD_T.FOOTER_CATALOG.TOTAL_TYPE',
            },
          }

    const cartColumns = [
      {
        Header: t('SELL_BY_PROD_T.CODE_LABEL'),
        accessor: 'codigo',
      },
      {
        Header: t('SELL_BY_PROD_T.DESCRIPTION_LABEL'),
        accessor: 'descripcion',
      },
      {
        Header: t('SELL_BY_PROD_T.QUANTITY_LABEL'),
        accessor: 'cantidad',
        Footer:
          (!seeCostProd && !seeMargenProd) ||
          (!seeCostProd && seeMargenProd) ? (
            <FooterTextValue
              value={footerValues.cantidad.value}
              type={t(footerValues.cantidad.key)}
              rounded
            />
          ) : (
            <></>
          ),
      },
      {
        Header: t('SELL_BY_PROD_T.PRICE_LABEL'),
        accessor: 'precio',
        Footer: (
          <FooterTextValue
            value={footerValues.precio.value}
            type={t(footerValues.precio.key)}
            rounded
          />
        ),
      },
      {
        Header: t('SELL_BY_PROD_T.TOTAL_LABEL'),
        accessor: 'total',
        Footer: (
          <FooterTextValue
            value={footerValues.total.value}
            type={t(footerValues.total.key)}
            rounded
          />
        ),
      },
      {
        Header: t('SELL_BY_PROD_T.COST_LABEL'),
        accessor: 'costo',
        Footer: seeCostProd ? (
          <FooterTextValue
            value={footerValues.costo.value}
            type={t(footerValues.costo.key)}
          />
        ) : (
          <></>
        ),
      },
      {
        Header: t('SELL_BY_PROD_T.MARGIN_LABEL'),
        accessor: 'margen',
        Footer: seeMargenProd ? (
          <FooterTextValue
            value={footerValues.margen.value}
            type={t(footerValues.margen.key)}
            seeMargenProd={seeMargenProd}
            rounded
          />
        ) : (
          <></>
        ),
      },
      {
        Header: t('SELL_BY_PROD_T.ACCIONES'),
        accessor: 'acciones',
        Footer: (
          <FooterTextValue
            value={footerValues.acciones.value}
            type={t(footerValues.acciones.key)}
            seeMargenProd={seeMargenProd}
            rounded
          />
        ),
      },
    ]

    if (!seeCostProd) {
      delete cartColumns[5]
    }
    if (!seeMargenProd) {
      delete cartColumns[6]
    }

    setCartColumns(cartColumns)
  }
  useEffect(() => {
    getCartColumns()
  }, [cart])

  return { columns: cartColumns }
}
