import React, { useState } from 'react'
import './navigation.css'
import VerticalNav from '../VerticalNav'
import HorizontalNav from '../HorizontalNav'
import Overlay from 'Components/Overlay'

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [drawerWidth, setDrawerWidth] = useState(70)

  const handleDrawer = () => {
    setIsOpen(!isOpen)
    !isOpen ? setDrawerWidth(320) : setDrawerWidth(70)
  }
  return (
    <>
      <div className="general-navigation">
        <VerticalNav
          isOpen={isOpen}
          drawerWidth={drawerWidth}
          handleDrawer={handleDrawer}
        />
        <Overlay isOpen={isOpen} handleDrawer={handleDrawer} />
        <HorizontalNav home={null} handleDrawer={handleDrawer} />
      </div>
    </>
  )
}

export default Navigation
