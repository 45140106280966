import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import img from '../../Assets/Images/Login/no.svg'
import { t } from 'i18next'

const ModalCustomerCounted = ({
  showModalCustomerCounted,
  setShowModalCustomerCounted,
  customerCounted,
  addCustomerCounted,
  setCustomerCounted,
}) => {
  const [error, setError] = useState('')
  const handleCustomerCounted = e => {
    setCustomerCounted(e.target.value)
  }

  useEffect(() => {
    if (customerCounted && customerCounted.trim() !== '') {
      setError('')
    }
  }, [showModalCustomerCounted, customerCounted])

  return (
    <Modal
      className="clienteContadoModal"
      show={showModalCustomerCounted}
      centered
      backdrop="static"
      backdropClassName="cliente-contado-backdrop">
      <div className="row">
        <div className="col-11">
          <h2>{t('customer.CustomerCounted.TitleModalSelect')}</h2>
        </div>
        <div className="col-1">
          <img
            className="img-close-modal"
            src={img}
            alt="close"
            onClick={() => {
              setShowModalCustomerCounted(false)
            }}
          />
        </div>
      </div>
      <input
        type="text"
        className="insert-customer-counted"
        placeholder="Cliente Contado"
        value={customerCounted}
        onChange={handleCustomerCounted}
      />
      {error && <p className="field-required-camp">{t(error)}</p>}

      <button
        className="btn"
        onClick={
          !customerCounted || customerCounted.trim() === ''
            ? () => setError('CANCEL_DISPATCH_MODAL.REQUIRED_CAMP')
            : addCustomerCounted
        }>
        {t('customer.CustomerCounted.Add')}
      </button>
    </Modal>
  )
}

export default ModalCustomerCounted
