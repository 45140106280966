import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useTable, useGlobalFilter } from 'react-table'
import Search from '../../Assets/Images/Departamentos/search.png'
import './table.css'
import { Filter, EyeSlash, Eye } from 'react-bootstrap-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquarePen, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FormatMoney } from 'Utils/functions.js'
import { VAPermissions } from 'Utils/constants'
import useValidatePermission from 'Hooks/useValidatePermission'
import { t } from 'i18next'
import { CustomSwitch } from 'Components/Switch/CustomSwitch.jsx'
import { useFreeTaxes } from 'state/context/CotizationTaxesContext'
function FilteringTable({
  data,
  columns,
  isProductPage,
  topButtons,
  hasButtons,
  hideFooter,
  isInventory,
  setHiddenColumns,
  hiddenColumns,
  hiddenColumnDiscount,
  handleCartEdit,
  handleCartPopulation,
  generateSalesOrder,
  isPriceNotApproved,
  isSaleOrderGenerated,
  showDiscountOption,
  visibleIcons,
  isDispatchGenerated,
}) {
  const isUserAllowed = useValidatePermission(VAPermissions.createOrder)
  const currentPath = location.pathname

  const { freeTaxes, setFreeTaxes } = useFreeTaxes()
  const handleSwitchChange = e => {
    setFreeTaxes(e.target.checked)
  }

  const seeWarehouseMPD1 = useValidatePermission(
    VAPermissions.seeWareHouse_D1_MP
  )

  const filteredData = useMemo(() => {
    if (!seeWarehouseMPD1) {
      return data?.filter(
        item =>
          !item?.['Código']?.includes('D1') && !item?.['Código']?.includes('MP')
      )
    } else {
      return data
    }
  }, [data, seeWarehouseMPD1])

  const tableInstance = useTable(
    {
      columns,
      data: filteredData,
      initialState: {
        hiddenColumns,
      },
    },
    useGlobalFilter
  )

  // const showButtonsEdit = () => {
  //   if (!visibleIcons) {
  //     setEditBotton('block')
  //   } else {
  //     setEditBotton('none')
  //   }
  // }

  // useEffect(() => {
  //   showButtonsEdit()
  // }, [data])

  const inventoryFilter = useRef(null)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    allColumns,
    state,
    setGlobalFilter,
  } = tableInstance

  // TABLE LOGIC
  const [showInventoryFilters, setShowInventoryFilters] = useState(false)

  // GLOBAL FILTER
  function GlobalFilter({ globalFilter, setGlobalFilter }) {
    return (
      <div className="inventory-search">
        <input
          value={globalFilter || ''}
          onChange={e => {
            setGlobalFilter(e.target.value)
          }}
          autoFocus
          placeholder="Buscar tienda por nombre o código"
        />
        <img className="inv-search-icon" src={Search} alt="Search icon" />
      </div>
    )
  }

  useEffect(() => {
    const handler = e => {
      if (!inventoryFilter?.current?.contains(e.target)) {
        setShowInventoryFilters(false)
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  }, [])

  useEffect(() => {
    const visibleColumns = allColumns
      .filter(column => column.isVisible && column.hideEnabled)
      .map(column => ({ ColumnName: column.Header, Status: column.isVisible }))
    // check if "Descuento" column is visible return true or false
    const isDiscountVisible = visibleColumns.some(
      column => column.ColumnName === 'Descuento'
    )
    if (hiddenColumnDiscount) {
      hiddenColumnDiscount(isDiscountVisible)
    }
    setHiddenColumns(visibleColumns)
  }, [state, allColumns])

  return (
    <>
      <div className="inventory-topbar">
        <div className="inventory-filter-options">
          <div>
            <button
              className="inventory-filter-button"
              onClick={() => setShowInventoryFilters(!showInventoryFilters)}>
              <Filter />
            </button>
            {showInventoryFilters && (
              <div ref={inventoryFilter} className="inventory-filters">
                {allColumns.map(column =>
                  column.hideEnabled &&
                  column.id !== 'Tienda' &&
                  column.id !== 'Código' &&
                  column.id !== 'Disponible' ? (
                    <label
                      id={column.id}
                      className="inventory-filter-option"
                      key={column.id}>
                      <input
                        id={column.id}
                        type="checkbox"
                        {...column.getToggleHiddenProps()}
                      />
                      {column.id}
                      {column.isVisible ? <Eye /> : <EyeSlash />}
                    </label>
                  ) : null
                )}
              </div>
            )}
          </div>
          {isSaleOrderGenerated && !isPriceNotApproved && isUserAllowed && (
            <button
              className={'inventory-sales-order-button ms-2'}
              onClick={generateSalesOrder}>
              {t('GenerateSalesOrder')}
            </button>
          )}

          {isDispatchGenerated &&
            !isPriceNotApproved &&
            isUserAllowed &&
            currentPath.includes('editar_cotizacion') && (
              <>
                <div className="switchContainer">
                  <CustomSwitch
                    checked={freeTaxes}
                    onChange={handleSwitchChange}
                  />
                </div>
                <label className="textSwitchContainer">
                  {t('FreeTaxQuotations')}
                </label>
              </>
            )}
        </div>
        {isProductPage ? null : (
          <GlobalFilter
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
        {hasButtons ? topButtons : null}
      </div>
      <div className={`scrollable ${isInventory ? 'tableContainer' : ''}`}>
        <table className="inventory-table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, indez) => (
                  <th key={indez} {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row)
              return (
                <tr key={index} {...row.getRowProps()}>
                  {row.cells.map((cell, indez) => {
                    return cell.column.Header === 'Imagen' ? (
                      <td
                        {...cell.getCellProps({
                          style: {
                            maxWidth: '200px',
                          },
                        })}>
                        <img
                          src={cell.value}
                          alt="product-image"
                          style={{ maxWidth: '50px' }}
                        />
                      </td>
                    ) : cell.column.Header === 'Precio Unitario' ||
                      cell.column.Header === 'Descuento' ? (
                      <td {...cell.getCellProps()}>
                        $ {FormatMoney(cell.value, false, 2)}
                      </td>
                    ) : cell.column.Header === 'Precio Total' ? (
                      <td {...cell.getCellProps()}>
                        $ {FormatMoney(cell.value, true, 2)}
                      </td>
                    ) : cell.column.Header === 'Acciones' ? (
                      <td key={cell.value} {...cell.getCellProps()}>
                        <div className="inventory-actions">
                          <FontAwesomeIcon
                            icon={faSquarePen}
                            onClick={() => {
                              handleCartPopulation()
                            }}
                            style={{
                              display: hasButtons,
                              fontSize: '2.5rem',
                            }}
                          />
                          <FontAwesomeIcon
                            style={{
                              display: hasButtons,
                              color: '#F64E60',
                              fontSize: '2rem',
                            }}
                            icon={faTrashCan}
                            onClick={() => {
                              handleCartEdit(cell.row.original, cell.row.id)
                            }}
                          />
                        </div>
                      </td>
                    ) : (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
          {hideFooter ? null : (
            <tfoot
              className={isInventory ? 'stickyFooter' : null}
              style={{
                borderBottom: isProductPage && 'none',
                borderLeft: isProductPage && 'none',
                borderRight: isProductPage && 'none',
              }}>
              {footerGroups.map((group, index) => (
                <tr
                  key={index}
                  {...group.getFooterGroupProps()}
                  className="hidde-border-bottom">
                  {group.headers.map((column, indez) => {
                    return column.Header === 'Referencia' ? (
                      <td
                        key={indez}
                        style={{
                          border: isProductPage && 'none',
                          verticalAlign: 'top',
                          width: '18%',
                        }}
                        {...column.getFooterProps()}>
                        {column.render('Footer')}
                      </td>
                    ) : column.Header === 'Precio Unitario' ? (
                      !showDiscountOption ? (
                        <td
                          key={indez}
                          style={{
                            border: isProductPage && 'none',
                            verticalAlign: 'top',
                          }}
                          {...column.getFooterProps()}>
                          {column.render('Footer')}
                        </td>
                      ) : (
                        <td
                          key={indez}
                          style={{ border: isProductPage && 'none' }}></td>
                      )
                    ) : (
                      <td
                        key={indez}
                        style={{ border: isProductPage && 'none' }}
                        {...column.getFooterProps()}>
                        {column.render('Footer')}
                      </td>
                    )
                  })}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>
    </>
  )
}
export default FilteringTable
