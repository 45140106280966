import React from 'react'
import { images } from 'Assets/Images'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'

const DEFAULT_VALUE = 'Precio no incluye ITBMS'

export const Square1ToPrintNRegular = ({ products, i }) => {
  const { templateRef } = usePrinterContext()

  const prod = products?.content?.products?.[0]
  const terms = products?.content?.terms || DEFAULT_VALUE

  return (
    <div ref={el => (templateRef.current[i] = el)}>
      <div
        id="printer-preview-left-canvas"
        className="printer-preview-left-canvas">
        <div className="printer-preview-left-content-body">
          <p className="printer-preview-left-content-body-title">
            {prod?.ProductName || ''}
          </p>
          <p className="printer-preview-left-content-body-sku">
            {prod?.Sku || ''}
          </p>
          {prod?.BasePrice && (
            <div className="printer-preview-left-content-body-price-wrapper">
              <p className="printer-preview-mini-text">$</p>
              <p
                className="printer-preview-left-content-body-price"
                style={{ fontSize: '200px', marginTop: '78px' }}>
                {prod?.BasePrice?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p className="printer-preview-mini-text">
                {prod?.BasePrice?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
        </div>
        <div className="printer-preview-left-content-footer">
          <div className="printer-preview-content-footer-input-wrapper">
            <p
              className="printer-preview-left-content-footer-legal-1"
              style={{ border: 'none', textAlign: 'end' }}>
              {terms}
            </p>
          </div>
        </div>
        <img
          className="printer-preview-left-image"
          src={images.printer.templates.novey.regular1}
          alt="template-preview"
          style={{ opacity: 0 }}
        />
      </div>
    </div>
  )
}
