/** @format */

import { SET_COMPANY, SET_WAREHOUSE } from 'redux/types'

const initState = {
  CompanyCode: '',
  CompanyName: '',
  CompanyId: '',
  WarehouseCode: '',
  WarehouseId: '',
  WarehouseDescription: '',
  WarehouseName: '',
}

export default function companyReducer(state = initState, action) {
  switch (action.type) {
    case SET_COMPANY:
      return {
        ...state,
        ...action.payload,
      }
    case SET_WAREHOUSE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
