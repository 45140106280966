/* eslint-disable no-fallthrough */
import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  ADD_ONE,
  ADD_MANY,
  REMOVE_ONE,
  SET_DISCOUNT,
  EMPTY_CART,
  IS_LOADING_ADDRESS,
} from '../types'

import { agregarCotizacion } from '../actions/cotizacionActions'

const initialState = {
  isLoadingProduct: false,
  cart: [],
}

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_CART:
      if (action?.payload?.product?.Price) {
        const productId =
          action.payload?.product?.ProductId ||
          action.payload?.product?.productId
        if (
          state.cart.findIndex(product => product.ProductId === productId) !==
          -1
        ) {
          const cart = state.cart.reduce((cartAcc, product) => {
            if (product.ProductId === productId) {
              cartAcc.push({
                ...action.payload.product,
              })
            } else {
              cartAcc.push(product)
            }
            return cartAcc
          }, [])
          return { ...state, cart }
        }
        return {
          ...state,
          cart: [
            ...state.cart,
            {
              ...action.payload.product,
              Quantity: action.payload.qty,
            },
          ],
        }
      }

    case REMOVE_FROM_CART:
      return {
        ...state,
        cart: state.cart.filter(
          product => product.ProductId !== action.payload.product.ProductId
        ),
      }

    case ADD_ONE:
      if (action?.payload?.product?.Price) {
        return {
          ...state,
          cart: state.cart.map(p => {
            if (p.ProductId === action.payload.product.ProductId) {
              const Quantity = action.payload.newQuantity
              const DiscountAmount =
                p.BasePrice - p.Price < 0 ? 0 : p.BasePrice - p.Price
              const DiscountPct =
                ((p.BasePrice - p.Price) * 100) / p.Price < 0
                  ? 0
                  : ((p.BasePrice - p.Price) * 100) / p.Price
              return {
                ...p,
                Quantity,
                Amount: p.Price * Quantity,
                Tax: p.Price * Quantity * p.TaxCategoryValue,
                AmountWithTax:
                  p.Price * Quantity + p.Price * Quantity * p.TaxCategoryValue,
                DiscountAmount,
                DiscountAmountLocal: DiscountAmount,
                DiscountPct,
              }
            }
            return p
          }),
        }
      }

    case ADD_MANY:
      return {
        ...state,
        cart: state.cart.map(p => {
          if (p.ProductId === action.payload.product.ProductId) {
            const Quantity = action.payload.numberOfProd
            const DiscountAmount =
              p.BasePrice - p.Price < 0 ? 0 : p.BasePrice - p.Price
            const DiscountPct =
              ((p.BasePrice - p.Price) * 100) / p.Price < 0
                ? 0
                : ((p.BasePrice - p.Price) * 100) / p.Price
            return {
              ...p,
              Quantity,
              Amount: p.Price * Quantity,
              Tax: p.Price * Quantity * p.TaxCategoryValue,
              AmountWithTax:
                p.Price * Quantity + p.Price * Quantity * p.TaxCategoryValue,
              DiscountAmount,
              DiscountAmountLocal: DiscountAmount,
              DiscountPct,
            }
          }
          return p
        }),
      }

    case REMOVE_ONE:
      return {
        ...state,
        cart: state.cart
          .map(p => {
            if (p.ProductId === action.payload.product.ProductId) {
              const Quantity = action.payload.newQuantity
              const DiscountAmount =
                p.BasePrice - p.Price < 0 ? 0 : p.BasePrice - p.Price
              const DiscountPct =
                ((p.BasePrice - p.Price) * 100) / p.Price < 0
                  ? 0
                  : ((p.BasePrice - p.Price) * 100) / p.Price
              const updatedProduct = {
                ...p,
                Quantity,
                Amount: p.Price * Quantity,
                Tax: p.Price * Quantity * p.TaxCategoryValue,
                AmountWithTax:
                  p.Price * Quantity + p.Price * Quantity * p.TaxCategoryValue,
                DiscountAmount,
                DiscountAmountLocal: DiscountAmount,
                DiscountPct,
              }
              if (updatedProduct.Quantity > 0) {
                return updatedProduct
              }
              return null
            }
            return p
          })
          .filter(Boolean),
      }

    case SET_DISCOUNT:
      // eslint-disable-next-line no-case-declarations
      const productId = action.payload?.product?.ProductId
      if (
        state.cart.findIndex(product => product.ProductId === productId) !== -1
      ) {
        const cart = state.cart.map(product => {
          if (product.ProductId === productId) {
            product = {
              ...action.payload?.product,
              isPriceChanged: product.isPriceChanged,
            }
            return product
          } else {
            return product
          }
        })
        agregarCotizacion({ Lines: cart })
        return { ...state, cart }
      }
      return { ...state, cart: [...state.cart] }
    case EMPTY_CART:
      return { ...state, cart: [] }

    case IS_LOADING_ADDRESS:
      return { ...state, isLoadingProduct: action.payload.isLoadingProduct }
    case 'IS_PRICE_CHANGED':
      // eslint-disable-next-line no-case-declarations
      const changeCart = [
        ...state.cart.slice(0, action.payload.index),
        {
          ...state.cart[action.payload.index],
          isPriceChanged: action.payload.value,
        },
        ...state.cart.slice(action.payload.index + 1),
      ]

      return {
        ...state,
        cart: [...changeCart],
      }
    default:
      return state
  }
}
