export const companyTypes = [
  '64',
  '22',
  '26',
  '04',
  '09',
  '07',
  '75',
  '72',
  '81',
  '82',
  '83',
  '84',
]
export const personTypes = ['22', '26', '11', '00', '81', '82', '83', '84']
