import React from 'react'
import { Card, CardContent, CardMedia, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import './card-deal.css'

const CardDeal = ({ image, title, copy, link }) => {
  return (
    <Link
      to={link}
      style={{ textDecoration: 'none' }}
      className="vad-card-deal__container">
      <Card
        sx={{
          maxWidth: 388,
          height: '100%',
          borderRadius: 0,
          boxShadow: '2px 5px 20px 1px rgb(0 0 0 / 20%);',
        }}
        className="vad-card-deal__wrapper">
        <CardMedia
          className="productCardImage"
          component="img"
          alt="imagen-oferta"
          image={image}
        />
        <CardContent>
          <Typography variant="h3" fontSize={16} mb={1}>
            {title}
          </Typography>
          <Typography paragraph={true} fontSize={15} mb={0}>
            {copy}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  )
}

export default CardDeal
