export const getPriceListPayload = (list, customerId, isUpdate = false) => {
  return {
    CustomerId: customerId,
    PriceListId: null,
    ListPriceListId: list?.map((list, i) => ({
      PriceListId: list,
      DefaultPriceList: isUpdate ? false : i === 0,
      Status: true,
    })),
  }
}
