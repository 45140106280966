/* eslint-disable no-useless-catch */
import {
  createQuickListService,
  getAllQuickListsService,
} from 'redux/services/quickListsServices'

export const getAllQuickListsAction = payload => async () => {
  try {
    const response = await getAllQuickListsService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return response
    }
  } catch (err) {
    throw err
  }
}

export const createQuickListAction = payload => async () => {
  try {
    const response = await createQuickListService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return response
    }
  } catch (err) {
    throw err
  }
}
