import React, { useEffect } from 'react'
import CacheBuster from 'react-cache-buster'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'

import { VAContextProvider } from '../Context/VAContext'
import routes from './Routes'
import AppRoutes from './app.router'
// REDUX
import { Provider } from 'react-redux'
import store, { persistor } from '../redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { googleMapsApiKey, history } from '../Utils/constants'
import Loading from 'Components/Loading'
import analytics from 'shared/Analytics'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { CustomerFormContextProvider } from 'state/context/CustomerFormContext'
import { FreeTaxesProvider } from 'state/context/CotizationTaxesContext'
import { PrinterTemplatesProvider } from 'state/context/PrinterTemplatesContext'
import { LoadScript } from '@react-google-maps/api'
function WebRoutes() {
  const version = require('../../package.json').version
  const enabledCache = process.env.REACT_APP_CACHE_BUSTER_ENABLE

  useEffect(() => {
    const isEnable = process.env.REACT_APP_SMARTLOOK_ENABLE === 'true'
    isEnable && analytics.initialize()
  }, [])

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={enabledCache}
      isVerboseMode={false}
      loadingComponent={<Loading full />}
      metaFileDirectory={'../'} // If public assets are hosted somewhere other than root on your server.
    >
      <HelmetProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <CustomerFormContextProvider>
              <PrinterTemplatesProvider>
                <FreeTaxesProvider>
                  <VAContextProvider>
                    <Helmet>
                      <meta
                        httpEquiv="Content-Security-Policy"
                        content={`
                  img-src 'self' data: 
                  https://storage.googleapis.com
                  https://maps.googleapis.com
                  https://maps.gstatic.com
                  https://*.blob.core.windows.net;
                  object-src 'none';
                `}
                      />
                      <meta
                        httpEquiv="X-Content-Type-Options"
                        content="nosniff"
                      />
                      <meta name="referrer" content="no-referrer" />
                      <meta
                        httpEquiv="Permissions-Policy"
                        content="geolocation=(self 'https://www.google.com/maps'), microphone=(), camera=(), fullscreen=(), payment=()"
                      />
                    </Helmet>
                    <Router
                      history={history}
                      getUserConfirmation={() => {
                        /* Empty callback to block the default browser prompt */
                      }}>
                      <QueryParamProvider adapter={ReactRouter5Adapter}>
                        <LoadScript
                          googleMapsApiKey={googleMapsApiKey}
                          libraries={['places']}>
                          <Switch>
                            {routes.map(route => (
                              <AppRoutes
                                key={route.path}
                                path={route.path}
                                exact={route.exact}
                                isPrivate={route.isPrivate}
                                component={route.element}
                              />
                            ))}
                          </Switch>
                        </LoadScript>
                      </QueryParamProvider>
                    </Router>
                  </VAContextProvider>
                </FreeTaxesProvider>
              </PrinterTemplatesProvider>
            </CustomerFormContextProvider>
          </PersistGate>
        </Provider>
      </HelmetProvider>
    </CacheBuster>
  )
}

export default WebRoutes
