import React, { useState } from 'react'
import { useTable, useGlobalFilter } from 'react-table'
import './tableProductListPrinter.css'
import Pagination from 'Components/Pagination'
import { t } from 'i18next'
import { images } from 'Assets/Images'
export function TablePrintedProductList({
  data,
  columns,
  handleGetData,
  pageSize,
  setPageSize,
}) {
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: [],
    },
    useGlobalFilter
  )
  const [currentPage, setCurrentPage] = useState(data?.[0]?.currentPage || 1)
  const [firstIndex, setFirstIndex] = useState(0)
  const [lastIndex, setLastIndex] = useState(3)
  const pageNums = []
  const totalPages = Math.ceil(data?.[0]?.TotalRegisters / pageSize)

  for (let i = 1; i <= totalPages; i++) {
    pageNums.push(i)
  }
  const handleChangePage = page => {
    setCurrentPage(page)
    handleGetData({ pageSize, currentPage: page })
  }
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance

  return (
    <div className="printer-table-wrapper-table">
      <table
        className="printer-table table table-responsive printer-table-wrapper-table-body"
        {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, indez) => (
                <>
                  <th
                    className="row-center-text blod-font"
                    {...column.getHeaderProps()}
                    colSpan={
                      column?.children && column?.children?.length > 0
                        ? column.children?.length
                        : 1
                    }
                    key={'m' + indez}>
                    {column.Header.includes('Check')
                      ? ''
                      : column.render('Header')}
                  </th>
                </>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row)
            return (
              <tr key={index} {...row.getRowProps()}>
                {row.cells.map((cell, indez) => {
                  return cell.column.Header.includes('Imagen') ? (
                    <td {...cell.getCellProps()} className="">
                      {/* {formatValue(value, indexess, cell.column.Header)} */}
                      <img
                        src={cell.row.values[cell.column.Header]}
                        alt={cell.row.values?.Imagen}
                        className="print-table-product-image"
                      />
                    </td>
                  ) : cell.column.Header.includes('Acciones') ? (
                    <td
                      {...cell.getCellProps()}
                      key={'without' + indez}
                      className="">
                      <a
                        href={cell.row.values[cell.column.Header]}
                        target="_blank"
                        rel="noreferrer">
                        <img
                          src={images.printer.pdf}
                          alt={cell.row.values[cell.column.Header]}
                          className="print-table-product-image"
                        />
                      </a>
                    </td>
                  ) : (
                    <td
                      {...cell.getCellProps()}
                      className="show-cell-report-content"
                      key={'without' + indez}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="footer-table-printer" style={{ paddingRight: 16 }}>
        <div className="cs-footer-content">
          <p data-testid="SHOW_PAGINATION_LABEL">
            {t('SEARCH_CLIENT_T.SHOW_PAGINATION_LABEL', {
              registers: data?.[0]?.TotalRegisters,
              pageSize: pageSize * currentPage - (pageSize - 1),
              elementsPerPage: pageSize * currentPage,
            })}
          </p>
          <select
            name="changePageSize"
            id="pageSize_selector"
            onChange={e => {
              setPageSize(e.target.value)
              handleGetData({ pageSize: e.target.value, currentPage: 1 })
            }}
            value={pageSize}>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </select>
        </div>
        <Pagination
          type={'pagination'}
          firstIndex={firstIndex}
          lastIndex={lastIndex}
          currentPage={currentPage}
          pageNums={pageNums}
          setCurrentPage={handleChangePage}
          setFirstIndex={setFirstIndex}
          setLastIndex={setLastIndex}
          margin={0}
        />
      </div>
    </div>
  )
}
