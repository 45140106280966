import React from 'react'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { Square1 } from './cmf/Square1'
import { Square2 } from './cmf/Square2'
import { Square3 } from './cmf/Square3'
import { Square1NRegular } from './regular/Square1'
import { Square2NRegular } from './regular/Square2'
import { Square1NOffer } from './offer/Square1'
import { Square2NOffer } from './offer/Square2'
import { Square1NCmfOffer } from './cmfOffer/Square1'
import { Square2NCmfOffer } from './cmfOffer/Square2'
import { Square3NCmfOffer } from './cmfOffer/Square3'
import { Square3NRegular } from './regular/Square3'
import { Square3NOffer } from './offer/Square3'

export const REGULAR_CMF = 'REGULAR_CMF'
export const OFFER_CMF = 'OFFER_CMF'
export const REGULAR = 'REGULAR'
export const OFFER = 'OFFER'

export const NoveyTemplates = ({ products, i }) => {
  const {
    currentPage,
    allTemplatesContent,
    showAllTemplates,
    selectedTemplateType,
  } = usePrinterContext()

  const isCurrentPage = products.page === currentPage
  if (!isCurrentPage && !showAllTemplates) return null

  const currentTemplate = allTemplatesContent.find(
    content => content.page === currentPage
  )?.template?.[0]

  const isRegCMF = selectedTemplateType?.key === REGULAR_CMF
  const isOfferCMF = selectedTemplateType?.key === OFFER_CMF
  const isReg = selectedTemplateType?.key === REGULAR
  const isOffer = selectedTemplateType?.key === OFFER

  if (isOfferCMF) {
    return (
      <>
        {currentTemplate === 'square-1' && (
          <Square1NCmfOffer i={i} products={products} />
        )}
        {currentTemplate === 'square-2' && (
          <Square2NCmfOffer i={i} products={products} />
        )}
        {currentTemplate === 'square-3' && (
          <Square3NCmfOffer i={i} products={products} />
        )}
        {currentTemplate === 'square-4' && (
          <Square3NCmfOffer i={i} products={products} />
        )}
      </>
    )
  }
  if (isReg) {
    return (
      <>
        {currentTemplate === 'square-1' && (
          <Square1NRegular i={i} products={products} />
        )}
        {currentTemplate === 'square-2' && (
          <Square2NRegular i={i} products={products} />
        )}
        {currentTemplate === 'square-3' && (
          <Square3NRegular i={i} products={products} />
        )}
        {currentTemplate === 'square-4' && (
          <Square3NRegular i={i} products={products} />
        )}
      </>
    )
  }
  if (isOffer) {
    return (
      <>
        {currentTemplate === 'square-1' && (
          <Square1NOffer i={i} products={products} />
        )}
        {currentTemplate === 'square-2' && (
          <Square2NOffer i={i} products={products} />
        )}
        {currentTemplate === 'square-3' && (
          <Square3NOffer i={i} products={products} />
        )}
        {currentTemplate === 'square-4' && (
          <Square3NOffer i={i} products={products} />
        )}
      </>
    )
  }

  if (isRegCMF) {
    return (
      <>
        {currentTemplate === 'square-1' && (
          <Square1 i={i} products={products} />
        )}
        {currentTemplate === 'square-2' && (
          <Square2 i={i} products={products} />
        )}
        {currentTemplate === 'square-3' && (
          <Square3 i={i} products={products} />
        )}
        {currentTemplate === 'square-4' && (
          <Square3 i={i} products={products} />
        )}
      </>
    )
  }
  return null
}
