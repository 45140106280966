import React, { useState } from 'react'
import no from '../../../Assets/Images/Login/no.svg'
import './generateSalesOrder.css'
import { Formik, Field, Form } from 'formik'
import { BoxArrowUp } from 'react-bootstrap-icons'

import { t } from 'i18next'
import renderGenericMultipleSelectForm from 'Components/GenericMultipleSelectForm'
import { useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'

const GenerateSalesOrder = ({
  setShowModalGenerateSalesOrder,
  processSalesOrder,
}) => {
  const [showCreditExtraFields, setShowCreditExtraFields] = useState(true)
  const [errorCreditAccount, setErrorCreditAccount] = useState(null)
  const [errorOrderNumber, setErrorOrderNumber] = useState(null)
  const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState('')
  const [showComercialDropdown, setShowComercialDropdown] = useState(false)
  const [accountSelectedState, setAccountSelectedState] = useState('')
  const [loading, setLoading] = useState(false)
  const { creditAccountsResponse } = useSelector(state => state.catalog)

  const clientHasCreditAccount =
    creditAccountsResponse?.message !== 'ERROR' &&
    creditAccountsResponse?.Data?.length

  const disableSubmitButton = showCreditExtraFields
    ? !accountSelectedState
    : false

  const closeModalConfirmStartSalesOrder = () => {
    setShowModalGenerateSalesOrder(false)
  }

  const handleCreditRadioSelected = (isCredit, values) => {
    if (!isCredit) {
      values.orderNumber = ''
      setFile(null)
    }

    setShowCreditExtraFields(isCredit)
  }

  const onChangeFile = e => {
    const file = e.target.files[0]
    setFileName(file.name)
    setFile(file)
  }

  function validateCreditAccount(value) {
    if (value === null || value === '') {
      setErrorCreditAccount(t('GENERATE_SALES_ORDER_T.REQUIRED_CAMP'))
      return false
    }

    setErrorCreditAccount(null)
    return true
  }

  function validateOrderNumber(value) {
    if (value === null || value === '') {
      setErrorOrderNumber(t('GENERATE_SALES_ORDER_T.REQUIRED_CAMP'))
      return false
    }

    setErrorOrderNumber(null)
    return true
  }

  const validateSalesOrder = async values => {
    let isValid = true

    if (showCreditExtraFields) {
      values.file = file
      isValid =
        isValid &&
        validateCreditAccount(accountSelectedState) &&
        validateOrderNumber(values.orderNumber) &&
        accountSelectedState !== null
    }
    if (!isValid) return

    setLoading(true)

    await processSalesOrder({
      ...values,
      accountItemSelected: accountSelectedState,
    })
    setLoading(false)
  }

  return (
    <div className="modal-box-generate-sales-order">
      <div className="modal-box-generate-sales-order-container">
        <div className="confirmation-header">
          <span
            onClick={closeModalConfirmStartSalesOrder}
            className="close-modal">
            <img src={no} alt="close" />
          </span>
        </div>
        <div>
          <Formik
            initialValues={{
              credit: 'yes',
              direct: '',
              orderNumber: '',
              file: '',
            }}
            onSubmit={async values => {
              validateSalesOrder(values)
            }}>
            {({ values }) => (
              <Form>
                <div id="radio-credit" className="form-field-title">
                  {t('GENERATE_SALES_ORDER_T.COMERCIAL_CREDIT_LABEL')}
                </div>
                <div
                  role="group"
                  aria-labelledby="radio-credit"
                  className="mt-2">
                  <label className="form-field-option">
                    <Field
                      type="radio"
                      name="credit"
                      value="yes"
                      onClick={() => handleCreditRadioSelected(true, values)}
                    />
                    <span>{t('GENERATE_SALES_ORDER_T.YES_OPTION_LABEL')}</span>
                  </label>
                  <label className="form-field-option ms-5">
                    <Field
                      type="radio"
                      name="credit"
                      value="no"
                      onClick={() => handleCreditRadioSelected(false, values)}
                    />
                    <span>{t('GENERATE_SALES_ORDER_T.NO_OPTION_LABEL')}</span>
                  </label>
                </div>
                {showCreditExtraFields && (
                  <div id="credit-extra-fields" className="credit-extra-fields">
                    {clientHasCreditAccount
                      ? renderGenericMultipleSelectForm(
                          t('GENERATE_SALES_ORDER_T.EFECCTIVE_ACCOUNT_TITLE'),
                          showComercialDropdown,
                          setShowComercialDropdown,
                          accountSelectedState,
                          setAccountSelectedState,
                          creditAccountsResponse?.Data,
                          t(
                            'GENERATE_SALES_ORDER_T.EFECCTIVE_ACCOUNT_PLACEHOLDER'
                          ),
                          'DEL',
                          false,
                          'Description'
                        )
                      : null}
                    {(errorCreditAccount && errorCreditAccount !== '') ||
                    !clientHasCreditAccount ? (
                      <div className="field-error-msg">
                        {errorCreditAccount ||
                          'No se han podido rescatar las cuentas'}
                      </div>
                    ) : null}
                    <div className="form-field-title mt-4">
                      <label htmlFor="order-number">
                        {t('GENERATE_SALES_ORDER_T.BUY_ORDER_LABEL')}
                      </label>
                      <div>
                        <Field
                          className="form-field-text-input"
                          id="order-number"
                          name="orderNumber"
                          placeholder={t(
                            'GENERATE_SALES_ORDER_T.SET_BUY_ORDER_PLACEHOLDER'
                          )}
                          value={values.orderNumber}
                          onBlur={event => {
                            validateOrderNumber(event.target.value)
                            values.orderNumber = event.target.value
                          }}
                        />
                      </div>
                      {errorOrderNumber && errorOrderNumber !== '' && (
                        <div className="field-error-msg">
                          {errorOrderNumber}
                        </div>
                      )}
                    </div>
                    <div className="form-field-title mt-4">
                      <label>
                        {t('GENERATE_SALES_ORDER_T.SET_BUY_ORDER_FILE_LABEL')}
                      </label>
                      <div className="add-file">
                        <label htmlFor="sales-order-file-upload">
                          <BoxArrowUp />
                          {t(
                            'GENERATE_SALES_ORDER_T.SEARCH_FOR_DOCUMENT_LABEL'
                          )}
                        </label>
                        <input
                          id="sales-order-file-upload"
                          type="file"
                          style={{ display: 'none' }}
                          accept=".pdf,.png,.jpg,.jpeg"
                          onChange={onChangeFile}
                        />
                        {fileName ? (
                          <div className="add-file-label">{fileName}</div>
                        ) : (
                          <div className="add-file-label-placeholder">
                            {t(
                              'GENERATE_SALES_ORDER_T.FILE_NOT_SELECTED_LABEL'
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className="modal-buttons">
                  <button
                    type="submit"
                    className="btn modal-si"
                    disabled={disableSubmitButton || loading}>
                    {loading ? (
                      <CircularProgress
                        sx={{
                          '& .MuiCircularProgress-svg': { color: '#FFFFFF' },
                        }}
                        size={18}
                        style={{ marginTop: '5px' }}
                      />
                    ) : (
                      t('GENERATE_SALES_ORDER_T.CONTINUE_BUTTON_LABEL')
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default GenerateSalesOrder
