import React, { useEffect } from 'react'
import no from '../../../Assets/Images/Login/no.svg'
import './confirmStartSalesOrder.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { t } from 'i18next'
import { animateScroll as scroll } from 'react-scroll'

const ConfirmStartSalesOrder = ({
  setShowModalConfirmStartSalesOrder,
  setConfirmStartSalesOrder,
}) => {
  const confirmState = () => {
    setConfirmStartSalesOrder(true)
    setShowModalConfirmStartSalesOrder(false)
    setTimeout(() => {
      scroll.scrollToTop({
        delay: 0,
        smooth: 'linear',
        duration: 1,
      })
    }, 90)
  }

  const closeModalConfirmStartSalesOrder = () => {
    setShowModalConfirmStartSalesOrder(false)
  }

  useEffect(() => {
    if (window.scrollY > 0) {
      scroll.scrollToTop({
        delay: 0,
        smooth: 'linear',
        duration: 1,
      })
    }
  }, [])

  return (
    <div className="modal-box-confirm-start-sales-order">
      <div className="delete-confirm-container">
        <div className="confirmation-header">
          <span
            onClick={closeModalConfirmStartSalesOrder}
            className="close-modal">
            <img src={no} alt="close" />
          </span>
        </div>
        <div>
          <div className="confirmation-message">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              size={'4x'}
              style={{ color: '#ff2a40' }}
            />
            <p className="confirmation-text-delete mt-4">
              Por favor revisa y confirma que los productos,
              <br />
              cantidades y precios sean los correctos
            </p>
          </div>
          <div className="modal-buttons">
            <button
              onClick={closeModalConfirmStartSalesOrder}
              className="btn modal-si">
              Volver a la cotización
            </button>
            <button onClick={confirmState} className="btn modal-no">
              {t('GenerateSalesOrder')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmStartSalesOrder
