import Navigation from 'Components/Navigation'
import React from 'react'
import './printer-preview.css'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import CloseIcon from '@mui/icons-material/Close'
import { FormatMoney } from 'Utils/functions'
import { TemplatesSection } from './components/TemplatesSection'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import PrintIcon from '@mui/icons-material/Print'
import { Link } from 'react-router-dom'
import { Template } from './components/Template'
import { TemplateToPrint } from './components/TemplateToPrint'
import NoImage from '../../Assets/Images/Logos/no-image.png'
import { CustomCheckBox } from 'Components/CustomCheckBox'

const createArray = (length, value = 1) => {
  return Array.from({ length }, (_, index) => index + value)
}

const PrinterPreview = () => {
  const {
    selectedProducts,
    selectedTemplateType,
    templateRef,
    selectedTemplates,
    allTemplatesContent,
    setAllTemplatesContent,
    currentPage,
    setCurrentPage,
  } = usePrinterContext()

  const [addPage, setAddPage] = React.useState(allTemplatesContent.length || 1)
  const currentTemplate = allTemplatesContent.find(
    content => content.page === currentPage
  )

  const addProductToTemplate = (product, currentPage) => {
    const newAllTemplatesContent = allTemplatesContent.map(template => {
      if (template.page === currentPage) {
        return {
          ...template,
          content: {
            products: [...template.content.products, product],
          },
        }
      }
      return template
    })
    setAllTemplatesContent(newAllTemplatesContent)
  }

  const addProductToNewTemplate = product => {
    const newPage = addPage + 1
    setAllTemplatesContent([
      ...allTemplatesContent,
      {
        page: addPage + 1,
        template: [currentTemplate.template[0]],
        content: {
          products: [product],
        },
      },
    ])
    setAddPage(newPage)
    setCurrentPage(newPage)
  }

  return (
    <>
      <Navigation />
      <div className="printer-overlay" id="printer-overlay">
        <PrintIcon style={{ fontSize: '50px' }} />
        <p className="printer-overlay-text">
          Preparando la impresión, <br /> por favor espere...
        </p>
      </div>
      <div className="printer-preview-wrapper">
        <div className="printer-preview-left-wrapper">
          <p className="printer-preview-left-title">
            Preferencias de impresión <br />
            {selectedTemplateType.value}
          </p>
          <div className="printer-preview-left-image-wrapper">
            <div>
              {allTemplatesContent?.map((prods, i) => {
                return <Template key={prods.page} i={i} products={prods} />
              })}
            </div>
            <div id="to-print-elements" className="to-print-elements">
              {allTemplatesContent?.map((content, i) => {
                return <TemplateToPrint key={i} i={i} content={content} />
              })}
            </div>
          </div>
          <div className="printer-preview-left-indicator-badge-wrapper">
            {createArray(addPage).map((_, index) => (
              <div
                key={index}
                className="printer-preview-page-indicator-wrapper">
                <button
                  onClick={() => {
                    const newPage = addPage - 1
                    setAddPage(newPage)
                    const newAllTemplatesContent = allTemplatesContent.filter(
                      template => template.page !== index + 1
                    )
                    const newAllTemplatesContentWithNewPageOrder =
                      newAllTemplatesContent.map(template => {
                        if (template.page > index + 1) {
                          return {
                            ...template,
                            page: template.page - 1,
                          }
                        }
                        return template
                      })
                    setAllTemplatesContent(
                      newAllTemplatesContentWithNewPageOrder
                    )

                    setCurrentPage(1)
                    console.log(templateRef.current)
                    templateRef.current = templateRef.current.filter(
                      (_, i) => i !== index
                    )
                    console.log(templateRef.current)
                  }}
                  className="printer-preview-left-indicator-badge-minus">
                  <CloseIcon />
                </button>
                <button
                  onClick={() => setCurrentPage(index + 1)}
                  style={{
                    backgroundColor:
                      currentPage === index + 1 ? '#FF0000' : '#afafaf',
                  }}
                  className="printer-preview-left-indicator-badge">
                  {index + 1}
                </button>
              </div>
            ))}
            <button
              onClick={() => {
                const newPage = addPage + 1
                setAddPage(newPage)
                setAllTemplatesContent([
                  ...allTemplatesContent,
                  {
                    page: newPage,
                    template: [selectedTemplates[0]],
                    content: {
                      products: [],
                    },
                  },
                ])
                setCurrentPage(newPage)
              }}
              disabled={allTemplatesContent.some(
                template => template.content.products.length === 0
              )}
              className="printer-preview-left-indicator-badge-plus">
              +
            </button>
          </div>
        </div>
        <div className="printer-preview-right-wrapper">
          <p className="printer-preview-right-title">
            Etiquetas seleccionadas ({selectedProducts?.length})
          </p>
          {selectedProducts?.map(product => {
            const isProductSelected = allTemplatesContent?.find(
              template =>
                template?.page === currentPage &&
                template?.content?.products?.some(
                  prod => prod.ProductId === product.ProductId
                )
            )
            const isProductInOtherTemplate = allTemplatesContent?.find(
              template =>
                template?.page !== currentPage &&
                template?.content?.products?.some(
                  prod => prod.ProductId === product.ProductId
                )
            )
            const quantityOfProductInAllTemplates = allTemplatesContent
              .map(template => template.content.products)
              .flat()
              .filter(prod => prod.ProductId === product.ProductId).length
            return (
              <div
                key={product.ProductId}
                className="printer-preview-right-product-wrapper">
                <p
                  className={`printer-page-indicator-badge 
                  ${isProductSelected?.page === currentPage ? 'active' : ''}`}>
                  {isProductSelected?.page ||
                    isProductInOtherTemplate?.page ||
                    '-'}
                </p>
                <img
                  src={product?.ImageProduct || NoImage}
                  alt=""
                  className="printer-preview-right-product-image"
                />
                <div className="printer-preview-right-product-info">
                  <div className="printer-preview-right-product-info-wrapper">
                    <p className="printer-preview-right-product-text-title">
                      {product.ProductName}
                    </p>
                    <CustomCheckBox
                      key={product.ProductId}
                      checked={!!isProductSelected}
                      onChange={() => {
                        const newAllTemplatesContent = allTemplatesContent.map(
                          template => {
                            const needSingleProduct =
                              currentTemplate.template?.[0] === 'square-1'
                            const needTwoProducts =
                              currentTemplate.template?.[0] === 'square-2'
                            const needFourProducts =
                              currentTemplate.template?.[0] === 'square-3' ||
                              currentTemplate.template?.[0] === 'square-4'
                            const validation = needSingleProduct
                              ? isProductSelected
                              : needTwoProducts
                              ? currentTemplate.content.products.length >= 2
                              : needFourProducts
                              ? currentTemplate.content.products.length >= 4
                              : false

                            const newProducts = validation
                              ? currentTemplate.content.products.filter(
                                  prod => prod.ProductId !== product.ProductId
                                )
                              : isProductSelected
                              ? currentTemplate.content.products.filter(
                                  prod => prod.ProductId !== product.ProductId
                                )
                              : [...currentTemplate.content.products, product]

                            if (template.page === currentPage) {
                              return {
                                ...template,
                                content: {
                                  products: needSingleProduct
                                    ? [product]
                                    : newProducts,
                                },
                              }
                            }
                            return template
                          }
                        )
                        setAllTemplatesContent(newAllTemplatesContent)
                      }}
                    />
                  </div>
                  <div className="printer-preview-right-product-info-wrapper">
                    <div className="printer-preview-right-product-info-price">
                      <p className="printer-preview-right-product-text">
                        $ {FormatMoney(product.BasePrice)}
                      </p>
                      <p className="printer-preview-right-product-text-bold">
                        {selectedTemplateType.value
                          .split(' ')
                          .slice(1)
                          .join(' ')}
                      </p>
                    </div>
                    <div className="printer-preview-quantity-wrapper">
                      <button
                        className="printer-preview-quantity-button"
                        onClick={() => {
                          let removed = false
                          const newProducts =
                            currentTemplate.content.products.filter(prod => {
                              if (
                                prod.ProductId === product.ProductId &&
                                !removed
                              ) {
                                removed = true
                                return false
                              }
                              return true
                            })

                          const newAllTemplatesContent =
                            allTemplatesContent.map(template => {
                              if (template.page === currentPage) {
                                return {
                                  ...template,
                                  content: {
                                    products: newProducts,
                                  },
                                }
                              }
                              return template
                            })
                          setAllTemplatesContent(newAllTemplatesContent)
                        }}>
                        <RemoveIcon />
                      </button>
                      <input
                        className="printer-preview-quantity-input"
                        type="text"
                        disabled
                        value={quantityOfProductInAllTemplates}
                      />
                      <button
                        className="printer-preview-quantity-button"
                        onClick={() => {
                          const isSingleProduct =
                            currentTemplate.template[0] === 'square-1'
                          const isTwoProducts =
                            currentTemplate.template[0] === 'square-2'
                          const isFourProducts =
                            currentTemplate.template[0] === 'square-3' ||
                            currentTemplate.template[0] === 'square-4'
                          const currentProducts =
                            currentTemplate.content.products

                          if (isSingleProduct) {
                            if (currentProducts.length === 1) {
                              addProductToNewTemplate(product)
                              return
                            }
                            addProductToTemplate(product, currentPage)
                          }
                          if (isTwoProducts) {
                            if (currentProducts.length === 2) {
                              addProductToNewTemplate(product)
                              return
                            }
                            addProductToTemplate(product, currentPage)
                          }
                          if (isFourProducts) {
                            if (currentProducts.length === 4) {
                              addProductToNewTemplate(product)
                              return
                            }
                            addProductToTemplate(product, currentPage)
                          }
                        }}>
                        <AddIcon />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
          <div className="printer-preview-more-text-wrapper">
            <Link
              to={`/printer?type=${selectedTemplateType?.key}`}
              className="printer-preview-more-text">
              Agregar más etiquetas
            </Link>
          </div>
          <div className="divider" />
          <p className="printer-preview-right-title">Tipos de plantillas</p>
          <TemplatesSection />
        </div>
      </div>
    </>
  )
}

export default PrinterPreview
