import React from 'react'
import { images } from 'Assets/Images'
import {
  CheckCircleFill,
  PencilSquare,
  XCircleFill,
} from 'react-bootstrap-icons'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'

const DEFAULT_VALUE = 'Precio no incluye ITBMS'

export const Square1NRegular = ({ products, i }) => {
  const { currentPage, allTemplatesContent, setAllTemplatesContent } =
    usePrinterContext()

  const currentContent = allTemplatesContent.find(
    content => content.page === currentPage
  )
  const currentTerms = currentContent?.content?.terms || DEFAULT_VALUE

  const [isEditing, setIsEditing] = React.useState(false)
  const [terms, setTerms] = React.useState(currentTerms)

  const prod = products?.content?.products?.[0]

  const handleEditTerms = () => {
    const updatedContent = allTemplatesContent.map(content => {
      if (content.page === currentPage) {
        return {
          ...content,
          content: {
            ...content.content,
            terms,
          },
        }
      }
      return content
    })
    setAllTemplatesContent(updatedContent)
    setIsEditing(false)
  }

  return (
    <div
      id="printer-preview-left-canvas"
      className="printer-preview-left-canvas">
      <div className="printer-preview-left-content-body">
        <p className="printer-preview-left-content-body-title">
          {prod?.ProductName || ''}
        </p>
        <p className="printer-preview-left-content-body-sku">
          {prod?.Sku || ''}
        </p>
        {prod?.BasePrice && (
          <div className="printer-preview-left-content-body-price-wrapper">
            <p className="printer-preview-mini-text">$</p>
            <p
              className="printer-preview-left-content-body-price"
              style={{ fontSize: '200px', marginTop: '78px' }}>
              {prod?.BasePrice?.toString()?.split('.')?.[0] || ''}.
            </p>
            <p className="printer-preview-mini-text">
              {prod?.BasePrice?.toString()?.split('.')?.[1] || '00'}
            </p>
          </div>
        )}
      </div>
      <div className="printer-preview-left-content-footer">
        <div className="printer-preview-content-footer-input-wrapper">
          {isEditing && (
            <textarea
              className="printer-preview-left-content-footer-legal-1"
              type="text"
              value={terms}
              disabled={!isEditing}
              onChange={e => setTerms(e.target.value)}
              style={{
                border: isEditing ? '1px solid #000' : 'none',
                textAlign: 'end',
              }}
            />
          )}
          {!isEditing && (
            <p
              className="printer-preview-left-content-footer-legal-1"
              style={{
                border: isEditing ? '1px solid #000' : 'none',
                textAlign: 'end',
              }}>
              {terms}
            </p>
          )}
          <div className="printer-terms-edit-wrapper-1">
            {isEditing && (
              <CheckCircleFill
                onClick={handleEditTerms}
                className="printer-preview-content-check"
              />
            )}
            {isEditing && (
              <XCircleFill
                onClick={() => {
                  setIsEditing(false)
                  setTerms(DEFAULT_VALUE)
                }}
                className="printer-preview-content-circle"
              />
            )}
            {!isEditing && (
              <PencilSquare
                onClick={() => setIsEditing(true)}
                className="printer-preview-content-pencil"
              />
            )}
          </div>
        </div>
      </div>
      <img
        className="printer-preview-left-image"
        src={images.printer.templates.novey.regular1}
        alt="template-preview"
      />
    </div>
  )
}
