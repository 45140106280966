import { aludraApi } from '../../Api/axios'

const mdl38 = {
  getById: '/mdl38/Calculator/getById/',
  getByIdProductTaxonomy: '/mdl38/Calculator/getDetailByProductTaxonomy',
  doCalculator: '/mdl38/Calculator/doCalculator',
  doFormulaCalculator:
    '/mdl38/Calculator/doFormulaCalculator?CalculatorFormulaId=',
}

export const getById = async ({ id, requestId }) => {
  return aludraApi.get(mdl38.getById + id, {
    headers: {
      RequestId: requestId,
    },
  })
}

export const getByIdProductTaxonomy = async ({
  departmentId,
  categoryId,
  subCategoryId,
  productId,
  companyId,
  requestId,
}) => {
  return aludraApi.get(
    `${mdl38.getByIdProductTaxonomy}/DepartmentId/${departmentId}/CategoryId/${categoryId}/SubCategoryId/${subCategoryId}/ProductId/${productId}/CompanyId/${companyId}`,
    {
      headers: {
        RequestId: requestId,
      },
    }
  )
}
export const calculateFormula = async ({ CalculatorFormulaId, payload }) => {
  return aludraApi.post(
    `${mdl38.doFormulaCalculator}${CalculatorFormulaId}`,
    payload
  )
}
export const calculateMaterials = async ({ payload, requestId }) => {
  return aludraApi.post(mdl38.doCalculator, payload, {
    headers: {
      RequestId: requestId,
    },
  })
}
