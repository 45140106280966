import Smartlook from 'smartlook-client'

export class GlobalAnalytics {
  isInitialized = false

  constructor() {
    this.isInitialized = false
  }

  async initialize() {
    try {
      const isEnable = process.env.REACT_APP_SMARTLOOK_ENABLE === 'true'
      if (!isEnable) return
      await Smartlook.init(process.env.REACT_APP_SMARTLOOK_KEY)
      const isInitialized = Smartlook.initialized()
      sessionStorage.setItem(
        'smartLookIsIdentify',
        JSON.stringify(isInitialized)
      )
      this.isInitialized = isInitialized
    } catch (e) {
      console.log(e)
    }
  }

  async identify(userId, user) {
    if (!this.isInitialized) {
      await this.initialize()
    }
    Smartlook.identify(userId, user)
  }

  async track(eventName, options = {}) {
    if (!this.isInitialized) {
      await this.initialize()
    }
    Smartlook.track(eventName, options)
  }

  async resume() {
    await Smartlook.resume()
  }

  async pause() {
    await Smartlook.pause()
    this.isInitialized = false
  }

  async reset() {
    await Smartlook.restart()
    this.isInitialized = false
  }
}
