import React from 'react'
import No from '../../Assets/Images/Login/no.svg'
import './CheckDeliveryCostsModal.css'
import { Modal } from 'react-bootstrap'
import { ExclamationTriangleFill } from 'react-bootstrap-icons'
import { Box } from '@mui/material'

function CheckDeliveryCostsModal({
  isNotValidCostState,
  setIsNotValidCostState,
  textTitle,
}) {
  return (
    <Modal show={isNotValidCostState} centered>
      <Box>
        <div className="check-costs-modal-box">
          <div className="check-costs-modal-close">
            <img
              src={No}
              alt="close-modal"
              onClick={() => setIsNotValidCostState(false)}
            />
          </div>
          <div className="check-costs-page-container">
            <div className="check-costs-page-header">
              <ExclamationTriangleFill className="check-costs-page-warning-icon" />
              <h2>{textTitle}</h2>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default CheckDeliveryCostsModal
