import { aludraApi } from 'Api/axios'

const mdl22 = {
  searchListURL: 'mdl22/products/productCustomList/search',
  createNewListURL:
    'mdl22/products/productCustomList/addUpdateproductCustomList',
}

export const getAllQuickListsService = async payload => {
  return aludraApi.post(mdl22.searchListURL, payload)
}

export const createQuickListService = async payload => {
  return aludraApi.post(mdl22.createNewListURL, payload)
}
