import React, { useState, useCallback, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import MiniLoading from '../MiniLoading'
import './salesman.css'
import { toTitleCase } from '../../Utils/functions'
import no from '../../Assets/Images/Login/no.svg'
import {
  addSalesAgent,
  getSalesAgents,
} from '../../redux/actions/cotizacionActions'

function ChangeSalesman({ handleOpen, open, setAgent }) {
  const [salesAgents, setSalesAgents] = useState([])
  const [search, setSearch] = useState([])
  const [loading, setLoading] = useState(false)
  const [newAgent, setNewAgent] = useState({})
  const openRef = useRef(null)

  const dispatch = useDispatch()

  // REDUX STATE
  const WarehouseId = useSelector(
    state => state?.cotizacion?.QuoteHeader?.WarehouseId
  )

  // SEARCH ALL SALESMEN
  const searchSalesAgents = useCallback(async () => {
    const payload = {
      PageNumber: 1,
      PageSize: 500,
      WarehouseId,
    }
    await dispatch(
      getSalesAgents(payload, setSearch, setSalesAgents, setLoading)
    )
  }, [WarehouseId])

  // FILTERS SALESMEN
  const filterSalesman = e => {
    if (e.target.value.length > 0) {
      const newSalesman = salesAgents.filter(salesman => {
        const agent = salesman?.FullName?.toLowerCase() || ''
        const input = e.target?.value?.toLowerCase()
        return agent.includes(input)
      })
      setSearch(newSalesman)
    } else {
      searchSalesAgents()
    }
  }

  // SETS THE NEW SALESMAN
  const onChangeSalesman = e => {
    setNewAgent(JSON.parse(e.target.value))
  }

  // UPDATES AGENT IN QUOTE
  const updateAgent = () => {
    const isEmpty = Object.keys(newAgent).length === 0
    if (!isEmpty) {
      setAgent({
        SalesAgentFullName: newAgent.FullName,
        SalesAgentId: newAgent.CustomerId,
        SalesAgentNumberId: newAgent.NumberId,
        SalesAgentRoleDescription: newAgent.RoleName,
      })
      handleOpen(false)
      dispatch(addSalesAgent(newAgent.CustomerId))
    }
  }

  // CLOSE WHEN CLICKED OUTSIDE
  useEffect(() => {
    const handler = e => {
      if (!openRef.current.contains(e.target)) {
        // openRef.current.className="salesman-navbar-closed"
        handleOpen(false)
      }
    }

    document.addEventListener('mousedown', handler)

    return () => {
      document.removeEventListener('mousedown', handler)
    }
  }, [])

  // CLOSE
  const closeBar = () => {
    handleOpen(false)
    setNewAgent({})
  }

  useEffect(() => {
    if (open) {
      searchSalesAgents()
    }
  }, [open])

  return (
    <>
      <div
        className={open ? 'salesman-navbar' : 'salesman-navbar-closed'}
        ref={openRef}>
        {open && (
          <>
            <div className="close-salesman">
              <h2 className="salesman-title">
                Elige un nombre y asigna a la tarea
              </h2>
              <img src={no} alt="close" onClick={closeBar} />
            </div>
            <hr />
            <div className="search-bar-salesman">
              <input
                type="text"
                name="search-salesman"
                placeholder="Buscar"
                onChange={filterSalesman}
              />
            </div>
            <div className="salesman-list">
              {loading ? (
                <MiniLoading />
              ) : (
                <>
                  <ul>
                    {search.map((agent, index) => {
                      return (
                        <li onChange={onChangeSalesman} key={index}>
                          <input
                            type="radio"
                            id={agent.CustomerId}
                            value={JSON.stringify(agent)}
                            name="salesman"
                          />
                          <label id={agent.CustomerId}>
                            {toTitleCase(agent.FullName)}
                          </label>
                        </li>
                      )
                    })}
                  </ul>
                  <div>
                    <button
                      className="change-salesman-button"
                      onClick={updateAgent}>
                      Aplicar
                    </button>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default ChangeSalesman
