/* eslint-disable no-useless-catch */
import {
  deleteCustomerService,
  deleteListPricesService,
  fetchDistrictService,
  fetchGeneralsService,
  fetchProvincesByCountryService,
  fetchProvincesService,
  fetchTownshipService,
  getBranchesService,
  getCountriesService,
  getCustomerByIdService,
  getCustomerGroupService,
  getCustomersService,
  getDocumentTypesService,
  getFinalPriceService,
  getListPricesByCustomerService,
  getListPricesService,
  getPriceListByCustomerIdService,
  getRazonesPrecioService,
  getStadisticsService,
  getTelephoneTypesService,
  getWharehouseService,
  mdl03GetCustomerByIdService,
  registerCustomerService,
  restorePasswordService,
  saveListPricesService,
  searchCustomersService,
  updateAddressService,
  updateCustomerService,
  validateCustomerEmailService,
} from 'redux/services/clientsServices'

export const getCustomerById =
  (id, mdl03 = false, params) =>
  async () => {
    try {
      const response = await (mdl03
        ? params
          ? mdl03GetCustomerByIdService(id, true, params)
          : mdl03GetCustomerByIdService(id)
        : getCustomerByIdService(id))
      const { data, status } = response
      if (status === 200 && data?.Data) {
        return data.Data
      }
    } catch (err) {
      throw err
    }
  }

export const getWharehouseInfo = payload => async () => {
  try {
    const response = await getWharehouseService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    }
  } catch (err) {
    throw err
  }
}

export const searchCustomers = payload => async () => {
  try {
    const response = await searchCustomersService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    }
  } catch (err) {
    throw err
  }
}

export const deleteCustomers = payload => async () => {
  try {
    const response = await deleteCustomerService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    }
  } catch (err) {
    throw err
  }
}

export const getPriceListByCustomerId = customerId => async () => {
  try {
    const response = await getPriceListByCustomerIdService(customerId)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    } else {
      return data.Status
    }
  } catch (err) {
    throw err
  }
}

export const validateCustomerEmail = customerEmail => async () => {
  try {
    const response = await validateCustomerEmailService(customerEmail)
    const { data, status } = response
    if (status === 200 && data?.Info && data.Status.Code === 200) {
      return data.Info.AcceptedUser
    } else return false
  } catch (err) {
    throw err
  }
}

export const getListPricesAction = payload => async () => {
  try {
    const response = await getListPricesService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    } else {
      return data.Status
    }
  } catch (err) {
    throw err
  }
}

export const saveListPricesAction = payload => async () => {
  try {
    const response = await saveListPricesService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    } else {
      return data.Status
    }
  } catch (err) {
    throw err
  }
}

export const deleteListPricesAction = payload => async () => {
  try {
    const response = await deleteListPricesService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    } else {
      return data.Status
    }
  } catch (err) {
    throw err
  }
}

export const getListPricesByCustomerAction = payload => async () => {
  try {
    const response = await getListPricesByCustomerService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    } else {
      return data.Status
    }
  } catch (err) {
    throw err
  }
}

export const registerCustomerAction = payload => async () => {
  try {
    const response = await registerCustomerService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return response
    } else {
      return data.Status
    }
  } catch (err) {
    throw err
  }
}

export const updateCustomerAction = payload => async () => {
  try {
    const response = await updateCustomerService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return response
    } else {
      return data.Status
    }
  } catch (err) {
    throw err
  }
}

export const updateAddressAction = payload => async () => {
  try {
    const response = await updateAddressService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    } else {
      return 'Error: ' + data.Status
    }
  } catch (err) {
    // throw err;
    return 'Error: ' + err
  }
}

export const fetchGeneralsAction = payload => async () => {
  try {
    const response = await fetchGeneralsService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    } else {
      return 'Error: ' + data.Status
    }
  } catch (err) {
    // throw err;
    return 'Error: ' + err
  }
}

export const fetchProvincesAction = () => async () => {
  try {
    const response = await fetchProvincesService()
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    } else {
      return 'Error: ' + data.Status
    }
  } catch (err) {
    // throw err;
    return 'Error: ' + err
  }
}

export const fetchProvincesByCountryAction = payload => async () => {
  try {
    const response = await fetchProvincesByCountryService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    } else {
      return 'Error: ' + data.Status
    }
  } catch (err) {
    // throw err;
    return 'Error: ' + err
  }
}
export const fetchDistrictAction = payload => async () => {
  try {
    const response = await fetchDistrictService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    } else {
      return 'Error: ' + data.Status
    }
  } catch (err) {
    // throw err;
    return 'Error: ' + err
  }
}

export const fetchTownshipAction = payload => async () => {
  try {
    const response = await fetchTownshipService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    } else {
      return 'Error: ' + data.Status
    }
  } catch (err) {
    // throw err;
    return 'Error: ' + err
  }
}

export const getBranchesAction = payload => async () => {
  try {
    const response = await getBranchesService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    } else {
      return 'Error: ' + response
    }
  } catch (err) {
    // throw err;
    return 'Error: ' + err
  }
}

export const getFinalPriceAction = payload => async () => {
  try {
    const response = await getFinalPriceService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    } else {
      return 'Error: ' + response
    }
  } catch (err) {
    // throw err;
    return 'Error: ' + err
  }
}

export const getRazonesPrecioAction = payload => async () => {
  try {
    const response = await getRazonesPrecioService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    } else {
      return 'Error: ' + response
    }
  } catch (err) {
    // throw err;
    return 'Error: ' + err
  }
}

export const getCustomersAction = payload => async () => {
  try {
    const response = await getCustomersService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    } else {
      return 'Error: ' + response
    }
  } catch (err) {
    // throw err;
    return 'Error: ' + err
  }
}

export const getStadisticsAction = payload => async () => {
  try {
    const response = await getStadisticsService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    } else {
      return 'Error: ' + response
    }
  } catch (err) {
    // throw err;
    return 'Error: ' + err
  }
}

export const getCountriesAction = () => async () => {
  try {
    const response = await getCountriesService()
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    } else {
      return 'Error: ' + response
    }
  } catch (err) {
    // throw err;
    return 'Error: ' + err
  }
}
export const getTelephoneTypesAction = () => async () => {
  try {
    const response = await getTelephoneTypesService()
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    } else {
      return 'Error: ' + response
    }
  } catch (err) {
    // throw err;
    return 'Error: ' + err
  }
}
export const getCustomerGroupAction = payload => async () => {
  try {
    const response = await getCustomerGroupService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    } else {
      return 'Error: ' + response
    }
  } catch (err) {
    // throw err;
    return 'Error: ' + err
  }
}

export const getDocumentTypesAction = payload => async () => {
  try {
    const response = await getDocumentTypesService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    } else {
      return 'Error: ' + response
    }
  } catch (err) {
    // throw err;
    return 'Error: ' + err
  }
}

export const restorePasswordAction = payload => async () => {
  try {
    const response = await restorePasswordService(payload)
    const { data, status } = response
    if (status === 200 && data?.Data) {
      return data.Data
    } else {
      return response.data.Status.Message
    }
  } catch (err) {
    // throw err;
    return err
  }
}
