import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import GenericModal from 'Components/GenericModal'

export const EmailModal = () => {
  const [showModalAlertEmail, setShowModalAlertEmail] = useState(false)

  const showAlertEmailFuntion = () => {
    setShowModalAlertEmail(true)
    setTimeout(() => {
      setShowModalAlertEmail(false)
    }, 4000)
  }
  const handleShowModalAlertEmail = () => {
    if (showModalAlertEmail === false) {
      showAlertEmailFuntion()
    } else {
      setShowModalAlertEmail(false)
    }
  }
  return (
    <Modal
      show={showModalAlertEmail}
      backdrop="static"
      className="modal-clase-price-change"
      centered>
      <GenericModal
        setIsOpen={handleShowModalAlertEmail}
        text={null}
        mostrarButtons={false}
      />
    </Modal>
  )
}
