/* eslint-disable no-useless-catch */
import { getPuntosGordosByClientService } from 'redux/services/puntosGordosServices'

export const getPuntosGordosByClient = id => async () => {
  try {
    const response = await getPuntosGordosByClientService(id)
    const { data, status } = response
    if (status === 200 && data?.data) {
      return data.data
    }
  } catch (err) {
    throw err
  }
}
