import React, { useState, useEffect } from 'react'
import productoRecomendadoArrow from '../../Assets/Images/Product/producto_recomendado_arrow.svg'
import { Row } from 'react-bootstrap'
import './recommended.css'
import ProductCard from 'Components/ProductCard'

function ProductsCarrousel({ data, title, productsRef }) {
  const [firstIndex, setFirstIndex] = useState(0)
  const [lastIndex, setLastIndex] = useState(4)
  const [finalData, setFinalData] = useState(data)

  const handleNext = () => {
    if (firstIndex + 4 < finalData.length) {
      setLastIndex(lastIndex + 4)
      setFirstIndex(firstIndex + 4)
    }
  }

  const handlePrev = () => {
    if (firstIndex > 0) {
      setFirstIndex(firstIndex - 4)
      setLastIndex(lastIndex - 4)
    }
  }

  const formatData = data => {
    const numberOfFullRows = Math.floor(data.length / 4)
    let numberOfElementsLastRow = finalData.length - numberOfFullRows * 4
    while (numberOfElementsLastRow !== 4 && numberOfElementsLastRow !== 0) {
      finalData.push({ key: finalData.length, empty: true })
      numberOfElementsLastRow++
    }
  }

  const makeItClean = async products => {
    const cleanData = products.map(product => {
      const newProduct = {
        ...product,
        ProductName: product.name,
        ProductId: product.productId,
        SystemCode: product.systemCode,
        TaxCategoryValue: 0.7,
      }
      delete newProduct.name
      delete newProduct.productId
      delete newProduct.systemCode
      return newProduct
    })
    setFinalData(cleanData)
  }

  useEffect(() => {
    makeItClean(finalData)
  }, [])

  useEffect(() => {
    formatData(finalData)
  }, [finalData])

  return (
    <>
      <div className="recommended-products">
        <h2>{title}</h2>
        <div
          className={
            finalData ? 'products-carrousel' : 'products-carrousel-empty'
          }>
          {finalData && (
            <img
              src={productoRecomendadoArrow}
              onClick={handlePrev}
              style={{
                opacity: firstIndex === 0 ? '0' : '1',
                transform: 'rotate(180deg)',
              }}
              alt="left-arrow"
            />
          )}
          <div
            className={
              finalData
                ? 'products-carrousel-content'
                : 'products-carrousel-empty'
            }>
            <Row>
              {finalData ? (
                finalData.slice(firstIndex, lastIndex).map((product, index) => {
                  return (
                    <ProductCard
                      key={index}
                      product_id={product.productId}
                      data={product}
                      productsRef={productsRef}
                    />
                  )
                })
              ) : (
                <h3>No existen productos relacionados</h3>
              )}
            </Row>
          </div>
          {finalData && (
            <img
              src={productoRecomendadoArrow}
              onClick={handleNext}
              style={{ opacity: lastIndex >= finalData.length ? '0' : '1' }}
              alt="right-arrow"
            />
          )}
        </div>
      </div>
    </>
  )
}

export default ProductsCarrousel
