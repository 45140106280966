import {
  ADD_TO_LIST,
  REMOVE_FROM_LIST,
  CLEAR_LIST,
  CART_FROM_LIST,
} from '../types'

const initialState = {
  list: [],
  cartFromList: false,
}

export default function listReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_LIST:
      if (
        state.list.findIndex(
          product => product.ProductId === action.payload.ProductId
        ) === -1
      ) {
        return {
          ...state,
          list: [...state.list, action.payload],
        }
      } else {
        return {
          ...state,
          list: state.list,
        }
      }

    case REMOVE_FROM_LIST:
      return {
        ...state,
        list: state.list.filter(
          product => product.ProductId !== action.payload.ProductId
        ),
      }

    case CLEAR_LIST:
      return {
        ...initialState,
      }

    case CART_FROM_LIST:
      return {
        ...state,
        cartFromList: action.payload,
      }

    default:
      return state
  }
}
