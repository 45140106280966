import React, { useEffect, useCallback, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import moment from 'moment'
import { ROUTES } from '../Utils/constants'

import { useSelector } from 'react-redux'
import { useErrorBoundary } from 'use-error-boundary'
import ErrorHandler from '../Components/ErrorHandler'
import { useLogout } from 'Hooks/useLogout'
import Loading from 'Components/Loading'

const AppRoutes = ({
  component: Component,
  path,
  exact,
  isPrivate,
  ...rest
}) => {
  const { logout } = useLogout()
  const { ErrorBoundary, didCatch, error } = useErrorBoundary()

  const [loading, setLoading] = useState(true)
  const loginDay = useSelector(state => state.login?.loginAt)
  const token = useSelector(state => state.login?.user?.CustomerInfo?.LoginCode)

  const checkSessionTime = useCallback(async () => {
    const day = moment().day()
    if (day !== loginDay) {
      await logout()
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    loading && checkSessionTime()
    window.addEventListener('click', checkSessionTime)
    return () => {
      window.removeEventListener('click', checkSessionTime)
    }
  }, [loading])

  if (didCatch) return <ErrorHandler error={error.message} />
  if (loading) return <Loading full />

  return (
    <ErrorBoundary>
      <Route
        path={path}
        exact={exact}
        render={props => {
          if (isPrivate && token !== 200) {
            return <Redirect to={{ pathname: ROUTES.login }} />
          } else {
            if (path === ROUTES.login && token === 200) {
              return <Redirect to={{ pathname: ROUTES.home }} />
            }
            return <Component {...props} />
          }
        }}
        {...rest}
      />
    </ErrorBoundary>
  )
}

export default AppRoutes
