import { aludraApi } from 'Api/axios'

const mdl14 = {
  calculatorURL: 'mdl14/calculator/chzMaterials',
  ValidateCommercialCreditURL: 'mdl14/thirdparty/execute',
}

export const calculatorServices = async payload => {
  return aludraApi.post(mdl14.calculatorURL, payload)
}

export const validateAmountOnCommercialCreditServices = async payload => {
  return aludraApi.post(mdl14.ValidateCommercialCreditURL, payload)
}
