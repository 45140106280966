import { addAmounts } from 'redux/actions/cotizacionActions'
import { useDispatch } from 'react-redux'

export const useGetCotizacionPrice = () => {
  const dispatch = useDispatch()
  const getCotizacionPrices = ({ quote, freeTaxes, setCotizacion }) => {
    const quotationHasProducts = quote?.Lines?.length > 0
    let { subtotal, DiscountAmount, Quantity, itbms, total } = {
      subtotal: 0,
      DiscountAmount: 0,
      Quantity: 0,
      itbms: 0,
      total: 0,
    }

    if (quotationHasProducts) {
      quote.Lines.forEach(item => {
        subtotal += item.Amount
        DiscountAmount += item.DiscountAmount * item.Quantity
        Quantity += item.Quantity
        itbms += freeTaxes ? 0 : item.Amount * item.TaxCategoryValue
      })

      total = subtotal + itbms
    }

    dispatch(
      addAmounts({
        Quantity,
        Amount: quotationHasProducts ? subtotal : 0,
        Tax: quotationHasProducts ? itbms : 0,
        AmountWithTax: quotationHasProducts ? total : 0,
        DiscountAmount: quotationHasProducts ? parseFloat(DiscountAmount) : 0,
        SubTotal: quotationHasProducts ? subtotal : 0,
      })
    )
    setCotizacion &&
      setCotizacion({
        ...quote,
        Amount: quotationHasProducts ? parseFloat(subtotal) : 0,
        AmountWithTax: quotationHasProducts ? parseFloat(total) : 0,
        Tax: quotationHasProducts ? parseFloat(itbms) : 0,
        DiscountAmount: quotationHasProducts ? parseFloat(DiscountAmount) : 0,
        SubTotal: quotationHasProducts ? subtotal : 0,
      })
  }
  return { getCotizacionPrices }
}
