import React, { useState, useMemo, useEffect, useContext } from 'react'
import Navigation from '../../Components/Navigation'
import Pagination from '../../Components/Pagination'
import { Modal } from 'react-bootstrap'
import GenericModal from 'Components/GenericModal'
import ActionTable from 'Components/ActionTable'
import './commonSearchDocument.css'
import { t } from 'i18next'
import { useSelector, useDispatch } from 'react-redux'
import {
  salesRelatedDocuments,
  searchSalesDocuments,
} from 'redux/actions/cotizacionActions'
import { functionFilterStatusList } from 'Utils/functions'
import { VAContext } from '../../Context/VAContext'
import AgregarCliente from '../../Components/Clientes/AgregarCliente'
import EliminarCliente from '../../Components/Clientes/EliminarCliente'
import CrearCliente from '../../Views/Clientes/CrearCliente'
import no from '../../Assets/Images/Login/no.svg'
import { deleteCustomers, searchCustomers } from 'redux/actions/clientsActions'
import { StringParam, useQueryParams } from 'use-query-params'
import { CustomerContext } from 'state/context/CustomerFormContext'

function CommonSearchDocument({
  title,
  objColumns,
  filterOptions,
  setTableData,
  placeholder,
  documentTypeIdDocument,
  isCustomer,
  inModal,
  setShowModalClienteContado,
  setClienteCotizacion,
  handleModal,
  resetAddressStatus,
  setDefaultWarehouseToQuotation,
}) {
  const { setMandatoryAddress } = CustomerContext()
  const [document, setDocument] = useState([])
  const [loading, setLoading] = useState(false)
  const { CompanyCode, CompanyId } = useSelector(state => state.company)
  const isCustomerUser = useSelector(state => state.login.isCustomer)
  const salesAgentId = useSelector(
    state => state.login.user?.AuthenticationInfo?.UserId
  )

  const [currentPage, setCurrentPage] = useState(1)
  const [registers, setRegisters] = useState(null)
  const [firstIndex, setFirstIndex] = useState(0)
  const [lastIndex, setLastIndex] = useState(3)

  const [elementsPerPage, setElementsPerPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const pageNums = []
  const totalPages = Math.ceil(registers / pageSize)

  const dispatch = useDispatch()

  const [currentDate, setCurrentDate] = useState()
  const [priorDate, setPriorDate] = useState(null)
  const [filterLasts, setFilterLasts] = useState(false)

  const [filterStatusList, setFilterStatusList] = useState([])

  const [searchParam, setSearchParam] = useQueryParams({ search: StringParam })
  const [searchString, setSearchString] = useState(searchParam?.search || '')

  const [showModalNoRegisters, setShowModalNoRegisters] = useState(false)

  const [clientSelectedDelete] = useState('')
  const [confirmDeleteClient, setConfirmDeleteClient] = useState(false)
  const [showModalDeleteClient, setShowModalDeleteClient] = useState(false)
  const [showModalAddSuccessClient, setShowModalAddSuccessClient] =
    useState(false)
  const [showModalEditClient, setShowModalEditClient] = useState(false)
  const { showModalCreateClient, selectClient } = useContext(VAContext)
  const [edition, setEdition] = useState(false)
  const [prevCompanyId, setPrevCompanyId] = useState(CompanyId)

  const isSearchQuote = window.location.pathname.includes('buscar_cotizacion')

  const columns = React.useMemo(() => objColumns)

  const handleSearch = () => {
    setCurrentPage(1)
    setLastIndex(3)
    setFirstIndex(0)
    setPriorDate(null)
    setFilterLasts(false)
    if (searchString === '' || searchString === searchParam?.search) return
    fetchDocuments({ page: 1 })
  }

  const fetchDocuments = async ({ cleanFilter, page } = {}) => {
    setLoading(true)

    const actualPage = page || currentPage
    cleanFilter && setSearchString('')
    const searchParamValue = cleanFilter ? undefined : searchString || undefined
    setSearchParam({ search: searchParamValue }, 'push')

    const payload = {
      CompanyId,
      ...(isCustomer ? { CompanyCode } : {}),
      ...(isCustomer
        ? { RoleName: 'Customer' }
        : { DocumentTypeId: documentTypeIdDocument }),
      ...(isCustomer ? { GlobalExecution: 1 } : {}),
      ...(isCustomer ? {} : { DocumentNumber: null }),
      ...(isCustomer
        ? {}
        : cleanFilter
        ? { HeaderStatus: null }
        : filterStatusList.length && prevCompanyId === CompanyId
        ? { HeaderStatus: filterStatusList }
        : { HeaderStatus: null }),
      PageNumber: cleanFilter ? 1 : actualPage,
      SearchText: cleanFilter ? '' : searchString,
      PageSize: cleanFilter ? 10 : parseFloat(pageSize),
      DateStart: cleanFilter ? null : priorDate,
      DateEnd: cleanFilter ? null : currentDate,
      ...(isCustomerUser && { SalesAgentId: salesAgentId }),
    }

    const documents = await dispatch(
      isCustomer
        ? searchCustomers(JSON.stringify(payload))
        : isSearchQuote
        ? salesRelatedDocuments(payload)
        : searchSalesDocuments(payload)
    )

    const elementsPage = pageSize * (actualPage - 1) + documents?.length

    if (documents?.length) {
      setDocument(documents)
      setRegisters(documents[0].TotalRegisters)
      setElementsPerPage(elementsPage)
      setLoading(false)
    } else {
      setDocument(null)
      setRegisters(1)
      setElementsPerPage(elementsPage)
      setShowModalNoRegisters(true)
      setTimeout(() => {
        setShowModalNoRegisters(false)
      }, 3000)
    }
    setLoading(false)
  }

  const data = useMemo(() => setTableData(document), [document])

  for (let i = 1; i <= totalPages; i++) {
    pageNums.push(i)
  }

  const handleChangePage = page => {
    setCurrentPage(page)
    fetchDocuments({ page })
  }

  const handleFilterLasts = () => {
    const today = new Date()
    setCurrentDate(today)
    setPriorDate(today)
    setFilterLasts(true)
    setCurrentPage(1)
    setLastIndex(3)
    setFirstIndex(0)
  }

  const handleFilterAll = () => {
    setFilterStatusList([])
    setCurrentDate(null)
    setPriorDate(null)
    setFilterLasts(false)
    setCurrentPage(1)
    setLastIndex(3)
    setFirstIndex(0)
    setSearchString('')
  }

  const handleFilterDateRange = (dateFrom, dateTo) => {
    if (dateTo !== null) {
      setCurrentDate(dateTo)
    }
    setPriorDate(dateFrom)
    setFilterLasts(false)
    setCurrentPage(1)
    setLastIndex(3)
    setFirstIndex(0)
  }

  const handleFilterStatusList = statusList => {
    functionFilterStatusList({
      statusList,
      setFilterStatusList,
      setCurrentPage,
      setLastIndex,
      setFirstIndex,
    })
  }

  const handleModalAdd = () => {
    setShowModalAddSuccessClient(!showModalAddSuccessClient)
  }

  const handleModalCreateEdit = () => {
    setShowModalEditClient(!showModalEditClient)
  }

  const deleteCustomer = async () => {
    const deleteCustomer = await dispatch(deleteCustomers(clientSelectedDelete))
    if (deleteCustomer) {
      setConfirmDeleteClient(false)
      fetchDocuments()
    }
  }

  const clientToEdit = CustomerId => {
    selectClient(CustomerId, false, true)
    showModalCreateClient(1)
    setEdition(true)
  }

  useEffect(() => {
    let cleanFilter = true

    if (prevCompanyId !== CompanyId) {
      setCurrentPage(1)
      setSearchString('')
      fetchDocuments({ cleanFilter })
      setPrevCompanyId(CompanyId)
      setFilterLasts(false)
    } else {
      cleanFilter = false
      setPrevCompanyId(CompanyId)
      fetchDocuments()
    }
  }, [priorDate, CompanyId, filterStatusList, pageSize])

  useEffect(() => {
    if (confirmDeleteClient) {
      deleteCustomer()
    }
  }, [confirmDeleteClient])

  return (
    <>
      {!inModal || !isCustomer ? <Navigation /> : null}
      <section className={inModal ? '' : 'common-search-content'}>
        {!inModal && (
          <header className="common-search-header">
            <h2>{t(title)}</h2>
          </header>
        )}
        <div className="common-search-main">
          <ActionTable
            data={data}
            columns={columns}
            loader={loading}
            handleFilterAll={handleFilterAll}
            handleFilterLasts={handleFilterLasts}
            filterLasts={filterLasts}
            handleFilterDateRange={handleFilterDateRange}
            handleFilterStatusList={handleFilterStatusList}
            showModalEditClient={showModalEditClient}
            setShowModalEditClient={setShowModalEditClient}
            clientToEdit={clientToEdit}
            inModal={inModal}
            setShowModalClienteContado={setShowModalClienteContado}
            setClienteCotizacion={setClienteCotizacion}
            handleModal={handleModal}
            resetAddressStatus={resetAddressStatus}
            setDefaultWarehouseToQuotation={setDefaultWarehouseToQuotation}
            isActionsExtra={false}
            placeholder={placeholder}
            filterOptions={filterOptions}
            searchString={searchString}
            setSearchString={setSearchString}
            handleSearch={handleSearch}
            isCustomer={isCustomer}
          />
          {!loading ? (
            <div className="common-search-footer">
              <div className="cs-footer-content">
                <p>
                  {t('SEARCH_CLIENT_T.SHOW_PAGINATION_LABEL', {
                    registers,
                    pageSize: pageSize * currentPage - (pageSize - 1),
                    elementsPerPage,
                  })}
                </p>

                <select
                  name="changePageSize"
                  id="pageSize_selector"
                  onChange={e => {
                    setPageSize(e.target.value)
                  }}
                  value={pageSize}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                </select>
              </div>
              <Pagination
                type={'pagination'}
                firstIndex={firstIndex}
                lastIndex={lastIndex}
                currentPage={currentPage}
                pageNums={pageNums}
                setCurrentPage={handleChangePage}
                setFirstIndex={setFirstIndex}
                setLastIndex={setLastIndex}
                margin={0}
              />
            </div>
          ) : null}
        </div>
      </section>
      <Modal show={showModalDeleteClient} centered>
        <EliminarCliente
          setShowModalDeleteClient={setShowModalDeleteClient}
          setConfirmDeleteClient={setConfirmDeleteClient}
        />
      </Modal>
      <Modal show={showModalEditClient} className="modal-clase-edit-customer">
        <div className="modal-box-client">
          <div className="restore-container">
            <div className="modal-header-restore">
              {edition ? (
                <h2>{t('SEARCH_CLIENT_T.EDIT_CLIENT_LABEL')}</h2>
              ) : (
                <h3>{t('SEARCH_CLIENT_T.CREATE_CLIENT_LABEL')}</h3>
              )}
              <img
                src={no}
                alt="close"
                onClick={() => {
                  handleModalCreateEdit()
                  setEdition(false)
                  selectClient('', false, false)
                  setMandatoryAddress(false)
                }}
              />
            </div>
            <CrearCliente handleClose={() => setShowModalEditClient(false)} />
          </div>
        </div>
      </Modal>
      <Modal show={showModalAddSuccessClient}>
        <AgregarCliente handleModalAdd={handleModalAdd}></AgregarCliente>
      </Modal>
      <Modal
        show={showModalNoRegisters}
        backdrop="static"
        className="modal-class"
        centered>
        <GenericModal
          setIsOpen={() => setShowModalNoRegisters(false)}
          showCaution={true}
          hideCheck={true}
          text={t('GENERIC_MODAL.NO_ITEMS_FOUND')}
          mostrarButtons={false}
        />
      </Modal>
    </>
  )
}

export default CommonSearchDocument
