import React from 'react'
import { images } from 'Assets/Images'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)
const currentMonth = new Date().toLocaleString('es-ES', { month: 'long' })
const DEFAULT_VALUE = `Promoción válida del 1 al 30 de ${capitalize(
  currentMonth
)} 2024. Precio no incluye ITBMS`

export const Square1ToPrintCOffer = ({ products, i }) => {
  const { templateRef } = usePrinterContext()

  const prod = products?.content?.products?.[0]
  const terms = products?.content?.terms || DEFAULT_VALUE

  return (
    <div ref={el => (templateRef.current[i] = el)}>
      <div
        id="printer-preview-left-canvas"
        className="printer-preview-left-canvas">
        <div className="printer-preview-left-content-body">
          <p className="printer-preview-left-content-body-title">
            {prod?.ProductName || ''}
          </p>
          <p className="printer-preview-left-content-body-sku">
            {prod?.Sku || ''}
          </p>
          {prod?.Price && (
            <div className="printer-preview-left-content-body-price-wrapper">
              <p
                className="printer-preview-mini-text"
                style={{ color: '#000' }}>
                $
              </p>
              <p
                className="printer-preview-left-content-body-price"
                style={{ color: '#000', fontSize: '170px', marginTop: '60px' }}>
                {prod?.Price?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p
                className="printer-preview-mini-text"
                style={{ color: '#000' }}>
                {prod?.Price?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
          {prod?.BasePrice && (
            <div
              style={{
                position: 'absolute',
                bottom: '-84px',
                right: 0,
                width: '170px',
                height: '1px',
                background: 'red',
                transform: 'rotate(-6deg)',
              }}
            />
          )}
          {prod?.BasePrice && (
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                gap: '10px',
                position: 'absolute',
                bottom: '-100px',
                right: '0',
              }}>
              <p
                style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  marginBottom: '-5px',
                  color: '#000',
                }}>
                Antes
              </p>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <p
                  className="printer-preview-mini-text"
                  style={{
                    color: '#000',
                    fontSize: '20px',
                    marginTop: '-12px',
                  }}>
                  $
                </p>
                <p
                  className="printer-preview-left-content-body-price"
                  style={{ color: '#000', fontSize: '45px', marginTop: 0 }}>
                  {prod?.BasePrice?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p
                  className="printer-preview-mini-text"
                  style={{
                    color: '#000',
                    fontSize: '20px',
                    marginTop: '-12px',
                  }}>
                  {prod?.BasePrice?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="printer-preview-left-content-footer">
          <div className="printer-preview-content-footer-input-wrapper">
            <p
              className="printer-preview-left-content-footer-legal-1"
              style={{
                border: 'none',
                textAlign: 'end',
                background: 'transparent',
              }}>
              {terms}
            </p>
          </div>
        </div>
        <img
          className="printer-preview-left-image"
          src={images.printer.templates.cochez.offer1}
          alt="template-preview"
          style={{ opacity: 0 }}
        />
      </div>
    </div>
  )
}
