import React from 'react'
import { getCmfCreditService } from 'redux/services/printerServices'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'

export const useGetCmfCredit = () => {
  const { currentPage, allTemplatesContent, setAllTemplatesContent } =
    usePrinterContext()
  const [loading, setLoading] = React.useState(false)

  const fetchCmfCredit = async (price, sku) => {
    try {
      setLoading(true)
      const data = await getCmfCreditService({
        loanTerm: 36,
        cashValueProduct: price,
      })
      const updatedContent = allTemplatesContent.map(content => {
        if (content.page === currentPage) {
          return {
            ...content,
            content: {
              ...content.content,
              products: content?.content.products?.map(product => {
                if (product?.Sku !== sku) {
                  return product
                }
                return {
                  ...product,
                  cmfCredit: data?.monthlyQuota || '',
                }
              }),
            },
          }
        }
        return content
      })
      setAllTemplatesContent(updatedContent)
    } catch (error) {
      console.error('error', error)
    } finally {
      setLoading(false)
    }
  }

  return { fetchCmfCredit, loading }
}
