import { t } from 'i18next'

export const approvedColumns = ({
  hasProductsWithCancel = false,
  isCustomer = false,
}) => {
  const setColumns = [
    {
      Header: 'check',
      accessor: 'isSelected',
      width: '5%',
      arrowOrderEnabled: false,
    },
    {
      Header: t('SELL_ORDER_T.REFERENCE_HEADER'),
      accessor: t('SELL_ORDER_T.REFERENCE_ACCESOR'),
      width: '10%',
      arrowOrderEnabled: true,
    },
    {
      Header: t('SELL_ORDER_T.DESCRIPTION_LABEL'),
      accessor: t('SELL_ORDER_T.DESCRIPTION_ACCESOR'),
      width: '50%',
      arrowOrderEnabled: true,
    },
    {
      Header: t('SELL_ORDER_T.QTY_HEADER'),
      accessor: t('SELL_ORDER_T.QTY_ACCESOR'),
      width: '10%',
    },
    {
      Header: t('SELL_ORDER_T.UNITY_PRICE_HEADER'),
      accessor: t('SELL_ORDER_T.UNITY_PRICE_ACCESOR'),
      width: '10%',
      arrowOrderEnabled: true,
    },
    {
      Header: t('SELL_ORDER_T.PRECIO_TOTAL_HEADER'),
      accessor: t('SELL_ORDER_T.PRECIO_TOTAL_ACCESOR'),
      width: '10%',
      arrowOrderEnabled: true,
    },
    ...(hasProductsWithCancel
      ? [
          {
            Header: 'Cancelados',
            accessor: 'cancelados',
            width: '5%',
            arrowOrderEnabled: true,
          },
        ]
      : []),
    {
      Header: t('SELL_ORDER_T.PENDING_LABEL'),
      accessor: t('SELL_ORDER_T.PENDING_ACCESOR'),
      width: '5%',
    },
    ...(!isCustomer
      ? [
          {
            Header: t('SELL_ORDER_T.DISPATCH_SOLICITUDE_LABEL'),
            accessor: t('SELL_ORDER_T.DISPATCH_SOLICITUDE_ACCESOR'),
            width: '5%',
          },
        ]
      : []),
    ...(!isCustomer
      ? [
          {
            Header: t('SELL_ORDER_T.ACTIONS_HEADER'),
            accessor: t('SELL_ORDER_T.ACTIONS_ACCESOR'),
            width: '10%',
          },
        ]
      : []),
  ]

  return setColumns
}

export const closeColumns = (hasProductsWithCancel = false) => {
  const setColumns = [
    {
      Header: t('SELL_ORDER_T.REFERENCE_HEADER'),
      accessor: t('SELL_ORDER_T.REFERENCE_ACCESOR'),
      width: '10%',
      arrowOrderEnabled: true,
    },
    {
      Header: t('SELL_ORDER_T.DESCRIPTION_LABEL'),
      accessor: t('SELL_ORDER_T.DESCRIPTION_ACCESOR'),
      width: '50%',
      arrowOrderEnabled: true,
    },
    {
      Header: t('SELL_ORDER_T.QTY_HEADER'),
      accessor: t('SELL_ORDER_T.QTY_ACCESOR'),
      width: '10%',
    },
    {
      Header: t('SELL_ORDER_T.UNITY_PRICE_HEADER'),
      accessor: t('SELL_ORDER_T.UNITY_PRICE_ACCESOR'),
      width: '10%',
      arrowOrderEnabled: true,
    },
    {
      Header: t('SELL_ORDER_T.PRECIO_TOTAL_HEADER'),
      accessor: t('SELL_ORDER_T.PRECIO_TOTAL_ACCESOR'),
      width: '10%',
      arrowOrderEnabled: true,
    },
    ...(hasProductsWithCancel
      ? [
          {
            Header: 'Cancelados',
            accessor: 'cancelados',
            width: '5%',
            arrowOrderEnabled: true,
          },
        ]
      : []),
    {
      Header: t('SELL_ORDER_T.PENDING_LABEL'),
      accessor: t('SELL_ORDER_T.PENDING_ACCESOR'),
      width: '5%',
    },
  ]

  return setColumns
}

export const unaprovedColumns = () => {
  const setColumns = [
    {
      Header: t('SELL_ORDER_T.REFERENCE_HEADER'),
      accessor: t('SELL_ORDER_T.REFERENCE_ACCESOR'),
      width: '15%',
      arrowOrderEnabled: true,
    },
    {
      Header: t('SELL_ORDER_T.DESCRIPTION_LABEL'),
      accessor: t('SELL_ORDER_T.DESCRIPTION_ACCESOR'),
      width: '40%',
      arrowOrderEnabled: true,
    },
    {
      Header: t('SELL_ORDER_T.QTY_HEADER'),
      accessor: t('SELL_ORDER_T.QTY_ACCESOR'),
      width: '10%',
    },
    {
      Header: t('SELL_ORDER_T.UNITY_PRICE_HEADER'),
      accessor: t('SELL_ORDER_T.UNITY_PRICE_ACCESOR'),
      width: '10%',
      arrowOrderEnabled: true,
    },
    {
      Header: t('SELL_ORDER_T.PRECIO_TOTAL_HEADER'),
      accessor: t('SELL_ORDER_T.PRECIO_TOTAL_ACCESOR'),
      width: '10%',
      arrowOrderEnabled: true,
    },
    {
      Header: t('SELL_ORDER_T.PENDING_LABEL'),
      accessor: t('SELL_ORDER_T.PENDING_ACCESOR'),
      width: '5%',
    },
  ]
  return setColumns
}
