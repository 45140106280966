/* eslint-disable no-useless-catch */
import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  ADD_ONE,
  ADD_MANY,
  REMOVE_ONE,
  SET_DISCOUNT,
  EMPTY_CART,
  IS_LOADING_ADDRESS,
} from '../types'

import { getMarginTotal } from 'Utils/functions'
import { getFinalPriceMdl22Service } from 'redux/services/clientsServices'

export const priceListAddToCart = (
  payload,
  CustomerId = null,
  contadoClient = false
) => {
  return async (dispatch, getState) => {
    const QuoteHeader = getState().cotizacion.QuoteHeader
    const { WarehouseAddress } = QuoteHeader
    const PriceListId = QuoteHeader.PriceListId

    const combinedPayload = payload.map(prod => ({
      product: prod,
      qty: prod.qty || prod.Quantity || 1,
      hashasDiscount: prod.BasePrice > prod.FinalPrice,
    }))

    const ProductIds = payload.map(product => product.ProductId)
    const { WarehouseId, CompanyId, WarehouseCode, WarehouseDescription } =
      getState().company
    let productAddedToCart = false

    try {
      const payloadService = {
        CompanyId,
        WarehouseId,
        ProductIds,
        ...(CustomerId ? { CustomerId } : { PriceListId }),
      }

      const response = await getFinalPriceMdl22Service(payloadService)
      const { data, status } = response
      if (status === 200 && data?.Data) {
        const selectList = response.data.Data
        const warehouse = {
          WarehouseId,
          WarehouseCode,
          WarehouseDescription,
          WarehouseAddress,
        }
        const products = selectList.map(item => {
          const product = combinedPayload.find(
            p =>
              p.product.ProductId.toUpperCase() === item.ProductId.toUpperCase()
          )
          const Quantity = product ? product.qty : 0
          const Price = item.FinalPrice

          return {
            selectList: { ...item, Quantity, Price },
            payload: { ...product, Quantity, Price },
            warehouse,
            Quantity,
            Price,
            qty: Quantity,
          }
        })
        for (const prod of products) {
          const action = addToCart(
            getPayloadProduct(
              prod.selectList,
              prod.payload,
              prod.warehouse,
              prod.Quantity,
              prod.Price
            ),
            prod.qty
          )

          dispatch(action)
        }
        productAddedToCart = true
      }
    } catch (err) {
      throw err
    }

    dispatch(isLoadingProduct({ isLoadingProduct: false }))
    return productAddedToCart
  }
}

const getPayloadProduct = (selectList, payload, warehouse, Quantity, Price) => {
  const FinalPrice = selectList.FinalPrice

  const BasePrice = payload.product.BasePrice
  if (
    payload.product.NegotiatedReason !== null &&
    payload.product.NegotiatedReason
  ) {
    Price = payload.product?.Price
  }
  const AmountWithTax =
    parseFloat(Price * payload.product.TaxCategoryValue + Price) * Quantity
  const Amount = parseFloat(Price) * Quantity
  const DiscountAmount = BasePrice - Price < 0 ? 0 : BasePrice - Price
  const DiscountPct =
    ((BasePrice - Price) * 100) / Price < 0
      ? 0
      : ((BasePrice - Price) * 100) / Price

  const modifiedProduct = {
    ...payload.product,
    BasePrice,
    BasePriceLocal: BasePrice,
    FinalPrice,
    Price,
    Quantity,
    Tax: Amount * payload.product.TaxCategoryValue,
    Cost: payload.product.Cost || payload.product.AvgCost || 0.0,
    AmountWithTax,
    Amount,
    DiscountAmount,
    DiscountAmountLocal: DiscountAmount,
    DiscountPct,
    WarehouseId: warehouse.WarehouseId,
    WarehouseCode: warehouse.WarehouseCode,
    WarehouseDescription: warehouse.WarehouseDescription,
    WarehouseAddress: warehouse.WarehouseAddress,
    QuoteHeaderId:
      payload?.DocHeaderId || '00000000-0000-0000-0000-000000000000',
    NegotiatedPriceBy: payload.product?.NegotiatedPriceBy || null,
    NegotiatedPriceByName: payload.product?.NegotiatedPriceByName || null,
    NegotiatedReason: payload.product?.NegotiatedReason || null,
    NegotiatedReasonCode: payload.product?.NegotiatedReasonCode || null,
    NegotiatedReasonId: payload.product?.NegotiatedReasonId || null,
    Margin: getMarginTotal(
      Price,
      payload.product.Cost || payload.product.AvgCost || 0.0,
      Quantity
    ),
    AppliesForExtendedWarranty: payload.product?.AppliesForExtendedWarranty,
    AuthorizedPriceBy: payload.product?.AuthorizedPriceBy || null,
    AuthorizedPriceByName: payload.product?.AuthorizedPriceByName || null,
    BackOrder: payload.product?.BackOrder,
    CreatedBy: payload.product?.CreatedBy || null,
    CreatedDate: payload.product?.CreatedDate || null,
    DocLineId: payload.product?.DocLineId || null,
    FinalPriceList: FinalPrice,
  }

  return modifiedProduct
}

const addToCart = (payload, qty) => ({
  type: ADD_TO_CART,
  payload: {
    product: payload,
    qty,
  },
})

const removeFromCart = payload => ({
  type: REMOVE_FROM_CART,
  payload,
})

const addOne = ({ product, newQuantity }) => ({
  type: ADD_ONE,
  payload: {
    product,
    newQuantity,
  },
})

const addMany = payload => ({
  type: ADD_MANY,
  payload,
})
const removeOne = ({ product, newQuantity }) => ({
  type: REMOVE_ONE,
  payload: {
    product,
    newQuantity,
  },
})
const setDiscount = payload => ({
  type: SET_DISCOUNT,
  payload,
})

const emptyCart = () => ({
  type: EMPTY_CART,
})

const isLoadingProduct = payload => ({
  type: IS_LOADING_ADDRESS,
  payload,
})

export {
  addToCart,
  emptyCart,
  addMany,
  removeFromCart,
  addOne,
  removeOne,
  setDiscount,
  isLoadingProduct,
  getPayloadProduct,
}
