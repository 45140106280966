import React, { useContext, useEffect } from 'react'
import no from '../../../Assets/Images/Login/no.svg'
import PrincipalSearchCustomers from '../../../Views/Clientes/PrincipalSearchCustomers'
import CrearCliente from '../../../Views/Clientes/CrearCliente'
import { VAContext } from '../../../Context/VAContext'
import { useDispatch } from 'react-redux'
import { activeModalCustomer } from 'redux/actions/cotizacionActions'

function PrincipalModal({
  handleShowModalClient,
  handleModalAdd,
  setClienteCotizacion,
  resetAddressStatus,
  setDefaultWarehouseToQuotation,
}) {
  const { modalClientCreate } = useContext(VAContext)
  const dispatch = useDispatch()

  const ComponentToShow = () => {
    if (modalClientCreate === 1) {
      return <CrearCliente />
    }
    if (modalClientCreate === 0) {
      return (
        <PrincipalSearchCustomers
          handleModal={handleShowModalClient}
          setClienteCotizacion={setClienteCotizacion}
          resetAddressStatus={resetAddressStatus}
          setDefaultWarehouseToQuotation={setDefaultWarehouseToQuotation}
        />
      )
    }
  }

  useEffect(() => {
    ComponentToShow()
  }, [modalClientCreate])

  return (
    <div className="modal-box-client">
      <div className="restore-container-cliente">
        <div className="modal-header-cliente">
          <h2>Buscar o crear clientes</h2>
          <img
            src={no}
            alt="close"
            onClick={() => {
              handleShowModalClient()
              dispatch(activeModalCustomer(false))
            }}
          />
        </div>
        <div>{ComponentToShow()}</div>
      </div>
    </div>
  )
}

export default PrincipalModal
