import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import no from '../../../../Assets/Images/Login/no.svg'
import './ModalPrinterTemplates.css'
import { images } from 'Assets/Images'
import { useHistory } from 'react-router'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { StringParam, useQueryParams } from 'use-query-params'
import { useLocation } from 'react-router-dom'

export const ModalPrinterTemplates = ({
  show,
  onClose,
  withNavigation = true,
}) => {
  const [searchParam] = useQueryParams({ type: StringParam })
  const location = useLocation()
  const { selectedTemplates, setSelectedTemplates, setCurrentPage } =
    usePrinterContext()
  const { push } = useHistory()
  const [localSelectedTemplates, setLocalSelectedTemplates] =
    React.useState(selectedTemplates)

  const handleSelectTemplate = template => {
    setLocalSelectedTemplates(prev => {
      if (prev.includes(template)) {
        return prev.filter(item => item !== template)
      }
      return [...prev, template]
    })
  }

  const handleClose = () => {
    onClose()
  }

  const handleSave = () => {
    setSelectedTemplates(localSelectedTemplates)
    withNavigation && push('/printer/preview')
    onClose()
  }

  useEffect(() => {
    const isPrinterScreen = location.pathname === '/printer'
    if (isPrinterScreen && !searchParam?.type) {
      setLocalSelectedTemplates([])
      setCurrentPage(1)
    }
  }, [])

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop
      enforceFocus={false}
      centered
      contentClassName="printer-modal-root-wrapper">
      <div className="printer-modal-wrapper">
        <div className="printer-modal-header">
          <img
            src={no}
            alt="close"
            style={{ cursor: 'pointer' }}
            onClick={handleClose}
          />
        </div>
        <div className="printer-modal-content">
          <h3 className="printer-modal-content-title">
            Selecciona las plantillas de etiquetas que deseas imprimir
          </h3>
          <div className="printer-modal-content-option-wrapper">
            <button
              className="printer-modal-content-option"
              onClick={() => handleSelectTemplate('square-1')}>
              <div
                className="printer-modal-content-image-wrapper"
                style={{
                  ...(localSelectedTemplates.includes('square-1') && {
                    border: '2px solid #022767',
                  }),
                }}>
                <img
                  src={images.printer.square}
                  alt=""
                  className="printer-modal-content-image"
                />
              </div>
              <p className="printer-modal-content-text">
                Tonga y Bin<br></br>
                {`11" x 8.5"`}
                <br></br>Horizontal
              </p>
            </button>
            <button
              className="printer-modal-content-option"
              onClick={() => handleSelectTemplate('square-2')}>
              <div
                className="printer-modal-content-image-wrapper"
                style={{
                  ...(localSelectedTemplates.includes('square-2') && {
                    border: '2px solid #022767',
                  }),
                }}>
                <img
                  src={images.printer.doubleSquare}
                  alt=""
                  className="printer-modal-content-image"
                />
              </div>
              <p className="printer-modal-content-text">
                Frente Góndola<br></br>
                {`8.5" x 5.5"`}
                <br></br>Horizontal
              </p>
            </button>
            <button
              className="printer-modal-content-option"
              onClick={() => handleSelectTemplate('square-3')}>
              <div
                className="printer-modal-content-image-wrapper"
                style={{
                  ...(localSelectedTemplates.includes('square-3') && {
                    border: '2px solid #022767',
                  }),
                }}>
                <img
                  src={images.printer.squares}
                  alt=""
                  className="printer-modal-content-image"
                />
              </div>
              <p className="printer-modal-content-text">
                Frente Góndola<br></br>
                {`4" x 5"`}
                <br></br>Vertical
              </p>
            </button>
            <button
              className="printer-modal-content-option"
              onClick={() => handleSelectTemplate('square-4')}>
              <div
                className="printer-modal-content-image-wrapper"
                style={{
                  ...(localSelectedTemplates.includes('square-4') && {
                    border: '2px solid #022767',
                  }),
                }}>
                <img
                  src={images.printer.squares}
                  alt=""
                  className="printer-modal-content-image"
                />
              </div>
              <p className="printer-modal-content-text">
                Frente Góndola<br></br>
                {`5.5" x 8.5"`}
                <br></br>Vertical
              </p>
            </button>
          </div>
          {withNavigation ? (
            <button
              disabled={!localSelectedTemplates.length}
              onClick={handleSave}
              className="printer-modal-content-button">
              Aplicar
            </button>
          ) : (
            <button
              onClick={handleSave}
              className="printer-modal-content-button">
              Listo
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}
