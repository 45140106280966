// Estado de los reducer de login
import { ROUTES, companiesCode, salesRoles } from 'Utils/constants'
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_COMPANY,
  LOGIN_SUCCESS_COMPANY,
  LOGIN_ERROR_COMPANY,
  COMPANY_ERROR_STORE,
  IS_CUSTOMER,
} from '../types'

import { setCompany } from './companyAction'
import {
  loginCompanyService,
  loginUserService,
} from 'redux/services/clientsServices'
// Consumir WS de login
export const loginUser = (user, pass, company, props) => {
  const loginUser = () => ({
    type: LOGIN_USER,
  })

  // SI EL USUARIO EXISTE
  const loginSuccess = data => ({
    type: LOGIN_SUCCESS,
    payload: data,
  })

  // SI HUBO ERROR
  const loginError = estado => ({
    type: LOGIN_ERROR,
    payload: estado,
  })

  const loginErrorStore = estado => ({
    type: COMPANY_ERROR_STORE,
    payload: estado,
  })

  const loginCompany = () => ({
    type: LOGIN_COMPANY,
  })

  const loginSuccessCompany = data => ({
    type: LOGIN_SUCCESS_COMPANY,
    payload: data,
  })

  const loginErrorCompany = estado => ({
    type: LOGIN_ERROR_COMPANY,
    payload: estado,
  })

  const isCustomer = payload => ({
    type: IS_CUSTOMER,
    payload,
  })

  return async dispatch => {
    let sw = false
    dispatch(loginCompany())
    try {
      const responseCompany = await loginCompanyService(company)
      // eslint-disable-next-line no-throw-literal
      if (responseCompany.data.Data.length === 0) throw 'empresanoexiste'
      if (responseCompany.data.Data[0].CompanyName !== 'Cochez')
        // eslint-disable-next-line no-throw-literal
        throw 'empresanoexiste'
      dispatch(loginSuccessCompany(responseCompany.data.Data))
      sw = true
    } catch (error) {
      switch (error) {
        case 'empresanoexiste':
          dispatch(loginErrorCompany('La empresa no existe, intente de nuevo'))
          break
      }
      sw = false
    }

    if (sw) {
      dispatch(loginUser())
      try {
        // extraer la data del usuario
        const userCredentials = {
          Email: user,
          Password: pass,
          Tenant: 2,
        }
        const response = await loginUserService(userCredentials)
        if (response.data?.Data?.CustomerInfo?.LoginCode === 404)
          dispatch(loginError('Este usuario no existe'))
        if (response.data?.Data?.CustomerInfo?.LoginCode === 401)
          dispatch(loginError('Credenciales Inválidas'))
        if (response.data?.Status?.Code === 400)
          dispatch(loginError('Ha ocurrido un error'))
        else if (response.data?.Data?.CustomerInfo?.LoginCode === [])
          dispatch(loginError('Credenciales Inválidas'))
        if (response.data?.Data?.CustomerInfo?.Warehouses === null)
          dispatch(loginErrorStore(true))
        else dispatch(loginErrorStore(false))

        if (response.data.Data.CustomerInfo.LoginCode === 200) {
          const hasCustomerRole =
            response.data.Data.AuthenticationInfo.UserRoles.some(
              roleObject => roleObject.RoleName === salesRoles.customer
            )
          dispatch(isCustomer(hasCustomerRole))
          const hash = {}
          const initialWarehouse =
            response.data.Data?.CustomerInfo?.Warehouses?.filter(warehouse =>
              hash[warehouse.CompanyId]
                ? false
                : (hash[warehouse.CompanyId] = true)
            ).sort((or1, or2) => {
              if (or1.CompanyCode < or2.CompanyCode) {
                return -1
              } else if (or1.CompanyCode > or2.CompanyCode) {
                return 1
              } else {
                return 0
              }
            })[0]

          switch (initialWarehouse?.CompanyCode) {
            case companiesCode.cochez:
              // eslint-disable-next-line no-case-declarations
              const cochezWarehouses =
                response.data.Data?.CustomerInfo?.Warehouses?.filter(
                  warehouse => warehouse.CompanyCode === companiesCode.cochez
                ) // GET WAREHOUESES FROM CO
              window.localStorage.setItem('company', 'Cochez')
              dispatch(
                setCompany({
                  CompanyCode: initialWarehouse.CompanyCode,
                  CompanyName: initialWarehouse.CompanyName,
                  CompanyId: initialWarehouse.CompanyId,
                  WarehouseCode: initialWarehouse.WarehouseCode,
                  WarehouseId: initialWarehouse.WarehouseId,
                  WarehouseDescription: initialWarehouse.WarehouseDescription,
                  WarehouseAddress: initialWarehouse.WarehouseAddress, // include WarehouseAddress to this response
                  WarehouseName: initialWarehouse.WarehouseName,
                  DefaultWarehouse: cochezWarehouses.length === 1,
                })
              )
              break
            case 'NO':
              // eslint-disable-next-line no-case-declarations
              const noveyWarehouses =
                response.data.Data?.CustomerInfo?.Warehouses?.filter(
                  warehouse => warehouse.CompanyCode === 'NO'
                ) // GET WAREHOUESES FROM NO
              window.localStorage.setItem('company', 'Novey')
              dispatch(
                setCompany({
                  CompanyCode: initialWarehouse.CompanyCode,
                  CompanyName: initialWarehouse.CompanyName,
                  CompanyId: initialWarehouse.CompanyId,
                  WarehouseCode: initialWarehouse.WarehouseCode,
                  WarehouseId: initialWarehouse.WarehouseId,
                  WarehouseDescription: initialWarehouse.WarehouseDescription,
                  WarehouseAddress: initialWarehouse.WarehouseAddress, // include WarehouseAddress to this response
                  WarehouseName: initialWarehouse.WarehouseName,
                  DefaultWarehouse: noveyWarehouses.length === 1,
                })
              )
              break
            default:
              window.localStorage.setItem('company', '')
              break
          }

          if (response?.data?.Data?.CustomerInfo?.Warehouses !== null) {
            dispatch(loginSuccess(response.data.Data))
            props.history.push(ROUTES.home)
          }
        }
      } catch (error) {}
    }
  }
}
