import { images } from 'Assets/Images'
import React from 'react'
import {
  CheckCircleFill,
  PencilSquare,
  XCircleFill,
} from 'react-bootstrap-icons'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
const DEFAULT_VALUE = 'Precio no incluye ITBMS'

export const Square2NRegular = ({ products, i }) => {
  const { currentPage, allTemplatesContent, setAllTemplatesContent } =
    usePrinterContext()

  const currentContent = allTemplatesContent.find(
    content => content.page === currentPage
  )
  const currentTerms = currentContent?.content?.terms || DEFAULT_VALUE

  const [isEditing, setIsEditing] = React.useState(false)
  const [terms, setTerms] = React.useState(currentTerms)

  const prod1 = products?.content?.products?.[0]
  const prod2 = products?.content?.products?.[1] || {}

  const handleEditTerms = () => {
    const updatedContent = allTemplatesContent.map(content => {
      if (content.page === currentPage) {
        return {
          ...content,
          content: {
            ...content.content,
            terms,
          },
        }
      }
      return content
    })
    setAllTemplatesContent(updatedContent)
    setIsEditing(false)
  }

  return (
    <div
      key={products.page}
      id="printer-preview-left-canvas-square-2"
      className="printer-preview-left-canvas-square-2">
      <div
        className="printer-preview-left-canvas-square-2-box"
        style={{
          height: '100%',
          top: '0',
        }}>
        <div
          className="printer-preview-left-content-body-square-2"
          style={{ top: '90px' }}>
          <p className="printer-preview-left-content-body-square-2-title">
            {prod1?.ProductName || ''}
          </p>
          <p className="printer-preview-left-content-body-square-2-sku">
            {prod1?.Sku || ''}
          </p>
          {prod1?.BasePrice && (
            <div className="printer-preview-left-content-body-square-2-price-wrapper">
              <p className="printer-preview-mini-text-square-2">$</p>
              <p
                className="printer-preview-left-content-body-square-2-price"
                style={{ fontSize: '124px', marginTop: '40px' }}>
                {prod1?.BasePrice?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p className="printer-preview-mini-text-square-2">
                {prod1?.BasePrice?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
        </div>
        <div
          className="printer-preview-left-content-footer-square-2"
          style={{ top: 'calc(50% - 25px)' }}>
          <div className="printer-preview-content-footer-input-wrapper">
            {isEditing && (
              <textarea
                className="printer-preview-left-content-footer-square-2-legal"
                type="text"
                rows={3}
                value={terms}
                disabled={!isEditing}
                onChange={e => setTerms(e.target.value)}
                style={{
                  border: isEditing ? '1px solid #000' : 'none',
                  textAlign: 'end',
                  background: 'transparent',
                }}
              />
            )}
            {!isEditing && (
              <p
                className="printer-preview-left-content-footer-square-2-legal"
                style={{
                  border: isEditing ? '1px solid #000' : 'none',
                  textAlign: 'end',
                  background: 'transparent',
                }}>
                {terms}
              </p>
            )}
            <div className="printer-terms-edit-wrapper-1">
              {isEditing && (
                <CheckCircleFill
                  size={14}
                  onClick={handleEditTerms}
                  className="printer-preview-content-check"
                />
              )}
              {isEditing && (
                <XCircleFill
                  size={14}
                  onClick={() => {
                    setIsEditing(false)
                    setTerms(DEFAULT_VALUE)
                  }}
                  className="printer-preview-content-circle"
                />
              )}
              {!isEditing && (
                <PencilSquare
                  size={14}
                  onClick={() => setIsEditing(true)}
                  className="printer-preview-content-pencil"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="printer-preview-left-canvas-square-2-box"
        style={{
          height: '50%',
          top: '50%',
        }}>
        <div
          className="printer-preview-left-content-body-square-2-2"
          style={{ top: '90px' }}>
          <p className="printer-preview-left-content-body-square-2-title-2">
            {prod2?.ProductName || ''}
          </p>
          <p className="printer-preview-left-content-body-square-2-sku-2">
            {prod2?.Sku || ''}
          </p>
          {prod2?.BasePrice && (
            <div className="printer-preview-left-content-body-square-2-price-wrapper">
              <p className="printer-preview-mini-text-square-2">$</p>
              <p
                className="printer-preview-left-content-body-square-2-price-2"
                style={{ fontSize: '124px', marginTop: '40px' }}>
                {prod2?.BasePrice?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p className="printer-preview-mini-text-square-2">
                {prod2?.BasePrice?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
        </div>
        <div
          className="printer-preview-left-content-footer-square-2-2"
          style={{ top: 'calc(100% - 25px)' }}>
          <div className="printer-preview-content-footer-input-wrapper">
            {isEditing && (
              <textarea
                className="printer-preview-left-content-footer-square-2-legal"
                type="text"
                rows={3}
                value={terms}
                disabled={!isEditing}
                onChange={e => setTerms(e.target.value)}
                style={{
                  border: isEditing ? '1px solid #000' : 'none',
                  textAlign: 'end',
                  background: 'transparent',
                }}
              />
            )}
            {!isEditing && (
              <p
                className="printer-preview-left-content-footer-square-2-legal"
                style={{
                  border: isEditing ? '1px solid #000' : 'none',
                  textAlign: 'end',
                  background: 'transparent',
                }}>
                {terms}
              </p>
            )}
            <div className="printer-terms-edit-wrapper-1">
              {isEditing && (
                <CheckCircleFill
                  onClick={handleEditTerms}
                  size={14}
                  className="printer-preview-content-check"
                />
              )}
              {isEditing && (
                <XCircleFill
                  size={14}
                  onClick={() => {
                    setIsEditing(false)
                    setTerms(DEFAULT_VALUE)
                  }}
                  className="printer-preview-content-circle"
                />
              )}
              {!isEditing && (
                <PencilSquare
                  size={14}
                  onClick={() => setIsEditing(true)}
                  className="printer-preview-content-pencil"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <img
        className="printer-preview-left-image"
        src={images.printer.templates.novey.regular2}
        alt="template-preview"
      />
      {/* <div
            style={{
              pageBreakAfter: 'always',
            }}>
            &nbsp;
          </div> */}
    </div>
  )
}
