import React, { useRef, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Navigation from '../../Components/Navigation'
import { Lock } from 'react-bootstrap-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './misListas.css'
import { useSelector, useDispatch } from 'react-redux'
import {
  emptyCart,
  priceListAddToCart,
} from '../../redux/actions/cartActions.js'
import {
  clearList,
  addToList,
  removeFromList,
} from '../../redux/actions/listActions'
import MiniLoading from '../../Components/MiniLoading'
import Search from '../../Assets/Images/Departamentos/search.png'
import ListProduct from '../../Components/ListProduct'
import ProductSearch from '../../Components/ProductSearch'
import NoImage from '../../Assets/Images/Logos/no-image.png'
import Modal from 'react-bootstrap/Modal'
import RemoveProduct from '../../Components/ProductList/RemoveProduct'
import { t } from 'i18next'
import {
  createQuickListAction,
  getAllQuickListsAction,
} from 'redux/actions/quickListsActions'
import { ROUTES } from 'Utils/constants'
import { getPriceInventory } from 'redux/actions/priceProductsAction'
import { getFragmentedService } from 'Utils/Helpers/getFragmentedService'
import { useDebounce } from 'Hooks/useDebounce'

function MisListas() {
  const debounce = useDebounce()
  const stateListDetails = 0
  const stateNewList = 1
  const stateEditList = 2
  const [selected, setSelected] = useState('')
  const productsRef = useRef(null)
  const listContainer = useRef(null)
  const [lists, setLists] = useState([])
  const [containerLoader, setContainerLoader] = useState(true)
  const [loading, setLoading] = useState(false)
  const [loadingList, setLoadingList] = useState(false)
  const [selectedList, setSelectedList] = useState({})
  const [products, setProducts] = useState([])
  const [search, setSearch] = useState([])
  const [searchPrices, setSearchPrices] = useState([])
  const [misListasLength, setMisListasLength] = useState(0)
  const [listasRapidasLength, setListasRapidasLength] = useState(0)
  const [viewState, setViewState] = useState(stateListDetails)
  const [newListName, setNewListName] = useState('')
  const [selectAll, setSelectAll] = useState(false)
  const history = useHistory()
  const [editingListObject, setEditingListObject] = useState({})
  const [showModalRemoveProduct, setShowModalRemoveProduct] = useState(false)
  const [confirmRemoveProduct, setConfirmRemoveProduct] = useState(false)
  const [productToRemove, setProductToRemove] = useState({})

  const [textSearch, setTextSearch] = useState('')

  // STATE OF CHECKS
  const [checkedState, setCheckedState] = useState(null)

  const dispatch = useDispatch()

  // REDUX
  const UserId = useSelector(
    state => state.login?.user?.CustomerInfo?.CustomerId
  )
  const WarehouseId = useSelector(state => state?.company?.WarehouseId)
  const CompanyId = useSelector(state => state?.company?.CompanyId)
  const currentList = useSelector(state => state.list.list)
  const currentCart = useSelector(state => state.cart.cart)

  // FILTER PRODUCTS PER COMPANY
  const filterProductsByCompany = productsArray => {
    const filteredProducts = []
    for (let i = 0; i < productsArray.length; i++) {
      const companies = productsArray[i].Company
      // eslint-disable-next-line array-callback-return
      companies?.filter(company => {
        if (company.CompanyId === CompanyId) {
          filteredProducts.push(productsArray[i])
        }
      })
    }
    return filteredProducts
  }

  // SOLICITAR LAS LISTAS
  const getAllLists = async () => {
    setLoadingList(true)

    const payload = {
      Status: true,
      UserId,
      WarehouseId,
    }
    const response = await dispatch(getAllQuickListsAction(payload))
    if (response && response.data?.Data) {
      if (selected) {
        const list = await response.data.Data.filter(list => {
          return list.ProductCustomListTypeId === selected
        })

        setLists(list[0]?.ListDetailProductCustomList)
        setSelectedList(list[0]?.ListDetailProductCustomList[0]) // SELECT THE FIRST AS DEFAULT
        setProducts(
          filterProductsByCompany(
            list[0]?.ListDetailProductCustomList[0].ProductList
          )
        )
        setSearch(
          filterProductsByCompany(
            list[0]?.ListDetailProductCustomList[0].ProductList
          )
        )
      } else {
        const generalData = await response?.data?.Data?.map(list => {
          return list.ListDetailProductCustomList
        }).flat()

        setMisListasLength(
          checkLength(generalData, '2581263E-0FC7-4731-A4D1-6D769A482940')
        )
        setListasRapidasLength(
          checkLength(generalData, '8277F210-DAA8-11EC-9D64-0242AC120002')
        )
        setLists(generalData)
        setSelectedList(generalData[0]) // SELECT THE FIRST AS DEFAULT
        setProducts(filterProductsByCompany(generalData[0].ProductList))
        setSearch(filterProductsByCompany(generalData[0].ProductList))
      }
    } else {
      console.error('Error getting quick List:', response.toString())
      setLoadingList(false)
      throw new Error(response.toString())
    }
  }

  // CHECK LIST LENGTH
  const checkLength = (array, value) => {
    const arrayForLength = array.filter(element => {
      return element.ProductCustomListTypeId === value
    }).length
    return arrayForLength
  }

  // CAMBIO DE TIPO LISTA
  const handleChange = e => {
    setTextSearch('')
    setSelected(e.target.value)
  }

  // CAMBIO DE LISTA
  const handleList = (e, list) => {
    setTextSearch('')
    if (currentCart.length > 0) {
      dispatch(emptyCart())
    }
    setViewState(stateListDetails)
    setSelectedList(list)
    setProducts(filterProductsByCompany(list.ProductList))
    setSearch(filterProductsByCompany(list.ProductList))
  }

  // HANDLE SEARCH
  const handleSearch = e => {
    setTextSearch(e.target.value)
    debounce(() => {
      if (e.target.value.length > 0) {
        const newSearch = products.filter(product => {
          return (
            product?.ProductName?.toLowerCase()?.includes(
              e.target.value.toLowerCase()
            ) ||
            product?.ShortDescription?.toLowerCase()?.includes(
              e.target.value.toLowerCase()
            ) ||
            product?.SystemCode?.toLowerCase()?.includes(
              e.target.value.toLowerCase()
            )
          )
        })
        setSearch(newSearch)
      } else {
        setSearch(products)
      }
    }, 500)
  }

  // HANDLE SELECT ALL
  const handleSelectAll = async (e, products) => {
    setSelectAll(!selectAll)
    const updatedCheckedState = await checkedState.map((item, index) =>
      products?.[index]?.Price ? e.target.checked : false
    )
    setCheckedState(updatedCheckedState)
    if (e.target.checked) {
      addAllProductsToCart(products)
    } else {
      dispatch(emptyCart())
      // eslint-disable-next-line no-const-assign
      currentCart = []
    }
  }

  const addAllProductsToCart = async products => {
    dispatch(priceListAddToCart(products))
  }

  const createNewList = async () => {
    const arrayOfIds = await currentList.map(product => product.ProductId)
    const payload = {
      Name: newListName,
      ProductCustomListId: null,
      Warehouses: WarehouseId,
      Description: 'Prueba de Creación de una lista de Mis Listas',
      ProductCustomListTypeId: '2581263e-0fc7-4731-a4d1-6d769a482940',
      UserId,
      Products: arrayOfIds,
      Status: true,
    }
    const response = await dispatch(createQuickListAction(payload))
    if (response && response.data?.Data) {
      setViewState(stateListDetails)
    } else {
      console.error('Error:', response.toString())
      throw new Error(response.toString())
    }
    getAllLists()
    dispatch(clearList())
  }

  const getPrices = async PriceInventoryPayload => {
    setLoading(true)
    try {
      const response = await getFragmentedService({
        service: payload => dispatch(getPriceInventory(payload)),
        payload: PriceInventoryPayload,
      })
      const updatedSearch = search.map(item => {
        const priceItem = response.find(
          product => product.ProductId === item.ProductId
        )
        return {
          ...item,
          ...priceItem,
        }
      })
      setSearchPrices(updatedSearch)
      setLoading(false)
      setLoadingList(false)
      return response
    } catch (error) {
      setLoading(false)
      throw error
    }
  }

  useEffect(() => {
    if (search?.length) {
      const productIds = search.map(item => item.ProductId)
      getPrices(productIds)
    }
  }, [search])

  useEffect(() => {
    if (containerLoader && productsRef && productsRef.current)
      setContainerLoader(false)
  }, [productsRef])

  useEffect(() => {
    getAllLists()
    dispatch(emptyCart())
  }, [selected])

  useEffect(() => {
    if (products && products.length > 0) {
      setCheckedState(new Array(products.length).fill(false))
      setSelectAll(false)
    }
  }, [products])

  // START EDIT LIST PROCESS
  const handleEditList = async (e, list) => {
    e.stopPropagation()

    setViewState(stateEditList)
    dispatch(clearList())

    if (currentCart.length > 0) {
      dispatch(emptyCart())
    }

    if (list.ProductList != null) {
      setProducts(filterProductsByCompany(list.ProductList))
      setSearch(filterProductsByCompany(list.ProductList))

      // HACK: ProductSearch uses clearList() which interferes with addToList below
      await timeout(500)

      list.ProductList.forEach(product => {
        dispatch(addToList(product))
      })
    }

    setEditingListObject(list)
  }

  const removeProductEditList = product => {
    setProductToRemove(product)
    handleModalRemoveProduct()
  }

  const saveEditList = async () => {
    const arrayOfIds = await currentList.map(product => product.ProductId)

    const payload = {
      Name: editingListObject.Name,
      ProductCustomListId: editingListObject.ProductCustomListId,
      Warehouses: null,
      Description: editingListObject.Description,
      ProductCustomListTypeId: editingListObject.ProductCustomListTypeId,
      UserId: editingListObject.UserId,
      Products: arrayOfIds,
      Status: true,
    }
    const response = await dispatch(createQuickListAction(payload))
    if (response && response.data?.Data) {
      setViewState(stateListDetails)
    } else {
      console.error('Error:', response.toString())
      throw new Error(response.toString())
    }
    getAllLists()
    dispatch(clearList())
  }

  const getMenuName = () => {
    return viewState === stateEditList && editingListObject != null
      ? editingListObject.Name
      : t('MY_LISTS_T.LIST_DEFAULT_TITLE')
  }

  function timeout(delay) {
    // eslint-disable-next-line promise/param-names
    return new Promise(res => setTimeout(res, delay))
  }

  const handleModalRemoveProduct = () => {
    setShowModalRemoveProduct(!showModalRemoveProduct)
  }

  useEffect(() => {
    if (confirmRemoveProduct) {
      dispatch(removeFromList(productToRemove))
      setConfirmRemoveProduct(false)
    }
  }, [confirmRemoveProduct])

  return (
    <>
      <Navigation />
      <div className="my_list_body">
        <Row className="lists_container">
          <Col xs={3} className="my_lists">
            <div className="my_lists_container">
              <h2>{getMenuName()}</h2>
              {viewState === stateListDetails && (
                <div
                  className="list_creation"
                  onClick={() => {
                    // setOpenInputNewList(!openInputNewList)
                    dispatch(clearList())
                    setViewState(stateNewList)
                  }}>
                  <label className="add_list_text" htmlFor="add_list">
                    {t('MY_LISTS_T.CREATE_LIST_LABEL')}{' '}
                  </label>
                  <div id="add_list" className="add_list">
                    +
                  </div>
                </div>
              )}
              {viewState === stateNewList && (
                <div className="add_list_input">
                  <input
                    onChange={e => {
                      setNewListName(e.target.value)
                    }}
                    type="text"
                    className="add_list_create"
                    name="add_list_create"
                    placeholder={t('MY_LISTS_T.ADD_NAME_PLACEHOLDER')}
                  />
                  <div className="add_list_buttons">
                    <button
                      className="add_list_cancelar"
                      onClick={() => {
                        setViewState(stateListDetails)
                        dispatch(clearList())
                      }}>
                      {t('MY_LISTS_T.CANCEL_BUTTON')}{' '}
                    </button>
                    <button
                      className="add_list_continuar"
                      disabled={currentList < 1 || newListName.length < 1}
                      onClick={createNewList}>
                      {t('MY_LISTS_T.CONTINUE_BUTTON')}{' '}
                    </button>
                  </div>
                </div>
              )}
              {viewState === stateEditList && (
                <>
                  <div className="add_list_buttons">
                    <button
                      className="add_list_cancelar"
                      onClick={() => {
                        setViewState(stateListDetails)
                        setEditingListObject(null)
                      }}>
                      {t('MY_LISTS_T.CANCEL_BUTTON')}{' '}
                    </button>
                    <button
                      className="add_list_continuar"
                      onClick={saveEditList}>
                      {t('MY_LISTS_T.SAVE_BUTTON')}{' '}
                    </button>
                  </div>

                  <ul className="my-new-list-products">
                    {currentList.map(product => {
                      return (
                        <li
                          key={product.ProductId}
                          className="product-edit-list">
                          <div className="product-edit-list-img">
                            <img
                              src={
                                product?.Images
                                  ? product?.Images[0]?.URL
                                  : NoImage
                              }
                              className="img-fluid"
                              alt="product"
                            />
                          </div>
                          <div className="product-edit-list-text">
                            <h3>{product?.ProductName}</h3>
                            <p>{product?.BrandName}</p>
                            <p>{product?.Sku}</p>
                          </div>
                          <div className="product-edit-list-trash">
                            <FontAwesomeIcon
                              style={{ fontSize: '1.5rem' }}
                              icon={faTrashCan}
                              onClick={() => {
                                removeProductEditList(product)
                              }}
                            />
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </>
              )}
              {viewState === stateNewList && (
                <ul className="my-new-list-products">
                  {currentList.map(product => {
                    return (
                      <li key={product.ProductId} className="product-from-list">
                        <div className="product-from-list-img">
                          <img
                            src={
                              product?.Images
                                ? product?.Images[0]?.URL
                                : NoImage
                            }
                            className="img-fluid"
                            alt="product"
                          />
                        </div>
                        <div className="product-from-list-text">
                          <h3>{product?.ProductName}</h3>
                          <p>{product?.Brand}</p>
                          <p>{product?.Sku}</p>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              )}
              {viewState === stateListDetails && (
                <>
                  <div className="lists_types">
                    <div className="list_type">
                      <input
                        id="todo"
                        type="radio"
                        name="list_type"
                        value=""
                        onChange={handleChange}
                        checked={selected === ''}
                      />
                      <label htmlFor="todo">{t('MY_LISTS_T.TODO_LABEL')}</label>
                    </div>
                    <div className="list_type">
                      <input
                        id="mis_listas"
                        type="radio"
                        name="list_type"
                        value="2581263E-0FC7-4731-A4D1-6D769A482940"
                        onChange={handleChange}
                        checked={
                          selected === '2581263E-0FC7-4731-A4D1-6D769A482940'
                        }
                        disabled={!misListasLength > 0}
                      />
                      <label htmlFor="mis_listas">
                        {t('MY_LISTS_T.LIST_DEFAULT_TITLE')}
                      </label>
                    </div>
                    <div className="list_type">
                      <input
                        id="listas_rapidas"
                        type="radio"
                        name="list_type"
                        value="8277F210-DAA8-11EC-9D64-0242AC120002"
                        onChange={handleChange}
                        checked={
                          selected === '8277F210-DAA8-11EC-9D64-0242AC120002'
                        }
                        disabled={!listasRapidasLength > 0}
                      />
                      <label htmlFor="listas_rapidas">
                        {t('MY_LISTS_T.RAPID_LISTS_LABEL')}
                      </label>
                    </div>
                  </div>
                  <ul className="user_lists">
                    {loadingList ? (
                      <MiniLoading />
                    ) : (
                      lists.map((list, index) => {
                        return (
                          <li
                            className={
                              selectedList.ProductCustomListId ===
                              list.ProductCustomListId
                                ? 'user_list_selected'
                                : 'user_list'
                            }
                            onClick={e => handleList(e, list)}
                            key={index}>
                            <div className="list-name">
                              <p>{list.Name}</p>
                              {list.ProductCustomListTypeId ===
                              '8277F210-DAA8-11EC-9D64-0242AC120002' ? (
                                <Lock />
                              ) : (
                                <FontAwesomeIcon
                                  style={{ color: '#25B0F8', fontSize: '2rem' }}
                                  icon={faPlusCircle}
                                  onClick={e => handleEditList(e, list)}
                                />
                              )}
                            </div>
                            <div>
                              {list?.ProductList &&
                              filterProductsByCompany(list?.ProductList) ? (
                                <p>
                                  {
                                    filterProductsByCompany(list?.ProductList)
                                      ?.length
                                  }
                                </p>
                              ) : null}
                            </div>
                          </li>
                        )
                      })
                    )}
                  </ul>
                </>
              )}
            </div>
          </Col>
          {viewState === stateNewList || viewState === stateEditList ? (
            <Col xs={9} className="product-search">
              <ProductSearch productsRef={productsRef} inListScreen={true} />
            </Col>
          ) : (
            <Col xs={9} className="products_list" ref={listContainer}>
              {loading ? (
                <MiniLoading />
              ) : (
                <div>
                  <div className="list-title">
                    <h2>{selectedList?.Name}</h2>
                  </div>
                  <div className="list-search-bar">
                    <input
                      type="text"
                      placeholder={t('MY_LISTS_T.SEARCH_PLACEHOLDER')}
                      onChange={handleSearch}
                      value={textSearch}
                    />
                    <img
                      src={Search}
                      alt="search-icon"
                      className="search-icon"
                    />
                  </div>
                  <div className="select-all-list">
                    <label htmlFor="selectAll">
                      {t('MY_LISTS_T.SELECT_ALL_LABEL')}
                    </label>
                    <input
                      type="checkbox"
                      onChange={e => handleSelectAll(e, searchPrices)}
                      checked={selectAll}
                      id="selectAll"
                      name="selectAll"
                    />
                  </div>
                  <Row
                    xs={2}
                    sm={3}
                    md={4}
                    lg={4}
                    xl={5}
                    className="product-list-display-container">
                    {searchPrices?.map((product, index) => {
                      return (
                        <ListProduct
                          product={{
                            ...product,
                            BasePrice: product.Price,
                            FinalPrice: product.Price,
                          }}
                          checkedState={checkedState}
                          setCheckedState={setCheckedState}
                          position={index}
                          key={index}
                        />
                      )
                    })}
                  </Row>
                </div>
              )}
              <div className="add-list-to-quote">
                <button
                  disabled={
                    currentCart.length < 1 || checkedState?.indexOf(true) === -1
                  }
                  onClick={() =>
                    history.push({
                      pathname: ROUTES.createQuote,
                      state: { from: 'Mis Listas' },
                    })
                  }>
                  {t('MY_LISTS_T.CREATE_QUOTATION')}{' '}
                </button>
              </div>
            </Col>
          )}
        </Row>

        {/* Modal confirmar eliminar producto */}
        <Modal show={showModalRemoveProduct} centered>
          <RemoveProduct
            setShowModalRemoveProduct={handleModalRemoveProduct}
            setConfirmRemoveProduct={setConfirmRemoveProduct}
          />
        </Modal>
      </div>
    </>
  )
}

export default MisListas
