import React from 'react'
import { Box } from '@mui/material'

const Overlay = ({ isOpen, handleDrawer }) => {
  const handleClick = () => {
    if (isOpen) {
      handleDrawer()
    }
  }
  return isOpen ? (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 1, // valor de z-index más alto que el valor predeterminado (0)
      }}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: 9998,
          opacity: { xs: isOpen ? 1 : 0, md: 0 },
        }}
        onClick={handleClick}
      />
    </Box>
  ) : null
}

export default Overlay
