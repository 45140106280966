import React, { useState } from 'react'
import { useDebounce } from 'Hooks/useDebounce'
import { useSearchBox } from 'react-instantsearch-hooks-web'

const CustomSearchBox = ({ placeholder }) => {
  const debounce = useDebounce()
  const { refine } = useSearchBox()
  const [search, setSearch] = useState('')

  const handleSearch = e => {
    setSearch(e.target.value)
    e.target.value !== '' && debounce(() => refine(e.target.value), 500)
  }

  return (
    <div className="ais-SearchBox">
      <input
        type="text"
        className="ais-SearchBox-input searchBox-sp"
        placeholder={placeholder}
        value={search}
        onChange={handleSearch}
      />
    </div>
  )
}

export default CustomSearchBox
