import React, { createContext, useState, useContext } from 'react'

const PrinterTemplatesContext = createContext()

export const PrinterTemplatesProvider = ({ children }) => {
  const templateRef = React.useRef([])
  const [selectedTemplates, setSelectedTemplates] = useState([])
  const [selectedTemplateType, setSelectedTemplateType] = useState({
    value: '',
    key: '',
  })
  const [selectedProducts, setSelectedProducts] = useState([])
  const [showAllTemplates, setShowAllTemplates] = useState(false)

  const [currentPage, setCurrentPage] = React.useState(1)
  const [allTemplatesContent, setAllTemplatesContent] = React.useState([
    {
      page: 1,
      template: selectedTemplates?.[0],
      content: {
        products: [],
      },
    },
  ])

  return (
    <PrinterTemplatesContext.Provider
      value={{
        selectedTemplates,
        setSelectedTemplates,
        selectedProducts,
        setSelectedProducts,
        selectedTemplateType,
        setSelectedTemplateType,
        showAllTemplates,
        setShowAllTemplates,
        allTemplatesContent,
        setAllTemplatesContent,
        currentPage,
        setCurrentPage,
        templateRef,
      }}>
      {children}
    </PrinterTemplatesContext.Provider>
  )
}

export const usePrinterContext = () => {
  return useContext(PrinterTemplatesContext)
}
