/* eslint-disable no-prototype-builtins */
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { genderIds } from 'Utils/constants'
import { t } from 'i18next'
import React, { useState } from 'react'

export const DisplayGenres = ({
  formik,
  setValueStateSelect,
  valueStateSelect,
}) => {
  const [showStateSelect, setShowStateSelect] = useState(false)

  const hasValueStateDescription =
    valueStateSelect &&
    valueStateSelect.Description !== null &&
    valueStateSelect.Description !== ''

  const hasGenderIdError =
    formik.errors?.hasOwnProperty('genderId') &&
    formik.errors?.genderId !== null

  const handleCleanError = () => {
    const { errors, setErrors } = formik
    const updatedErrors = { ...errors }
    delete updatedErrors.genderId
    setErrors(updatedErrors)
  }
  return (
    <>
      <div
        className="form-group"
        // eslint-disable-next-line react/no-unknown-property
        onLoad={() => {
          return (document.getElementById('mySelect').selectedIndex = 0)
        }}>
        <div className="no-margin-nopadding generic-address-search-filter list-prices-component">
          <button
            type="button"
            id="generic-address-filter-content-list-price"
            className="generic-address-filter-content-list-price"
            onClick={() => setShowStateSelect(!showStateSelect)}>
            {hasValueStateDescription ? (
              <p className="">{valueStateSelect.Description}</p>
            ) : (
              <p className={'generic-price-list-label-text-unselect'}>
                Género*
              </p>
            )}
            <FontAwesomeIcon
              icon={faChevronDown}
              className="generic-list-price-down-icon"
            />
          </button>
          <ul
            className={
              showStateSelect
                ? 'generic-address-filter-options ul-price-list ul-filter-option-two'
                : 'generic-address-filter-options-hidden'
            }
            onMouseLeave={e => {
              setShowStateSelect(false)
            }}>
            <li className="customer-filter-option" id="genderId">
              <div
                name="genderId"
                id="genderId"
                onClick={e => {
                  setShowStateSelect(false)
                  setValueStateSelect({
                    Description: t('CREATE_CLIENT_T.GENDER_FEM'),
                    genderId: genderIds.female,
                  })
                  formik.values.genderId = genderIds.female
                  handleCleanError()
                }}>
                {t('CREATE_CLIENT_T.GENDER_FEM')}{' '}
              </div>
            </li>
            <li className="customer-filter-option" id="genderId">
              <div
                name="genderId"
                id="genderId"
                onClick={e => {
                  setShowStateSelect(false)
                  setValueStateSelect({
                    Description: t('CREATE_CLIENT_T.GENTER_MALE'),
                    genderId: genderIds.male,
                  })
                  formik.values.genderId = genderIds.male
                  handleCleanError()
                }}>
                {t('CREATE_CLIENT_T.GENTER_MALE')}{' '}
              </div>
            </li>
          </ul>
        </div>
      </div>
      {hasGenderIdError && (
        <div className="input-feedback">
          <strong>{formik.errors?.genderId} </strong>
        </div>
      )}
    </>
  )
}
