import React, { useState, useEffect } from 'react'
import { GoogleMap, Autocomplete, Marker } from '@react-google-maps/api'
import MiniLoading from '../MiniLoading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import './mapa.css'
import { useSelector, useDispatch } from 'react-redux'
import { agregarDireccion } from '../../redux/actions/cotizacionActions.js'
import { CircularProgress } from '@mui/material'
import {
  fetchDistrictAction,
  fetchGeneralsAction,
  fetchProvincesByCountryAction,
  fetchTownshipAction,
  updateAddressAction,
} from 'redux/actions/clientsActions'
import { defaultCountryId } from 'Utils/constants'

import styles from './mapa.module.css'

function Mapa({
  setSelectedAddress,
  setDireccion,
  handleMetodoEntrega,
  isModalScreen,
}) {
  const clienteId = useSelector(
    state => state?.cotizacion?.QuoteHeader?.CustomerId
  )

  const mapContainerStyle = {
    height: '330px',
    width: '100%',
  }

  const dispatch = useDispatch()

  // COUNTRY
  const [countryCode, setCountryCode] = useState('')
  const [selectedCountry, setSelectedCountry] = useState('PÁIS')

  // PROVINCE
  const [provinces, setProvinces] = useState([])
  const [selectedProvince, setSelectedProvince] = useState('PROVINCIA')
  const [provinceCode, setProvinceCode] = useState('')

  // DISTRICT
  const [districts, setDistricts] = useState([])
  const [selectedDistrict, setSelectedDistrict] = useState('DISTRITO')
  const [districtCode, setDistrictCode] = useState('')

  // TOWNSHIP
  const [townships, setTownships] = useState([])
  const [selectedTownship, setSelectedTownship] = useState('CORREGIMIENTO')
  const [townshipCode, setTownshipCode] = useState('')

  // DROPDOWN LOGIC
  const [showCountries, setShowCountries] = useState(false)
  const [showProvinces, setShowProvinces] = useState(false)
  const [showDistricts, setShowDistricts] = useState(false)
  const [showTownships, setShowTownships] = useState(false)

  const [errors, setErrors] = useState({
    country: false,
    province: false,
    district: false,
    township: false,
    street: false,
    addressDetail: false,
  })

  //  MAP CONFIG
  const [lat, setLat] = useState(
    parseFloat(window.localStorage.getItem('lat')) || 8.98
  )
  const [lng, setLng] = useState(
    parseFloat(window.localStorage.getItem('long')) || -79.51
  )
  const [autocomplete, setAutocomplete] = useState(null)
  const [formIsLoading, setFormIsLoading] = useState(false)
  const [saveAddressLoadingState, setSaveAddressLoadingState] = useState(false)

  const onLoad = autocom => {
    setAutocomplete(autocom)
  }

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      setLat(autocomplete.getPlace().geometry.location.lat())
      setLng(autocomplete.getPlace().geometry.location.lng())
      fetchGenerals(
        autocomplete.getPlace().geometry.location.lat(),
        autocomplete.getPlace().geometry.location.lng()
      )
    }
  }
  const handleChangeValue = name => {
    setErrors({
      ...errors,
      [name]: false,
    })
  }

  const handleMarker = e => {
    setLat(e.latLng.lat())
    setLng(e.latLng.lng())
    fetchGenerals(e.latLng.lat(), e.latLng.lng())
  }

  // COUNTRIES FUNCTIONS
  const handleCountries = () => {
    setShowCountries(!showCountries)
  }

  const handleCountry = e => {
    setSelectedCountry(e.target.value)
    setCountryCode(e.target.id)
    setShowCountries(!showCountries)
    handleChangeValue('country')
  }

  // PROVINCE FUNCTIONS
  const handleProvinces = () => {
    setShowProvinces(!showProvinces)
  }

  const handleProvince = e => {
    setSelectedProvince(e.target.value)
    setProvinceCode(e.target.id)
    setShowProvinces(!showProvinces)
    fetchDistrict(e.target.id)
    setSelectedDistrict('DISTRITO')
    setSelectedTownship('CORREGIMIENTO')
    handleChangeValue('province')
  }

  // DISTRICT FUNCTIONS
  const handleDistricts = () => {
    setShowDistricts(!showDistricts)
  }

  const handleDistrict = e => {
    setSelectedDistrict(e.target.value)
    setDistrictCode(e.target.id)
    setShowDistricts(!showDistricts)
    fetchTownship(e.target.id)
    setSelectedTownship('CORREGIMIENTO')
    handleChangeValue('district')
  }

  // TOWNSHIP FUNCTIONS
  const handleTownships = () => {
    setShowTownships(!showTownships)
  }

  const handleTownship = e => {
    setSelectedTownship(e.target.value)
    setTownshipCode(e.target.id)
    setShowTownships(!showTownships)
    handleChangeValue('township')
  }

  // FORM HANDLING
  const handleForm = async e => {
    e.preventDefault()
    setErrors({
      country: !countryCode,
      province: !provinceCode,
      district: !districtCode,
      township: !townshipCode,
      street: !e.target.street.value,
      addressDetail: !e.target.alias.value,
    })
    if (
      countryCode &&
      provinceCode &&
      districtCode &&
      townshipCode &&
      e.target.street.value &&
      e.target.alias.value
    ) {
      const address = {
        AddressInfo: {
          type: 'temporal',
          Country: {
            CountryId: countryCode,
            Name: selectedCountry,
          },
          District: {
            DistrinctId: districtCode,
            Name: selectedDistrict,
          },
          Province: {
            ProvinceId: provinceCode,
            Name: selectedProvince,
          },
          Township: {
            TownshipId: townshipCode,
            Name: selectedTownship,
          },
          Street: e.target.street.value,
          AddressDetail: e.target.alias.value,
          PhoneNumber: e.target.telephone?.value || null,
        },
      }

      let addressCotizacion = {
        Address_AddressDetail: e.target.alias.value,
        Address_AddressId: null,
        Address_AddressType: null,
        Address_AddressTypeId: null,
        Address_BuildingId: null,
        Address_BuildingName: null,
        Address_BuildingType: null,
        Address_BuildingTypeId: null,
        Address_CountryId: countryCode,
        Address_CountryName: selectedCountry,
        Address_DistrictId: districtCode,
        Address_DistrictName: selectedDistrict,
        Address_HomeOrFloorNumber: null,
        Address_Latitude: lat || null,
        Address_Longitude: lng || null,
        Address_NeighborhoodId: null,
        Address_NeighborhoodName: null,
        Address_ProvinceId: provinceCode,
        Address_ProvinceName: selectedProvince,
        Address_Street: e.target.street.value,
        Address_TownshipId: townshipCode,
        Address_TownshipName: selectedTownship,
      }
      const payload = {
        customerId: clienteId,
        addressTypeId: '90ACBCF4-BE83-4F03-8B5E-5760FC92B59B',
        countryId: countryCode,
        provinceId: provinceCode,
        districtId: districtCode,
        townshipId: townshipCode,
        street: e.target.street.value,
        buildingTypeId: '18272DEA-60DB-4F71-B292-1FD44E3A2B30',
        addressDetail: e.target.alias.value,
        createdBy: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        lastModifiedBy: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        name: e.target.alias.value,
        phoneNumber: e.target.telephone?.value || null,
        latitude: lat || null,
        longitude: lng || null,
      }

      setFormIsLoading(true)
      setSaveAddressLoadingState(true)
      const Data = await dispatch(updateAddressAction(payload))
      if (Data && (Data?.AddressDetail || Data?.Name)) {
        addressCotizacion = {
          ...addressCotizacion,
          Address_AddressTypeId: Data.AddressTypeId,
          Address_AddressId: Data.AddressId,
          Address_BuildingTypeId: Data.BuildingTypeId,
        }

        setDireccion(true)
        setSelectedAddress(address)
        handleMetodoEntrega()
        setSaveAddressLoadingState(false)
        dispatch(agregarDireccion(addressCotizacion))
      } else {
        setSaveAddressLoadingState(false)
        console.error(Data)
      }
    }
  }

  // LOCATION ENDPOINTS
  const fetchGenerals = async (latitud, longitude) => {
    setFormIsLoading(true)
    const payload = {
      Latitude: latitud,
      Longitude: longitude,
    }
    const Data = await dispatch(fetchGeneralsAction(payload))
    if (Data && Data?.length > 0) {
      const locationData = Data[0]

      // COUNTRY
      setSelectedCountry(locationData.Country)
      setCountryCode(locationData.CountryId)

      // PROVINCE
      setSelectedProvince(locationData.Province)
      setProvinceCode(locationData.ProvinceId)

      // DISTRICT
      setSelectedDistrict(locationData.District)
      setDistrictCode(locationData.DistrictId)
      fetchDistrict(locationData.ProvinceId)

      // TOWNSHIP
      setSelectedTownship(locationData.Township)
      setTownshipCode(locationData.TownshipId)
      fetchTownship(locationData.DistrictId)

      setErrors({
        ...errors,
        country: false,
        province: false,
        district: false,
        township: false,
      })
    } else {
      console.error('Error getting Generals: ', Data)
    }
    setFormIsLoading(false)
  }

  const fetchProvinces = async () => {
    const Data = await dispatch(
      fetchProvincesByCountryAction(
        districtCode === '' ? defaultCountryId.countryId : districtCode
      )
    )
    if (Data && Data?.length > 0) {
      setProvinces(Data)
    } else {
      console.error('Error getting Provinces: ', Data)
    }
  }

  const fetchDistrict = async provinceCode => {
    const Data = await dispatch(fetchDistrictAction(provinceCode))
    if (Data && Data?.length > 0) {
      setDistricts(Data)
    } else {
      console.error('Error getting districts: ', Data)
    }
  }

  const fetchTownship = async districtCode => {
    const Data = await dispatch(fetchTownshipAction(districtCode))
    if (Data && Data?.length > 0) {
      setTownships(Data)
    } else {
      console.error('Error getting TownShips: ', Data)
    }
  }

  useEffect(() => {
    fetchProvinces()
  }, [])

  return (
    <>
      <div
        className={
          isModalScreen
            ? 'content-form-direccion-modal'
            : 'content-form-direccion'
        }>
        {isModalScreen ? (
          <label className="add-direction-title-label-modal">
            Agregar nueva dirección
          </label>
        ) : null}
        <div
          className={
            isModalScreen ? 'content-form-direccion-modal-padding' : null
          }>
          <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
            <input
              type="text"
              placeholder="Ingresa la dirección de envío"
              style={{
                boxSizing: `border-box`,
                width: `100%`,
                padding: `10px 25px`,
                borderRadius: `6px`,
                border: '1px solid #C8C8C8',
                fontSize: `14px`,
                outline: `none`,
                marginBottom: '5px',
                textOverflow: `ellipses`,
              }}
            />
          </Autocomplete>
          <GoogleMap
            id="search-map-build"
            mapContainerStyle={mapContainerStyle}
            zoom={15}
            onClick={handleMarker}
            center={{
              lat,
              lng,
            }}>
            <Marker
              position={{
                lat,
                lng,
              }}></Marker>
          </GoogleMap>
          {!formIsLoading ? (
            <form onSubmit={handleForm}>
              <p className="comment">Completa la dirección de entrega</p>
              <div className="form-group-select">
                <div>
                  <div className="address-search-filter">
                    <div
                      className="address-filter-content"
                      onClick={handleCountries}>
                      <h2>{`${selectedCountry}*`}</h2>
                      <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                    <ul
                      className={
                        showCountries
                          ? 'address-filter-options'
                          : 'address-filter-options-hidden'
                      }>
                      <li className="address-filter-option">
                        <input
                          type="radio"
                          className="radio"
                          id="2d3d33c2-3401-40a1-858b-ae0140b0d376"
                          name="pais"
                          value="PANAMA"
                          onClick={handleCountry}
                        />
                        <label htmlFor="2d3d33c2-3401-40a1-858b-ae0140b0d376">
                          PANAMÁ
                        </label>
                      </li>
                    </ul>
                  </div>
                  {errors.country && (
                    <p className={styles.errorMessage}>
                      Por favor, seleccione un país
                    </p>
                  )}
                </div>

                <div>
                  <div className="address-search-filter">
                    <div
                      className="address-filter-content"
                      onClick={handleProvinces}>
                      <h2>{`${selectedProvince}*`}</h2>
                      <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                    <ul
                      className={
                        showProvinces
                          ? 'address-filter-options'
                          : 'address-filter-options-hidden'
                      }>
                      {provinces.map(province => {
                        return (
                          <li
                            className="address-filter-option"
                            key={province.ProvinceId}>
                            <input
                              type="radio"
                              className="radio"
                              id={province.ProvinceId}
                              name="provincia"
                              value={province.Name}
                              onClick={handleProvince}
                            />
                            <label htmlFor={province.ProvinceId}>
                              {province.Name}
                            </label>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                  {errors.province && (
                    <p className={styles.errorMessage}>
                      Por favor, seleccione una provincia
                    </p>
                  )}
                </div>
              </div>
              <div className="form-group-select">
                <div>
                  <div className="address-search-filter">
                    <div
                      className="address-filter-content"
                      onClick={handleDistricts}>
                      <h2>{`${selectedDistrict}*`}</h2>
                      <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                    <ul
                      className={
                        showDistricts
                          ? 'address-filter-options'
                          : 'address-filter-options-hidden'
                      }>
                      {districts.map(district => {
                        return (
                          <li
                            className="address-filter-option"
                            key={district.DistrictId}>
                            <input
                              type="radio"
                              className="radio"
                              id={district.DistrictId}
                              name="distrito"
                              value={district.Name}
                              onClick={handleDistrict}
                            />
                            <label htmlFor={district.DistrictId}>
                              {district.Name}
                            </label>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                  {errors.district && (
                    <p className={styles.errorMessage}>
                      Por favor, seleccione un distrito
                    </p>
                  )}
                </div>
                <div>
                  <div className="address-search-filter">
                    <div
                      className="address-filter-content"
                      onClick={handleTownships}>
                      <h2>{`${selectedTownship}*`}</h2>
                      <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                    <ul
                      className={
                        showTownships
                          ? 'address-filter-options'
                          : 'address-filter-options-hidden'
                      }>
                      {townships.map(township => {
                        return (
                          <li
                            className="address-filter-option"
                            key={township.TownshipId}>
                            <input
                              type="radio"
                              className="radio"
                              id={township.TownshipId}
                              name="corregimiento"
                              value={township.Name}
                              onClick={handleTownship}
                            />
                            <label htmlFor={township.TownshipId}>
                              {township.Name}
                            </label>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                  {errors.township && (
                    <p className={styles.errorMessage}>
                      Por favor, seleccione un corregimiento
                    </p>
                  )}
                </div>
              </div>
              <div className="edificio-input">
                <input
                  type="text"
                  placeholder="Ingresa la dirección indicando nombre de edificio, piso, apartamento o número de casa*"
                  name="street"
                  maxLength={80}
                  onChange={() => handleChangeValue('street')}
                />
                {errors.street && (
                  <p className={styles.errorMessage}>
                    Por favor, introduzca una calle válida
                  </p>
                )}
              </div>
              <div className="group-inputs-casa-phone">
                <div className="edificio-input">
                  <input
                    type="text"
                    placeholder="Alias. Ej. Casa, oficina*"
                    name="alias"
                    maxLength={60}
                    onChange={() => handleChangeValue('addressDetail')}
                  />
                  {errors.addressDetail && (
                    <p className={styles.errorMessage}>
                      Por favor, introduzca un alias de dirección válido
                    </p>
                  )}
                </div>
                <div className="edificio-input">
                  <input
                    type="tel"
                    id="telephone_map"
                    pattern="\d+"
                    name="telephone"
                    minLength={7}
                    maxLength={8}
                    placeholder="Teléfono"
                    onChange={() => handleChangeValue('phoneNumber')}
                  />
                </div>
              </div>
              <div className="metodo-entrega-button-save">
                <button type="submit" className="entrega-buttons-save">
                  {saveAddressLoadingState ? (
                    <CircularProgress size={20} />
                  ) : isModalScreen ? (
                    'Guardar'
                  ) : (
                    'Continuar'
                  )}
                </button>
              </div>
            </form>
          ) : (
            <MiniLoading />
          )}
        </div>
      </div>
    </>
  )
}

export default Mapa
