import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { validateRoles } from 'Utils/functions'

export const useHasProductWithPriceUnderCost = () => {
  const cart = useSelector(state => state.cart.cart)
  const userRoles = useSelector(
    state => state.login?.user?.AuthenticationInfo.UserRoles
  )

  const isDistributionSeller = validateRoles(
    userRoles,
    'Vendedor Comercial Distribución'
  )
  const isConstructionSeller = validateRoles(
    userRoles,
    'Vendedor Comercial Construcción'
  )
  const isCommercialSeller = isDistributionSeller || isConstructionSeller

  const hasPriceUnderCost = useMemo(
    () =>
      cart?.some(
        line =>
          line?.Price < (line?.Cost || line?.AvgCost || 0.0) &&
          line?.NegotiatedReasonId !== null
      ),
    [cart]
  )

  return { hasPriceUnderCost, isCommercialSeller }
}
