import { SET_PRODUCTS_MATERIALS } from 'redux/types'

const initState = {
  productsCalculate: null,
}

export default function materialReducer(state = initState, action) {
  switch (action.type) {
    case SET_PRODUCTS_MATERIALS:
      return {
        ...state,
        productsCalculate: action.payload,
      }

    default:
      return state
  }
}
