import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import './DirectDeliveryModal.css'
import { ColumnRow } from './ColumnRow'

const DirectDeliveryModal = ({
  data,
  isDirectDelivery,
  listOfNewCosts,
  setListOfNewCosts,
}) => {
  const [selectData, setSelectData] = useState([])
  const { sellOrderObject } = useSelector(state => state.sellOrder)

  const populateItemsArrayOfPayload = () => {
    const arrayOfItems = []
    sellOrderObject?.Lines.forEach(lineItem => {
      const amountPicked =
        lineItem?.sellOrderTableValues?.solicitud_despacho *
        lineItem?.sellOrderTableValues?.precio_unitario
      const taxes = amountPicked * lineItem?.TaxCategoryValue
      const amountWithTaxPicked = amountPicked + taxes
      const qtyDelivered =
        lineItem?.sellOrderTableValues?.solicitud_despacho +
          lineItem?.sellOrderTableValues?.qtyDelivered || 0
      const qtyCanceled = lineItem?.sellOrderTableValues?.qtyCanceled || 0
      const qtyPending =
        lineItem?.sellOrderTableValues?.cantidad -
          (qtyDelivered + qtyCanceled) || 0
      const payloadFormation = {
        description: lineItem?.ProductName,
        reference: lineItem?.SystemCode,
        dispatchRequestLineDetailId: '00000000-0000-0000-0000-000000000000',
        salesOrderHeaderId: sellOrderObject?.salesOrderHeaderId,
        productId: lineItem?.ProductId,
        priceListId: lineItem?.PriceListId,
        warehouseId: sellOrderObject?.WarehouseId,
        quantity: lineItem?.sellOrderTableValues?.solicitud_despacho, // lineItem?.Quantity,
        amount: amountPicked, // lineItem?.Amount,
        amountWithTax: amountWithTaxPicked, // lineItem?.AmountWithTax,
        DiscountAmount: lineItem?.DiscountAmount,
        tax: taxes, // lineItem?.Tax,
        cost: lineItem?.Cost,
        NewCost: null,
        price: lineItem?.Price,
        discount: lineItem?.Discount,
        lineNum: lineItem?.LineNum,
        productSerialNumber: 0,
        createdBy: null,
        lastModifiedBy: null,
        status: 1,
        UoMId: lineItem?.UoMId
          ? lineItem.UoMId
          : '6360326E-23B2-40F0-9F7B-0056EBA3A1CB',
        NegotiatedReasonId: sellOrderObject?.NegotiatedReasonId,
        WarrantyProductId: lineItem?.WarrantyProductId,
        qtyOrdered: lineItem?.sellOrderTableValues?.cantidad,
        qtyDelivered,
        qtyPending,
      }

      const cleanEntries = Object.fromEntries(
        Object.entries(payloadFormation).filter(([_, v]) => v != null)
      )

      arrayOfItems.push(cleanEntries)
    })

    const listOfNewCostsIds = arrayOfItems?.map(item => ({
      productId: item.productId,
      newCost: 0,
    }))
    // save in state just the products ids and "newCost" property with the "Cost" value
    setListOfNewCosts(listOfNewCostsIds)
    return arrayOfItems
  }

  useEffect(() => {
    if (isDirectDelivery) {
      const data = populateItemsArrayOfPayload()
      setSelectData(data)
    }
  }, [isDirectDelivery])
  return (
    <>
      {isDirectDelivery && (
        <div className="container-SD">
          <h2 className="h2-space">
            Resumen de pedido <span>({selectData.length})</span>
          </h2>
          <p className="p-color">
            Verifica que los datos sean correctos y agrega nuevos costos
          </p>
          <div className="table-sapce ">
            <table className="single-product-cart inventory-table products">
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Descripción</th>
                  <th>Cantidad</th>
                  <th>Precio</th>
                  <th>Costo Actual</th>
                  <th>Costo Nuevo</th>
                </tr>
              </thead>
              <tbody>
                {selectData.map((item, index) => (
                  <ColumnRow
                    key={index}
                    item={item}
                    setListOfNewCosts={setListOfNewCosts}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  )
}

export default DirectDeliveryModal
