import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// REDUX ACTIONS
import { removeFacetFilters } from '../../redux/actions/productActions'
import './Home.css'
import { Link, withRouter } from 'react-router-dom'
import Navigation from '../../Components/Navigation'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

//  IMPORT ASSETS
import calculadoraLogo from '../../Assets/Images/Home/calculator.svg'
import { t } from 'i18next'
import { getStadisticsService } from 'redux/services/clientsServices'
import { searchSalesDocumentsService } from 'redux/services/cotizacionServices'
import {
  ROUTES,
  docHeaderStatusDescription,
  documentTypeId,
} from 'Utils/constants'
import CalculatorModal from 'Components/CalculatorModal'
import MiniLoading from 'Components/MiniLoading'
import { options } from './constants/menuOptions'

function Home() {
  // LISTADO
  const [cotizaciones, setCotizaciones] = useState([])
  const [isLoadingQuotes, setIsLoadingQuotes] = useState(false)
  const [cotizacionesAbiertas, setCotizacionesAbiertas] = useState(null)
  const [open, setOpen] = useState(false)
  const [newCustomers, setNewCustomers] = useState(0)
  const [loadingStats, setLoadingStats] = useState(false)
  const [cotizacionUpdated] = useState(false)
  const companyId = useSelector(state => state.company?.CompanyId)

  // REDUX STATE
  const dispatch = useDispatch()
  const user = useSelector(state => state.login.user)
  const isCustomer = useSelector(state => state.login.isCustomer)

  const handleModal = () => {
    setOpen(!open)
  }

  const status = cotizacion => {
    if (
      cotizacion?.DocHeaderStatusDescription ===
        docHeaderStatusDescription.draft ||
      cotizacion?.DocHeaderStatusDescription ===
        docHeaderStatusDescription.closed
    ) {
      return '#FF96A0' // "#ED5F5F";
    } else if (
      cotizacion?.DocHeaderStatusDescription ===
        docHeaderStatusDescription.open ||
      cotizacion?.StatusDescription === docHeaderStatusDescription.fullyBilled
    ) {
      return '#2DCD7A'
    }
  }

  const fetchCotizaciones = async () => {
    setIsLoadingQuotes(true)
    const payload = {
      CompanyId: companyId,
      DocumentTypeId: documentTypeId.QUOTATION,
      SalesAgentId: user.AuthenticationInfo.UserId,
      PageNumber: 1,
      PageSize: 6,
    }
    const response = await searchSalesDocumentsService(payload)
    const Data = response?.data?.Data
    if (Data && Data?.length > 0) {
      setCotizaciones(Data || 0)
    } else {
      setIsLoadingQuotes(false)
      throw new Error(Data)
    }
    setIsLoadingQuotes(false)
  }

  const fetchEstadistica = async () => {
    setLoadingStats(true)
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0')
    const day = String(today.getDate()).padStart(2, '0')

    const formattedDate = `${year}-${month}-${day}`

    const payload = {
      CompanyId: companyId,
      CreatedDate: formattedDate,
      SalesAgentId: user.AuthenticationInfo.UserId,
    }
    const response = await getStadisticsService(payload)
    const Data = response?.data?.Data
    if (Data && Data?.length > 0) {
      setNewCustomers(Data[0]?.CustomerCount || 0)
      setCotizacionesAbiertas(Data[0]?.QuoteOpenBySalesAgent || 0)
    } else {
      throw new Error(Data)
    }
    setLoadingStats(false)
  }

  useEffect(() => {
    dispatch(removeFacetFilters())
    if (user) {
      fetchCotizaciones()
      fetchEstadistica()
    }
  }, [user, companyId])

  useEffect(() => {
    if (cotizacionUpdated) {
      fetchCotizaciones()
    }
  }, [cotizacionUpdated])

  return (
    <div data-testid="home-wrapper">
      <Navigation />
      <section className="home-content">
        <Row>
          <Col xl={7}>
            <div className="top-layout-container"></div>
            <div className="row row-cols-3 gy-4 gx-3 second-layout">
              {options.map((option, index) => {
                if (isCustomer && option.hiddenByClient) return null
                return (
                  <Link
                    to={option.link}
                    key={t(option.title)}
                    className="col remove-decorations"
                    isdepartment={option.isdepartment}>
                    <div
                      className="option h-100"
                      style={{
                        pointerEvents: option.disabled ? 'none' : '',
                        backgroundColor: option.disabled ? '#dddddd' : '',
                        color: option.disabled ? '#808080' : '',
                      }}>
                      <div className="row">
                        <div
                          className={
                            (index % 2 === 0
                              ? 'img-icon-option-yellow'
                              : 'img-icon-option-purple') + ' col-2 '
                          }>
                          <img
                            src={option.image}
                            alt={t(option.title)}
                            className="img-fluid img-calculator"
                            style={{
                              filter: option.disabled
                                ? 'invert(51%) sepia(0%) saturate(1%) hue-rotate(218deg) brightness(99%) contrast(89%)'
                                : '',
                              zIndex: 100000,
                              padding: 2,
                            }}
                          />
                        </div>

                        <h2 className="col-9 label-option">
                          {t(option.title)}
                        </h2>
                      </div>
                      <p className="row">{t(option.text)}</p>
                    </div>
                  </Link>
                )
              })}
              <div className="col">
                <div
                  className="option calculadora-home h-100"
                  onClick={handleModal}>
                  <div className="row ">
                    <div className="col-2 img-icon-option-purple">
                      <img
                        src={calculadoraLogo}
                        alt="calculadora"
                        className="img-fluid img-calculator"
                      />
                    </div>
                    <h2 className="col-9 label-option">
                      {t('HOME_T.MATERIAL_CALCULATOR_LABEL')}
                    </h2>
                  </div>
                  <p>{t('HOME_T.MATERIAL_CALCULATOR_DESCRIPTION')} </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={5}>
            <Row>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <div className="quotes-info-left row">
                  <h2 className="col-12">
                    {t('HOME_T.CLIENT_CREATED_CROSS_DAY_TITLE')}
                  </h2>
                  <div data-testid="customer-statistic-data" className="col-12">
                    {loadingStats ? (
                      <MiniLoading
                        productsLoading={loadingStats}
                        noMargin
                        align="start"
                      />
                    ) : (
                      <p>{newCustomers}</p> || (
                        <span className="no-stats-results-text">
                          {t('NO_RECORDS')}
                        </span>
                      )
                    )}
                  </div>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <div className="quotes-info-right row ">
                  <h2 className="col-12">{t('HOME_T.OPEN_QUOTATION_TITLE')}</h2>
                  <div data-testid="quote-statistic-data" className="col-12">
                    {loadingStats ? (
                      <MiniLoading
                        productsLoading={loadingStats}
                        noMargin
                        align="start"
                      />
                    ) : (
                      <p>{cotizacionesAbiertas}</p> || (
                        <span className="no-stats-results-text">
                          {t('NO_RECORDS')}
                        </span>
                      )
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="my-quotes second-layout">
                  <div className="my-quotes-header">
                    <Link
                      to={ROUTES.myTasks}
                      className="remove-decorations"
                      title="¡Ir a mis tareas!">
                      <h3>{t('HOME_T.MY_ACTIVITIES_LABEL')}</h3>
                    </Link>
                  </div>
                  <div className="quotes-list">
                    {isLoadingQuotes ? (
                      <MiniLoading productsLoading={isLoadingQuotes} />
                    ) : cotizaciones && cotizaciones.length ? (
                      cotizaciones.map((cotizacion, index) => {
                        return (
                          <div key={index}>
                            <Link
                              className="remove-decorations"
                              to={`${ROUTES.editQuoteHeaderId}${cotizacion?.DocHeaderId}/${cotizacion?.DocumentNumber}`}
                              title={
                                'Ir a cotización ' + cotizacion?.DocumentNumber
                              }>
                              <div className="col">
                                <div className="wrapper-stepper ">
                                  <div
                                    className="circle-stepper"
                                    style={{ borderColor: status(cotizacion) }}>
                                    <h5 className="remove-decorations"></h5>
                                  </div>
                                  <div
                                    className={
                                      index !== cotizaciones.length - 1
                                        ? 'vertical-line-stepper'
                                        : 'vertical-line-stepper-hidden'
                                    }></div>
                                </div>
                              </div>
                              <div className="col">
                                <div className="row information-quotation">
                                  <p className="remove-decorations quotation-number">
                                    {' '}
                                    {t('HOME_T.QUOTATION_NUMBER')}{' '}
                                    {cotizacion?.DocumentNumber}{' '}
                                  </p>
                                  <br />
                                  <p className="p-date remove-decorations">
                                    {' '}
                                    {t('HOME_T.ASIGNATION_DATE')}{' '}
                                    {cotizacion?.CreatedDate.slice(0, 10)}
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        )
                      })
                    ) : (
                      <h3 className="text-no-records-activities">
                        {t('NO_RECORDS')}
                      </h3>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
      <CalculatorModal
        openCalc={open}
        handleModal={handleModal}
        isModal={true}
      />
    </div>
  )
}

export default withRouter(Home)
