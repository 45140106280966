import React, { useState, useRef } from 'react'
import './calculator.css'
import calculatorWhite from '../../Assets/Images/Generals/calculadora.svg'
import minichevron from '../../Assets/Images/Generals/chevron-up.svg'
import Backdrop from '@mui/material/Backdrop'
import CalculatorModal from 'Components/CalculatorModal'

function Calculator() {
  const [showCalculator, setShowCalculator] = useState(false)
  const [showOption, setShowOption] = useState(false)
  const arrow = useRef(null)

  const handleOpenCalculator = () => {
    if (showCalculator) {
      arrow.current.style.transform = 'rotate(360deg)'
      setShowCalculator(false)
      setShowOption(false)
    } else {
      arrow.current.style.transform = 'rotate(180deg)'
      setShowCalculator(true)
    }
  }

  const handleOption = () => {
    setShowOption(!showOption)
  }

  return (
    <div>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: 99999999,
          backgroundColor: 'rgba(27, 32, 46, 0.63)',
        }}
        open={showCalculator || showOption}
        onClick={handleOpenCalculator}></Backdrop>
      <div className={`calculator ${!showCalculator && 'calculator-closed'}`}>
        <div className="calculator-top">
          <div
            className="calculator-top-content"
            onClick={handleOpenCalculator}>
            <img src={calculatorWhite} alt="calculator" />
            <img src={minichevron} alt="chevron" ref={arrow} />
          </div>
        </div>
        <div
          className={
            showCalculator && !showOption
              ? 'calculator-choosable'
              : 'calculator-choosable-hidden'
          }>
          <CalculatorModal
            openCalc={showCalculator}
            handleModal={handleOption}
            isModal={false}
          />
        </div>
      </div>
    </div>
  )
}

export default Calculator
