/**
 * @returns {string} - min available birthday date for a new client in format YYYY-MM-DD
 * @example
 * const minAvailableBirthday = getMinAvailableBirthday();
 * console.log(minAvailableBirthday); // 2003-01-01 (if current date is 2021-01-01)
 */
export const getMinAvailableBirthday = () => {
  const currentDate = new Date()
  const maxBirthdayDate = new Date(
    currentDate.getFullYear() - 18,
    currentDate.getMonth(),
    currentDate.getDate()
  )

  const year = maxBirthdayDate.getFullYear()
  const month = String(maxBirthdayDate.getMonth() + 1).padStart(2, '0')
  const day = String(maxBirthdayDate.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}
