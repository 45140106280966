import React from 'react'
import no from '../../../Assets/Images/Login/no.svg'
import check from '../../../Assets/Images/Login/check.png'
import './agregarCliente.css'

function AgregarCliente({ handleModalAdd }) {
  return (
    <div className="modal-box-add-client">
      <div className="add-modal-container">
        <div className="add-client-modal-header">
          <span onClick={handleModalAdd} className="close-modal">
            <img src={no} alt="close" />
          </span>
        </div>
        <div className="success-add-client-message ">
          <img src={check} alt="check" />
          <p className="confirmation-text-delete">
            El cliente ha sido creado
            <br />
            exitosamente
          </p>
        </div>
      </div>
    </div>
  )
}

export default AgregarCliente
