import { images } from 'Assets/Images'
import React from 'react'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)
const currentMonth = new Date().toLocaleString('es-ES', { month: 'long' })
const DEFAULT_VALUE = `Promoción válida del 1 al 30 de ${capitalize(
  currentMonth
)} 2024. Precio no incluye ITBMS`

export const Square2ToPrintCOffer = ({ products, i }) => {
  const { templateRef } = usePrinterContext()

  const terms = products?.content?.terms || DEFAULT_VALUE
  const prod1 = products?.content?.products?.[0]
  const prod2 = products?.content?.products?.[1] || {}

  return (
    <div ref={el => (templateRef.current[i] = el)}>
      <div
        key={products.page}
        id="printer-preview-left-canvas-square-2"
        className="printer-preview-left-canvas-square-2">
        <div
          className="printer-preview-left-canvas-square-2-box"
          style={{
            height: '100%',
            top: '0',
          }}>
          <div
            className="printer-preview-left-content-body-square-2"
            style={{ top: '90px' }}>
            <p className="printer-preview-left-content-body-square-2-title">
              {prod1?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-2-sku">
              {prod1?.Sku || ''}
            </p>
            {prod1?.Price && (
              <div className="printer-preview-left-content-body-square-2-price-wrapper">
                <p
                  className="printer-preview-mini-text-square-2"
                  style={{ color: '#000' }}>
                  $
                </p>
                <p
                  className="printer-preview-left-content-body-square-2-price"
                  style={{
                    color: '#000',
                    fontSize: '124px',
                    marginTop: '40px',
                  }}>
                  {prod1?.Price?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p
                  className="printer-preview-mini-text-square-2"
                  style={{ color: '#000' }}>
                  {prod1?.Price?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
            {prod1?.BasePrice && (
              <div
                style={{
                  position: 'absolute',
                  bottom: '-40px',
                  right: 0,
                  width: '100px',
                  height: '1px',
                  background: 'red',
                  transform: 'rotate(-12deg)',
                  marginBottom: '4px',
                }}
              />
            )}
            {prod1?.BasePrice && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: '4px',
                  position: 'absolute',
                  bottom: '-52px',
                  right: '0',
                }}>
                <p
                  style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}>
                  Antes
                </p>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <p
                    className="printer-preview-mini-text"
                    style={{
                      color: '#000',
                      fontSize: '12px',
                      marginTop: '-12px',
                    }}>
                    $
                  </p>
                  <p
                    className="printer-preview-left-content-body-price"
                    style={{ color: '#000', fontSize: '30px', marginTop: 0 }}>
                    {prod1?.BasePrice?.toString()?.split('.')?.[0] || ''}.
                  </p>
                  <p
                    className="printer-preview-mini-text"
                    style={{
                      color: '#000',
                      fontSize: '12px',
                      marginTop: '-12px',
                    }}>
                    {prod1?.BasePrice?.toString()?.split('.')?.[1] || '00'}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div
            className="printer-preview-left-content-footer-square-2"
            style={{ top: 'calc(50% - 18px)' }}>
            <div className="printer-preview-content-footer-input-wrapper">
              <p
                className="printer-preview-left-content-footer-legal-2"
                style={{
                  border: 'none',
                  textAlign: 'end',
                  fontSize: '8px',
                  color: '#000',
                  background: 'transparent',
                }}>
                {terms}
              </p>
            </div>
          </div>
        </div>
        <div
          className="printer-preview-left-canvas-square-2-box"
          style={{
            height: '50%',
            top: '50%',
          }}>
          <div
            className="printer-preview-left-content-body-square-2-2"
            style={{ top: '90px' }}>
            <p className="printer-preview-left-content-body-square-2-title-2">
              {prod2?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-2-sku-2">
              {prod2?.Sku || ''}
            </p>
            {prod2?.Price && (
              <div className="printer-preview-left-content-body-square-2-price-wrapper">
                <p
                  className="printer-preview-mini-text-square-2"
                  style={{ color: '#000' }}>
                  $
                </p>
                <p
                  className="printer-preview-left-content-body-square-2-price-2"
                  style={{
                    color: '#000',
                    fontSize: '124px',
                    marginTop: '40px',
                  }}>
                  {prod2?.Price?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p
                  className="printer-preview-mini-text-square-2"
                  style={{ color: '#000' }}>
                  {prod2?.Price?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
            {prod2?.BasePrice && (
              <div
                style={{
                  position: 'absolute',
                  bottom: '-40px',
                  right: 0,
                  width: '100px',
                  height: '1px',
                  background: 'red',
                  transform: 'rotate(-12deg)',
                  marginBottom: '4px',
                }}
              />
            )}
            {prod2?.BasePrice && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: '4px',
                  position: 'absolute',
                  bottom: '-52px',
                  right: '0',
                }}>
                <p
                  style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}>
                  Antes
                </p>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <p
                    className="printer-preview-mini-text"
                    style={{
                      color: '#000',
                      fontSize: '12px',
                      marginTop: '-12px',
                    }}>
                    $
                  </p>
                  <p
                    className="printer-preview-left-content-body-price"
                    style={{ color: '#000', fontSize: '30px', marginTop: 0 }}>
                    {prod2?.BasePrice?.toString()?.split('.')?.[0] || ''}.
                  </p>
                  <p
                    className="printer-preview-mini-text"
                    style={{
                      color: '#000',
                      fontSize: '12px',
                      marginTop: '-12px',
                    }}>
                    {prod2?.BasePrice?.toString()?.split('.')?.[1] || '00'}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div
            className="printer-preview-left-content-footer-square-2-2"
            style={{ top: 'calc(100% - 18px)' }}>
            <div className="printer-preview-content-footer-input-wrapper">
              <p
                className="printer-preview-left-content-footer-legal-2"
                style={{
                  border: 'none',
                  textAlign: 'end',
                  fontSize: '8px',
                  color: '#000',
                  background: 'transparent',
                }}>
                {terms}
              </p>
            </div>
          </div>
        </div>
        <img
          className="printer-preview-left-image"
          src={images.printer.templates.cochez.offer2}
          alt="template-preview"
          style={{ opacity: 0 }}
        />
      </div>
    </div>
  )
}
