/* eslint-disable no-useless-catch */
import {
  calculateFormula,
  calculateMaterials,
  getById,
  getByIdProductTaxonomy,
} from 'redux/services/materialsCalculatorServices'
import { SET_PRODUCTS_MATERIALS } from 'redux/types'

export const saveProductsMaterials = product => ({
  type: SET_PRODUCTS_MATERIALS,
  payload: product,
})

export const getByIdCalculator =
  ({ id, requestId }) =>
  async () => {
    try {
      const response = await getById({ id, requestId })
      const { data, status } = response
      if (status === 200 && data?.Data) {
        return data.Data
      }
    } catch (err) {
      throw err
    }
  }
export const calculateDoFormula =
  ({ CalculatorFormulaId, payload }) =>
  async () => {
    try {
      const response = await calculateFormula({
        CalculatorFormulaId,
        payload,
      })
      const { data, status } = response
      if (status === 200 && data?.Data) {
        return data.Data
      }
    } catch (err) {
      throw err
    }
  }
export const getByIdProductTaxonomyCalculator =
  ({
    departmentId,
    categoryId,
    subCategoryId,
    productId,
    companyId,
    requestId,
  }) =>
  async () => {
    try {
      const response = await getByIdProductTaxonomy({
        departmentId,
        categoryId,
        subCategoryId,
        productId,
        companyId,
        requestId,
      })
      const { data, status } = response
      if (status === 200 && data?.Data) {
        return data.Data
      }
    } catch (err) {
      throw err
    }
  }
export const calculateDoCalculator =
  ({ payload, requestId }) =>
  async () => {
    try {
      const response = await calculateMaterials({ payload, requestId })
      const { data, status } = response
      if (status === 200 && data?.Data) {
        return data.Data
      } else {
        // Manejar el caso donde la respuesta no es 200 pero aún se desea devolver data
        return data
      }
    } catch (err) {
      // Si hay un error, intenta obtener los datos de la respuesta si están disponibles
      if (err.response) {
        return err.response.data
      } else {
        throw err // Relanza el error si no hay datos de la respuesta
      }
    }
  }
