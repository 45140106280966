import { useState } from 'react'

export const useDebounce = () => {
  const [time, setTime] = useState()
  return (callback, wait = 500) => {
    clearTimeout(time)
    const timeout = setTimeout(() => callback(), wait)
    setTime(timeout)
  }
}
