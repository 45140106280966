import { useDispatch, useSelector } from 'react-redux'
import {
  addToCart,
  emptyCart,
  getPayloadProduct,
  isLoadingProduct,
} from 'redux/actions/cartActions'
import {
  getFinalPriceMdl22Service,
  getProductPriceInventoryService,
} from 'redux/services/clientsServices'

export const useCheckFinalPrice = () => {
  const dispatch = useDispatch()
  const quoteHeader = useSelector(state => state.cotizacion.QuoteHeader)
  const company = useSelector(state => state.company)
  const cart = useSelector(state => state.cart.cart)

  const fethCheckFinalPrice = async (prod, customerId, newCompany) => {
    const PriceListId = quoteHeader.PriceListId
    const ProductIds = prod.map(product => product.product.ProductId)
    const WarehouseId = newCompany?.WarehouseId || company.WarehouseId
    const WarehouseCode = newCompany?.WarehouseCode || company.WarehouseCode
    const CompanyId = newCompany?.CompanyId || company.CompanyId
    const WarehouseDescription =
      newCompany?.WarehouseDescription || company.WarehouseDescription
    const finalPricePayload = {
      CompanyId,
      WarehouseId,
      ProductIds,
      ...(customerId ? { CustomerId: customerId } : { PriceListId }),
    }

    const response = await getFinalPriceMdl22Service(finalPricePayload)
    const { data, status } = response

    if (status === 200 && data?.Data) {
      const selectList = response.data.Data

      const warehouse = {
        WarehouseId,
        WarehouseCode,
        WarehouseDescription,
        WarehouseAddress: quoteHeader?.WarehouseAddress,
      }

      const products = selectList.map(item => {
        const product = prod.find(p => p.product.ProductId === item.ProductId)
        const Quantity = product ? product.qty : 0
        const Price = item.FinalPrice

        return {
          selectList: { ...item, Quantity, Price },
          payload: { ...product, Quantity, Price },
          warehouse,
          Quantity,
          Price,
          qty: Quantity,
        }
      })

      return products
    }
  }

  const checkFinalPrice = async ({ prods, customerId, newCompany } = {}) => {
    dispatch(isLoadingProduct({ isLoadingProduct: true }))
    const productList = prods || cart
    if (!productList.length) {
      return dispatch(isLoadingProduct({ isLoadingProduct: false }))
    }
    const companyCode = newCompany?.CompanyCode || company.CompanyCode
    const companyId = newCompany?.CompanyId || company.CompanyId
    const WarehouseId = newCompany?.WarehouseId || company.WarehouseId

    const batches = []
    for (let i = 0; i < productList.length; i += 99) {
      batches.push(productList.slice(i, i + 99))
    }

    dispatch(emptyCart())

    for (const batch of batches) {
      const ProductIds = batch.map(product => product.ProductId)

      const combinedPayload = batch.map(prod => ({
        product: prod,
        qty: prod.Quantity,
      }))

      const priceInventoryPayload = {
        CompanyId: companyId,
        CompanyCode: companyCode,
        WarehouseId,
        ProductIds: ProductIds || [],
        UoMId: null,
        PriceListId: null,
      }
      const response = await getProductPriceInventoryService(
        priceInventoryPayload
      )
      const {
        data: { Data: updatedProducts },
      } = response

      const orderedProducts = await fethCheckFinalPrice(
        combinedPayload,
        customerId,
        newCompany
      )

      orderedProducts?.forEach((orderedProduct, index) => {
        const updatedProduct = updatedProducts?.find(
          updatedProduct =>
            updatedProduct.ProductId === orderedProduct.selectList.ProductId
        )
        if (updatedProduct) {
          orderedProducts[index].payload.product.Cost = updatedProduct.Cost
          orderedProducts[index].payload.product.AvgCost =
            updatedProduct.AvgCost
          orderedProducts[index].payload.product.AvgPrice =
            updatedProduct.AvgPrice
        }
      })

      for (const prod of orderedProducts) {
        const action = addToCart(
          getPayloadProduct(
            prod.selectList,
            prod.payload,
            prod.warehouse,
            prod.Quantity,
            prod.Price
          ),
          prod.qty
        )

        dispatch(action)
      }
    }
    dispatch(isLoadingProduct({ isLoadingProduct: false }))
  }

  return { checkFinalPrice }
}
