import React, { createContext, useState, useContext } from 'react'

const CotizationTaxesContext = createContext()

export const FreeTaxesProvider = ({ children }) => {
  const [freeTaxes, setFreeTaxes] = useState(false)

  return (
    <CotizationTaxesContext.Provider value={{ freeTaxes, setFreeTaxes }}>
      {children}
    </CotizationTaxesContext.Provider>
  )
}

export const useFreeTaxes = () => {
  return useContext(CotizationTaxesContext)
}
