import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'react-bootstrap'
import './tareas.css'
import { ROUTES } from 'Utils/constants'

function Tareas({ tarea, generals, keyProp }) {
  const [isOpen, setIsOpen] = useState(false)

  const handleModal = () => {
    setIsOpen(!isOpen)
  }

  const status = () => {
    if (tarea.StatusDescription !== 'Completado') {
      return '#F897A1'
    }
  }

  return (
    <Row key={keyProp}>
      <div className="quote-activity">
        <Col xl={9} lg={9} md={9} sm={12} xs={12}>
          <div
            className="quote-activity-generals"
            style={{ flexBasis: generals.flex }}>
            <p>
              <span className="strong-text">{tarea?.Name}</span>
            </p>
            <p>Fecha de asignación: {tarea?.Date?.slice(0, 10) || 'MEH'}</p>
          </div>
        </Col>

        <Col xl={2} lg={2} md={2} sm={6} xs={6}>
          <div
            className="quote-activity-status"
            style={{ backgroundColor: status() }}>
            <p>{tarea?.StatusDescription}</p>
          </div>
        </Col>

        <Col xl={1} lg={1} md={1} sm={6} xs={6}>
          <div className="quote-activity-tooltips">
            <div
              style={{
                display: 'flex',
                backgroundColor: '#FFDE20',
                width: '20px',
                height: '20px',
                borderRadius: '20%',
                alignItems: 'center',
                position: 'relative',
              }}>
              <Link
                to={`${ROUTES.editQuoteHeaderId}${
                  tarea?.DocHeaderId || tarea?.Key
                }/${tarea?.DocumentNumber || tarea?.Description}`}>
                <FontAwesomeIcon
                  icon={faEye}
                  style={{ width: '20px', color: 'white' }}
                />
              </Link>
            </div>
            <FontAwesomeIcon onClick={handleModal} icon={faTrashCan} />
          </div>
        </Col>

        <div
          className="status-icon"
          style={{ backgroundColor: status() }}></div>
      </div>
    </Row>
  )
}

export default Tareas
