import React from 'react'
import { images } from 'Assets/Images'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'

const DEFAULT_VALUE = 'Precio no incluye ITBMS'

export const Square3ToPrintNRegular = ({ products, i }) => {
  const { templateRef } = usePrinterContext()

  const terms = products?.content?.terms || DEFAULT_VALUE

  const prod1 = products?.content?.products?.[0]
  const prod2 = products?.content?.products?.[1] || {}
  const prod3 = products?.content?.products?.[2] || {}
  const prod4 = products?.content?.products?.[3] || {}

  return (
    <div ref={el => (templateRef.current[i] = el)}>
      <div
        id="printer-preview-left-canvas-square-3"
        className="printer-preview-left-canvas-square-3">
        <div
          className="printer-preview-left-canvas-square-3-box"
          style={{
            height: '100%',
            top: '0',
          }}>
          <div className="printer-preview-left-content-body-square-3">
            <p className="printer-preview-left-content-body-square-3-title">
              {prod1?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-3-sku">
              {prod1?.Sku || ''}
            </p>
            {prod1?.BasePrice && (
              <div
                className="printer-preview-left-content-body-square-3-price-wrapper"
                style={{ marginTop: '20px' }}>
                <p className="printer-preview-mini-text-square-3">$</p>
                <p
                  className="printer-preview-left-content-body-square-3-price"
                  style={{
                    fontSize: '82px',
                    marginTop: '40px',
                  }}>
                  {prod1?.BasePrice?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p className="printer-preview-mini-text-square-3">
                  {prod1?.BasePrice?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
          </div>
          <div
            className="printer-preview-left-content-footer-square-3"
            style={{
              marginTop: '60px',
            }}>
            <div className="printer-preview-content-footer-input-wrapper">
              <p
                className="printer-preview-left-content-footer-legal-2"
                style={{
                  border: 'none',
                  textAlign: 'end',
                  background: 'transparent',
                }}>
                {terms}
              </p>
            </div>
          </div>
        </div>
        <div
          className="printer-preview-left-canvas-square-3-box"
          style={{
            height: '100%',
            top: '0',
            left: '50%',
          }}>
          <div className="printer-preview-left-content-body-square-3">
            <p className="printer-preview-left-content-body-square-3-title">
              {prod2?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-3-sku">
              {prod2?.Sku || ''}
            </p>
            {prod2?.BasePrice && (
              <div
                className="printer-preview-left-content-body-square-3-price-wrapper"
                style={{ marginTop: '20px' }}>
                <p className="printer-preview-mini-text-square-3">$</p>
                <p
                  className="printer-preview-left-content-body-square-3-price"
                  style={{
                    fontSize: '82px',
                    marginTop: '40px',
                  }}>
                  {prod2?.BasePrice?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p className="printer-preview-mini-text-square-3">
                  {prod2?.BasePrice?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
          </div>
          <div
            className="printer-preview-left-content-footer-square-3"
            style={{
              marginTop: '60px',
            }}>
            <div className="printer-preview-content-footer-input-wrapper">
              <p
                className="printer-preview-left-content-footer-legal-2"
                style={{
                  border: 'none',
                  textAlign: 'end',
                  background: 'transparent',
                }}>
                {terms}
              </p>
            </div>
          </div>
        </div>
        <div
          className="printer-preview-left-canvas-square-3-box"
          style={{
            height: '50%',
            top: '50%',
          }}>
          <div className="printer-preview-left-content-body-square-3-2">
            <p className="printer-preview-left-content-body-square-3-title-2">
              {prod3?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-3-sku-2">
              {prod3?.Sku || ''}
            </p>
            {prod3?.BasePrice && (
              <div
                className="printer-preview-left-content-body-square-3-price-wrapper"
                style={{ marginTop: '20px' }}>
                <p className="printer-preview-mini-text-square-3">$</p>
                <p
                  className="printer-preview-left-content-body-square-3-price-2"
                  style={{
                    fontSize: '82px',
                    marginTop: '40px',
                  }}>
                  {prod3?.BasePrice?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p className="printer-preview-mini-text-square-3">
                  {prod3?.BasePrice?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
          </div>
          <div
            className="printer-preview-left-content-footer-square-3-2"
            style={{
              marginTop: '55px',
            }}>
            <div className="printer-preview-content-footer-input-wrapper">
              <p
                className="printer-preview-left-content-footer-legal-2"
                style={{
                  border: 'none',
                  textAlign: 'end',
                  background: 'transparent',
                }}>
                {terms}
              </p>
            </div>
          </div>
        </div>
        <div
          className="printer-preview-left-canvas-square-3-box"
          style={{
            height: '50%',
            top: '50%',
            left: '50%',
          }}>
          <div className="printer-preview-left-content-body-square-3-2">
            <p className="printer-preview-left-content-body-square-3-title-2">
              {prod4?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-3-sku-2">
              {prod4?.Sku || ''}
            </p>
            {prod4?.BasePrice && (
              <div
                className="printer-preview-left-content-body-square-3-price-wrapper"
                style={{ marginTop: '20px' }}>
                <p className="printer-preview-mini-text-square-3">$</p>
                <p
                  className="printer-preview-left-content-body-square-3-price-2"
                  style={{
                    fontSize: '82px',
                    marginTop: '40px',
                  }}>
                  {prod4?.BasePrice?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p className="printer-preview-mini-text-square-3">
                  {prod4?.BasePrice?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
          </div>
          <div
            className="printer-preview-left-content-footer-square-3-2"
            style={{
              marginTop: '55px',
            }}>
            <div className="printer-preview-content-footer-input-wrapper">
              <p
                className="printer-preview-left-content-footer-legal-2"
                style={{
                  border: 'none',
                  textAlign: 'end',
                  background: 'transparent',
                }}>
                {terms}
              </p>
            </div>
          </div>
        </div>
        <img
          className="printer-preview-left-image"
          src={images.printer.templates.novey.regular3}
          alt="template-preview"
          style={{ opacity: 0 }}
        />
      </div>
    </div>
  )
}
