import React from 'react'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

// TOOLTIP CMF STYLE
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0)',
    boxShadow: '0px 3px 6px #00000029',
    fontSize: 13,
    padding: '9px 19px',
    lineHeight: '16px',
    maxWidth: 210,
    maxHeight: 410,
    overflow: 'auto',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}))

export default LightTooltip
