/** @format */

import React, { useEffect, useState, useRef } from 'react'
import Col from 'react-bootstrap/Col'
import './metodoEntrega.css'
import domicilio from '../../Assets/Images/MetodoEntrega/domicilio.svg'
import Direcciones from '../Direcciones'
import Sucursales from '../Sucursales'
import { Shop } from 'react-bootstrap-icons'
import { useDispatch, useSelector } from 'react-redux'
import { companiesCode, fullfilmentIds } from 'Utils/constants'
import { getBranchesAction } from 'redux/actions/clientsActions'

const MetodoEntrega = ({
  handleMetodoEntrega,
  setDireccion,
  setSelectedAddress,
  insideForm,
  selectedAddress,
  direccion,
  validateOnSuccessCallAddress,
  setFulfillmentPlanId,
  hasDefaultWarehouse,
}) => {
  const dispatch = useDispatch()
  const userId = useSelector(
    state => state?.cotizacion?.QuoteHeader?.CustomerId
  )
  // See which tab needs to be activated
  const fulfillmentPlanId = useSelector(
    state => state?.cotizacion?.QuoteHeader?.Lines[0]?.FulfillmentPlanId
  )
  // quotation state to define the fulfillment plan
  const quotationHeader = useSelector(state => state?.cotizacion?.QuoteHeader)
  // DIRECCION
  const [mostrarDireccion, setMostrarDireccion] = useState(
    // userId ? true : false
    !(
      (fulfillmentPlanId &&
        fulfillmentPlanId ===
          fullfilmentIds?.entregaSucursal?.fulfillmentPlanId) ||
      (hasDefaultWarehouse !== undefined && !hasDefaultWarehouse)
    )
  )

  const [sucursales, setSucursales] = useState([])
  // REFERENCIAS
  const refDireccion = useRef(null)
  const refSucursal = useRef(null)

  // CART
  const cart = useSelector(state => state.cart.cart)

  // CREATE PAYLOAD PRODUCTO PICKUP
  const payloadProductSelected = () => {
    const tempProducts = []
    // eslint-disable-next-line array-callback-return
    cart.map(p => {
      const productPayload = { sku: p.Sku, qty: p.Quantity }
      tempProducts.push(productPayload)
      // setProductsToPickup(tempProducts);
      dispatch({ type: 'SET_PRODUCTS_TO_PICKUP', payload: tempProducts })
    })
  }

  const setPlainIdSucural = () => {
    if (typeof setFulfillmentPlanId !== 'undefined')
      setFulfillmentPlanId(fullfilmentIds.branchDelivery.fulfillmentPlanId)
  }
  const setPlanIdDomicilio = () => {
    const homeFulfillmentPlanId = fullfilmentIds.homeDelivery.fulfillmentPlanId

    if (typeof setFulfillmentPlanId !== 'undefined')
      setFulfillmentPlanId(homeFulfillmentPlanId)
  }
  // MOSTRAR SUCURSALES (TAB)
  const handleMostrarSucursales = () => {
    // set the value fulfillmentPlanId as a 75963CA6-D4C7-43BD-9342-4C3FF372A37B sucursal
    setMostrarDireccion(false)
    setPlainIdSucural()
  }

  // MOSTRAR DIRECCIONES (TAB)
  const handleMostrarDireccion = () => {
    // set the value fulfillmentPlanId as a C15B501B-E075-4843-8F17-FF964A83D9A7 a domicilio
    setMostrarDireccion(true)
    setPlanIdDomicilio()
  }

  // OBTENER TODAS LAS SUCURSALES
  const fetchSucursal = async () => {
    // if quotation have CustomerNameDocument but no CustomerId is counted client
    if (
      quotationHeader.CustomerNameDocument !== null &&
      quotationHeader.CustomerId === null
    ) {
      handleMostrarSucursales()
    }
    const payload = {
      CompanyId: 'AEE876CB-7183-4BEE-8FCA-984B7A1F6BA9',
      CompanyCode: companiesCode.cochez,
      Code: null,
      GlobalExecution: true,
      SearchText: null,
      WarehouseId: null,
      BranchOfficeId: null,
    }
    const Data = await dispatch(getBranchesAction(payload))
    if (Data && Data?.length > 0) {
      setSucursales(Data)
    } else {
      throw new Error(Data)
    }
  }

  useEffect(() => {
    if (mostrarDireccion) {
      // set the value fulfillmentPlanId as a C15B501B-E075-4843-8F17-FF964A83D9A7 for domiciolio
      setPlanIdDomicilio()
    } else {
      // set the value fulfillmentPlanId as a 75963CA6-D4C7-43BD-9342-4C3FF372A37B  for sucursal
      setPlainIdSucural()
    }
  }, [mostrarDireccion])

  useEffect(() => {
    payloadProductSelected()

    return () => {}
  }, [])

  useEffect(() => {
    fetchSucursal()
  }, [])

  return (
    <Col className="h-100">
      <div
        className="metodo-entrega-main"
        style={{ boxShadow: insideForm && 'none' }}>
        <div
          className="metodo-entrega-header"
          style={{ padding: insideForm && '0' }}>
          <h2>
            {insideForm
              ? 'Elegir método de entrega'
              : 'Selecciona el método de entrega de la compra'}
          </h2>
        </div>
        {!userId && (
          <div
            className="delivery-cliente-contado"
            style={{ padding: insideForm && '0' }}>
            <p>{`La entrega a domicilio no es válida para "Cliente Contado"`}</p>
          </div>
        )}
        <div
          className="metodo-entrega-buttons"
          style={{ padding: insideForm && '0' }}>
          <button
            className={
              mostrarDireccion
                ? 'metodo-entrega-selected'
                : 'button-not-selected'
            }
            onClick={handleMostrarDireccion}
            ref={refDireccion}
            disabled={!userId}>
            <img
              src={domicilio}
              alt="domilicio"
              className={mostrarDireccion ? '' : 'img-domicilio-white'}
              style={{ width: '1.5vw', height: '1.5vw' }}
            />
            Entrega a domicilio
          </button>
          <button
            className={
              mostrarDireccion
                ? 'button-not-selected'
                : 'metodo-entrega-selected'
            }
            onClick={handleMostrarSucursales}
            ref={refSucursal}>
            <Shop style={{ width: '1.2vw', height: '1.2vw' }} />
            Retiro en sucursal
          </button>
        </div>
        {!mostrarDireccion && sucursales?.length > 0 && (
          <Sucursales
            sucursales={sucursales}
            setSelectedAddress={setSelectedAddress}
            handleMetodoEntrega={handleMetodoEntrega}
            setDireccion={setDireccion}
            insideForm={insideForm}
            selectedAddress={selectedAddress}
            direccion={direccion}
          />
        )}
        {mostrarDireccion && (
          <Direcciones
            setSelectedAddress={setSelectedAddress}
            setDireccion={setDireccion}
            handleMetodoEntrega={handleMetodoEntrega}
            insideForm={insideForm}
            validateOnSuccessCallAddress={validateOnSuccessCallAddress}
            selectedAddress={selectedAddress}
          />
        )}
      </div>
    </Col>
  )
}

export default MetodoEntrega
