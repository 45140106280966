import React from 'react'

export const CustomCheckBox = ({ key, checked, onChange }) => {
  return (
    <div className="checkbox-wrapper-65">
      <label htmlFor={key}>
        <input type="checkbox" id={key} checked={checked} onChange={onChange} />
        <span className="cbx">
          <svg width="12px" height="11px" viewBox="0 0 12 11">
            <polyline points="1 6.29411765 4.5 10 11 1"></polyline>
          </svg>
        </span>
      </label>
    </div>
  )
}
