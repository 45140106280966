/* eslint-disable no-prototype-builtins */
import React, { useContext } from 'react'
import { t } from 'i18next'
import { CircularProgress } from '@mui/material'
import { Row, Col } from 'react-bootstrap'
import { VAContext } from 'Context/VAContext'
import NewCustomerMap from 'Components/NewCustomerMap'
import { DisplayListPrices } from '../DisplayListPrices'
import { DisplayCustomerGroup } from './DisplayCustomerGroup'
import { useSelector } from 'react-redux'
import { isCallCenterUser } from 'Utils/functions'
import { VAPermissions, customerTypeAllowedRoles } from 'Utils/constants'
import { useValidateRoleAndPermission } from 'Hooks/useValidateRoleAndPermission'

export const TabLegal = ({
  props: {
    formikLegal,
    setValueStateSelectCustomerGroup,
    valueStateSelectCustomerGroup,
    documentType,
    tipoTelefono,
    setValueState,
    valueState,
    loadingSave,
    setHasAddress,
    hasAddress,
    setCountryCode,
    setProvinceCode,
    setDistrictCode,
    setTownshipCode,
    street,
    setStreet,
    name,
    setName,
  },
}) => {
  const { clientSelected } = useContext(VAContext)
  const loginInfo = useSelector(state => state.login?.user?.AuthenticationInfo)
  const isCallCenter = isCallCenterUser(loginInfo.UserRoles)
  const canUseCustomerType = useValidateRoleAndPermission(
    Object.values(customerTypeAllowedRoles),
    VAPermissions.customerType
  )

  return (
    <div className="form-area">
      <form>
        <Row>
          {/* TYPE DOCUMENT COMERCIAL */}
          <Col md={{ span: 6, offset: 6 }}>
            <div className="form-group radio-group">
              {documentType.map((tipo, index) => (
                <div className="custom-control custom-radio mr-2" key={index}>
                  <input
                    type="radio"
                    id={`radiodocumento${index}`}
                    className="custom-control-input"
                    name="documentTypeId"
                    value={tipo.DocumentTypeId.toUpperCase()}
                    checked={
                      formikLegal.values?.documentTypeId.toUpperCase() ===
                      tipo.DocumentTypeId.toUpperCase()
                    }
                    onChange={formikLegal.handleChange}
                    onBlur={formikLegal.handleBlur}
                  />
                  <label
                    className="custom-control-label label-radio"
                    htmlFor={`radiodocumento${index}`}
                    style={{
                      marginRight: '15px',
                    }}>
                    {tipo.Name}
                  </label>
                </div>
              ))}
            </div>
            {formikLegal.touched.documentTypeId &&
              formikLegal.errors.documentTypeId && (
                <div className="input-feedback">
                  <strong>{formikLegal.errors.documentTypeId}</strong>
                </div>
              )}
          </Col>
          {/* COMERCIAL NAME */}
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className="form-group">
              <input
                type="text"
                name="legalName"
                className={
                  formikLegal.touched.legalName && formikLegal.errors.legalName
                    ? 'form-control error'
                    : 'form-control'
                }
                placeholder={t('CREATE_CLIENT_T.COMPANY_NAME')}
                value={formikLegal.values.legalName}
                onChange={e => {
                  const value = e.target.value
                  formikLegal.setFieldValue('legalName', value.toUpperCase())
                }}
                onBlur={formikLegal.handleBlur}
              />
            </div>
            <div className="input-feedback">
              {formikLegal.touched.legalName &&
                formikLegal.errors.legalName && (
                  <strong>
                    {t('CREATE_CLIENT_T.INTRODUCE_VALID_COMPANY_NAME')}{' '}
                  </strong>
                )}
            </div>
          </Col>

          {/* RUC */}
          <Col xl={5} lg={6} md={6} sm={6}>
            <div className="form-group">
              <input
                type="text"
                name="ruc"
                maxLength={20}
                className={
                  formikLegal.touched.ruc && formikLegal.errors.ruc
                    ? 'form-control error'
                    : 'form-control'
                }
                placeholder={t('CREATE_CLIENT_T.INTRODUCE_IDENT_PLACEHOLDER')}
                value={formikLegal.values.ruc}
                onChange={e => {
                  const value = e.target.value
                  formikLegal.setFieldValue('ruc', value.toUpperCase())
                }}
                onBlur={formikLegal.handleBlur}
              />
            </div>
            <div className="input-feedback">
              {formikLegal.touched.ruc && formikLegal.errors.ruc && (
                <strong>{formikLegal.errors.ruc}</strong>
              )}
            </div>
          </Col>
          {/* DV */}
          <Col xl={1} lg={6} md={6} sm={6}>
            <div className="form-group">
              <input
                type="text"
                name="dv"
                maxLength={2}
                className={
                  formikLegal.touched.dv && formikLegal.errors.dv
                    ? 'form-control error'
                    : 'form-control'
                }
                placeholder="DV"
                value={formikLegal.values.dv}
                onChange={e => {
                  const onlyNums = e.target.value.replace(/[^0-9]/g, '')
                  formikLegal.setFieldValue('dv', onlyNums)
                }}
                onBlur={formikLegal.handleBlur}
              />
            </div>
            <div className="input-feedback">
              {formikLegal.touched.dv && formikLegal.errors.dv && (
                <strong>{t('CREATE_CLIENT_T.INVALID_DV')}</strong>
              )}
            </div>
          </Col>
          {/* TIPOCLIENTE */}
          <Col xl={6} lg={6} md={6} sm={12}>
            {canUseCustomerType && (
              <DisplayCustomerGroup
                formik={formikLegal}
                valueStateSelectCustomerGroup={valueStateSelectCustomerGroup}
                setValueStateSelectCustomerGroup={
                  setValueStateSelectCustomerGroup
                }
              />
            )}
          </Col>
          {/* regexEmail */}
          <Col xl={6} lg={6} md={6} sm={12}>
            <div className="form-group">
              <input
                type="email"
                name="generalStore[0].generalEmail[0].address"
                className={
                  formikLegal.touched.hasOwnProperty('generalStore') &&
                  formikLegal.errors.hasOwnProperty('generalEmail')
                    ? formikLegal.errors.generalEmail[0].address
                      ? 'form-control error'
                      : 'form-control'
                    : 'form-control'
                }
                placeholder={t('CREATE_CLIENT_T.EMAIL_PLACEHOLDER')}
                value={
                  formikLegal.values?.generalStore[0]?.generalEmail[0]?.address
                }
                onChange={formikLegal.handleChange}
                onBlur={formikLegal.handleBlur}
              />
            </div>
            <div className="input-feedback">
              {formikLegal.touched.hasOwnProperty('generalStore') &&
                formikLegal.errors.hasOwnProperty('generalEmail') && (
                  <strong>{formikLegal.errors.generalEmail[0].address}</strong>
                )}
            </div>
          </Col>
          {/* TYPE PHONE NUMBER */}
          <Col xl={12} lg={12} md={12} sm={12}>
            <div className="form-group radio-group">
              {tipoTelefono.map((tipo, index) => (
                <div className="custom-control custom-radio mr-2" key={index}>
                  <input
                    type="radio"
                    id={`radiotipotelefonoJuridico${index}`}
                    className="custom-control-input"
                    name="generalStore[0].generalTelephone[0].telephoneTypeId"
                    value={tipo.TelephoneTypeId.toUpperCase()}
                    checked={
                      formikLegal.values?.generalStore[0]?.generalTelephone[0]?.telephoneTypeId?.toUpperCase() ===
                      tipo?.TelephoneTypeId?.toUpperCase()
                    }
                    onChange={formikLegal.handleChange}
                  />
                  <label
                    className="custom-control-label label-radio"
                    htmlFor={`radiotipotelefonoJuridico${index}`}
                    style={{
                      marginRight: '15px',
                    }}>
                    {tipo.Description}
                  </label>
                </div>
              ))}
            </div>
            {formikLegal.touched?.generalStore &&
              formikLegal.touched?.generalStore[0]?.generalTelephone &&
              formikLegal.touched?.generalStore[0]?.generalTelephone[0] &&
              formikLegal.errors?.telephoneTypeId && (
                <div className="input-feedback">
                  <strong>{formikLegal.errors?.telephoneTypeId}</strong>
                </div>
              )}
          </Col>
          {/* PHONENUMBER */}
          <Col lxl={6} lg={6} md={12} sm={12}>
            <div className="form-group">
              <input
                type="text"
                maxLength={8}
                name="generalStore[0].generalTelephone[0].number"
                className={
                  formikLegal.touched.hasOwnProperty('generalStore') &&
                  formikLegal.errors.hasOwnProperty('generalTelephone')
                    ? formikLegal.errors.generalTelephone[0]?.number
                      ? 'form-control'
                      : 'form-control error'
                    : 'form-control'
                }
                placeholder={t('CREATE_CLIENT_T.PHONE_NUMBER_PLACEHOLDER')}
                value={
                  formikLegal.values?.generalStore[0]?.generalTelephone[0]
                    ?.number
                }
                onChange={e => {
                  const onlyNums = e.target.value.replace(/[^0-9]/g, '')
                  formikLegal.setFieldValue(
                    'generalStore[0].generalTelephone[0].number',
                    onlyNums
                  )
                }}
                onBlur={formikLegal.handleBlur}
              />
            </div>
            <div className="input-feedback">
              {formikLegal.touched.hasOwnProperty('generalStore') &&
                formikLegal.errors.hasOwnProperty('generalTelephone') && (
                  <strong>
                    {formikLegal.errors?.generalTelephone[0]?.number}
                  </strong>
                )}
            </div>
          </Col>
          {/* LISTAS */}
          <Col xl={6} lg={6} md={6} sm={12}>
            {isCallCenter && (
              <DisplayListPrices
                setValueState={setValueState}
                valueState={valueState}
              />
            )}
          </Col>
          {/* MAPA DIRECCIÓN */}
          {/* DEBE MANTENERSE DE ESTA FORMA O SE PUEDE GENERAR UN ERROR EN CASO DE QUE OCURRA UNA RENDERIZACIÓN */}
          {!clientSelected?.edit && (
            <Col lg={12}>
              <NewCustomerMap
                setHasAddress={setHasAddress}
                hasAddress={hasAddress}
                setCountryCode={setCountryCode}
                setProvinceCode={setProvinceCode}
                setDistrictCode={setDistrictCode}
                setTownshipCode={setTownshipCode}
                personType={'JUR'}
                street={street}
                setStreet={setStreet}
                name={name}
                setName={setName}
                formik={formikLegal}
              />
            </Col>
          )}
          {/* RECIBIR PROMOCIONES */}
          <Col lg={12}>
            <div className="custom-control d-inline-block mr-2">
              <input
                type="checkbox"
                className="custom-control-input checkbox-input"
                name="receivePromotions"
                id="multicheckbox1"
                value={formikLegal.values.receivePromotions}
                checked={formikLegal.values.receivePromotions}
                onChange={formikLegal.handleChange}
              />
              <label className="custom-control-label" htmlFor="multicheckbox1">
                {t('CREATE_CLIENT_T.ADVERTISE_EMAIL_INFO')}{' '}
              </label>
            </div>
          </Col>
          <div className="section-buttons">
            <button
              disabled={loadingSave}
              type="button"
              onClick={formikLegal.handleSubmit}
              className="btn btn-primary form-submit buttons-save">
              {loadingSave ? (
                <CircularProgress size="2.5rem" />
              ) : !clientSelected?.edit ? (
                t('CREATE_CLIENT_T.CREATE_CLIENT_SAVE_BUTTON')
              ) : (
                t('CREATE_CLIENT_T.SAVE_BUTTON')
              )}
            </button>
          </div>
        </Row>
      </form>
    </div>
  )
}
