import React from 'react'
import { useSelector } from 'react-redux'
import { CochezTemplates } from './components/cochez/CochezTemplates'
import { NoveyTemplates } from './components/novey/NoveyTemplates'

export const REGULAR_CMF = 'REGULAR_CMF'
export const OFFER_CMF = 'OFFER_CMF'
export const REGULAR = 'REGULAR'
export const OFFER = 'OFFER'

export const Template = ({ products, i }) => {
  const { CompanyId } = useSelector(state => state.company)
  const isCochez =
    CompanyId?.toUpperCase() === 'AEE876CB-7183-4BEE-8FCA-984B7A1F6BA9'

  if (!isCochez) return <NoveyTemplates products={products} i={i} />
  if (isCochez) return <CochezTemplates products={products} i={i} />
  return null
}
