import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getListPricesAction } from 'redux/actions/clientsActions'
import { getCochezListPricesService } from 'redux/services/clientsServices'

export const usePriceListLogic = () => {
  const searchTextRef = useRef(null)

  const dispatch = useDispatch()
  const companyId = useSelector(state => state.company.CompanyId)

  const [listPrices, setListPrices] = useState([])
  const [allListPrices, setAllListPrices] = useState([])

  const searchListPrice = event => {
    const value = event.target.value.toLowerCase()
    const filteredList = allListPrices?.filter(list => {
      return (
        list.Description.toLowerCase().includes(value) ||
        list.Code?.toLowerCase().includes(value)
      )
    })
    setListPrices(filteredList)
  }

  const searchListPriceText = value => {
    const filteredList = allListPrices?.filter(list => {
      return (
        list.Description?.toLowerCase().includes(value) ||
        list.Code?.toLowerCase().includes(value)
      )
    })
    setListPrices(filteredList)
  }

  const fetchListPrices = async () => {
    const payload = {
      CompanyId: companyId,
      PriceListTypeId: '86912B5E-FF79-4D8D-8D3F-BF30C2C942FB',
      PageNumber: 1,
      PageSize: 999,
    }
    const listPricesData = await dispatch(getListPricesAction(payload))
    if (listPricesData.length > 0) {
      setAllListPrices(listPricesData)
      setListPrices(listPricesData)
    }
  }

  const fetchCochezListPrices = async () => {
    try {
      const { Data: data } = await getCochezListPricesService({
        Origin: 'VA',
        SearchText: '',
        PageNumber: 1,
        PageSize: 99,
      })
      if (data.length > 0) {
        const formatData = data.map(item => {
          return {
            PriceListId: item?.PriceListId,
            Description: item?.PriceListName,
          }
        })
        setAllListPrices(formatData)
        setListPrices(formatData)
      }
    } catch (error) {
      console.log('fetchCochezListPrices -> error', error)
    }
  }

  useEffect(() => {
    const priceListServiceByComapany = {
      'AEE876CB-7183-4BEE-8FCA-984B7A1F6BA9': fetchCochezListPrices,
      '652A83C8-F4A7-4041-A9B0-7971623F0DDA': fetchListPrices,
    }
    const actualCompanyId =
      companyId?.toUpperCase() || 'AEE876CB-7183-4BEE-8FCA-984B7A1F6BA9'

    priceListServiceByComapany[actualCompanyId]()
  }, [companyId])

  return {
    listPrices,
    searchListPrice,
    searchListPriceText,
    searchTextRef,
  }
}
