import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

function Pagination({
  firstIndex,
  lastIndex,
  currentPage,
  pageNums,
  setCurrentPage,
  setFirstIndex,
  setLastIndex,
  margin,
}) {
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
      setLastIndex(lastIndex - 1)
      setFirstIndex(firstIndex - 1)
    }
  }

  const handlePageClick = e => {
    const value = parseInt(e.target.value)
    setCurrentPage(value)
    setLastIndex(value + 2)
    setFirstIndex(value - 1)
  }

  const handleReturnFirst = () => {
    setCurrentPage(1)
    setLastIndex(3)
    setFirstIndex(0)
  }

  const handleForward = () => {
    setCurrentPage(lastIndex + 1)
    setFirstIndex(lastIndex)
    setLastIndex(lastIndex + 3)
  }

  const handleNextPage = () => {
    if (currentPage <= pageNums.length - 1) {
      setCurrentPage(currentPage + 1)
      setLastIndex(lastIndex + 1)
      setFirstIndex(firstIndex + 1)
    }
  }

  return (
    <>
      <div className="pagination" style={{ marginTop: margin }}>
        <FontAwesomeIcon
          className="table-buttons"
          onClick={handlePrevPage}
          icon={faChevronLeft}
        />
        <button
          className="page-regular"
          style={{ display: firstIndex > 0 ? 'block' : 'none' }}
          onClick={handleReturnFirst}>
          ...
        </button>
        {pageNums.slice(firstIndex, lastIndex).map(pageNum => {
          return (
            <button
              key={pageNum}
              className={
                currentPage === pageNum ? 'page-active' : 'page-regular'
              }
              value={pageNum}
              onClick={handlePageClick}>
              {pageNum}
            </button>
          )
        })}
        <button
          className="page-regular"
          style={{
            display: lastIndex <= pageNums.length - 1 ? 'block' : 'none',
          }}
          onClick={handleForward}>
          ...
        </button>
        <FontAwesomeIcon
          className="table-buttons"
          onClick={handleNextPage}
          icon={faChevronRight}
        />
      </div>
    </>
  )
}

export default Pagination
