import React, { useEffect } from 'react'
import './creditAccountsModal.css'
import { CustomerContext } from 'state/context/CustomerFormContext'
import { FormatMoney } from 'Utils/functions'
import no from '../../../../Assets/Images/Login/no.svg'
import search from '../../../../Assets/Images/BuscarCotizacion/search.svg'
import eyeCorrect from '../../../../Assets/Images/Eyes/correct.svg'
import eyeWrong from '../../../../Assets/Images/Eyes/wrong.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { validateAmountOnCommercialCreditServices } from 'redux/services/mdl14Services'
import { sleep } from 'Utils/Helpers/fakeTimers'

const companies = {
  '652A83C8-F4A7-4041-A9B0-7971623F0DDA': 'Novey', // 03
  'AEE876CB-7183-4BEE-8FCA-984B7A1F6BA9': 'Cochez', // 01
}
const companiesCode = {
  '652A83C8-F4A7-4041-A9B0-7971623F0DDA': '03', // Novey
  'AEE876CB-7183-4BEE-8FCA-984B7A1F6BA9': '01', // Cochez
}

export const CreditAccountsModal = ({ customerId }) => {
  const { creditAccounts, setShowCreditAccounts } = CustomerContext()
  const [textSearch, setTextSearch] = React.useState('')
  const [showClearSearch, setShowClearSearch] = React.useState(false)
  const [allCreditAccounts, setAllCreditAccounts] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [allDataToSearch, setAllDataToSearch] = React.useState([])

  const getAllCreditAccounts = async () => {
    try {
      setLoading(true)
      const allCreditAccounts = []
      const signatures = []
      for (const account of creditAccounts) {
        const companyCode =
          companiesCode[account.CompanyId.toUpperCase()] || '01'

        const {
          data: { Data },
        } = await validateAmountOnCommercialCreditServices({
          product: 'oracle-credit-commercial-customer',
          no_cia: companyCode,
          no_cliente: account.CreditAccountNumber,
        })
        const allImages = Data?.firmas
          ?.map(item => item?.imagen)
          ?.slice(0, creditAccounts?.length)
        if (signatures.length === 0 && allImages?.length !== 0) {
          for (const image of allImages) {
            const img = new Image()
            img.src = image
            img.onload = () => {
              signatures.push({ icon: eyeCorrect, url: image })
            }
            img.onerror = () => {
              signatures.push({ icon: eyeWrong, url: null })
            }
          }
        }
        if (allImages?.length <= 1) {
          await sleep(2000)
        }

        allCreditAccounts.push({
          ...Data?.cuentas?.[0],
          company: companies[account.CompanyId.toUpperCase()] || 'N/A',
        })
      }
      const newAllCreditAccounts = allCreditAccounts.map((item, index) => {
        return {
          ...item,
          signature: signatures[index] || { icon: eyeWrong, url: null },
        }
      })

      setAllCreditAccounts(newAllCreditAccounts)
      setAllDataToSearch(newAllCreditAccounts)
    } catch (error) {
      console.log('getAllCreditAccountsError -->', error)
    } finally {
      setLoading(false)
    }
  }

  const handleLocalSearch = textSearch => {
    const newAllCreditAccounts = allDataToSearch.filter(item => {
      return (
        item?.no_cliente
          ?.toString()
          ?.toLowerCase()
          .includes(textSearch.toLowerCase()) ||
        item?.nombre?.toLowerCase().includes(textSearch.toLowerCase()) ||
        item?.company?.toLowerCase().includes(textSearch.toLowerCase())
      )
    })
    setAllCreditAccounts(newAllCreditAccounts)
  }

  useEffect(() => {
    getAllCreditAccounts()
  }, [])

  return (
    <div className="customer-credits-account-modal-wrapper">
      <div className="new-table-wrapper">
        <div className="customer-credits-account-modal-header">
          <h2 className="customer-credits-account-modal-title">
            Cuentas crédito
          </h2>
          <img
            src={no}
            alt="close"
            className="customer-credits-account-modal-close"
            onClick={() => setShowCreditAccounts(false)}
          />
        </div>
        <div className="customer-credits-account-modal-search-wrapper">
          <div className="customer-credits-account-modal-search">
            <input
              type="text"
              placeholder="Filtrado de la lista"
              disabled={loading}
              value={textSearch}
              onChange={e => setTextSearch(e.target.value?.trim())}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  handleLocalSearch(textSearch)
                  setShowClearSearch(textSearch !== '')
                }
              }}
            />
            <div className="customer-credits-account-modal-search-icon">
              {loading ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="spinner seach-spinner"
                  style={{ fontSize: 20 }}
                />
              ) : (
                <img
                  src={search}
                  alt="search-icon"
                  onClick={() => {
                    handleLocalSearch(textSearch)
                    setShowClearSearch(textSearch !== '')
                  }}
                />
              )}
            </div>
          </div>
          {showClearSearch && (
            <button
              className="customer-credits-account-modal-button"
              onClick={() => {
                getAllCreditAccounts(customerId)
                setTextSearch('')
                setShowClearSearch(false)
              }}>
              Traer todos
            </button>
          )}
        </div>
        <div className="new-table-container" style={{ overflow: 'auto' }}>
          <table className="new-table-container-table">
            <thead>
              <tr>
                <th>Cuenta</th>
                <th>Descripción</th>
                <th>Compañía</th>
                <th>Límite de crédito</th>
                <th>Saldo adeudado</th>
                <th>Crédito caducado</th>
                <th>Cuenta exenta</th>
                <th>Firma autorizada</th>
              </tr>
            </thead>
            <tbody>
              {allCreditAccounts?.map(item => {
                return (
                  <tr className="new-table-content-row" key={item.no_cliente}>
                    <td className="new-table-content-col">{item.no_cliente}</td>
                    <td className="new-table-content-col">{item.nombre}</td>
                    <td className="new-table-content-col">{item?.company}</td>
                    <td className="new-table-content-col">
                      {'$' + FormatMoney(item?.limite, true, 2)}
                    </td>
                    <td className="new-table-content-col">
                      {'$' + FormatMoney(item?.saldo, true, 2)}
                    </td>
                    <td className="new-table-content-col">
                      {item?.bloqueado && (
                        <p className="single-product-cart-a-badge yes">Si</p>
                      )}
                      {!item?.bloqueado && (
                        <p className="single-product-cart-a-badge no">No</p>
                      )}
                    </td>
                    <td className="new-table-content-col">
                      {item?.exento !== 'N' && (
                        <p className="single-product-cart-a-badge yes">Si</p>
                      )}
                      {item?.exento === 'N' && (
                        <p className="single-product-cart-a-badge no">No</p>
                      )}
                    </td>
                    <td className="new-table-content-col">
                      {item?.signature?.url && (
                        <a
                          href={item?.signature?.url}
                          style={{ cursor: 'pointer' }}
                          target="_blank"
                          rel="noreferrer">
                          <img src={item?.signature?.icon} alt="signature" />
                        </a>
                      )}
                      {!item?.signature?.url && (
                        <img src={item?.signature?.icon} alt="signature" />
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          {!allCreditAccounts?.length && !loading && (
            <div className="customer-credits-account-modal-empty">
              No se encontraron registros.
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
