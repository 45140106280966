// @ts-nocheck
import { useSelector } from 'react-redux'

export default function useValidatePermission(searchVAPermission) {
  const userRoles = useSelector(
    state => state.login?.user?.AuthenticationInfo.UserRoles
  )

  for (let i = 0; i < userRoles.length; i++) {
    const role = userRoles[i]

    const hasVAPermision = role.Permissions.some(
      permission => permission.Value === searchVAPermission
    )

    if (hasVAPermision) return true
  }

  return false
}
