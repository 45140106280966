import { getDateFormatted } from 'Utils/functions'
import { getPromotionService } from 'redux/services/promotionServices'

export const getPromotions = () => {
  return async (dispatch, getState) => {
    const { company } = getState()
    const payload = {
      Companies: [
        {
          CompanyId: company.CompanyId,
        },
      ],
      PromotionId: null,
      WarehouseId: company.WarehouseId,
      SearchText: null,
      Status: true,
      PageNumber: 1,
      PageSize: 20,
      StartDate: getDateFormatted(new Date()),
      EndDate: getDateFormatted(new Date()),
    }
    try {
      const response = await getPromotionService(payload)
      const { data, status } = response
      if (status === 200 && data?.Data) {
        return data.Data
      }
    } catch (err) {}
  }
}
