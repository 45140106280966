import React, { useState, useEffect } from 'react'
import no from '../../Assets/Images/Login/no.svg'
import './modalCancelPendingDispatch.css'
import { Formik, Field, Form } from 'formik'
import { t } from 'i18next'
import { Modal } from '@mui/material'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { getReasonCancelDispatch } from 'redux/actions/sellOrderActions'

const ModalCancelPendingDispatch = ({
  openModalCancelPendingDispatchState,
  setOpenModalCancelPendingDispatchState,
  processCancelDispatch,
  setCancelDispatchProductSelected,
  saveCancelDispatch,
}) => {
  const [showCancelDispExtraFields, setShowCancelDispExtraFields] =
    useState(true)
  const [errorCancelDisp, setErrorCancelDisp] = useState(null)
  const [showReasonDropdown, setShowReasonDropdown] = useState(false)
  const [dropdownSelectedState, setDropdownSelectedState] = useState({})
  const [reasonCancelDispatch, setReasonCancelDispatch] = useState([])
  const dispatch = useDispatch()

  const closeModalConfirmStartSalesOrder = () => {
    setOpenModalCancelPendingDispatchState(false)
    cleanAll()
  }

  const renderGenericMultipleSelectForm = (
    title,
    showState,
    setShowState,
    valueState,
    setValueState,
    mappedInfo,
    placeholder,
    controlValue
  ) => {
    return (
      <Row className="no-margin-nopadding cancel-dispatch-father-container">
        <div className="cancel-dispatch-title-text">{title}</div>
        <Col className="no-margin-nopadding cancel-dispatch-search-filter">
          <div
            className="cancel-dispatch-filter-content"
            onClick={() => setShowState(!showState)}>
            <h2
              className={
                valueState
                  ? 'cancel-dispatch-label-text'
                  : 'cancel-dispatch-label-placeholder'
              }>
              {valueState ? valueState.Description : placeholder}
            </h2>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
          <ul
            className={
              showState
                ? 'cancel-dispatch-filter-options'
                : 'cancel-dispatch-filter-options-hidden'
            }>
            {mappedInfo.map((item, index) => {
              return (
                <li
                  className="cancel-dispatch-filter-option"
                  key={item.ReasonId}>
                  <input
                    type="radio"
                    className="radio"
                    id={item.ReasonId}
                    name={item.Name}
                    value={item.ReasonId}
                    onClick={values => {
                      setValueState(item)
                      setShowState(false)
                    }}
                  />
                  <label htmlFor={item.ReasonId}>{item.Description}</label>
                </li>
              )
            })}
          </ul>
        </Col>
        {errorCancelDisp && errorCancelDisp !== '' && (
          <div className="cancel-dispatch-field-error-msg">
            {errorCancelDisp}
          </div>
        )}
      </Row>
    )
  }

  const handleCancelDispRadioSelected = (isCancelDisp, values) => {
    if (!isCancelDisp) {
      values.accountNumber = ''
      values.orderNumber = ''
    }

    setShowCancelDispExtraFields(isCancelDisp)
  }

  function validateMotive(value) {
    if (!value?.Description) {
      setErrorCancelDisp(t('CANCEL_DISPATCH_MODAL.REQUIRED_CAMP'))
      return false
    }

    setErrorCancelDisp(null)
    return true
  }

  const cleanAll = () => {
    setShowCancelDispExtraFields(true)
    setErrorCancelDisp(null)
    setShowReasonDropdown(false)
    setDropdownSelectedState({})
    setCancelDispatchProductSelected({})
  }

  const validateSalesOrder = values => {
    let isValid = true

    if (showCancelDispExtraFields) {
      isValid = isValid && validateMotive(dropdownSelectedState)
      if (isValid) {
        saveCancelDispatch(dropdownSelectedState.ReasonId)
      }
    }
    if (!isValid) return
    processCancelDispatch(false)

    cleanAll()
  }

  const getReasonCancelDispatchService = async () => {
    const response = await dispatch(getReasonCancelDispatch())
    if (response) {
      setReasonCancelDispatch(response)
    }
  }

  useEffect(() => {
    getReasonCancelDispatchService()
  }, [])

  return (
    <Modal open={openModalCancelPendingDispatchState}>
      <div className="cancel-dispatch-modal-box-generate-sales-order">
        <div className="cancel-dispatch-modal-box-generate-sales-order-container">
          <div className="cancel-dispatch-confirmation-header">
            <span
              onClick={closeModalConfirmStartSalesOrder}
              className="cancel-dispatch-close-modal">
              <img src={no} alt="close" />
            </span>
          </div>
          <div className="cancel-dispatch-content-container">
            <Formik
              initialValues={{
                cancel_dispatch: 'yes',
                direct: '',
                orderNumber: '',
                accountNumber: '',
                file: '',
              }}
              onSubmit={async values => {
                validateSalesOrder(values)
              }}>
              {({ values }) => (
                <Form>
                  <div
                    id="radio-cancel-dispatch"
                    className="cancel-dispatch-form-field-title">
                    {t('CANCEL_DISPATCH_MODAL.YOU_SURE_TITLE')}
                  </div>
                  <div
                    role="group"
                    aria-labelledby="radio-cancel-dispatch"
                    className="cancel-dispatch-mt-2">
                    <label className="cancel-dispatch-form-field-option">
                      <Field
                        type="radio"
                        name="cancel_dispatch"
                        value="yes"
                        onClick={() =>
                          handleCancelDispRadioSelected(true, values)
                        }
                      />
                      <span>{t('CANCEL_DISPATCH_MODAL.YES_OPTION_LABEL')}</span>
                    </label>
                    <label className="cancel-dispatch-form-field-option ms-5">
                      <Field
                        type="radio"
                        name="cancel_dispatch"
                        value="no"
                        onClick={() =>
                          handleCancelDispRadioSelected(false, values)
                        }
                      />
                      <span>{t('CANCEL_DISPATCH_MODAL.NO_OPTION_LABEL')}</span>
                    </label>
                  </div>

                  {showCancelDispExtraFields && (
                    <div
                      className="cancel-dispatch-form-field-multiselect-container"
                      id="cancel-dispatch-extra-fields">
                      {renderGenericMultipleSelectForm(
                        t('CANCEL_DISPATCH_MODAL.REASON_CANCEL_TITLE'),
                        showReasonDropdown,
                        setShowReasonDropdown,
                        dropdownSelectedState,
                        setDropdownSelectedState,
                        reasonCancelDispatch,
                        t('CANCEL_DISPATCH_MODAL.REASON_PLACEHOLDER'),
                        'DEL',
                        cleanAll
                      )}
                    </div>
                  )}

                  <div className="cancel-dispatch-modal-buttons">
                    <button
                      type="submit"
                      className="cancel-dispatch-btn cancel-dispatch-modal-si">
                      {t('CANCEL_DISPATCH_MODAL.CONTINUE_BUTTON_LABEL')}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalCancelPendingDispatch
