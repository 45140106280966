/* eslint-disable no-useless-catch */
import { calculatorServices } from 'redux/services/mdl14Services'

export const calculatorAction = (payload, callBack) => async () => {
  try {
    const response = await calculatorServices(payload)
    const { data, status } = response
    if (status === 200 && data) {
      callBack(data)
    }
  } catch (err) {
    throw err
  }
}
