import { customerTypeId } from 'Utils/constants'

export const getFormatedClient = userData => {
  const isNaturalClient =
    userData?.CustomerType?.CustomerTypeId.toLowerCase() ===
    customerTypeId.natural.toLowerCase()
  const formatedNaturalClient = {
    tenant: 2,
    aludraAPP: true,
    firstName: userData?.CustomerInfo?.FirstName || '',
    lastName: userData?.CustomerInfo?.LastName || '',
    fullName: userData?.CustomerInfo?.FullName || '',
    idNumber: userData?.CustomerInfo?.IDNumber || '',
    birthdate: userData?.CustomerInfo?.Birthdate || '',
    cP_Passport: userData?.CustomerInfo?.IDNumber || '',
    gender: userData?.Gender?.Description || '',
    genderId: userData?.Gender?.GenderId || '',
    DV: userData?.CustomerInfo?.DV || '',
    nationalityId: userData?.CustomerInfo?.NationalityId || '',
    documentTypeId: userData?.CustomerInfo?.DocumentTypeId || '',
    customerTypeId: userData?.CustomerInfo?.CustomerTypeId || '',
    customerId: userData?.CustomerInfo?.CustomerId || '',
    generalTelephone: [
      {
        telephoneId:
          userData?.TelephoneList?.[0]?.TelephoneInfo?.TelephoneId || '',
        number: userData?.TelephoneList?.[0]?.TelephoneInfo?.Number || '',
        telephoneTypeId:
          userData?.TelephoneList?.[0]?.TelephoneInfo?.TelephoneTypeId || '',
      },
    ],
    generalEmail: [
      {
        emailId: userData?.EmailList?.[0]?.EmailInfo?.EmailId || '',
        address: userData?.EmailList?.[0]?.EmailInfo?.Address || '',
        emailTypeId: userData?.EmailList?.[0]?.EmailInfo?.EmailTypeId || '',
        defaultEmail: true,
      },
    ],
    generalGroup: [
      { customerGroupId: userData?.CustomerGroupList?.[0]?.CustomerGroupId },
    ],
    rolesName: ['CUSTOMER'],
  }
  const formatedLegalClient = {
    tenant: userData?.AdministrativeStoreInfo
      ? userData?.CustomerInfo?.SaaSId
      : 0,
    aludraAPP: true,
    customerId: userData?.CustomerInfo?.CustomerId || '',
    country: 'panama',
    email: userData?.CustomerInfo?.Email || '',
    customerTypeId: userData?.CustomerType?.CustomerTypeId || '',
    customerStatus: 'active',
    customerType: userData?.CustomerType?.Description || '',
    ruc: userData?.CustomerInfo?.RUC || '',
    dv: userData?.CustomerInfo?.DV || '',
    documentTypeId: userData?.CustomerInfo?.DocumentTypeId || '',
    legalName: userData?.CustomerInfo?.LegalName || '',
    comercialName: userData?.CustomerInfo?.ComercialName || '',
    generalStore: [
      {
        StoreId: userData?.AdministrativeStoreInfo?.StoreInfo?.StoreId || '',
        AdministrativeLocation: true,
        generalTelephone: [
          {
            telephoneId:
              userData?.AdministrativeStoreInfo?.TelephoneList?.[0]
                ?.TelephoneInfo?.TelephoneId || '',
            number:
              userData?.AdministrativeStoreInfo?.TelephoneList?.[0]
                ?.TelephoneInfo?.Number || '',
            telephoneTypeId:
              userData?.AdministrativeStoreInfo?.TelephoneList?.[0]
                ?.TelephoneInfo?.TelephoneTypeId || '',
            defaultMobile: true,
          },
        ],
        generalEmail: [
          {
            emailId:
              userData?.AdministrativeStoreInfo?.EmailList?.[0]?.EmailInfo
                ?.EmailId || '',
            address:
              userData?.AdministrativeStoreInfo?.EmailList?.[0]?.EmailInfo
                ?.Address || '',
            emailTypeId:
              userData?.AdministrativeStoreInfo?.EmailList?.[0]?.EmailInfo
                ?.EmailTypeId || '',
          },
        ],
        name: userData?.CustomerInfo?.LegalName || '',
      },
    ],
    generalGroup: [
      { customerGroupId: userData?.CustomerGroupList?.[0]?.CustomerGroupId },
    ],
    rolesName: ['CUSTOMER'],
  }
  const formatedClient = isNaturalClient
    ? formatedNaturalClient
    : formatedLegalClient

  return formatedClient
}
