import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { images } from 'Assets/Images'
import React, { useEffect } from 'react'
import {
  CheckCircleFill,
  PencilSquare,
  XCircleFill,
} from 'react-bootstrap-icons'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { useGetCmfCredit } from '../../../hooks/useGetCmfCredit'
const DEFAULT_VALUE =
  'La tarjeta de crédito CMF es emitida por CM Financiera, S.A., sujeto a previa aprobación de esa entidad. Para más información ingresa a cmfinanciera.com. Cuotas calculadas con tasa de interés nominal del 57% anual. La responsabilidad de CM Financiera se limita exclusivamente a aspectos propios del crédito otorgado y no por los productos o servicios adquiridos mediante el uso del crédito.'

export const Square2CCmf = ({ products, i }) => {
  const { currentPage, allTemplatesContent, setAllTemplatesContent } =
    usePrinterContext()

  const currentContent = allTemplatesContent.find(
    content => content.page === currentPage
  )
  const currentTerms = currentContent?.content?.terms || DEFAULT_VALUE

  const { fetchCmfCredit, loading } = useGetCmfCredit()
  const [isEditing, setIsEditing] = React.useState(false)
  const [terms, setTerms] = React.useState(currentTerms)

  const prod1 = products?.content?.products?.[0]
  const prod2 = products?.content?.products?.[1] || {}

  const handleEditTerms = () => {
    const updatedContent = allTemplatesContent.map(content => {
      if (content.page === currentPage) {
        return {
          ...content,
          content: {
            ...content.content,
            terms,
          },
        }
      }
      return content
    })
    setAllTemplatesContent(updatedContent)
    setIsEditing(false)
  }

  useEffect(() => {
    if (prod1?.Sku && !prod1?.cmfCredit) {
      fetchCmfCredit(prod1?.BasePrice, prod1?.Sku)
    }
    if (prod2?.Sku && !prod2?.cmfCredit) {
      fetchCmfCredit(prod2?.BasePrice, prod2?.Sku)
    }
  }, [prod1?.Sku, prod2?.Sku])

  return (
    <div
      key={products.page}
      id="printer-preview-left-canvas-square-2"
      className="printer-preview-left-canvas-square-2">
      <div
        className="printer-preview-left-canvas-square-2-box"
        style={{
          height: '100%',
          top: '0',
        }}>
        <div className="printer-preview-left-content-body-square-2">
          <p className="printer-preview-left-content-body-square-2-title">
            {prod1?.ProductName || ''}
          </p>
          <p className="printer-preview-left-content-body-square-2-sku">
            {prod1?.Sku || ''}
          </p>
          {prod1?.BasePrice && (
            <div
              className="printer-preview-left-content-body-square-2-price-wrapper"
              style={{ marginTop: '-10px' }}>
              <p
                className="printer-preview-mini-text-square-2"
                style={{ color: '#000' }}>
                $
              </p>
              <p
                className="printer-preview-left-content-body-square-2-price"
                style={{ color: '#000' }}>
                {prod1?.BasePrice?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p
                className="printer-preview-mini-text-square-2"
                style={{ color: '#000' }}>
                {prod1?.BasePrice?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
        </div>
        <div className="printer-preview-left-content-footer-square-2">
          <div className="printer-preview-left-content-footer-square-2-quotes">
            <p></p>
            <p
              className="printer-preview-left-content-footer-square-2-quote-text"
              style={{ color: '#000' }}>
              36 <br /> cuotas
            </p>
            <p
              className="printer-preview-left-content-footer-square-2-quote-text"
              style={{ color: '#000' }}>
              {!loading && '$' + (prod1?.cmfCredit || '')}
              {loading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="spinner"
                  style={{ width: 20 }}
                />
              )}
            </p>
          </div>
          <div className="printer-preview-content-footer-input-wrapper">
            <textarea
              className="printer-preview-left-content-footer-square-2-legal"
              type="text"
              rows={3}
              value={terms}
              disabled={!isEditing}
              onChange={e => setTerms(e.target.value)}
              style={{ border: isEditing ? '1px solid #000' : 'none' }}
            />
            <div className="printer-terms-edit-wrapper-1">
              {isEditing && (
                <CheckCircleFill
                  size={14}
                  onClick={handleEditTerms}
                  className="printer-preview-content-check"
                />
              )}
              {isEditing && (
                <XCircleFill
                  size={14}
                  onClick={() => {
                    setIsEditing(false)
                    setTerms(DEFAULT_VALUE)
                  }}
                  className="printer-preview-content-circle"
                />
              )}
              {!isEditing && (
                <PencilSquare
                  size={14}
                  onClick={() => setIsEditing(true)}
                  className="printer-preview-content-pencil"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="printer-preview-left-canvas-square-2-box"
        style={{
          height: '50%',
          top: '50%',
          // background: 'green',
        }}>
        <div className="printer-preview-left-content-body-square-2-2">
          <p className="printer-preview-left-content-body-square-2-title-2">
            {prod2?.ProductName || ''}
          </p>
          <p className="printer-preview-left-content-body-square-2-sku-2">
            {prod2?.Sku || ''}
          </p>
          {prod2?.BasePrice && (
            <div
              className="printer-preview-left-content-body-square-2-price-wrapper"
              style={{ marginTop: '-10px' }}>
              <p
                className="printer-preview-mini-text-square-2"
                style={{ color: '#000' }}>
                $
              </p>
              <p
                className="printer-preview-left-content-body-square-2-price-2"
                style={{ color: '#000' }}>
                {prod2?.BasePrice?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p
                className="printer-preview-mini-text-square-2"
                style={{ color: '#000' }}>
                {prod2?.BasePrice?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
        </div>
        <div className="printer-preview-left-content-footer-square-2-2">
          <div className="printer-preview-left-content-footer-square-2-quotes">
            <p></p>
            <p
              className="printer-preview-left-content-footer-square-2-quote-text"
              style={{ color: '#000' }}>
              36 <br /> cuotas
            </p>
            <p
              className="printer-preview-left-content-footer-square-2-quote-text"
              style={{ color: '#000' }}>
              {!loading && '$' + (prod2?.cmfCredit || '')}
              {loading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="spinner"
                  style={{ width: 20 }}
                />
              )}
            </p>
          </div>
          <div className="printer-preview-content-footer-input-wrapper">
            <textarea
              className="printer-preview-left-content-footer-square-2-legal"
              type="text"
              rows={3}
              value={terms}
              disabled={!isEditing}
              onChange={e => setTerms(e.target.value)}
              style={{ border: isEditing ? '1px solid #000' : 'none' }}
            />
            <div className="printer-terms-edit-wrapper-1">
              {isEditing && (
                <CheckCircleFill
                  onClick={handleEditTerms}
                  size={14}
                  className="printer-preview-content-check"
                />
              )}
              {isEditing && (
                <XCircleFill
                  size={14}
                  onClick={() => {
                    setIsEditing(false)
                    setTerms(DEFAULT_VALUE)
                  }}
                  className="printer-preview-content-circle"
                />
              )}
              {!isEditing && (
                <PencilSquare
                  size={14}
                  onClick={() => setIsEditing(true)}
                  className="printer-preview-content-pencil"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <img
        className="printer-preview-left-image"
        src={images.printer.templates.cochez.cmf2}
        alt="template-preview"
      />
    </div>
  )
}
